<template>
  <div class="input-wrapper date-time-wrapper">
    <label class="label filled-input">
      {{ placeHolder }}</label>
    <input type="date" class="input" v-model="internDate" :required="required" v-on:change="dateTimeChanged">
    <input type="time" class="input" v-model="internTime" :required="required" v-on:change="dateTimeChanged">
  </div>

</template>

<script>
import moment from "moment";
import {isDefined} from "@/utils";

export default {
  name: "CustomDateTimePicker",
  data() {
    return {
      internDate: undefined,
      internTime: undefined,
      dateTimeFormat: "YYYY-MM-DD HH:mm",
      dateFormat: "YYYY-MM-DD",
      timeFormat: "HH:mm"
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        if (isDefined(newVal)) {
          const date = moment(newVal, this.dateTimeFormat).format(this.dateFormat);
          const time = moment(newVal, this.dateTimeFormat).format(this.timeFormat);
          this.internDate = date;
          this.internTime = time;
        }
      }
    }
  },
  props: {
    value: {
      required: true
    },
    placeHolder: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    dateTimeChanged() {


      if (!this.internDate) {
        this.internDate = moment().format(this.dateFormat);
      }

      if (!this.internTime) {
        this.internTime = moment().format(this.timeFormat);
      }

      const newDateTime = moment(this.internDate).toDate();
      const timeAsDate = moment(this.internTime, this.timeFormat).toDate();
      newDateTime.setHours(timeAsDate.getHours());
      newDateTime.setMinutes(timeAsDate.getMinutes());

      this.$emit('update:value', newDateTime.format(this.dateTimeFormat));

    }
  }
}
</script>

<style scoped lang="less">

.date-time-wrapper {
  width: 100% !important;
  display: flex;
  flex-direction: row;
}

</style>
