<template>
  <div class="main">
    <div v-visibility-change="visibilityChange"></div>

    <div class="side-header">{{ confirmedOnly ? $t('your-chats') : $t('contact-requests') }}</div>

    <div class="search-input" :class="$mq" v-if="$mq !== 'mobile' || !chatOpen">
      <input v-model="searchValue" v-on:input="search" type="text" :placeholder="$t('search')">
      <i class="fal fa-search fa-lg"></i>
    </div>

    <div class="chats vld-parent">

      <loading :active.sync="initialLoading"
               :is-full-page="false"
               color="var(--primary-color)"></loading>
      <div class="empty-chat-wrapper" v-if="chats.length === 0 && !initialLoading">
        <h3> {{ confirmedOnly ? $t("no-chat-users-available") : $t('no-contact-requests-available') }}</h3>
      </div>
      <div class="chatcontainer" :class="$mq" v-else-if="!initialLoading">
        <perfect-scrollbar ref="scrolluser" :options="scrollOptions" style="height:100%">
          <ul ref="chatuserlist" class="chatuserlist">
            <li ref="chatusers" :class="{active:selectedUser && chat.id == selectedUser.id}" class="chatbox"
                v-for="chat in chats"
                v-if="$mq !== 'mobile' || !chatOpen || (selectedUser && chat.id == selectedUser.id)">
              <a class="overlaylink" @click="changeUser(chat)"></a>
              <div class="overlaycontent">
                <div v-if="$mq === 'mobile' && chatOpen" class="backcontainer"><i
                    class="fal fa-chevron-left fa-2x"></i></div>
                <div class="imgcontainer">
                  <img :src="getImage(chat)">
                  <div v-if="chat.unread_messages || (chat.contactUrl && !splitChatAndContactRequests)"
                       class="badgecontainer"><span
                      class="badge"> {{ chat.unread_messages > 0 ? chat.unread_messages : "!" }} </span></div>
                </div>
                <div>
                  <div class="chatname">{{ chat.fullName }}</div>
                  <div class="chatposition">{{ chat.position }}</div>
                  <div class="chatorganization">{{ chat.organization }}</div>
                </div>
                <div
                    v-if="($mq !== 'mobile' && selectedUser && chat.id == selectedUser.id) || ($mq === 'mobile' && !chatOpen)"
                    class="arrowcontainer"><i
                    class="fal fa-chevron-right fa-2x"></i></div>
              </div>
            </li>
          </ul>
        </perfect-scrollbar>
        <div class="messaging vld-parent" id="megs_cont" v-if="$mq !== 'mobile' || chatOpen">

          <div style="position:relative;overflow:hidden;height:100%;">
            <div class="chatlistwrapper" style="overflow:hidden;">
              <loading :active.sync="isLoading"
                       :is-full-page="false"
                       color="var(--primary-color)"></loading>
              <perfect-scrollbar ref="scroll_chat" :options="scrollOptions" style="height:100%">
                <ul id="chatlist" class="chatlist">
                  <li class="msgbox" v-for="msg in messages">
                    <div v-if="getTimeline(msg)" class="notification">
                      <div class="pending">
                        <span class="p_spam">{{ getNotification(msg) }}</span>
                      </div>
                    </div>
                    <div v-if="msg.sender_id == selectedUser.id" :id="getMessageId(msg)">
<!--                      <div class="incoming_msg_img">-->
<!--                        <img v-if="getThumbnail(selectedUser)" :src="getThumbnail(selectedUser)" :alt="getInitials(selectedUser)" class="img">-->
<!--                        <p v-else>{{ getInitials(selectedUser) }}</p>-->
<!--                      </div>-->
                      <div class="received_msg">
                        <div class="received_withd_msg"><span class="msg_arrow_l"></span>
                          <div class="time_date">{{ getTimestamp(msg) }}</div>
                          <div :class="{contact_request:!msg.message}" v-html="escapeHtml(getMessage(msg))"></div>
                        </div>
                      </div>
                    </div>
                    <div v-else :id="getMessageId(msg)" class="outgoing_msg">
                      <div class="sent_msg">
                        <span class="msg_arrow"></span>
                        <div class="time_date">{{ getTimestamp(msg) }}<span class="status"
                                                                          :id="getStatusId(msg)">{{
                            getStatus(msg)
                          }}</span>
                        </div>
                        <div :class="{contact_request:!msg.message}" v-html="escapeHtml(getMessage(msg))"></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </perfect-scrollbar>
            </div>
            <VEmojiPicker ref="emoji_picker" @select="selectEmoji" :i18n="emojiI18n" :localStoragePrefix="localStorageKeyPrefix()" :writeInLocalStorage="true" />
          </div>
          <div class="type_msg" id="footer">
            <div class="input_msg_write">
              <div class="input_msg_text_area">
                <div style="min-height:42px;">
                <textarea @input="countCharactersLeft($event.target);" @keydown="handleTextInput($event);"
                          ref="message_input" id="message_input" rows="1" data-min-rows='1' data-max-rows='4'
                          type="text" class="write_msg" :placeholder="$t('chat-input-placeholder')" maxlength="1000"
                          style="overflow:auto"></textarea>
                </div>
                <div ref="text_word_count_container" id="text_word_count_container">{{ $t('chat-chars-left') }} <span
                    ref="word_count" id="word_count">1000</span>
                </div>
              </div>
              <div class="emoji-toggle">
                <a @click="toggle_emojis()">
                  <i class="fal fa-smile-plus fa-2x"></i>
                </a>
              </div>
              <div ref="message_send_button" id="message_send_button" class="input_msg_button">
                <div class="msg_send_btn" @click="sendMessage()">
                  <img src="../assets/icons/chat_send_message_icon.png"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat_side_menu" v-if="$mq !== 'mobile' || chatOpen">
          <div class="chat_side_menu_button"><a @click="openProfileDialog(selectedUser,false)"><i
              class="fal fa-user-alt"></i>
            <div>{{ $t('to-profile') }}</div></a></div>
          <div v-if="videoChatEnabled(selectedUser)" class="chat_side_menu_button">
            <a @click="goToVideoChat()">
              <i class="fal fa-video"></i><!-- img :src="getVideoChatImage()" -->
              <div>{{ $t('video-chat') }}</div>
            </a>
          </div>
          <div v-if="selectedUser && selectedUser.contactUrl" class="chat_side_menu_button">
            <a @click="confirmContact(selectedUser)">
              <i class="fal fa-envelope"></i>
              <div>{{ $t('profile-accept-contact') }}</div>
            </a>
          </div>
          <div v-if="selectedUser && selectedUser.contactUrl" class="chat_side_menu_button">
            <a @click="ignoreContact(selectedUser)">
              <i class="fal fa-times-circle"></i>
              <div>{{ $t('profile-ignore-contact') }}</div>
            </a>
          </div>
          <div v-if="$mq === 'mobile' && chatOpen" class="chat_side_menu_button">
            <a @click="changeUser(selectedUser)">
              <i class="fal fa-long-arrow-left"></i>
              <div>{{ $t('back') }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  addMessageCallback,
  deleteMessageCallback,
  isNotificationAllowed,
  isNotificationDefault,
  isNotificationDenied,
  isNotificationSupported,
  register,
  updateUnreadMessages
} from '@/push-backend';
import backend from '../mm-backend'
import {uuid} from 'vue-uuid';
import {openDB} from 'idb';
import visibility from 'vue-visibility-change';
import localStorageHelper from "../localstorage/local-storage";
import matomo from "../matomo/tracker";
import videoChatImage from '@/assets/img/videochat.png';
import VEmojiPicker from 'v-emoji-picker';
import axios from "axios";
import {eventBus, logger} from "@/main";
import {chatMixIn} from "@/mixins/chat-mixin";
import {isDefined} from "@/utils";
import {User} from "@/entities/User";

Vue.use(visibility);

export default {
  mixins: [chatMixIn],
  data() {
    return {
      deviceId: localStorageHelper.getDeviceId(),
      chats: [],
      readMessages: [],
      selectedUser: undefined,
      chatOpen: false,
      chatOpenScrollPosition: undefined,
      isLoading: false,
      confirmedOnly: undefined,
      searchValue: undefined,
      searchTimer: undefined,
      requestCancelTokensSearch: [],
      splitChatAndContactRequests: this.$splitChatAndContactRequests
    }
  }, beforeMount() {
    this.confirmedOnly = this.$route.path !== '/contact-requests' && this.splitChatAndContactRequests;
  },
  mounted() {
    this.get();
    this.checkIfPushIsEnabled();
    addMessageCallback(this.handlePush);
    this.$root.$on('chat_msg', this.onChatMsg);
    this.$root.$on('chat_status', this.onChatStatus);
  },
  beforeDestroy() {
    deleteMessageCallback(this.handlePush);
    this.$root.$off('chat_status', this.onChatStatus);
    this.$root.$off('chat_msg', this.onChatMsg);
  },
  methods: {
    get() {
      const self = this;
      this.isLoading = true;

      backend.getChatUsers(this.confirmedOnly, this.searchValue, self.$route.params.userId).then(function (resp) {
        self.chats = resp.data.users;
        eventBus.$emit('update-chat-users-count', {
          unread_messages: resp.data.unread_messages,
          contact_requests: resp.data.contact_requests
        })
        matomo.trackChatList();
        if (self.chats.length > 0) {
          if (self.$route.params.userId) {
            const userById = self.chats.find(chat => chat.id === +self.$route.params.userId);
            self.loadMessages(userById ? userById : self.chats[0]); // fallback
          } else {
            self.loadMessages(self.chats[0]);
          }
          updateUnreadMessages(self.getUnreadMessages());
        } else {
          self.initialLoading = false;
        }
      }, error => {
        self.initialLoading = false;
      })
    },

    onChatMsg(msg) {
      // console.log('chat message received: ' + JSON.stringify(msg));
      if (msg.sender_id && this.selectedUser && msg.sender_id == this.selectedUser.id) {
        this.loadMessage(this.selectedUser, msg.id);
      } else {
        this.loadChats();
      }
    },

    onChatStatus(status) {
      // console.log('chat status changed: ' + JSON.stringify(status));
      if (status.sender_id && this.selectedUser && status.sender_id == this.selectedUser.id) {
        if (status.id_read_list) {
          const unknown = this.markMessagesRead(status.id_read_list);
          Array.prototype.push.apply(this.readMessages, unknown);
        }
      }
    },

    markMessagesRead(id_read_list) {
      let unknown = [];
      id_read_list.forEach(msgId => {
        const index = this.messages.findIndex(msg => msg.id === msgId);
        if (index >= 0) {
          this.messages[index].read = '1';
          Vue.set(this.messages, index, this.messages[index]);
        } else {
          unknown.push(msgId);
        }
      });
      return unknown;
    },

    search() {
      window.clearTimeout(this.searchTimer);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      for (let source of this.requestCancelTokensSearch) {
        source.cancel('Request Canceled');
      }
      this.requestCancelTokensSearch.push(source);
      let self = this;
      this.searchTimer = window.setTimeout(function () {
        self.isLoading = true;
        backend.getChatUsers(self.confirmedOnly, self.searchValue, null, source.token).then(function (resp) {
          self.chats = resp.data.users;
          if (self.chats.length > 0) {
            if (self.$route.params.userId) {
              const userById = self.chats.find(chat => chat.id === +self.$route.params.userId);
              self.loadMessages(userById ? userById : self.chats[0]); // fallback
            } else {
              self.loadMessages(self.chats[0]);
            }
            updateUnreadMessages(self.getUnreadMessages());
          } else {
            self.initialLoading = false;
          }

        }).catch(function (error) {
          if (axios.isCancel(error)) {
            logger.log("Request canceled")
          } else {
            self.isLoading = false;
          }
        });
      }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));

    },
    loadMessage(user, msgId) {
      const index = this.messages.findIndex(msg => msg.id === msgId);
      if (index >= 0) {
        // console.log('msg id ' + msgId + ' already loaded');
        return false;
      }
      this.loadMessages(user);
      return true;
    },
    loadMessages(user) {
      const self = this;
      self.isLoading = true; //loading begins
      this.selectedUser = user;
      backend.getMessages(this.selectedUser.id).then(function (resp) {
        self.previousDate = null;
        self.messages = resp.data;
        self.selectedUser.unread_messages = null;
        updateUnreadMessages(self.getUnreadMessages());
        self.isLoading = false; //loading finished
        if (self.initialLoading || self.$mq === 'mobile') {
          self.initialLoading = false;
          self.$nextTick(function () {
            self.countCharactersLeft(self.$refs.message_input);
          });
        }
        self.$nextTick(function () {
          self.scrollToBottom();
          if (self.$mq !== 'mobile') {
            self.scrollUserVisible();
          }
        });
      }, error => {
        if (self.initialLoading) {
          self.initialLoading = false;
        }
        self.isLoading = false;
      });
    },

    getUnreadMessages() {
      let unread = 0;
      this.chats.forEach(chat => {
        if (chat.unread_messages) {
          unread += chat.unread_messages;
        }
      });
      return unread;
    },

    getSelectedUserIndex() {
      if (this.selectedUser) {
        for (let i = 0; i < this.chats.length; i++) {
          if (this.chats[i].id == this.selectedUser.id) {
            return i;
          }
        }
      }
      return -1;
    },

    scrollUserVisible() {
      let selectedIndex = this.getSelectedUserIndex();
      if (selectedIndex >= 0) {
        if (isDefined(this.$refs.chatusers) && isDefined(this.$refs.chatuserlist)) {
          let useritem = this.$refs.chatusers[selectedIndex];
          let scrollbar = this.$refs.chatuserlist.parentNode;
          if (useritem.offsetTop + useritem.offsetHeight > scrollbar.scrollTop + scrollbar.offsetHeight ||
              useritem.offsetTop + useritem.offsetHeight < scrollbar.scrollTop) {
            scrollbar.scrollTop = useritem.offsetTop;
          }
        }
      }
    },

    scrollToBottom() {
      if (this.$mq === 'mobile') {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        const container = this.$el.querySelector("#chatlist");
        if (container) {
          this.$nextTick(function () {
            container.parentNode.scrollTop = container.scrollHeight;
          });
        } else {
          logger.log("#chatlist not found");
        }
      }
    },

    changeUser(user) {
      if (user.id !== this.selectedUser.id) {
        this.messages = [];
        this.loadMessages(user);
      }
      if (!this.chatOpen) {
        this.chatOpenScrollPosition = window.scrollY;
      }
      this.chatOpen = !this.chatOpen;
      if (this.$mq === 'mobile') {
        this.$nextTick(function () {
          if (this.chatOpen) {
            window.scrollTo(0, document.body.scrollHeight);
          } else {
            window.scroll(0, this.chatOpenScrollPosition);
          }
        });
      }
    },
    getMessage(message) {
      if (message.message) {
        return message.message;
      } else {
        return this.$t('contact-request');
      }
    },
    getStatus(message) {
      let status;
      if (message.read == "1") {
        status = this.$t('chat-status-read');
      } else if (message.sent == "1") {
        status = this.$t('chat-status-delivered');
      } else {
        status = this.$t('chat-status-sent');
      }
      return status;
    },
    sendMessageText(message) {
      const timestamp = new Date();

      if (!message || message.length == 0 || message.trim().length == 0) return;

      this.$refs.message_input.value = '';
      this.countCharactersLeft(this.$refs.message_input);

      const self = this;
      let writtenAt = timestamp.toISOString();
      writtenAt = writtenAt.replace('T', ' ');
      writtenAt = writtenAt.substring(0, writtenAt.length - 5);
      const tempId = uuid.v1();
      this.messages.push({
        id: tempId,
        message: message,
        created_at: writtenAt,
        sender_id: User.me(),
      });
      this.scrollToBottom();
      self.$refs.message_input.disabled = true;
      backend.send(message, tempId, this.selectedUser.id, writtenAt).then(function (resp) {
        self.$refs.message_input.disabled = false;
        self.$refs.message_input.focus({preventScroll: true});
        const index = self.messages.findIndex(msg => msg.id === tempId);
        if (index >= 0) {
          Vue.set(self.messages, index, resp.data);
          self.readMessages = self.markMessagesRead(self.readMessages);
        }
      }).catch(function (error) {
        self.$toasted.global.error_notification({message: 'Could not send message'});
        self.$refs.message_input.disabled = false;
      });
    },

    checkIfPushIsEnabled() {
      if (isNotificationSupported()) {
        if (isNotificationDenied()) {
          this.$toasted.global.error_notification({message: this.$t('push-warning')});
        } else if (isNotificationDefault() || (isNotificationAllowed() && !localStorageHelper.getPushToken())) {
          this.registerForPushes();
        }
      }
    },

    registerForPushes() {
      register(localStorageHelper.getDeviceId()).then((deviceToken) => {
        logger.log("register push")
        backend.registerv2(localStorageHelper.getDeviceId(), localStorageHelper.getUser(), false).then(resp => {
          logger.log("Register with Push token ");
        });
      });
    },

    handlePush(payload) {
      logger.log("received message in Chat.vue");
      if (payload.data && payload.data.type == 'chat') {
        if (payload.data.sender_id == this.selectedUser.id) {
          this.loadMessage(this.selectedUser, payload.data.id);
          if (document.visibilityState === 'visible') {
            return true;
          }
        } else {
          this.loadChats();
        }
      }
      return false;
    },

    loadChats() {
      const self = this;
      backend.getChatUsers(this.confirmedOnly).then(function (resp) {
        self.chats = resp.data.users;
        updateUnreadMessages(self.getUnreadMessages());
      });
    },

    videoChatEnabled(user) {
      return process.env.VUE_APP_CHAT_URL && user.isVideoChatEnabled() && user && user.match_confirmed && user.video_chat_enabled;
    },

    getVideoChatImage() {
      return videoChatImage;
    },

    goToVideoChat() {
      eventBus.$emit('open-video-chat', this.selectedUser);
      //userutils.openVideoChat(true, this.selectedUser.id, this.selectedUser.fullName, userutils.getInitials(this.selectedUser), userutils.getThumbnail(this.selectedUser), null, false);
    },

    visibilityChange(evt, hidden) {
      const self = this;
      if (!hidden) {
        let db;
        openDB('egcf', 2).then(function (egcfDB) {
          db = egcfDB;
          var tx = db.transaction(['messages'], 'readonly');
          var store = tx.objectStore('messages');
          return store.getAll();
        }).then(function (messages) {
          if (messages.length > 0) {
            if (messages.length == 1) {
              self.handlePush(messages[0]);
            } else if (messages.length > 1) {
              backend.getChatUsers(this.confirmedOnly).then(function (resp) {
                self.chats = resp.data.users;
                if (self.chats.length > 0) {
                  self.loadMessages(self.selectedUser);
                }
              });
            }
            var tx = db.transaction(['messages'], 'readwrite');
            var store = tx.objectStore('messages');
            store.clear();
          }
          db.close();
        }).catch(function (error) {
          if (db) {
            db.close();
          }
          // ignore
        });
      }
    },

    openProfileDialog(user, isRecommendation) {
      eventBus.$emit('open-profile-dialog', user, isRecommendation);
    },

    confirmContact(user) {
      const self = this;
      self.isLoading = true;
      backend.confirmContactRequest(user.id).then(resp => {
        self.isLoading = false;
        if (isDefined(resp.data.error)) {
          self.$toasted.global.error_notification({message: resp.data.error});
        } else {
          self.$toasted.global.success_notification({message: resp.data.message});
          user.contactUrl = null;
          user.contact_confirmed = true;
        }
      });
    },
    ignoreContact(user) {
      const self = this;
      self.isLoading = true;
      backend.ignoreContactRequest(user.id).then(resp => {
        self.isLoading = false;
        if (resp.data.error) {
          self.$toasted.global.error_notification({message: resp.data.error});
        } else {
          self.$toasted.global.success_notification({message: resp.data.message});
          eventBus.$emit('ignore-contact',  user.id);
          user.contact_confirmed = false;
          user.contactUrl = null;
          self.chats = self.chats.filter(chat => chat.id !== user.id);
          if (self.chats.length === 0) {
            self.$router.push({name: 'Home'});
          }
        }
      });
    }
  }
}
</script>

