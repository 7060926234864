import axios from 'axios'
import localStorageHelper from "./localstorage/local-storage";
import Vue from 'vue';
import router, {componentHistory} from './router';
import langHelper from "./locales/lang-helper";
import errorHandler from "@/error-handler";
import {logger} from "@/main";
import mybookfair from "./mbf-backend";
import i18n from "@/i18n";
import {isDefined} from "@/utils";
import {User} from "@/entities/User";
import {Event} from "@/entities/Event";
import {Appointment} from "@/entities/Appointment";
import {Room} from "@/entities/Room";


const host = process.env.VUE_APP_URL

let showErrorToast = true;

axios.interceptors.response.use(response => response, (error) => {
    if (!axios.isCancel(error)) {
        if (!error.response && error.message === 'Network Error' && showErrorToast) {
            showErrorToast = false;

            Vue.toasted.error(i18n.t('network-error').toString(), {
                position: "bottom-right",
                duration: 10000,
                type: 'error',
                action: {
                    text: '',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                }
            });
            /*
            * The request was made and the server responded with a 4xx/5xx error
            */
        } else if (error.response) {
            if (error.response.status === 401) {
                // if you ever get an unauthorized,
                // send the user back to the entrypoint
                router.push({name: "EntryPoint"});
            } else if (error.response.status === 403 && showErrorToast && error.config.url.includes(host)) {
                showErrorToast = false;
                if (isDefined(localStorageHelper.getRegistrationCompleted())) {
                    Vue.toasted.error(i18n.t('logged-out-error').toString(), {
                        position: "bottom-right",
                        duration: 10000,
                        type: 'error',
                        action: {
                            text: '',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        }
                    });
                    localStorageHelper.clear();
                    router.push({name: "EntryPoint"});
                }
            } else if (showErrorToast && error.config.url.includes(host)) {
                showErrorToast = false;
                let errorMsg;
                try {
                    errorMsg = JSON.stringify(error);
                } catch (error) {
                    errorMsg = error.message !== undefined ? error.message : error;
                }

                const dumpMsg = {
                    error: errorMsg,
                    userAgent: window.navigator.userAgent,
                    history: componentHistory,
                    url: location.href,
                    consoleLogs: logger.consoleLogs
                }

                if (location.hostname !== 'localhost') {
                    errorHandler.sendErrorMessage(JSON.stringify(dumpMsg, null, 2)).then();
                    logger.log("Error Dump send")
                }

                Vue.toasted.error(`${i18n.t('request-failed-error').toString()} ${error.response.status} `, {
                    position: "bottom-right",
                    duration: 10000,
                    type: 'error',
                    action: {
                        text: '',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                });
            }
        }
        setTimeout(function () {
            showErrorToast = true;
        }, 5000);
    }
    return Promise.reject(error);

});

export default {
    addAuthParams: function (params) {
        params.me = localStorageHelper.getUserId();
        if (process.env.VUE_APP_USE_AUTH_HEADER !== 'true') {
            params.device = localStorageHelper.getDeviceId();
        }
        params.lang = langHelper.getCurrentLang();
        return params;
    },

    addAuthHeaders: function (headers) {
        if (process.env.VUE_APP_USE_AUTH_HEADER === 'true') {
            headers.Authorization = 'Device ' + localStorageHelper.getDeviceId();
        }
        return headers;
    },

    me() {
        return this.user(null);
    },

    user(user) {
        let params = {};
        if (user) {
            params['user'] = user;
        }
        return axios.get(`${host}/api/user`, {
            params: this.addAuthParams(params),
            headers: this.addAuthHeaders({})
        }).then((response) => {
            if (isDefined(response.data.user)) {
                response.data.user = new User(response.data.user);
            } else {
                response.data = new User(response.data);
            }
            return response;
        });
    },

    getMessages(peer) {
        return axios.get(`${host}/api/messages`, {
            params: this.addAuthParams({
                peer: peer
            }),
            headers: this.addAuthHeaders({})
        })
    },

    getChatUsers(confirmed, searchValue, includeUser, cancelToken) {
        return axios.get(`${host}/api/chatusersV2`, {
            params: this.addAuthParams({
                confirmed: confirmed ? 1 : 0,
                search: searchValue,
                include: includeUser
            }),
            headers: this.addAuthHeaders({}),
            cancelToken: cancelToken
        }).then((response) => {
            if (isDefined(response.data.users)) {
                response.data.users = response.data.users.map(u => new User(u));
            }
            return response;
        });
    },

    getGroup: function (group) {
        return axios.get(`${host}/api/groups/list`, {
            params: this.addAuthParams({
                group_id: group
            }),
            headers: this.addAuthHeaders({})
        });
    },
    getEventById(eventId) {
        return axios.get(`${host}/api/events/list`, {
            params: this.addAuthParams({
                event_id: eventId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    getGroupMessages: function (group) {
        return axios.get(`${host}/api/groups/messages`, {
            params: this.addAuthParams({
                group_id: group
            }),
            headers: this.addAuthHeaders({})
        })
    },
    sendGroupMessage: function (message, uuid, group, written_at) {
        return axios.post(`${host}/api/groups/send`, message, {
            params: this.addAuthParams({
                uuid: uuid,
                group_id: group,
                written_at: written_at
            }),
            headers: this.addAuthHeaders({})
        })
    },

    matches: function (searchObj, cancelToken) {
        return axios.post(`${host}/api/matches`, searchObj ? JSON.stringify(searchObj) : undefined, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({}),
            cancelToken: cancelToken
        }).then((response => {
            if (isDefined(response.data.recommendations)) {
                response.data.recommendations = response.data.recommendations.map(r => new User(r));
            }
            if (isDefined(response.data.users)) {
                response.data.users = response.data.users.map(u => new User(u));
            }
            return response;
        }));
    },
    getCompanies(searchObj, cancelToken) {
        return axios.post(`${host}/api/companies/list`, searchObj ? JSON.stringify(searchObj) : undefined, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({}),
            cancelToken: cancelToken
        });
    },

    send: function (message, uuid, peer, written_at) {
        return axios.post(`${host}/api/messages/send`, message, {
            params: this.addAuthParams({
                uuid: uuid,
                peer: peer,
                written_at: written_at
            }),
            headers: this.addAuthHeaders({})
        })
    },

    sendContactRequest(contactId, message) {
        const data = new URLSearchParams();
        data.append('message', message);

        return axios.post(`${host}/api/contact`, data, {
            params: this.addAuthParams({
                contact: contactId
            }),
            headers: this.addAuthHeaders({})
        })
    },
    registerOAuth(deviceId, type, code, callback) {
        logger.log("registerOAuth");
        let formData = new FormData();
        formData.append("device", deviceId);
        formData.append("type", type);
        formData.append("code", code);
        formData.append("model", navigator.userAgent);
        formData.append("platform", "web");
        if (callback) {
            formData.append("callback", callback);
        }
        formData.append("lang", langHelper.getCurrentLang());

        logger.log("formdata: " + formData);
        return axios.post(`${host}/api/oauth`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
    },
    registerToken(deviceId, token) {
        let formData = new FormData();
        formData.append("device", deviceId);
        formData.append("token", token);
        formData.append("lang", langHelper.getCurrentLang());

        return axios.post(`${host}/api/pretix/token`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    getFormData(deviceId, userData, autoConfirm, registrationCompleted) {

        if (!isDefined(userData.email)) {
            logger.error("Email is not defined!", true);
        }

        let formData = new FormData();
        formData.append("device", deviceId);
        formData.append("model", navigator.userAgent);
        formData.append("platform", "web");
        if (userData.id) {
            formData.append("me", userData.id);  // bei neu generieren keine angeben
        }
        if (userData.title) {
            formData.append("title", userData.title);
        }
        if (userData.headline) {
            formData.append("headline", userData.headline);
        }
        if (userData.position) {
            formData.append("position", userData.position);
        }
        if (userData.organization) {
            formData.append("organization", userData.organization);
        }
        if (userData.country) {
            formData.append("country", userData.country);
        }
        if (userData.web) {
            formData.append("web", userData.web);
        }
        if (userData.tel) {
            formData.append("tel", userData.tel);
        }
        if (userData.themes) {
            formData.append("themes", JSON.stringify(userData.themes));
        }
        if (userData.show_contact_data !== undefined) {
            formData.append("show_contact_data", userData.show_contact_data ? "1" : "0");
        }

        if (userData.video_chat_allowed !== undefined) {
            formData.append("video_chat_allowed", userData.video_chat_allowed ? "1" : "0");
        }

        if (userData.linkedin) {
            formData.append("linkedin", userData.linkedin);
        }
        if (userData.twitter) {
            formData.append("twitter", userData.twitter);
        }
        if (userData.instagram) {
            formData.append("instagram", userData.instagram);
        }
        if (userData.facebook) {
            formData.append("facebook", userData.facebook);
        }

        let notEmptyObject = false;
        for (let i in userData.photo) {
            notEmptyObject = true;
            break;
        }
        if (userData.photo && notEmptyObject) {
            logger.log("Register with Photo:")
            logger.log(userData.photo)
            formData.append("photo", userData.photo);
        }

        /*let lang = navigator.language;
        if (lang && lang.length > 2) {
            lang = lang.substring(0, 2);
        }
        if (!lang || lang.length != 2) {
            lang = langHelper.getCurrentLang();
        }*/
        let lang = langHelper.getCurrentLang();
        formData.append("lang", lang);
        formData.append("email", userData.email);
        formData.append("firstname", userData.firstName);
        formData.append("lastname", userData.lastName);
        if (autoConfirm || (( isDefined(process.env.VUE_APP_MYBOOKFAIR_USER_HOST) && process.env.VUE_APP_MYBOOKFAIR_USER_HOST.length > 0))) {
            formData.append("autoConfirm", "1");
        }
        let deviceToken = localStorageHelper.getPushToken();
        if (deviceToken) {
            formData.append("deviceToken", deviceToken);
        }

        formData.append("video_chat_supported", process.env.VUE_APP_ENABLE_VIDEO_CHAT === 'true' ? "1" : "0");
        formData.append("registration_completed", (registrationCompleted || localStorageHelper.getRegistrationCompleted()) ? "1" : "0");

        return formData;
    },
    registrationCompleted(deviceId, userData, autoConfirm) {
        return this.saveData(deviceId, userData, autoConfirm, true);
    },
    saveData(deviceId, userData, autoConfirm, registrationCompleted = false) {
        let mbfHost = process.env.VUE_APP_MYBOOKFAIR_USER_HOST;
        if (mbfHost != null && mbfHost.length > 0) {
            return mybookfair.saveData(this.getFormData(deviceId, userData, autoConfirm, registrationCompleted));
        } else {
            return this.registerv2(deviceId, userData, autoConfirm, registrationCompleted);
        }
    },
    registerv2(deviceId, userData, autoConfirm, registrationCompleted = false) {
        let formData = this.getFormData(deviceId, userData, autoConfirm, registrationCompleted);
        return new Promise(function (resolve, reject) {
            let request = new XMLHttpRequest();
            request.onreadystatechange = function () {
                if (request.readyState === XMLHttpRequest.DONE) {
                    if (request.status === 200) {
                        resolve(request.response);
                    } else {
                        reject(request.status);
                    }
                }
            }
            request.open("POST", host + "/api/registerv2");
            request.send(formData);
        });
    },
    uploadProfileImage(image) {
        return new Promise(function (resolve, reject) {
            let formData = new FormData();
            formData.append("photo", image);
            formData.append("me", localStorageHelper.getUserId());
            formData.append("device", localStorageHelper.getDeviceId());
            let request = new XMLHttpRequest();
            request.onreadystatechange = function () {
                if (request.readyState === XMLHttpRequest.DONE) {
                    if (request.status === 200) {
                        resolve(request.response);
                    } else {
                        reject(request.status);
                    }
                }
            }
            request.open("POST", host + "/api/user/photo");
            request.send(formData);
        });
    },
    resendConfirmationEmail() {
        return axios.post(`${host}/api/resend`, undefined, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    logoutUser() {
        return axios.get(`${host}/api/user/logout`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    deleteUser(last, all) {
        return axios.get(`${host}/api/user/delete`, {
            params: this.addAuthParams({
                last: last,
                all: all
            }),
            headers: this.addAuthHeaders({})
        });
    },
    deleteUserOnAllDevices() {
        return this.deleteUser(undefined, 1);
    },
    deleteUserFromCurrentDevice() {
        return this.deleteUser(undefined, undefined);
    },
    requestUserDelete() {
        return this.deleteUser(1, undefined);
    },
    deleteUserProfilePicture() {
        return axios.delete(`${host}/api/user/photo`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    hideUser(userId){
        return axios.post(`${host}/api/user/hide`, undefined, {
            params: this.addAuthParams({
                user_id: userId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    unHideUser(userId) {
        return axios.post(`${host}/api/user/unhide`, undefined, {
            params: this.addAuthParams({
                user_id: userId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    excludeRecommendation(userId) {
        return axios.post(`${host}/api/user/exclude-recommendation`, undefined, {
            params: this.addAuthParams({
                user_id: userId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    excludeCompanyRecommendation(companyId) {
        return axios.post(`${host}/api/companies/exclude-recommendation`, undefined, {});
    },
    getSharedContacts(userId, page, pageSize) {
        return axios.post(`${host}/api/user/shared-contacts`, undefined, {
            params: this.addAuthParams({
                other: userId,
                page,
                pageSize
            }),
            headers: this.addAuthHeaders({})
        }).then((response) => {
            if (isDefined(response.data.shared_contacts)) {
                response.data.shared_contacts = response.data.shared_contacts.map(u => new User(u));
            }
            return response;
        });
    },

    findFreeSlotsForRoom(userId, roomId, from, to, duration) {
        const body = {
            room_id: roomId,
            from: from,
            to: to,
            duration: duration
        }
        return axios.post(`${host}/api/appointment/findFreeSlots`, body ? JSON.stringify(body) : undefined, {
            params: this.addAuthParams({
                other: userId,
            }),
            headers: this.addAuthHeaders({})
        });
    },
    getAllAppointments() {
        return axios.get(`${host}/api/appointment/getAppointments`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        }).then((response) => {
            if (isDefined(response.data.appointments)) {
                response.data.appointments = response.data.appointments.map(a => new Appointment(a));
            }
            if (isDefined(response.data.events)) {
                response.data.events = response.data.events.map(e => new Event(e));
            }
            if (isDefined(response.data.rooms)) {
                response.data.rooms = response.data.rooms.map(r => new Room(r));
            }
            if (isDefined(response.data.users)) {
                response.data.users = response.data.users.map(u => new User(u));
            }
            return response;
        });
    },
    blockAppointment(name, duration, date) {
        const body = {
            name,
            duration,
            date
        }
        return axios.post(`${host}/api/appointment/blockAppointment`, body ? JSON.stringify(body) : undefined, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        }).then((response) => {
            if (isDefined(response.data.appointments)) {
                response.data.appointments = response.data.appointments.map(a => new Appointment(a));
            }
            if (isDefined(response.data.events)) {
                response.data.events = response.data.events.map(e => new Event(e));
            }
            if (isDefined(response.data.rooms)) {
                response.data.rooms = response.data.rooms.map(r => new Room(r));
            }
            if (isDefined(response.data.users)) {
                response.data.users = response.data.users.map(u => new User(u));
            }
            return response;
        });
    },
    changeAppointment(appointment){
        return axios.post(`${host}/api/appointment/changeAppointment`, appointment ? JSON.stringify(appointment) : undefined, {
            params: this.addAuthParams({
                id: appointment.id
            }),
            headers: this.addAuthHeaders({})
        }).then((response) => {
            if (isDefined(response.data.appointments)) {
                response.data.appointments = response.data.appointments.map(a => new Appointment(a));
            }
            if (isDefined(response.data.events)) {
                response.data.events = response.data.events.map(e => new Event(e));
            }
            if (isDefined(response.data.rooms)) {
                response.data.rooms = response.data.rooms.map(r => new Room(r));
            }
            if (isDefined(response.data.users)) {
                response.data.users = response.data.users.map(u => new User(u));
            }
            return response;
        });
    },
    cancelAppointment(appointmentId) {
        return axios.get(`${host}/api/appointment/cancelAppointment`, {
            params: this.addAuthParams({
                appointment_id: appointmentId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    rejectAppointment(appointmentId) {
        return axios.get(`${host}/api/appointment/rejectAppointment`, {
            params: this.addAuthParams({
                appointment_id: appointmentId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    confirmAppointment(userId, appointmentId) {
        return axios.get(`${host}/api/appointment/confirmAppointment`, {
            params: this.addAuthParams({
                other: userId,
                appointment_id: appointmentId
            }),
            headers: this.addAuthHeaders({})
        });
    },
    getAllRooms() {
        return axios.get(`${host}/api/appointment/getRooms`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    suggestAppointment(appointment, other) {
        return axios.post(`${host}/api/appointment/suggestAppointment`, appointment ? JSON.stringify(appointment) : undefined, {
            params: this.addAuthParams({
                other: other
            }),
            headers: this.addAuthHeaders({})
        }).then((response) => {
            if (isDefined(response.data.appointments)) {
                response.data.appointments = response.data.appointments.map(a => new Appointment(a));
            }
            if (isDefined(response.data.events)) {
                response.data.events = response.data.events.map(e => new Event(e));
            }
            if (isDefined(response.data.rooms)) {
                response.data.rooms = response.data.rooms.map(r => new Room(r));
            }
            if (isDefined(response.data.users)) {
                response.data.users = response.data.users.map(u => new User(u));
            }
            return response;
        });
    },
    getCalendarForAppointment(appointment_id) {
        return axios.get(`${host}/api/appointment/getCalendar`, {
            params: this.addAuthParams({
                appointment_id: appointment_id
            }),
            headers: this.addAuthHeaders({})
        });
    },
    getAllEvents() {
        return axios.get(`${host}/api/events/list`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        }).then((response) => {

            if (isDefined(response.data.events)) {
                response.data.events = response.data.events.map(e => new Event(e));
            }
            if (isDefined(response.data.moderators)) {
                response.data.moderators = response.data.moderators.map(m => new User(m));
            }

            return response;
        });
    },
    registerToEvent(eventId, message) {
        return axios.post(`${host}/api/events/register`, undefined, {
            params: this.addAuthParams({
                event_id: eventId,
                lang: langHelper.getCurrentLang(),
                message: message
            }),
            headers: this.addAuthHeaders({})
        });
    },
    unregisterFromEvent(eventId) {
        return axios.post(`${host}/api/events/unregister`, undefined, {
            params: this.addAuthParams({
                event_id: eventId,
            }),
            headers: this.addAuthHeaders({})
        });
    },
    confirmEvent(token) {
        return axios.post(`${host}/api/events/confirm`, {token: token}, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    sendContactInvite(email, message, lang) {
        return axios.post(`${host}/api/user/invite`, undefined, {
            params: this.addAuthParams({
                email,
                message,
                lang
            }),
            headers: this.addAuthHeaders({})
        });
    },
    endCall(start, end, duration, receiver_id, successful) {
        return axios.post(`${host}/api/video/endCall`, {
            start: start,
            end: end,
            duration: duration,
            receiver_id: receiver_id,
            successful: successful
        }, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    getVersion() {
        return axios.get(`${host}/api/version`);
    },
    getVideoCollectionConfig() {
        return axios.get(`${host}/api/content/streams`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    getThemes() {
        return axios.get(`${host}/api/themes/config`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    getCountries() {
        return axios.get(`${host}/api/themes/countries`, {
            params: this.addAuthParams({}),
            headers: this.addAuthHeaders({})
        });
    },
    confirmContactRequest(requester_id) {
        return axios.post(`${host}/api/confirm`, undefined, {
            params: this.addAuthParams({
                requester_id
            }),
            headers: this.addAuthHeaders({})
        });
    },
    ignoreContactRequest(requester_id) {
        return axios.post(`${host}/api/ignore`, undefined, {
            params: this.addAuthParams({
                requester_id
            }),
            headers: this.addAuthHeaders({})
        });
    }
}
