<template>
  <div class="main video-collection">

    <div class="video-collection-loading-indicator-container vld-parent" v-if="isLoading">
      <loading :active.sync="isLoading"
               :is-full-page="false"
               color="var(--primary-color)"></loading>
    </div>
    <template v-if="!isLoading && config">
      <div class="video-collection-category" v-for="category in config.categories">
        <div class="video-collection-category-header">
          {{ category.title }}
        </div>
        <div class="video-collection-category-video-container">
          <div class="video-collection-category-video-container-video" v-for="video in category.videos">
            <div  class="video-collection-category-video-container-video-frame">
              <lazy-component>
                <iframe :src="video.src" allow="autoplay; fullscreen" allowfullscreen></iframe>
              </lazy-component>
            </div>
            <div class="video-collection-category-video-container-video-header">{{ video.title }}</div>
            <ul class="video-collection-category-video-container-video-description-list">
              <li v-for="description in  video.descriptions">{{ description }}</li>
            </ul>
            <div class="video-collection-category-video-container-video-speakers">
              <div class="video-collection-category-video-container-video-speaker" v-for="speaker in video.speakers">
                {{ speaker }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import backend from "@/mm-backend";

export default {
  name: "VideoCollection",
  data() {
    return {
      config: undefined,
      isLoading: true
    }
  },
  beforeMount() {
    this.isLoading = true;
    const self = this;
    backend.getVideoCollectionConfig().then(resp => {
      self.config = resp.data;
      self.isLoading = false;
    });
  }
}
</script>

<style scoped lang="less">

.video-collection {

  &-loading-indicator-container {
    width: 100%;
    height: 100vh;
  }

  &-category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 36px;

    &-header {
      font-size: 32px;
      text-decoration: underline;
    }

    &-video-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &-video {
        text-align: left;
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-top: 32px;
        margin-right: 32px;

        &-frame {
          width: 100%;

          > div > iframe {
            width: 100%;
            height: 230px;
            border: none;
          }
        }

        &-header {
          font-size: 20px;
          font-weight: bold;
        }

        &-description-list {
          margin: 0;
          padding-inline-start: 20px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .video-collection-category-video-container-video {
    width: 100%;
  }
}

</style>
