<template>
  <div class="main profile profile-create">

    <div class="side-header">{{ $t('profile-create-headline') }}</div>

    <div class="inputs">

      <form name="form" id="form" class="form" v-on:submit.prevent="createProfile()">
        <div class="left-side">

          <CustomInputText v-bind:value.sync="userData.title" v-bind:place-holder="$t('title')"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.firstName" v-bind:place-holder="$t('firstName')"
                           v-bind:required="true"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.lastName" v-bind:place-holder="$t('lastName')"
                           v-bind:required="true"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.organization"
                           v-bind:place-holder="$t('organization')"></CustomInputText>
          <MBFSelect v-bind:selected-value.sync="userData.country" v-bind:options="countryList"
                     v-bind:place-holder="$t('country')" v-if="showCountrySelect" v-bind:option-selector="'iso_2'"
                     v-bind:translate-function="getDisplayValueForCountry"></MBFSelect>
          <CustomInputText v-bind:value.sync="userData.position"
                           v-bind:place-holder="$t('position')"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.tel" v-bind:place-holder="$t('phone')"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.email" v-bind:place-holder="$t('email')"
                           v-bind:required="true"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.web" v-bind:place-holder="'URL'"
                           v-bind:pattern="urlPattern"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.linkedin" v-bind:pattern="urlPattern"
                           v-bind:place-holder="$t('linkedin-profile-url')"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.twitter" v-bind:pattern="urlPattern"
                           v-bind:place-holder="$t('twitter-profile-url')"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.instagram" v-bind:pattern="urlPattern"
                           v-bind:place-holder="$t('instagram-profile-url')"></CustomInputText>
          <CustomInputText v-bind:value.sync="userData.facebook" v-bind:pattern="urlPattern"
                           v-bind:place-holder="$t('facebook-profile-url')"></CustomInputText>

          <span>* {{ $t('required-fields') }} </span>
          <div class="save-delete-buttons">
            <custom-button :onClicked="submitForm" :icon="'fal fa-chevron-right fa-2x'">
              {{ $t('profile-create') }}
            </custom-button>

          </div>
          <input style="display:none;" id="submit-input-profile-create" hidden type="submit">
        </div>
        <div class="right-side">
          <div class="image">
            <span>{{ $t('profile-image') }}</span>
            <img :src="getImage()" alt="Profilbild"
                 id="profile-image">
          </div>
          <custom-button :onClicked="openFilePickerDialog" :icon="'fal fa-chevron-right fa-2x'"
                         :backgroundColor="'dimgrey'" :width="300" :iconBackGroundColor="'#383838'">
            <input name="profile-image-input" id="profile-image-input" hidden type="file"
                   accept="image/png, image/jpeg"
                   v-on:change="onProfileImageChosen">
            {{ $t('picture-select') }}
          </custom-button>

          <CustomInputTextArea style="margin-top: 40px" v-bind:rows="1" v-bind:value.sync="userData.headline"
                               v-bind:place-holder="$t('current-headline')"
                               v-bind:max-length="255"></CustomInputTextArea>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import fallbackProfileImage from '@/assets/img/person.svg';
import SelectThemes from "@/themes/select/select-themes";
import CustomInputTextArea from "@/components/inputs/CustomInputTextArea";
import CustomInputText from "@/components/inputs/CustomInputText";
import MBFSelect from "@/components/inputs/MBFSelect";
import {isDefined} from "@/utils";
import {countriesStorage, logger} from "@/main";

export default {
  components: {SelectThemes, CustomInputTextArea, CustomInputText,MBFSelect},
  data() {
    return {
      userData: {},
      photoURL: undefined,
      photoAsFile: undefined,
      countryList: countriesStorage.getCountryList(),
      showCountrySelect: process.env.VUE_APP_SHOW_COUNTRY_SELECT === 'true',
      urlPattern: '[Hh][Tt][Tt][Pp][Ss]?:\\/\\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\\d{2,5})?(?:\\/[^\\s]*)?',
    }
  },
  props: {
    onCreateClicked: Function
  },
  mounted() {
    this.checkForPresetData();
  },
  methods: {
    createProfile() {
      if (this.onCreateClicked) {
        this.onCreateClicked(this.userData);
      }
    },

    submitForm() {
      document.getElementById('submit-input-profile-create').click();
    },
    openFilePickerDialog() {
      document.getElementById('profile-image-input').click();
    },
    getImage() {
      return this.photoURL ? this.photoURL : fallbackProfileImage;
    },
    onProfileImageChosen(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > process.env.VUE_APP_MAX_FILE_SIZE_FOR_PROFILE_PICTURE) {
          this.$toasted.global.error_notification({message: this.$tc('file-size-to-big', process.env.VUE_APP_MAX_FILE_SIZE_FOR_PROFILE_PICTURE_TEXT)});
        } else {
          this.photoURL = URL.createObjectURL(file);
          this.userData.photo = file;
          this.$forceUpdate();

        }
      }
    },
    getDisplayValueForCountry(country) {
      if (isDefined(country)) {
        return country.getDisplayNameByLanguage(this.$getCurrentLanguage());
      }
      return '';
    },
    checkForPresetData() {
      if (window.location.search) {
        let urlParams = new URLSearchParams(window.location.search);
        let presetParam = null;
        for(const pair of urlParams.entries()) {
          if (pair[1] || presetParam) {
            presetParam = null;
            break;
          } else {
            presetParam = pair[0];
          }
        }
        if (presetParam) {
          const url = new URL(window.location);
          url.searchParams.delete(presetParam);
          try {
            while(presetParam.length%4 != 0) {
              presetParam += "=";
            }
            const decodedData = window.atob(presetParam);
            const presetData = JSON.parse(decodedData);
            const presetUserData = {};
            if (presetData.t) { presetUserData.title = presetData.t; }
            if (presetData.fn) { presetUserData.firstName = presetData.fn; }
            if (presetData.ln) { presetUserData.lastName = presetData.ln; }
            if (presetData.e) { presetUserData.email = presetData.e; }
            if (presetData.c) { presetUserData.organization = presetData.c; }
            this.userData = presetUserData;
            window.history.pushState({}, '', url);
          }
          catch(error) {
            logger.log(error);
          }
        }
      }
    },

  }
}
</script>
