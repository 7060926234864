<template>

  <div v-if="category.has_selected || category.selected"
       :class="!allSelected ? 'not-all-selected-box':''">

    <!-- No selected entries header-->
    <div v-if="category.children && !category.has_selected && category.id === parent.id"
         class="themes-category-header">
      <h5 class="themes-category-header-text">{{ category.title }}</h5>
      <h3 class="none-selected-text">{{ $t("none-selected") }}</h3>
    </div>


    <div class="themes-category-entry" v-if="allSelected">
      <div :title="category.title">{{ category.title }} - {{ $t('all-others') }} </div>
    </div>

    <!-- Title of Root Category  -->
    <h5 v-if=" category.has_selected && step === 0" class="themes-category-header-text">
      {{ category.title }}
      <div v-if="canEditCategory(category)" v-on:click="onTitleClicked(getTabIdForCategory(category))"
           class="edit-themes-button">
        {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
      </div>
    </h5>

    <!-- Title of Child Category example  Continents of Countries   -->
    <hr class="line-break" v-if="category.has_selected && step !== 0 && !allSelected">
    <div class="themes-category-entry"
         v-if="!allSelected && category.has_selected && step !== 0">
      <div>{{ category.title }}</div>
    </div>


    <!-- Entries -->
    <div class="themes-category-entry"
         v-if="!allSelected && category.selected && step !== 0 && !enableEdit">
      <div>{{ category.title }}</div>
    </div>
    <div class="theme-category-edit" v-if="enableEdit && step !== 0">
      <MBFSelect v-bind:selected-value.sync="newValue" :options="optionsForEdit"
                 :no-selection-allowed="false" :translate-function="getDisplayValueForTheme">

      </MBFSelect>
      <custom-button v-if="category.selected && step !== 0 && parent.isEditable && enableEdit" class="primary-button"
                     :onClicked="saveClicked" :icon="'fal fa-chevron-right fa-2x'">
        {{ $t('saving') }}
      </custom-button>
    </div>
    <div v-if="category.selected && step !== 0 && parent.isEditable && !enableEdit" v-on:click="enableEditClicked()"
         class="edit-themes-button">
      {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
    </div>

    <div
        :class="[step === 0 ? 'combined-topic-container' : category.children ? 'active-topic-container' : '', category.children ? `step-${step}` : '']">

      <themes-category
          v-if="category.children && category.children.length > 0 && category.has_selected && !allSelected"
          v-for="child in category.children"
          v-bind:category="child" v-bind:parent="parent" v-bind:step="step+1" :key="child.id"></themes-category>


    </div>
  </div>
</template>


<script>
import themes from "@/themes/themes-helper";
import {profileThemeCategoriesMixin} from "@/mixins/profile-theme-categories-mixin";
import MBFSelect from "@/components/inputs/MBFSelect";
import {isDefined} from "@/utils";
import localStorageHelper from "@/localstorage/local-storage";
import backend from "@/mm-backend";
import {eventBus} from "@/main";

export default {
  name: "ThemesCategory",
  components: {MBFSelect},
  mixins: [profileThemeCategoriesMixin],
  props: {
    category: Object,
    parent: Object,
    step: Number
  },
  data() {
    return {
      allSelected: undefined,
      enableEdit: false,
      newValue: {},
      optionsForEdit: [],
      themesForEdit: []
    }
  },
  beforeMount() {
    const allThemes = themes.getCategoriesToDisplayForOwnProfileByLanguage(this.$getCurrentLanguage());
    const themesForEdit = allThemes.filter(t => t.id === this.parent.id);
    if (isDefined(themesForEdit[0])) {
      this.themesForEdit = themesForEdit[0].children;
      this.optionsForEdit = themesForEdit[0].children.map(c => c = {id: c.id});
    }
  },
  watch: {
    category: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          if (newVal.id !== this.parent.id) {
            this.newValue = {id: newVal.id};
            this.allSelected = this.allEntriesSelected();
          }
        }
      }
    }
  },
  methods: {
    getSelectedAs() {
      return 'selected';
    },
    enableEditClicked() {
      this.enableEdit = true;
    },
    getDisplayValueForTheme(theme) {
      return this.themesForEdit.filter(t => t.id === theme.id)[0].title
    },
    saveClicked() {
      const user = localStorageHelper.getUser();


      if (isDefined(user) && isDefined(user.themes)) {
        const userThemes = user.themes;
        userThemes[this.parent.id] = [this.newValue.id];
        const self = this;
        backend.saveData(localStorageHelper.getDeviceId(), user, true).then(resp => {
          const response = JSON.parse(resp);
          if (response.error) {
            self.$toasted.global.error_notification({message: response.error.message});
          } else {
            eventBus.$emit('themes-edited',userThemes);
            localStorageHelper.setUser(user);
            self.$toasted.global.success_notification({message: self.$t('profile-saved-success')});
          }
          self.enableEdit = false;
        });
      }
    }
  }
}
</script>
