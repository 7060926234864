<template>

  <div v-if="category.has_selected || category.selected || category.has_joint || category.joint"
       :class="!allSelected ? 'not-all-selected-box':''">


    <div class="themes-category-entry" v-if="allSelected">
      <div :title="category.title">{{ category.title }} - {{ $t(getAllStringForCategory(category)) }} </div>
    </div>

    <!-- Title of Root Category  -->
    <h5 v-if="(category.has_selected || category.has_joint)  && step === 0">{{ category.title }}</h5>

    <!-- Title of Child Category example  Continents of Countries   -->
    <hr class="line-break" v-if="(category.has_selected || category.has_joint)  && step !== 0 && !allSelected">
    <div class="themes-category-entry"
         v-if="!allSelected && (category.has_selected || category.has_joint) && step !== 0">
      <div v-bind:title="category.title">{{ category.title }}</div>
    </div>


    <!-- Entries -->
    <div class="themes-category-entry" :class="category.joint ? 'joint':''"
         v-if="!allSelected && (category.selected || category.joint) && step !== 0">
      <div v-bind:title="category.title">{{ category.title }}</div>
      <i v-if="category.joint" class="fal fa-user-friends fa-lg"></i>
    </div>


    <div
        :class="[step === 0 ? 'combined-topic-container' : category.children ? 'active-topic-container' : '', category.children ? `step-${step}` : '']">

      <themes-profile-others-categories
          v-if="category.children && category.children.length > 0 && (category.has_selected || category.has_joint) && !allSelected"
          v-for="child in category.children"
          v-bind:category="child" v-bind:parent="parent" v-bind:step="step+1"
          :key="child.id"></themes-profile-others-categories>


    </div>
  </div>
</template>

<script>
import {themesProfileOthersCategoriesMixin} from "@/themes/profile-others/themes-profile-others-categories-mixin";
import {profileThemeCategoriesMixin} from "@/mixins/profile-theme-categories-mixin";

export default {
  mixins: [themesProfileOthersCategoriesMixin, profileThemeCategoriesMixin],
  name: "themes-profile-others-categories",
  watch: {
    category: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          if (newVal.id !== this.parent.id) {
            this.allSelected = this.allEntriesSelected();
            this.allJoint = this.allEntriesJoint()
          }
        }
      }
    }
  },
  methods: {
    getSelectedAs() {
      return 'selected';
    },
    getJointAs() {
      return 'joint';
    }
  }
}
</script>
