<template>
  <div>
    <!-- custom video chat -->
    <div class="videochat" v-if="opened && !useVideoServer">
      <div class="container" style="width:100%">
        <div class="content-container">

          <div class="info-container">
            <div class="info-text center">
              <h2 id="info-text"></h2>
              <a id="info-button" href="#" @click="retry($event);"></a>
            </div>
          </div>
          <div class="call-setup-container">
            <div class="call-setup-wrapper center">
              <div class="call-setup-header">
                <h3 id="call-status"></h3>
              </div>
              <div class="call-setup-img">
                <img id="caller-img" src="" alt="" class="call-setup-img" style="display:none"/>
              </div>
              <div class="call-setup-initials">
                <p id="caller-initials" style="display:none"></p>
              </div>
              <div class="call-setup-name">
                <p id="caller-name"></p>
              </div>
            </div>
          </div>
          <div class="video-chat-container">
            <div class="video-container">
              <video autoplay playsinline class="remote-video" id="remote-video"></video>
              <video autoplay playsinline muted class="local-video" id="local-video"></video>
              <img id="muted-img" src="@/assets/img/muted.svg">
            </div>
          </div>
          <div id="call-accept" class="call-setup-action">
            <a href="#" @click="accept($event)"><img src="@/assets/img/phone-accept.svg"></a>
          </div>
          <div id="call-setup" class="call-setup-action">
            <a href="#" @click="hangup($event);"><img src="@/assets/img/phone-hangup.svg"></a>
          </div>
          <div id="call-control">
            <div id="call-audio" class="call-control">
                        <span id="share-screen">
                            <a href="#" @click="share_screen($event)">
                              <img id="call-share-screen-on" src="@/assets/img/share-on.svg"
                                   :title="$t('video-chat-button-sharing-on')">
                              <img id="call-share-screen-off" src="@/assets/img/share-off.svg"
                                   :title="$t('video-chat-button-sharing-off')">
                              <img id="call-share-screen-off2" src="@/assets/img/share-off2.svg"
                                   :title="$t('video-chat-button-sharing-off')"></a>
                        </span>
              <span id="call-audio">
                            <a href="#" @click="toggle_audio($event)">
                              <img id="call-audio-img-on" src="@/assets/img/microphone-on.svg"
                                   :title="$t('video-chat-button-mic-off')">
                              <img id="call-audio-img-off" class="on" src="@/assets/img/microphone-off.svg"
                                   :title="$t('video-chat-button-mic-on')"></a>
                        </span>
              <span id="call-video">
                            <a href="#" @click="toggle_video($event)">
                              <img id="call-video-img-on" src="@/assets/img/video-on.svg"
                                   :title="$t('video-chat-button-cam-off')">
                              <img id="call-video-img-off" src="@/assets/img/video-off.svg"
                                   :title="$t('video-chat-button-cam-on')"></a>
                        </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Jitsi -->
    <div class="videochat" v-if="opened && useVideoServer">
      <div class="calltitle">{{ getCallTitle() }}</div>
      <div class="chatWrapper">
        <div class="call-status">{{ callStatus }}</div>
        <div id="chat" ref="chat" class="chats groupchat" :class="$mq">
        </div>
      </div>
    </div>


    <custom-dialog class="apiLoadingFailedDialog" ref="apiLoadingFailedDialog" id="apiLoadingFailedDialog" :submit-button-text="$t('retry')"
                   :second-button-text="$t('cancel')" :on-submit="loadJitsiApi" :on-second-button-submit="() => {}">
      <span class="header">{{$t('video-group-api-failed-dialog-text')}} </span>
    </custom-dialog>

  </div>


</template>

<script>
import backend from '@/mm-backend';
import moment from 'moment';
import Vue from 'vue'
import visibility from 'vue-visibility-change';
import localStorageHelper from "../localstorage/local-storage";
import {
  init,
  initInternal,
  setServer,
  setResourcePath,
  retry,
  accept,
  hangup,
  share_screen,
  toggle_audio,
  toggle_video
} from "../videochat";
import {durationString, isDefined} from "@/utils";
import {eventBus, logger} from "@/main";
import {User} from "@/entities/User";

Vue.use(visibility);

export default {
  variable: {
    api: undefined,
    socket: undefined,
    scriptEl: undefined,
    callData: undefined,
    filmstripDisplayVisible: true,
    interval: undefined,
    callStart: 0,
    callDuration: 0,
    callDurationCountStart: 0,
    callSuccessful: false,
    callState: 0,
    withoutToken: false //used for appointments
  },
  data() {
    return {
      lang: this.$getCurrentLanguage(),
      opened: false,
      user: {},
      call: null,
      callStatus: '',
      useVideoServer: !!(process.env.VUE_APP_VIDEO_CHAT_SERVER),
    }
  },
  mounted() {
    window.addEventListener("message", this.hangupMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.hangupMessage);
    if (this.opened) {
      hangup();
    }
  },
  methods: {
    startCallSetup() {
      if (this.opened) {
        if (this.useVideoServer) {
          if (this.isIncomingCall()) {
            this.callData = this.call;
            logger.log("call-accepted: " + JSON.stringify(this.callData));
            this.loadScriptAndStartCall();
          } else if (!this.withoutToken) {
            this.socket.emit('call-user', {id: this.user.id}, (response) => {
              this.callData = response;
              logger.log("call-user response: " + JSON.stringify(this.callData));
              this.loadScriptAndStartCall();
            });
          } else if (this.withoutToken) {
            this.socket.emit('call-token', {id: this.user.id}, (response) => {
              this.callData = response;
              logger.log("call-token response: " + JSON.stringify(this.callData));
              this.loadScriptAndStartCall();
            });
          }
        } else {
          setServer(process.env.VUE_APP_CHAT_URL + "/video");
          setResourcePath(process.env.BASE_URL);
          initInternal(process.env.VUE_APP_PUSH_NAME, localStorageHelper.getUserId(), this.user.id, this.call, localStorageHelper.getDeviceId(), this.user.fullName, this.user.getInitials(), this.user.photo, this.lang, this.call ? true : false);
        }
      }
    },
    isOutgoingCall() {
      return !this.isIncomingCall();
    },
    isIncomingCall() {
      return this.call;
    },
    outgoingCallAccepted(data) {
      if (this.opened) {
        this.callState = 1;
        this.updateCallStatus();
        this.$toasted.global.success_notification({message: this.$t('outgoing-call-accepted')});
        logger.log('outgoing-call-accepted received for ' + JSON.stringify(data));
      }
    },
    outgoingCallRejected(data) {
      if (this.opened) {
        this.$toasted.global.error_notification({message: this.$t('outgoing-call-declined')});
        this.closeVideoChat();
        logger.log('outgoing-call-rejected received for ' + JSON.stringify(data));
      }
    },
    openVideoChat(user, socket, withoutToken = false) {
      this.user = new User(user);
      this.socket = socket;
      this.call = null;
      this.opened = true;
      eventBus.$emit('update-busy-state', true);
      this.withoutToken = withoutToken;
      this.callStart = Date.now();
      this.callDuration = 0;
      this.callState = 0;
      this.updateCallStatus();
      this.callSuccessful = false;
      this.$nextTick(function () {
        this.startCallSetup();
      });
    },
    acceptIncomingVideoChat(user, socket, call) {
      this.user = new User(user);
      this.socket = socket;
      this.call = call;
      this.opened = true;
      eventBus.$emit('update-busy-state', true);
      this.callState = 1;
      this.updateCallStatus();
      this.$nextTick(function () {
        this.startCallSetup();
      });
    },
    accept(event) {
      accept(event);
    },
    retry(event) {
      retry(event);
    },
    hangup(event) {
      if (this.useVideoServer) {
      } else {
        hangup(event);
      }
    },
    toggle_audio(event) {
      toggle_audio(event);
    },
    toggle_video(event) {
      toggle_video(event);
    },
    share_screen(event) {
      share_screen(event);
    },
    hangupMessage(event) {
      if (event.origin == location.origin && event.data === "videochat-hangup") {
        logger.log("hangup received");
        this.closeVideoChat();
      }
    },
    closeVideoChat() {
      const closeButton = document.getElementById('profileCloseButtonIcon');
      if (closeButton) {
        closeButton.style.opacity = '1';
      }
      if (this.socket) {
        if (this.isOutgoingCall() && !this.callSuccessful) {
          this.socket.emit('call-stop', {id: this.user.id, call: this.callData});
        }
      }
      eventBus.$emit('update-busy-state', false);
      this.opened = false;
      this.user = {};
    },
    isJitsiApiLoaded() {
      return window.JitsiMeetExternalAPI;
    },
    loadJitsiApi(apiPath) {
      //const apiPath = 'https://' + process.env.VUE_APP_VIDEO_CHAT_SERVER + '/external_api.js';
      logger.log("Loading Jitsi Api: " + apiPath);
      this.loadScript(apiPath, () => {
        if (!window.JitsiMeetExternalAPI) {
          if(isDefined(this.$refs.apiLoadingFailedDialog)) {
            this.$refs.apiLoadingFailedDialog.openDialog();
          }
        } else {
          this.startCall(apiPath);
        }
      });
    },
    loadScript(src, cb) {
      this.scriptEl = document.createElement('script');
      this.scriptEl.addEventListener('error', cb)
      this.scriptEl.src = src;
      this.scriptEl.async = true;
      document.querySelector('head').appendChild(this.scriptEl);
      this.scriptEl.addEventListener('load', cb);

    },
    loadScriptAndStartCall() {
      if (!this.isJitsiApiLoaded()) {
        this.loadJitsiApi(this.callData.server_url);
      } else {
        this.startCall();
      }
    },
    startCall() {
      const domain = this.callData.server_url ? new URL(this.callData.server_url).hostname : process.env.VUE_APP_VIDEO_CHAT_SERVER;
      const user = localStorageHelper.getUser();
      const options = {
        roomName: this.callData.room,
        userInfo: {
          displayName: user.getName()
        },
        configOverwrite: {
          // https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/devices/middleware.js
          // https://github.com/jitsi/jitsi-meet/blob/master/config.js#L1201
          notifications: [
            'connection.CONNFAIL',
            'dialog.cameraNotFoundError',
            'dialog.cameraNotSendingData',
            'dialog.cameraPermissionDeniedError',
            'dialog.cameraUnknownError',
            'dialog.micNotFoundError',
            'dialog.micNotSendingData',
            'dialog.micPermissionDeniedError',
            'dialog.micUnknownError',
            'toolbar.talkWhileMutedPopup'
          ],
          disableRemoteMute: true,
          remoteVideoMenu: {
            disableKick: true
          },
          disableThirdPartyRequests: false,
          startWithVideoMuted: false,
          enableWelcomePage: false,
          disableProfile: true,
          disableDeepLinking: true,
          prejoinPageEnabled: false,
          hideConferenceTimer: true,
          hideConferenceSubject: true,
          hideParticipantsStats: true,
          disablePolls: true
        },
        interfaceConfigOverwrite: {
          SET_FILMSTRIP_ENABLED: false,
          DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
          DISABLE_VIDEO_BACKGROUND: true,
          VERTICAL_FILMSTRIP: this.$mq === 'mobile' ? true : false,
          CONNECTION_INDICATOR_DISABLED: true,
          VIDEO_QUALITY_LABEL_DISABLED: true,
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          DISABLE_FOCUS_INDICATOR: true,
          DEFAULT_BACKGROUND: 'lightgray',
          JITSI_WATERMARK_LINK: '',
          LANG_DETECTION: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_DEEP_LINKING_IMAGE: false,
          SHOW_POWERED_BY: false,
          TOOLBAR_BUTTONS: [
            'microphone', 'camera', 'desktop', 'hangup', 'chat', 'fullscreen'
            /*'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
              'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
              'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
              'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
              'tileview'*/
          ]
        },
        parentNode: document.querySelector("#chat"),
        jwt: this.callData.token,
      };
      this.api = new window.JitsiMeetExternalAPI(domain, options);
      this.api.addListener('videoConferenceJoined', function (event) {
        this.api.executeCommand('avatarUrl', user.getThumbnail());
      }.bind(this));
      this.api.addListener('readyToClose', this.readyToClose);
      this.filmstripDisplayVisible = true;
      this.api.addListener('filmstripDisplayChanged', function (event) {
        this.filmstripDisplayVisible = event.visible;
      }.bind(this));
      this.api.addListener('contentSharingParticipantsChanged', function (event) {
        if (event.data && event.data.length > 0) {
          if (this.filmstripDisplayVisible) {
            this.api.executeCommand('toggleFilmStrip');
          }
        } else {
          if (!this.filmstripDisplayVisible) {
            this.api.executeCommand('toggleFilmStrip');
          }
        }
      }.bind(this));
      this.api.addListener('participantJoined', function (event) {
        this.callSuccessful = true;
        this.callDurationCountStart = Date.now();
        this.callState = 2;
        this.updateCallStatus();
      }.bind(this));
      this.api.addListener('participantLeft', function (event) {
        if (this.api.getNumberOfParticipants() <= 1) {
          this.callState = 3;
          this.callDuration += (Date.now() - this.callDurationCountStart);
        }
      }.bind(this));
    },
    readyToClose() {
      if (this.isOutgoingCall()) {
        try {
          const callEnd = Date.now();
          if (this.api.getNumberOfParticipants() > 1) {
            this.callDuration += (Date.now() - this.callDurationCountStart);
          }

          backend.endCall(
              moment(this.callStart).utc().format('YYYY-MM-DD HH:mm:ss'),
              moment(callEnd).utc().format('YYYY-MM-DD HH:mm:ss'),
              this.callDuration,
              this.user.id,
              this.callSuccessful);
        } catch (error) {
        }
      }

      this.api.executeCommand('hangup');
      this.api.dispose();
      this.api = undefined;
      window.JitsiMeetExternalAPI = undefined;
      if (this.scriptEl) {
        document.querySelector('head').removeChild(this.scriptEl);
      } else {
        logger.log('script element not set');
      }
      this.scriptEl = undefined;
      this.closeVideoChat();
    },
    getCallTitle() {
      return this.$t('video-chat-with', {peer: this.user.fullName});
    },
    getCallStatus() {
      if (this.withoutToken) {
        return '';
      }
      if (this.callState < 2 && this.interval != undefined) {
        clearInterval(this.interval);
        this.interval = undefined;
      }
      switch (this.callState) {
        case 0:
          return this.$t('video-chat-it-rings');
        case 1 :
          return this.$t('video-chat-connecting');
        case 2 :
        case 3 : {
          if (this.interval == undefined) {
            let self = this;
            this.interval = setInterval(function () {
              self.updateCallStatus();
            }, 1000);
          }
          let diff = Date.now() - this.callDurationCountStart;
          return (this.callState == 2 ? this.$t('video-chat-running') : this.$t('video-chat-empty')) + ' - ' + durationString(diff);
        }
      }
    },
    updateCallStatus() {
      this.callStatus = this.getCallStatus();
    }
  }
}
</script>
<style>
</style>
<style lang="less">


.apiLoadingFailedDialog{
  z-index: 200 !important;
}

.videochat {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;

  .content-container {
    height: 100vh;
  }

  h3, p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.4em;
  }

  .chatWrapper {
    height: 80vh;
    width: calc((80vh - 30px) * 16 / 9);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: solid 2px var(--primary-color);
    position: relative;
  }

  #chat {
    width: 100%;
    height: calc(100% - 30px);
    vertical-align: middle;
    position: relative;
  }

  .calltitle {
    /*color: black;*/
    display: block;
    font-size: 36px;
    width: 100%;
    text-align: center;
    margin: 20px auto 20px auto;
  }

  .call-status {
    width: 100%;
    background: black;
    border-bottom: 0px solid gray;
    color: white;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 30px;
    line-height: 26px;
  }
}

</style>
