<template>
  <div :class="'custom-button' + (iconDisabled ? ' custom-button-noicon':'') +(disabled? ' custom-button-disabled':'')"
       v-on:click="onButtonClicked"
       :style="'width:'+ width+'px;min-width:'+minWidth+'px;background:'+backgroundColor" :defaultCursor=iconDisabled>
    <span class="text"><slot></slot> </span>
    <div class="icon" :style="'width:' +iconWidth+ 'px;background:'+ iconBackGroundColor" v-if="!iconDisabled">
      <i :class="icon"></i>
    </div>
  </div>
</template>

<script>
import {isDefined} from "@/utils";

export default {
  props: {
    onClicked: {
      required: true,
      type: Function
    },
    icon: {
      required: false,
      type: String,
      default: 'fal fa-chevron-right fa-2x'
    },
    width: {
      required: false,
      type: Number
    },
    iconWidth: {
      default: 60,
      required: false,
      type: Number
    },
    backgroundColor: {
      required: false,
      type: String
    },
    iconBackGroundColor: {
      required: false,
      type: String
    },
    iconDisabled: {
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minWidth: undefined
    }
  },
  watch: {
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!isDefined(newVal)) {
          this.minWidth = 250;
        }
      }
    }
  },
  methods: {
    onButtonClicked() {
      if (isDefined(this.onClicked) && !this.disabled) {
        this.onClicked();
      }
    }
  }
}
</script>

<style lang="less" scoped>

.custom-button {
  height: 40px;
  min-height: 40px;
  background: var(--secondary-color);
  display: inline-flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: relative;
  padding-left: 22px;
  padding-right: 70px;
  margin-right: 22px;
  box-sizing: border-box;

  .text {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 24px;
    color: white;
    margin-right: 10px;
    white-space: nowrap;
  }

  .icon {
    height: 40px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    position: absolute;
    right: 0;
    top: 0;

    > i {
      transition: transform 0.02s, -webkit-transform 0.02s;
      -webkit-transition: transform 0.02s, -webkit-transform 0.02s;
      color: white;
      transform: scale(1.0);
    }

    > i.fa-chevron-right {
      transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
      -webkit-transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
    }
  }
}

.custom-button-noicon {
  padding-left: 46px;
  padding-right: 46px;
}

.custom-button-disabled {
  background: var(--disabled-input-color);

  .icon {
    background: var(--disabled-input-color);
  }
}

[defaultCursor] {
  cursor: default;
}

.custom-button:hover > .icon > i {
  transform: scale(1.1);
}

.custom-button:hover > .icon > i.fa-chevron-right {
  transform: translateX(35%);
}


</style>
