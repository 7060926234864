import axios from "axios";

const host = "https://i.eyeled.de/eccpack/getDumps.php"

export default {

    sendErrorMessage(error){
        return axios.post(host,error,{
            params:{
                name : `web_${process.env.VUE_APP_PUSH_NAME}_${process.env.VUE_APP_VERSION}_`
            }
        });
    },
    sendDebugErrorMessage(error){
        return axios.post(host,error,{
            params:{
                name : `web_${process.env.VUE_APP_PUSH_NAME}_${process.env.VUE_APP_VERSION}_debug_`
            }
        });
    }

}
