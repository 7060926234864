import localStorage from '@/localstorage/local-storage';
import {deleteUrlSearchParam} from "@/utils";

export const supportedLanguages = ['de', 'en'];
export default {

    //gets the current language that is set in the cookie 'fbm_lang'
    //defaults to "en"
    getCurrentLang() {
        const search = window.location.search;
        const html = document.getElementsByTagName("html");
        if (search) {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('lang')) {
                let lang = urlParams.get('lang').toLowerCase();
                if (lang.length > 2) {
                    lang = lang.substring(0, 2);
                }
                const supportedLang = supportedLanguages.find(l => l === lang) ? lang : 'en'
                if (html && html.length > 0) {
                    html[0].setAttribute("lang", supportedLang);
                }
                localStorage.setLang(lang);
                return supportedLang;
            }
        }
        let lang = null;

        if (localStorage.getLang() !== null) {
            return localStorage.getLang();
        }

        if (html && html.length > 0) {
            lang = html[0].getAttribute("lang");
        }
        if (!lang && document.cookie && document.cookie !== '') {
            const lang_cookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('fbm_lang'));
            if (lang_cookie) {
                lang = lang_cookie.split('=')[1];
            }
        }
        const finalLang = lang ? supportedLanguages.find(l => l === lang) ? lang : "en" : "en"
        localStorage.setLang(finalLang);
        return lang ? supportedLanguages.find(l => l === lang) ? lang : "en" : "en";
    },

    setCurrentLang(lang) {
        localStorage.setLang(lang);
        deleteUrlSearchParam('lang');
        window.location.reload();
    }
}
