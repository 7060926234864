import {hashCode, isDefined} from "@/utils";

const uuidv4 = require('uuid/v4');

export class BaseVueEntity {

    vueId;

    constructor() {
        this.vueId = uuidv4()
    }

    //If vueId should be based on some property of Parent
    generateVueIdBasedOnProperty(property) {
        if (!isDefined(this[property])) {
            throw new Error(`Property ${property} is not defined!`);
        }
        this.vueId = hashCode(this[property]);
    }

    reload() {
        this.vueId = uuidv4();
    }
}