<template>


  <div class="select-themes-view">
    <div>
      <div class="tabs">
        <ul>
          <li v-for="tab of tabs" :class="activeTabId === tab.id ? 'is-active' :''">
            <div>{{ $t(tab.id + '-header') }}</div>
          </li>
        </ul>
      </div>


      <div class="tabs-content">

        <template v-if="activeTabId === 'start-tab'">

        <div class="tabs-content-sub-header">
          <div>{{ $t('start-tab-sub-header') }}</div>
        </div>

          <div class="tabs-content-description">{{ $t('start-tab-description') }}</div>

          <div class="start-tab-info-box">
            <div class="start-tab-info-box-left-bar">
              <i class="fal fa-info-circle fa-2x"></i>
            </div>
            <div class="start-tab-info-box-content" v-html="$t('start-tab-info-box-content')"></div>
          </div>
          <span class="start-tab-pre-privacy-box-text">{{ $t('start-tab-pre-privacy-box-text') }}</span>
          <div class="start-tab-privacy-box">
            <span v-html="$t('start-tab-privacy-box-text')"></span>

            <custom-check-box v-bind:checkbox-text="$t('matchmaking-privacy')"
                              v-bind:value.sync="acceptTermsOfUse"></custom-check-box>

            <custom-button :width="250" :on-clicked="login"
                           :icon="'fal fa-file-signature fa-2x'">
              {{ $t('start-tab-confirm-privacy-button-text') }}
            </custom-button>
          </div>
        </template>
        <template v-if="activeTabId === 'profile-create-tab'">
          <create-profile :on-create-clicked="onProfileCreated" ref="createProfile"></create-profile>
        </template>
      </div>
    </div>
  </div>


</template>

<script>
import themes from "@/themes/themes-helper";
import CustomCheckBox from "@/components/inputs/CustomCheckBox";
import {scrollToElement} from "@/utils";

export default {
  name: "LoginView",
  components: {CustomCheckBox},
  data() {
    return {
      acceptTermsOfUse: false,
      activeTabId: 'start-tab',
      tabs:[]
    }
  },
  beforeMount() {
    const tabs = [{id: 'start-tab'}];
    if (this.$createProfileEnabled) {
      tabs.push({id: 'profile-create-tab'});
    }
    tabs.push(...themes.getThemeSelectTabs());
    this.tabs = tabs;
  },
  methods: {
    onProfileCreated(userData){
      this.$emit("submit",userData);
    },
    login() {
      if (this.acceptTermsOfUse) {
        if(this.$createProfileEnabled) {
          this.activeTabId = 'profile-create-tab';
          this.$nextTick(() => {
            scrollToElement(this.$refs.createProfile.$el);
          })
        }else{
          this.$emit("submit",undefined);
        }
      } else {
        this.$toasted.show(this.$t('policy-warning').toString(), {
          duration: 2000,
          position: "top-center"
        });
      }
    },
  }
}
</script>

<style lang="less">

.select-themes-view {
  max-width: 1170px;
}

.start-tab-info-box {
  width: 100%;
  height: 160px;
  background-color: #bbdae8;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;

  &-left-bar {
    width: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1d78b5;

    > i {
      color: #FFFFFF;
    }
  }

  &-content {
    margin-left: 20px;

    > ul {
      list-style: disc;
      margin-block-start: 1em !important;
      margin-block-end: 1em !important;
      padding-inline-start: 40px !important;
    }

    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: normal;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 90%;

    > span {
      margin-left: 10px;
    }
  }

}

.start-tab-privacy-box {
  width: 100%;

  background-color: var(--primary-color);
  color: #FFFFFF;
  margin-top: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-weight: normal;

  > span {
    padding-bottom: 50px;
  }

  .custom-button {
    align-self: flex-end;
    background: #58585a !important;

    .icon {
      background: #333333 !important;
    }
  }

  .checkbox-wrapper {
    padding-bottom: 30px;

    .checkbox {
      border: none;
      background: #FFFFFF;
      flex-shrink: 0;
      margin-right: 10px;

      > i {
        color: #000000;
      }
    }

    .checkbox-label {
      a {
        color: #FFFFFF;
      }
    }
  }

  .fa-file-signature {
    font-size: 1.6em;
    padding-left: 5px;
  }
}

</style>
