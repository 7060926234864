import {BaseVueEntity} from "@/entities/BaseVueEntity";
import localStorageHelper from "@/localstorage/local-storage";

export class User extends BaseVueEntity {

    country;
    email;
    firstName;
    fullName;
    id;
    lang;
    lastChanged;
    lastName;
    role;
    roleName;
    tel;
    themes;
    video_chat_enabled;
    thumbnail;
    photo;

    constructor(userAsObject) {
        super()
        for (const [key, value] of Object.entries(userAsObject)) {
            this[key] = value;
        }
    }

    static me() {
        return localStorageHelper.getUserId();
    }

    static device() {
        return localStorageHelper.getDeviceId();
    }

    static isCorporatesEnabled() {
        const allowedRoles = ['Full Delegates 1', 'HPR Delegates 1', 'Trainees 1', 'Company Representatives 1', 'Corporate Staff', 'EULAR Staff', 'Pharmaceutical Company representatives'];
        const user = localStorageHelper.getUser();
        let isAllowed = false;
        if (user && user.role) {
            for (const allowedRole of allowedRoles) {
                if (user.role.endsWith(allowedRole)) {
                    isAllowed = true;
                    break;
                }
            }
        }
        return isAllowed;
    }

    getName() {
        let name = '';
        if (this.firstName) {
            name = this.firstName;
        }
        if (this.lastName && this.lastName.length > 0) {
            if (name.length > 0) {
                name += ' ';
            }
            name += this.lastName;
        }
        return name;
    }

    getInitials() {
        return this.initials ? this.initials : this.makeInitials('', this.firstName, this.lastName);
    }

    makeInitials(title, firstName, lastName) {
        let initials = '';
        if (firstName && firstName.length > 0) {
            initials += firstName[0];
        }
        if (lastName && lastName.length > 0) {
            initials += lastName[0];
        }
        return initials;
    }

    getThumbnail() {
        return this.thumbnail ? this.thumbnail : (this.photo ? this.photo : null);
    }

    isVideoChatEnabled() {
        return this.video_chat_enabled && (process.env.VUE_APP_ENABLE_VIDEO_CHAT === 'true');
    }


}
