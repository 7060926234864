<template>
  <div class="themes-container">

    <!-- General Info Themes, all Themes without search and offer and without calculate match -->
    <h3 v-if="selected_themes_without_search_and_offer.length > 0 || selected_themes_without_search_and_offer_separated.length > 0">
      {{ $t('general-info') }}
    </h3>
    <div class="themes-container-normal" v-if="selected_themes_without_search_and_offer.length > 0">
      <div class="themes-normal" v-for="category of selected_themes_without_search_and_offer">
        <themes-profile-others-categories v-if="category.has_selected || category.has_joint" v-bind:category="category"
                                          v-bind:parent="category"
                                          v-bind:step="0"
                                          :key="category.id+'_normal'"></themes-profile-others-categories>

      </div>
    </div>
    <div class="themes-container-normal" v-if="selected_themes_without_search_and_offer_separated.length > 0">
      <div class="themes-normal flex-break" v-for="category of selected_themes_without_search_and_offer_separated">
        <themes-profile-others-categories v-if="category.has_selected || category.has_joint" v-bind:category="category"
                                          v-bind:parent="category"
                                          v-bind:step="0"
                                          :key="category.id+'_normal'"></themes-profile-others-categories>

      </div>
    </div>

    <template v-if="splitJointThemes">
      <h3 class="shared-themes-header"
          v-if="selected_themes_with_search_joint.length > 0 || selected_themes_with_offer_joint.length > 0">
        {{ $t('shared-themes') }}
      </h3>
      <div class="shared-themes-container">
        <!-- Shared search -->
        <h3 v-if="selected_themes_with_search_joint.length > 0">{{ $t('me-searching') }}</h3>
        <template v-for="category of selected_themes_with_search_joint">
          <template v-if="category.showChildrenSeparately">
            <themes-profile-others-search v-if="child.has_joint_search" v-bind:category="child"
                                          v-bind:joint-only="true"
                                          v-bind:parent="category" v-for="child of category.children"
                                          v-bind:step="0" v-bind:for-joint="true"
                                          :key="child.id+'_search_joint'"></themes-profile-others-search>
          </template>
          <template v-else>
            <themes-profile-others-search v-if="category.has_joint_search" v-bind:category="category"
                                          v-bind:parent="category"
                                          v-bind:joint-only="true"
                                          v-bind:step="0" v-bind:for-joint="true"
                                          :key="category.id+'_search_joint'"></themes-profile-others-search>
          </template>
        </template>

        <!-- Shared offer -->
        <h3 v-if="selected_themes_with_offer_joint.length > 0">{{ $t('me-offering') }}</h3>
        <template v-for="category of selected_themes_with_offer_joint">

          <template v-if="category.showChildrenSeparately">
            <themes-profile-others-offer v-if="child.has_joint_offer" v-bind:category="child"
                                         v-bind:parent="category" v-for="child of category.children"
                                         v-bind:step="0" v-bind:for-joint="true"
                                         v-bind:joint-only="true"
                                         :key="child.id+'_offer_joint'"></themes-profile-others-offer>
          </template>
          <template v-else>
            <themes-profile-others-offer v-if="category.has_joint_offer" v-bind:category="category"
                                         v-bind:parent="category"
                                         v-bind:step="0" v-bind:for-joint="true"
                                         v-bind:joint-only="true"
                                         :key="category.id+'_offer_joint'"></themes-profile-others-offer>
          </template>

        </template>
      </div>

      <h3 class="different-themes-header" v-if="showDifferentThemesHeader()">
        {{ $t('different-themes') }}</h3>

      <div class="different-themes-container">
        <h3 v-if="selected_themes_with_search_and_offer_and_selected_search.length > 0">{{ $t('me-searching') }}</h3>
        <template v-for="category of selected_themes_with_search_and_offer_and_selected_search">
          <template v-if="category.showChildrenSeparately">
            <themes-profile-others-search v-if="child.has_selected_search" v-bind:category="child"
                                          v-bind:select-only="true"
                                          v-bind:parent="category" v-for="child of category.children"
                                          v-bind:step="0" :key="child.id+'_search'"></themes-profile-others-search>
          </template>
          <template v-else>
            <themes-profile-others-search v-if="category.has_selected_search" v-bind:category="category"
                                          v-bind:select-only="true"
                                          v-bind:parent="category"
                                          v-bind:step="0" :key="category.id+'_search'"></themes-profile-others-search>
          </template>
        </template>

        <h3 v-if="selected_themes_with_search_and_offer_and_selected_offer.length > 0">{{ $t('me-offering') }}</h3>
        <template v-for="category of selected_themes_with_search_and_offer_and_selected_offer">

          <template v-if="category.showChildrenSeparately">
            <themes-profile-others-offer v-if="child.has_selected_offer" v-bind:category="child"
                                         v-bind:parent="category"
                                         v-for="child of category.children"
                                         v-bind:select-only="true"
                                         v-bind:step="0" :key="child.id+'_offer'"></themes-profile-others-offer>
          </template>
          <template v-else>
            <themes-profile-others-offer v-if="category.has_selected_offer" v-bind:category="category"
                                         v-bind:parent="category"
                                         v-bind:select-only="true"
                                         v-bind:step="0" :key="category.id+'_offer'"></themes-profile-others-offer>
          </template>
        </template>
      </div>

    </template>

    <template v-else>
      <h3 v-if="selected_themes_with_search_joint_or_search_selected.length > 0">{{ $t('me-searching') }}</h3>
      <template v-for="category of selected_themes_with_search_joint_or_search_selected">
        <template v-if="category.showChildrenSeparately">
          <themes-profile-others-search v-bind:category="child"
                                        v-bind:parent="category" v-for="child of category.children"
                                        v-bind:step="0" v-bind:for-joint="true"
                                        :key="child.id+'_search'"></themes-profile-others-search>
        </template>
        <template v-else>
          <themes-profile-others-search v-bind:category="category"
                                        v-bind:parent="category"
                                        v-bind:step="0" v-bind:for-joint="true"
                                        :key="category.id+'_search'"></themes-profile-others-search>
        </template>
      </template>

      <h3 v-if="selected_themes_with_offer_joint_or_offer_selected.length > 0">{{ $t('me-offering') }}</h3>
      <template v-for="category of selected_themes_with_offer_joint_or_offer_selected">

        <template v-if="category.showChildrenSeparately">
          <themes-profile-others-offer v-bind:category="child"
                                       v-bind:parent="category" v-for="child of category.children"
                                       v-bind:step="0"
                                       :key="child.id+'_offer'"></themes-profile-others-offer>
        </template>
        <template v-else>
          <themes-profile-others-offer v-bind:category="category"
                                       v-bind:parent="category"
                                       v-bind:step="0"
                                       :key="category.id+'_offer'"></themes-profile-others-offer>
        </template>

      </template>
    </template>
  </div>
</template>

<script>
import themes from "@/themes/themes-helper";
import localStorageHelper from "@/localstorage/local-storage";
import ThemesProfileOthersCategories from "@/themes/profile-others/themes-profile-others-categories";
import ThemesProfileOthersSearch from "@/themes/profile-others/themes-profile-others-categories-search";
import ThemesProfileOthersOffer from "@/themes/profile-others/themes-profile-others-categories-offer";
import {isDefined} from "@/utils";
import backend from "@/mm-backend";
import {logger} from "@/main";

export default {
  name: "themes-profile-others",
  components: {
    ThemesProfileOthersOffer, ThemesProfileOthersSearch, ThemesProfileOthersCategories
  },
  data() {
    return {
      selected_themes: [],
      splitJointThemes: process.env.VUE_APP_SPLIT_JOINT_THEMES === 'true'
    }
  },
  computed: {
    selected_themes_with_search_and_offer() {
      return this.selected_themes.filter(e => e.hasSearchAndOffer);
    },
    selected_themes_with_search_and_offer_and_selected_search() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && category.has_selected_search);
    },
    selected_themes_with_search_and_offer_and_selected_offer() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && category.has_selected_offer);
    },
    selected_themes_with_search_joint() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && category.has_joint_search);
    },
    selected_themes_with_offer_joint() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && category.has_joint_offer);
    },
    selected_themes_with_search_joint_or_search_selected() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && (category.has_joint_search || category.has_selected_search));
    },
    selected_themes_with_offer_joint_or_offer_selected() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && (category.has_joint_offer || category.has_selected_offer));
    },
    selected_themes_without_search_and_offer() {
      return this.selected_themes.filter(category => !category.hasSearchAndOffer &&  (category.has_selected || category.has_joint ) && !category.separateRow);
    },
    selected_themes_without_search_and_offer_separated() {
      return this.selected_themes.filter(category => !category.hasSearchAndOffer && (category.has_selected || category.has_joint ) && category.separateRow);
    }
  },
  props: {
    userThemes: {
      type: Object,
      required: true
    },
    forOwnProfile: {
      required: false,
      type: Boolean
    }
  },
  watch: {
    userThemes: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.init();
        }
      }
    }
  }, methods: {
    showDifferentThemesHeader() {
      return (this.selected_themes_with_offer_joint.length > 0 || this.selected_themes_with_search_joint.length > 0) && (!this.allOfferThemesJoint() || !this.allSearchThemesJoint())
    },
    init() {
      this.buildThemeViews(this.userThemes);
    },
    buildThemeViews(userThemes) {
      let themesToDisplay = themes.getCategoriesToDisplayForProfileOthersByLanguage(this.$getCurrentLanguage());
      this.sortCategories(themesToDisplay);
      const ownUser = localStorageHelper.getUser();
      if (isDefined(ownUser) && isDefined(ownUser.themes)) {
        const myThemes = ownUser.themes;
        this.applyUserThemes(userThemes, themesToDisplay, myThemes);
        this.selected_themes = themesToDisplay;
      }else{
        logger.log("Could not get User from Localstorage. Loading from backend instead.")
        const self = this;
        backend.me().then(function (resp) {
          if(resp.data.confirmed){
            localStorageHelper.setUser(resp.data.user);
            const user = resp.data.user;
            const myThemes = user.themes;
            self.applyUserThemes(userThemes, themesToDisplay, myThemes);
            self.selected_themes = themesToDisplay;
          }
        });
      }
    },
    applyUserThemes(userThemes, themesToDisplay, myThemes) {
      for (const category of themesToDisplay)
        if (category.children) {
          this.traverseCategory(category.children, category, userThemes, [category], myThemes, this.forOwnProfile ? false : category.calculateMatch);
        }
    },
    traverseCategory(children, category, userThemes, parents, myThemes, calculateMatch) {
      for (const entry of children) {
        if (entry.children) {
          this.traverseCategory(entry.children, category, userThemes, [...parents, entry], myThemes, calculateMatch);
        } else {
          if (userThemes[category.id]) {
            if (category.hasSearchAndOffer) {
              if (userThemes[category.id].search && userThemes[category.id].search.length > 0 && userThemes[category.id].search.includes(entry.id)) {
                if (calculateMatch && myThemes[category.id] && myThemes[category.id].offer && myThemes[category.id].offer.includes(entry.id)) {
                  entry.joint_search = true;
                  category.has_joint_search = true;
                  parents.forEach(parent => {
                    parent.has_joint_search = true;
                  });
                } else {
                  entry.selected_as_search = true;
                  category.has_selected_search = true;
                  parents.forEach(parent => {
                    parent.has_selected_search = true;
                  });
                }
              }
              if (userThemes[category.id].offer && userThemes[category.id].offer.length > 0 && userThemes[category.id].offer.includes(entry.id)) {
                if (calculateMatch && myThemes[category.id] && myThemes[category.id].search && myThemes[category.id].search.includes(entry.id)) {
                  entry.joint_offer = true;
                  category.has_joint_offer = true;
                  parents.forEach(parent => {
                    parent.has_joint_offer = true;
                  });
                } else {
                  entry.selected_as_offer = true;
                  category.has_selected_offer = true;
                  parents.forEach(parent => {
                    parent.has_selected_offer = true;
                  });
                }
              }
            } else {
              if (userThemes[category.id] && userThemes[category.id].length > 0 && userThemes[category.id].includes(entry.id)) {
                if (calculateMatch && myThemes[category.id] && myThemes[category.id].includes(entry.id)) {
                  entry.joint = true;
                  category.has_joint = true;
                  parents.forEach(parent => {
                    parent.has_joint = true;
                  });
                } else {
                  entry.selected = true;
                  category.has_selected = true;
                  parents.forEach(parent => {
                    parent.has_selected = true;
                  });
                }
              }
            }
          }
        }
      }
    },
    sortCategories(themesToDisplay) {
      themesToDisplay.sort(function (a, b) {
        return a.sortOrder.localeCompare(b.sortOrder);
      });
      for (const category of themesToDisplay) {
        if (category.children) {
          this.sortCategory(category.children);
        }
      }
    },
    sortCategory(children) {
      for (const entry of children) {
        if (entry.children) {
          this.sortCategory(entry.children);
        } else {
          children.sort(function (a, b) {
            if (a.sortOrder && b.sortOrder) {
              return a.sortOrder.localeCompare(b.sortOrder);
            } else {
              return 0;
            }
          });
        }
      }
    },
    allOfferThemesJoint() {
      for (const category of this.selected_themes_with_search_and_offer) {
        if (category.has_joint_offer) {
          return this.allOfferThemesJointTraverse(category.children, true);
        }
      }
      return false;
    },
    allOfferThemesJointTraverse(children, allJoint) {
      for (const entry of children) {
        if (entry.children) {
          allJoint = this.allOfferThemesJointTraverse(entry.children, allJoint);
        } else {
          if (!entry.joint_offer) {
            return false;
          }
        }
      }
      return allJoint;
    },
    allSearchThemesJoint() {
      for (const category of this.selected_themes_with_search_and_offer) {
        if (category.has_joint_search) {
          return this.allSearchThemesJointTraverse(category.children, true)
        }
      }
      return false;
    },
    allSearchThemesJointTraverse(children, allJoint) {
      for (const entry of children) {
        if (entry.children) {
          allJoint = this.allSearchThemesJointTraverse(entry.children, allJoint);
        } else {
          if (!entry.joint_search) {
            return false;
          }
        }
      }
      return allJoint;
    }

  }
}
</script>
