<template>
  <div class="main">
    <div class="side-header">{{ $t('your-contacts') }}</div>

    <div class="search-and-sort-input-wrapper">
      <div class="search-input">
        <input v-model="searchValue" v-on:input="search" type="text" :placeholder="$t('search')">
        <i class="fal fa-search fa-lg"></i>
        <div v-if="searchValue && searchValue.length > 0 && filterEnabled" class="search-warning"><i
            class="fal fa-exclamation-triangle"></i> {{ $t('filter-active') }}
        </div>
      </div>


      <div v-if="$showInviteContactButton" class="invite-contact-button" v-on:click="openInviteContactDialog">
        {{ $t('invite-contact') }}
      </div>

      <custom-select :prefix="$t('sort-after')"
                     :options="sortValues"
                     :default="sortValue"
                     :translate-values="true"
                     v-on:input="sortValue=$event; sort()">
      </custom-select>
    </div>
    <div class="wrapper">
      <div class="filter-container">
        <custom-button v-if="isMobile" :onClicked="toggleFilter"
                       :icon="filterVisible ? 'fal fa-chevron-up fa-2x' :  'fal fa-chevron-down fa-2x'">Filter
        </custom-button>
        <themes-filter v-show="filterVisible" ref="themeFilter" v-bind:type="'contacts'"
                       v-bind:reset-filter="resetFilter"
                       v-bind:apply-filter="applyFilter" v-bind:is-loading="isLoading"></themes-filter>
      </div>
      <div class="contacts-container vld-parent">
        <loading :active.sync="isLoading"
                 :is-full-page="false"
                 color="var(--primary-color)"></loading>
        <div class="empty-contacts-wrapper" v-if="limitedContacts.length === 0 && !isLoading">
          <h3> {{
              filterEnabled ? $t('empty-filter-results') : searchValue ? $t('empty-search-results') :
                  $t("no-contacts-available")
            }}</h3>
        </div>

        <transition-group name="animatedList" tag="div"
                          v-bind:css="false"
                          v-on:before-enter="animationBeforeEnter"
                          v-on:enter="animationEnter"
                          v-on:leave="animationLeave">
          <ContactCard v-for="(contact,i) in limitedContacts" :key="contact.internalId" :contact="contact" v-bind:data-index="i"
                       :goToProfile="openProfileDialog"/>
        </transition-group>
        <div class="paginator-wrapper">
          <div class="paginator" v-if="!isLoading && this.limitedContacts.length !== 0">


            <custom-select
                :suffix="$t('entries-per-page')"
                :options="entriesPerPageOptions"
                :default="entriesPerPage"
                v-on:input="entriesPerPage=$event; get()">
            </custom-select>

            <div class="page-count">
              <span>{{ $t('page') }} {{ currentPage }} {{ $t('of') }}  {{ maxPages }}</span>
              <div class="arrows">
                <i v-on:click="showPreviousPage" class="fal fa-chevron-left fa-2x left-arrow "></i>
                <i v-on:click="showNextPage" class="fal fa-chevron-right fa-2x right-arrow"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <custom-dialog id="inviteContactDialog" ref="inviteContactDialog" class="inviteContactDialog"
                   v-bind:submit-button-text="$t('invite-contact-button')" :disable-buttons="true">
      <span class="header"> {{ $t('invite-contact-dialog-header') }}</span>

      <span class="description">{{ $t('invite-contact-dialog-description') }}</span>

      <form id="inviteContactForm" class="inviteContactForm" v-on:submit.prevent="sendContactInvite()">


        <custom-input-text v-bind:custom-type="'email'" v-bind:value.sync="inviteContactEmail" v-bind:required="true"
                           v-bind:place-holder=" $t('email')"></custom-input-text>

        <custom-input-text-area v-bind:value.sync="inviteContactMessage" v-bind:max-length="1024" v-bind:required="true"
                                v-bind:place-holder=" $t('your-message')"></custom-input-text-area>

        <div class="select-language-container">
          <span>{{ $t('invite-contact-dialog-language-select') }}</span>
          <custom-select v-bind:options.sync="supportedLanguages" v-bind:selected-value.sync="inviteContactLanguage"
                         v-bind:translate-values="true"></custom-select>
        </div>
        <custom-button :onClicked="submitInviteContactForm" :icon="'fal fa-chevron-right fa-2x'">
          {{ $t('invite-contact-button') }}
        </custom-button>
        <input id="submitInviteContact" style="display: none" hidden type="submit">
      </form>

    </custom-dialog>
  </div>
</template>
<script>
import matomo from "../../matomo/tracker";
import {networkersMixIn} from "@/mixins/networkers-mixin";
import backend from "@/mm-backend";
import ThemesFilter from "@/themes/filter/themes-filter";
import CustomInputTextArea from "@/components/inputs/CustomInputTextArea";
import CustomInputText from "@/components/inputs/CustomInputText";
import CustomToggle from "@/components/inputs/CustomToggle";
import {eventBus, onlineContactsStorage} from "@/main";
import {uuid} from "vue-uuid";


export default {
  components: {CustomToggle, CustomInputText, CustomInputTextArea, ThemesFilter},
  mixins: [networkersMixIn],
  data() {
    return {
      inviteContactMessage: undefined,
      inviteContactEmail: undefined,
      inviteContactLanguage: this.$getSupportedLanguages[0],
      supportedLanguages: this.$getSupportedLanguages,
    };
  },
  computed: {
    limitedContacts() {
      return this.networkers.slice((this.currentPage - 1) * this.entriesPerPage, this.currentPage * this.entriesPerPage);
    }
  },
  mounted() {
    eventBus.$on('setContactOnline', this.setContactOnline);
    eventBus.$on('setContactOffline', this.setContactOffline);
    eventBus.$on('setOnlineContacts',this.getOnlineContacts);
    eventBus.$on('setContactBusy',this.setContactBusy);
    eventBus.$on('setContactFree',this.setContactFree);
    this.get();
  },
  beforeDestroy() {
    eventBus.$off('setContactOnline', this.setContactOnline);
    eventBus.$off('setContactOffline', this.setContactOffline);
    eventBus.$off('setOnlineContacts',this.getOnlineContacts);
    eventBus.$off('setContactBusy',this.setContactBusy);
    eventBus.$off('setContactFree',this.setContactFree);
  },
  methods: {
    beforeGet() {
      this.getObservable = backend.matches.bind(backend);
      this.params = {
        confirmedOnly: true,
        pageSize: this.maxPagesToDisplay * this.entriesPerPage,
        page: 0,
        sort: this.sortValue
      };
    },
    afterGet() {
      this.getOnlineContacts();
      matomo.trackContactList();
    },
    openInviteContactDialog() {
      this.$refs.inviteContactDialog.openDialog();
    },
    submitInviteContactForm() {
      document.getElementById('submitInviteContact').click();
    },
    sendContactInvite() {
      const self = this;
      self.$refs.inviteContactDialog.showLoadingSpinner();
      backend.sendContactInvite(this.inviteContactEmail, this.inviteContactMessage, this.inviteContactLanguage).then(resp => {
        self.$refs.inviteContactDialog.closeLoadingSpinner();
        if (resp.data.error) {
          self.$toasted.global.error_notification({message: resp.data.error.message});
        } else {
          self.$refs.inviteContactDialog.closeDialogFromOutside();
          self.$toasted.global.success_notification({message: self.$t('contact-invite-send')});
        }
      },error => {
        self.$refs.inviteContactDialog.closeLoadingSpinner();
      });
    },
    getOnlineContacts() {
      const onlineContactsIds = onlineContactsStorage.getOnlineContacts();
      for (const onlineContactId of onlineContactsIds) {
        const index = this.networkers.findIndex(n => n.id === +onlineContactId)
        if (index > -1) {
          this.networkers[index].isOnline = true;
        }
      }
      const busyContactIds = onlineContactsStorage.getBusyContacts();
      for(const busyContactId of busyContactIds){
        const index = this.networkers.findIndex(n => n.id === +busyContactId)
        if (index > -1) {
          this.networkers[index].isBusy = true;
        }
      }
      this.$forceUpdate();
    },
    setContactOffline(id) {
      const index = this.networkers.findIndex(n => n.id === +id)
      if (index > -1) {
        this.networkers[index].isOnline = false;
        this.networkers[index].internalId = uuid.v4();
        this.$forceUpdate();
      }
    },
    setContactOnline(id) {
      const index = this.networkers.findIndex(n => n.id === +id)
      if (index > -1) {
        this.networkers[index].isOnline = true;
        this.networkers[index].internalId = uuid.v4();
        this.$forceUpdate();
      }
    },
    setContactBusy(id){
      const index = this.networkers.findIndex(n => n.id === +id)
      if (index > -1) {
        this.networkers[index].isBusy = true;
        this.networkers[index].internalId = uuid.v4();
        this.$forceUpdate();
      }
    },
    setContactFree(id){
      const index = this.networkers.findIndex(n => n.id === +id)
      if (index > -1) {
        this.networkers[index].isBusy = false;
        this.networkers[index].internalId = uuid.v4();
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style lang="less" scoped>

.wrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.contacts-container {
  display: flex;
  margin-top: 46px;
  margin-left: 29px;
  flex-flow: wrap;
  width: 100%;
  height: fit-content;
  min-width: 500px;
  min-height: 100px;
}

.inviteContactDialog {

  .description {
    font-weight: normal;
    margin-bottom: 20px;
    text-align: left;
  }

  .inviteContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .input-wrapper {
      width: 100% !important;
    }

    .select-language-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 20px 0;

      > span {
        margin-right: 12px;
        margin-bottom: 6px;
        font-weight: bold;
        align-self: flex-start;
      }

      .custom-select {
        width: 100% !important;
      }
    }
  }
}


</style>
