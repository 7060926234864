<template>
  <div class="input-wrapper">
    <input :type="customType ? customType : 'text'" class="input" v-model="internValue" :required="required"
           v-bind:style="readOnly ? 'caret-color: transparent !important;':''"
           v-bind:pattern="pattern" v-bind:disabled="disabled" :class="disabled ? 'disabled':''"
           v-on:keydown="onKeyDown"
           v-on:keyup="$emit('update:value',internValue)" v-bind:maxlength="maxLength" @input="onChange"
           v-on:click="onClick"
           spellcheck="false"/>
    <label class="label"
           v-bind:class="{'filled-input': value !== '' && value !== undefined && value !== null,'required': required && !value}">
      {{ placeHolder }}{{ required ? '*' : '' }}</label>
    <div class="hint" v-if="hint">{{ hint }}</div>
  </div>
</template>

<script>
import {isDefined} from "@/utils";

export default {
  name: "CustomInputText",
  props: {
    value: {
      required: true
    },
    placeHolder: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    pattern: {
      required: false,
      type: String
    },
    customType: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    maxLength: {
      required: false,
      type: Number,
      default: 255
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    hint: {
      required: false,
      type: String
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.internValue = newVal;
      }
    }
  },
  data() {
    return {
      internValue: undefined
    }
  },
  methods: {
    onKeyDown(event) {
      if (this.readOnly) {
        event.preventDefault();
        return false;
      }
    },
    onChange(inputEvent) {
      if (isDefined(inputEvent) && isDefined(inputEvent.data) && inputEvent.inputType === 'insertReplacementText')
        this.internValue = inputEvent.data;
      this.$emit('update:value', this.internValue)
    },
    onClick() {
      this.$emit('click');
    }
  }
}
</script>
