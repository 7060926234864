import fallbackProfileImage from "@/assets/img/person.svg";
import {isDefined} from "@/utils";

export let cardMixin = {

    methods: {
        getName(user) {
            return user.getName(user);
        },
        getImage(networker) {
            if (this.$refs.img && this.$refs.img.src === fallbackProfileImage) {
                return fallbackProfileImage;
            }
            return networker.thumbnail ? networker.thumbnail : (networker.photo ? networker.photo : fallbackProfileImage);
        },
        validUrl(url) {
            if (url) {
                if (url.startsWith("http://") || url.startsWith("https://")) {
                    return url;
                } else {
                    return "http://" + url;
                }
            }
            return url;
        },
        setFallBackImageForNetworker() {
            if (this.$refs.img) {
                this.$refs.img.src = fallbackProfileImage;
                this.$forceUpdate();
            }
        },
        getMatchingScore(networker){
            return isDefined(networker) ? networker.pc : 0
        }
    }

}
