<template>

  <div class="select-themes-view" v-if="loaded">
    <div>
      <div class="tabs" ref="tabsContainer">
        <ul>
          <li v-for="tab of tabs" :class="isActiveTab(tab.id) ? 'is-active' :''" v-on:click="setIsActive(tab.id)">
            <div>{{ $t(tab.id + '-header') }}</div>
          </li>
        </ul>
      </div>
      <div class="tabs-content" v-for="tab of tabs"
           v-bind:class="{'deactivated': !isActiveTab(tab.id),'with-sub-tabs':tab.subTabs}"
           v-if="!tab.deactivated && isActiveTab(tab.id)">

        <template v-if="tab.subTabs">
          <div class="tabs">
            <ul>
              <li v-for="subTab of getSubTabsForTab(tab)" :class="isActiveTab(subTab.id) ? 'is-active' :''"
                  v-on:click="setIsActive(subTab.id)">
                <div>{{ $t(subTab.id + '-header') }}</div>
              </li>
            </ul>
          </div>

          <div class="tabs-content" v-for="subTab of getSubTabsForTab(tab)" v-bind:class="{'deactivated': !isActiveTab(tab.id)}"
               v-if="!subTab.deactivated && isActiveTab(subTab.id)">


            <template v-for="category of getThemesForTab(subTab)">
              <div class="tabs-content-sub-header">
                <template v-if="tab.selectEntriesAs === 'offer'">
                  <div v-if="category['question.offer']">{{ category['question.offer'] }}</div>
                </template>
                <template v-if="tab.selectEntriesAs === 'search'">
                  <div v-if="category['question.search']">{{ category['question.search'] }}</div>
                </template>
                <template v-if="tab.selectEntriesAs === 'normal'">
                  <div v-if="category['question']">{{ category['question'] }}</div>
                </template>
                <div v-if="!category.question && !category['question.search'] && !category['question.offer']">
                  {{ getQuestion(subTab, category) }}
                </div>
              </div>

              <div class="category-container" v-bind:id="category.id">

                <template v-if="category.showChildrenSeparately">
                  <select-themes-categories v-bind:singleton="category.singleton" v-bind:parent="child"
                                            v-bind:maximum-select-allowed="category.maxCountForChildren"
                                            v-bind:disable-select-all-checkbox="category.disableSelectAllCheckbox"
                                            v-for="child of category.children" v-bind:key="child.key"
                                            v-bind:category="child" v-bind:step="0" v-on:update="onUpdate"
                                            v-bind:select-as="tab.selectEntriesAs"></select-themes-categories>
                </template>

                <select-themes-categories v-bind:singleton="category.singleton" v-bind:parent="category"
                                          v-bind:key="category.key"
                                          v-bind:disable-select-all-checkbox="category.disableSelectAllCheckbox"
                                          v-if="!category.showChildrenSeparately" v-on:update="onUpdate"
                                          v-bind:category="category" v-bind:step="0"
                                          v-bind:select-as="tab.selectEntriesAs"
                ></select-themes-categories>
              </div>
            </template>

            <span class="mandatory-warning" v-if="hasTabRequiredCategories(subTab.id)">*{{
                $t('required-fields')
              }}</span>
            <custom-button class="primary-button" :icon="'fal fa-chevron-right fa-2x'"
                           :on-clicked="(subTab.isLastTab || isUpdate) ? onSubmit : showNextTab">
              {{ (subTab.isLastTab || isUpdate) ? $t('save') : $t('next') }}
            </custom-button>

          </div>


        </template>
        <template v-else>
           <template v-for="category of getThemesForTab(tab)">
          <div class="tabs-content-sub-header">
            <template v-if="tab.selectEntriesAs === 'offer'">
              <div v-if="category['question.offer']">{{ category['question.offer'] }}</div>
            </template>
            <template v-if="tab.selectEntriesAs === 'search'">
              <div v-if="category['question.search']">{{ category['question.search'] }}</div>
            </template>
            <template v-if="tab.selectEntriesAs === 'normal'">
              <div v-if="category['question']">{{ category['question'] }}</div>
            </template>
            <div v-if="!category.question && !category['question.search'] && !category['question.offer']">
              {{ $t(tab.id + '-sub-header') }}
            </div>
          </div>

          <div class="category-container"  v-bind:id="category.id">

            <template v-if="category.showChildrenSeparately">
              <select-themes-categories v-bind:singleton="category.singleton" v-bind:parent="child"
                                        v-bind:maximum-select-allowed="category.maxCountForChildren"
                                        v-bind:disable-select-all-checkbox="category.disableSelectAllCheckbox"
                                        v-for="child of category.children" v-bind:key="child.key"
                                        v-bind:category="child" v-bind:step="0" v-on:update="onUpdate"
                                        v-bind:select-as="tab.selectEntriesAs"></select-themes-categories>
            </template>

            <select-themes-categories v-bind:singleton="category.singleton" v-bind:parent="category"
                                      v-bind:key="category.key"
                                      v-bind:disable-select-all-checkbox="category.disableSelectAllCheckbox"
                                      v-if="!category.showChildrenSeparately" v-on:update="onUpdate"
                                      v-bind:category="category" v-bind:step="0" v-bind:select-as="tab.selectEntriesAs"
            ></select-themes-categories>
          </div>
           </template>

          <span class="mandatory-warning" v-if="hasTabRequiredCategories(tab.id)">*{{ $t('required-fields') }}</span>
          <custom-button class="primary-button" :icon="'fal fa-chevron-right fa-2x'"
                         :on-clicked="(tab.isLastTab || isUpdate) ? onSubmit : showNextTab">
            {{ (tab.isLastTab || isUpdate) ? $t('save') : $t('next') }}
          </custom-button>
        </template>
      </div>
    </div>

    <div class="floatingErrorMessage" ref="floatingErrorMessage" v-if="floatingErrorMessage">
      <i class="fas fa-exclamation-triangle fa-2x"></i>
      <div>{{ floatingErrorMessage }}</div>
    </div>

  </div>
</template>

<script>

import themes from "@/themes/themes-helper";
import SelectThemesCategories from "@/themes/select/select-themes-categories";
import {uuid} from "vue-uuid";
import {isDefined, scrollToTop} from "@/utils";

export default {
  name: "select-themes",
  components: {SelectThemesCategories},
  props: {
    userThemes: {
      default: undefined,
      required: true
    },
    showStartTab: {
      default: false,
      required: false
    },
    startTabId: {
      required: false
    },
    isUpdate: {
      default: false,
      required: false
    },
    userRole: {
      default: undefined,
      required: true
    }
  },
  watch: {
    startTabId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (isDefined(newVal)) {
          this.activeTabId = newVal;
        }
      }
    }
  },
  data() {
    return {
      activeTabId: themes.getStartTabId(this.userRole),
      availableThemes: undefined,
      tabs: [],
      loaded: false,
      floatingErrorMessage: undefined,
    }
  },
  created() {
    this.$set(this, 'availableThemes', themes.getThemesForThemeSelectByLanguage(this.$getCurrentLanguage()));
    this.addKeysForUpdateToAllThemes();
    this.createTabs();
  },
  mounted() {
    const self = this;
    this.$nextTick(function () {
      if (self.userThemes) {
        self.applyUserThemes();
        self.onFinishedLoading();
      }
    });
  },
  methods: {
    createTabs() {
      const tabs = [];
      if (this.showStartTab) {
        tabs.push({
          id: 'start-tab',
          categoriesInTab: [],
          deactivated: true
        });
      }
      if (this.$createProfileEnabled) {
        tabs.push({
          id: 'profile-create-tab',
          categoriesInTab: [],
          deactivated: true
        });
      }
      tabs.push(...themes.getThemeSelectTabs());
      this.tabs = tabs;
    },
    async onFinishedLoading() {
      this.loaded = true;
      await this.$nextTick();
      scrollToTop();
    },
    addKeysForUpdateToAllThemes() {
      for (const category of this.availableThemes) {
        if (category.showChildrenSeparately && category.children) {
          for (const child of category.children) {
            this.$set(child, 'key', uuid.v4());
          }
          continue;
        }
        // key is used to update component with Vue :key property
        this.$set(category, 'key', uuid.v4());
      }
    },
    onUpdate(categoryId) {
      this.checkForAllSelectedCategoriesAndEnableCheckboxes();
      const isTabValid = this.validateCategoriesInTab(this.activeTabId, categoryId);
      if (isTabValid !== true) {
        this.floatingErrorMessage = isTabValid.message;
      } else {
        this.floatingErrorMessage = undefined;
      }
      let categoryToUpdate = this.availableThemes.find(c => c.id === categoryId); // search flat in themes first for performance
      if (categoryToUpdate !== undefined) {
        this.$set(categoryToUpdate, 'key', uuid.v4());
      } else {
        categoryToUpdate = this.findCategory(categoryId);
        if (categoryToUpdate !== undefined) {
          this.$set(categoryToUpdate, 'key', uuid.v4());
        }
      }
    },
    findRootForCategory(categoryId) {
      for (const c of this.availableThemes) {
        if (c.id === categoryId) {
          return c;
        }
        let category = this.findRootForCategoryTraverse(c.children, categoryId);
        if (category !== undefined) {
          return c;
        }
      }
      return undefined;
    },
    findRootForCategoryTraverse(children, categoryId) {
      for (const child of children) {
        if (child.children) {
          if (child.id === categoryId) {
            return child;
          } else {
            this.findCategoryTraverse(child.children, categoryId);
          }
        }
      }
      return undefined;
    },
    findCategory(categoryId) {
      for (const c of this.availableThemes) {
        if (c.id === categoryId) {
          return c;
        }
        let category = this.findCategoryTraverse(c.children, categoryId);
        if (category !== undefined) {
          return category;
        }
      }
      return undefined;
    },
    findCategoryTraverse(children, categoryId) {
      for (const child of children) {
        if (child.children) {
          if (child.id === categoryId) {
            return child;
          } else {
            this.findCategoryTraverse(child.children, categoryId);
          }
        }
      }
      return undefined;
    },
    showCategoriesNotValidErrorMessage(isTabValid) {
      this.$toasted.show(isTabValid.message, {
        duration: 2000,
        position: "top-center"
      });
    },
    showNextTab() {


      for (const [i, tab] of this.tabs.entries()) {
        const isSubTab = (isDefined(tab.subTabs) && isDefined(tab.subTabs.find(t => t.id === this.activeTabId)));
        if (tab.id === this.activeTabId || isSubTab) {
          const isTabValid = this.validateCategoriesInTab(this.activeTabId);
          if (isTabValid === true) {

            if (isSubTab) {
              //Check if active Tab is last Subtab
              let subTabs = this.getSubTabsForTab(tab);
              const currentTabPos = subTabs.findIndex(t => t.id === this.activeTabId);
              if (currentTabPos === subTabs.length - 1) {
                const nextTab = this.tabs[i + 1];
                if (isDefined(nextTab.subTabs)) {
                  this.activeTabId = this.getSubTabsForTab(nextTab)[0].id;
                } else {
                  this.activeTabId = nextTab.id;
                }
              } else {
                const nextTab = subTabs[currentTabPos + 1];
                this.activeTabId = nextTab.id;
              }
            } else {
              const nextTab = this.tabs[i + 1];
              if (isDefined(nextTab.subTabs)) {
                this.activeTabId = this.getSubTabsForTab(nextTab)[0].id;
              } else {
                this.activeTabId = nextTab.id;
              }
            }
            scrollToTop();
          } else {
            this.scrollToCategory(isTabValid.categoryId);
            this.showCategoriesNotValidErrorMessage(isTabValid);
          }
          break;
        }
      }
    },
    // validates selected Categories in Tab. Returns Category id if Category is not Valid, else returns true
    // when optionalCategoryId is set only this category is validated for this tab
    validateCategoriesInTab(tabId, optionalCategoryId) {

      const tab = this.getTabById(tabId);
      const categoriesForTab = this.getThemesForTab(tab);
      const selectedThemes = this.getSelectedThemes(true);

      let categoriesInTab = tab.categoriesInTab;
      if (isDefined(optionalCategoryId)) {
        const rootCategory = this.findRootForCategory(optionalCategoryId);
        if (rootCategory !== undefined) {
          optionalCategoryId = rootCategory.id;
        }
        categoriesInTab = categoriesInTab.filter(c => c === optionalCategoryId);
      }


      for (const category of categoriesInTab) {
        const c = categoriesForTab.find(c => c.id === category);

        if (c.maxSelectCount) {
          if (c.hasSearchAndOffer) {
            if (selectedThemes[category] && selectedThemes[category][tab.selectEntriesAs]) {
              if (selectedThemes[category][tab.selectEntriesAs].length > c.maxSelectCount) {
                return {
                  categoryId: c.id,
                  message: this.$tc('selection-error-too-many', c.maxSelectCount, {
                    max: c.maxSelectCount,
                    title: c.title
                  })
                };
              }
            }
          } else {
            if (selectedThemes[category]) {
              if (selectedThemes[category].length > c.maxSelectCount) {
                return {
                  categoryId: c.id,
                  message: this.$tc('selection-error-too-many', c.maxSelectCount, {
                    max: c.maxSelectCount,
                    title: c.title
                  })
                };
              }
            }
          }
        }

        if (c.required) {
          if (c && !c.hasSearchAndOffer) {
            if (!selectedThemes[category] || selectedThemes[category].length === 0) {
              return {categoryId: c.id, message: this.$t('selection-error-mandatory', {title: c.title})};
            }
          } else if (c && c.hasSearchAndOffer) {
            if (!selectedThemes[category] || !selectedThemes[category][tab.selectEntriesAs] || selectedThemes[category][tab.selectEntriesAs].length === 0) {
              return {categoryId: c.id, message: this.$t('selection-error-mandatory', {title: c.title})};
            }
          }
        }
        if (c.singleton) {
          if (c && !c.hasSearchAndOffer) {
            if (selectedThemes[category] && selectedThemes[category].length > 1) {
              return {categoryId: c.id, message: this.$tc('selection-error-too-many', 1, {max: 1, title: c.title})};
            }
          } else if (c && c.hasSearchAndOffer) {
            if (isDefined(selectedThemes[category]) && isDefined(selectedThemes[category][tab.selectEntriesAs]) && selectedThemes[category][tab.selectEntriesAs].length > 1) {
              return {categoryId: c.id, message: this.$tc('selection-error-too-many', 1, {max: 1, title: c.title})};
            }
          }
        }
      }
      return true;
    },
    validateCurrentActiveTab() {
      return this.validateCategoriesInTab(this.activeTabId);
    },
    // validates Categories of all Tabs. Returns Tab Id and Category id if an Category is not Valid, else return true
    validateAllTabs() {
      for (const tab of this.tabs) {
        const isCategoryValid = this.validateCategoriesInTab(tab.id);
        if (isCategoryValid !== true) {
          return {tabId: tab.id, categoryId: isCategoryValid.categoryId, message: isCategoryValid.message};
        }
      }
      return true;
    },
    getTabById(tabId) {
      for (const t of this.tabs) {
        if (isDefined(t.subTabs)) {
          for (const subTab of t.subTabs) {
            if (subTab.id === tabId) {
              return subTab;
            }
          }
        }
        if (t.id === tabId) {
          return t;
        }
      }
      return undefined;
    },
    isActiveTab(tabId) {
      if (this.activeTabId === tabId) {
        return true;
      }
      //check for subTab

      for (const t of this.tabs) {
        if (isDefined(t.subTabs) && t.id === tabId) {
          for (const subTab of t.subTabs) {
            if (this.activeTabId === subTab.id) {
              return true;
            }
          }
        }
      }
      return false;
    },
    setIsActive(tabId) {
      let tab = this.getTabById(tabId);

      if (!isDefined(tab) || tab.deactivated) {
        return;
      }
      const isTabValid = this.validateCategoriesInTab(this.activeTabId)
      if (isTabValid === true) {
        if (isDefined(tab.subTabs)) {
          let subTabs = this.getSubTabsForTab(tab);
          this.activeTabId = subTabs[0].id;
        } else {
          this.activeTabId = tabId;
        }
      } else {
        this.showCategoriesNotValidErrorMessage(isTabValid);
      }
    },
    hasTabRequiredCategories(tabId) {
      const tab = this.getTabById(tabId);
      for (const category of tab.categoriesInTab) {
        const c = this.availableThemes.find(c => c.id === category);
        if (c.required) {
          return true;
        }
      }
      return false;
    },
    checkForAllSelectedCategoriesAndEnableCheckboxes() {
      for (const category of this.availableThemes) {
        if (category.hasSearchAndOffer) {
          category.allSelectedSearch = this.checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(category.children, 'search')
          category.allSelectedOffer = this.checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(category.children, 'offer');
        } else {
          category.allSelected = this.checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(category.children, 'normal');
        }
      }
    },
    checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(children, selectAs) {
      let allSelected = true;
      for (const child of children) {
        if (child.children) {
          if (selectAs === 'offer') {
            child.allSelectedOffer = this.checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(child.children, 'offer');
            if (!child.allSelectedOffer) {
              allSelected = false;
            }
          } else if (selectAs === 'search') {
            child.allSelectedSearch = this.checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(child.children, 'search');
            if (!child.allSelectedSearch) {
              allSelected = false;
            }
          } else if (selectAs === 'normal') {
            child.allSelected = this.checkForAllSelectedCategoriesAndEnableCheckboxesTraverse(child.children, 'normal');
            if (!child.allSelected) {
              allSelected = false;
            }
          }
        } else {
          if (selectAs === 'offer') {
            if (!child.selected_offer) {
              allSelected = false;
            }
          } else if (selectAs === 'search') {
            if (!child.selected_search) {
              allSelected = false;
            }
          } else if (selectAs === 'normal') {
            if (!child.selected) {
              allSelected = false;
            }
          }
        }
      }
      return allSelected;
    },
    applyUserThemes() {
      for (const [key, value] of Object.entries(this.userThemes)) {
        if (!Array.isArray(value)) {
          if (value.search) {
            for (const entryId of value.search) {
              this.selectEntry(key, entryId, 'search');
            }
          }
          if (value.offer) {
            for (const entryId of value.offer) {
              this.selectEntry(key, entryId, 'offer');
            }
          }
        } else {
          for (const entryId of value) {
            this.selectEntry(key, entryId)
          }
        }
      }
      this.checkForAllSelectedCategoriesAndEnableCheckboxes()
    },
    selectEntry(categoryId, entryId, selectedAs) {
      const category = this.availableThemes.find(category => category.id === categoryId);

      if (category && category.children) {
        this.selectEntryTraverse(category.children, entryId, selectedAs)
      }

      this.$forceUpdate();
    },
    selectEntryTraverse(children, entryId, selectedAs) {
      for (const child of children) {
        if (child.children) {
          this.selectEntryTraverse(child.children, entryId, selectedAs);
        } else {
          if (child.id === entryId) {
            switch (selectedAs) {
              case 'offer':
                this.$set(child, 'selected_offer', true);
                break;
              case 'search':
                this.$set(child, 'selected_search', true);
                break;
              default:
                this.$set(child, 'selected', true);
                break;
            }
          }
        }
      }
    },
    getSubTabsForTab(tab) {
      return tab.subTabs;
    },
    getThemesForTab(tab) {
      return this.availableThemes.filter(category => tab.categoriesInTab.includes(category.id));
    },
    getQuestion(tab, category) {
      return this.$t(tab.id + '-sub-header');
    },
    getSelectedThemes(withoutParentIds) {
      const selectedThemes = {};
      for (const category of this.availableThemes) {
        this.getSelectedThemesInCategoryTraverse(category.children, selectedThemes, category, withoutParentIds);
      }
      return selectedThemes;
    },
    getSelectedThemesInCategoryTraverse(children, selectedThemes, category, withoutParentIds) {
      for (const child of children) {
        if (child.children) {
          if (!withoutParentIds) {
            if (category.hasSearchAndOffer) {
              if (child.allSelectedSearch || child.allSelectedOffer) {
                if (!selectedThemes[category.id]) {
                  selectedThemes[category.id] = {};
                }
                if (child.allSelectedOffer) {
                  if (!selectedThemes[category.id].offer) {
                    selectedThemes[category.id].offer = [];
                  }
                  selectedThemes[category.id].offer.push(child.id);
                }
                if (child.allSelectedSearch) {
                  if (!selectedThemes[category.id].search) {
                    selectedThemes[category.id].search = [];
                  }
                  selectedThemes[category.id].search.push(child.id);
                }
              }
            } else {
              if (child.allSelected) {
                if (!selectedThemes[category.id]) {
                  selectedThemes[category.id] = [];
                }
                selectedThemes[category.id].push(child.id);
              }
            }
          }
          this.getSelectedThemesInCategoryTraverse(child.children, selectedThemes, category, withoutParentIds);
        } else {
          if (category.hasSearchAndOffer) {
            if (child.selected_offer || child.selected_search) {
              if (!selectedThemes[category.id]) {
                selectedThemes[category.id] = {};
              }
              if (child.selected_offer) {
                if (!selectedThemes[category.id].offer) {
                  selectedThemes[category.id].offer = [];
                }
                selectedThemes[category.id].offer.push(child.id);
              }
              if (child.selected_search) {
                if (!selectedThemes[category.id].search) {
                  selectedThemes[category.id].search = [];
                }
                selectedThemes[category.id].search.push(child.id);
              }
            }
          } else {
            if (child.selected) {
              if (!selectedThemes[category.id]) {
                selectedThemes[category.id] = [];
              }
              selectedThemes[category.id].push(child.id);
            }
          }
        }
      }
    },
    onSubmit() {
      const areTabsValid = this.isUpdate ? this.validateCategoriesInTab(this.activeTabId) : this.validateAllTabs();
      if (areTabsValid === true) {
        const selectedThemes = this.getSelectedThemes();
        this.$emit('submit', selectedThemes);
      } else {
        this.scrollToCategory(areTabsValid.categoryId);
        this.showCategoriesNotValidErrorMessage(areTabsValid);
      }
    },
    scrollToCategory(id) {
      const e = document.getElementById(id);
      if (isDefined(e)) {
        e.scrollIntoView();
      }
    }
  }
}
</script>
