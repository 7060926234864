import * as logger from 'loglevel';
import moment from "moment";
import errorHandler from "@/error-handler";
import {componentHistory} from "@/router";

export class Logger {

    _consoleLogs = [];
    _debugLogSend = false;

    constructor() {
        this._consoleLogs = [];
        logger.setLevel(process.env.VUE_APP_LOG_LEVEL);
    }

    log(msg) {
        msg = `${moment().format('YYYY-MM-DD HH:mm:ss:SSS')}: ${msg}`;
        logger.info(msg);
        this._consoleLogs.push(msg);
        if (this._consoleLogs.length > 10) {
            this._consoleLogs.shift();
        }
    }


    sendBasicErrorDump(){
        if(this._debugLogSend){
            return;
        }
        this._debugLogSend = true;
        window.setTimeout(() => {this._debugLogSend = false},2000);
        const dumpMsg = {
            userAgent: window.navigator.userAgent,
            history: componentHistory,
            url: location.href,
            consoleLogs: this.consoleLogs
        }
        this.log("sending debug error dump");
        errorHandler.sendDebugErrorMessage(JSON.stringify(dumpMsg, null, 2)).then();
    }

    sendErrorDump(error,additionalData = undefined) {
        let stackTrace;
        if (error instanceof Error) {
            stackTrace = error.stack;
        } else {
            stackTrace = new Error().stack;
        }
        let errorMsg = {
            error: error.toString(),
            stack: stackTrace,
            userAgent: window.navigator.userAgent,
            history: componentHistory,
            url: location.href,
            consoleLogs: this.consoleLogs,
            data: additionalData
        }

        if (location.hostname !== 'localhost') {
            this.log("Error Dump send")
            errorHandler.sendErrorMessage(JSON.stringify(errorMsg, null, 2)).then();
        }
    }

    error(error, sendDump = false) {
        error = `${moment().format('YYYY-MM-DD HH:mm:ss:SSS')}: ${error}`;
        logger.error(error)
        this._consoleLogs.push(error);
        if (this._consoleLogs.length > 10) {
            this._consoleLogs.shift();
        }
        if (sendDump) {
            this.sendErrorDump(error);
        }
    }


    get consoleLogs() {
        return this._consoleLogs;
    }
}
