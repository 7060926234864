<template>
  <div :id="id" role="dialog" class="dialog" v-if="isOpen()">
    <div class="close-button" v-if="!disableCloseButton" v-on:click="submit(true)">
      <div class="close-button-icon"></div>
    </div>
    <div class="dialog-content">
      <slot></slot>

      <div class="dialog-buttons" v-if="!disableButtons">
        <custom-button :onClicked="submitted" :icon="'fal fa-chevron-right fa-2x'">
          {{ submitButtonText }}
        </custom-button>
        <custom-button v-if="secondButtonText && onSecondButtonSubmit" :onClicked="onSecondButtonClick"
                       :icon="'fal fa-chevron-right fa-2x'">
          {{ secondButtonText }}
        </custom-button>
      </div>
    </div>
    <loading :active.sync="showSpinner"
             :is-full-page="false"
             color="var(--primary-color)">
    </loading>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String
    },
    onSubmit: {
      required: false,
      type: Function
    },
    onSecondButtonSubmit: {
      required: false,
      type: Function
    },
    onCancel: {
      required: false,
      type: Function
    },
    submitButtonText: {
      required: false,
      type: String
    },
    secondButtonText: {
      required: false,
      type: String
    },
    canCloseDialogValidationFunction: {
      required: false,
      type: Function
    },
    disableButtons: {
      required: false,
      type: Boolean
    },
    closeWhenReadyObservable: {
      required: false,
      type: Function
    },
    disableCloseButton:{
      required:false,
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      data: undefined,
      opened: false,
      showSpinner: false
    };
  },
  destroyed() {
    this.removeBackdrop();
  },
  methods: {
    openDialog(data) {
      if (data) {
        this.data = data;
      }
      if (!this.isOpen()) {
        this.opened = true;
        this.createBackdrop();
      }
    },
    createBackdrop() {
      let div = document.createElement('div');
      div.id = `backdrop-${this.id}`;
      div.className = "backdrop";
      document.body.appendChild(div);
    },
    removeBackdrop() {
      let div = document.querySelector(`#backdrop-${this.id}`);
      if (div) {
        div.parentNode.removeChild(div);
      }
    },
    isOpen() {
      return this.opened;
    },
    submitted() {
      this.submit(false);
    },
    showLoadingSpinner() {
      this.showSpinner = true;
    },
    closeLoadingSpinner() {
      this.showSpinner = false;
    },
    onSecondButtonClick() {
      if (this.onSecondButtonSubmit) {
        this.onSecondButtonSubmit(this.data);
      }
      this.closeDialog();
    },
    closeDialogFromOutside() {
      this.opened = false;
      this.removeBackdrop();
    },
    closeDialog() {
      if (this.canCloseDialogValidationFunction) {
        if (this.canCloseDialogValidationFunction()) {
          this.opened = false;
          this.removeBackdrop();
        }
      } else {
        this.opened = false;
        this.removeBackdrop();
      }
    },
    submit(canceled) {
      if (!canceled && this.closeWhenReadyObservable) {
        this.showLoadingSpinner();
        this.closeWhenReadyObservable(this.data).subscribe(resp => {
          if (resp) {
            this.closeDialog();
          }
          this.closeLoadingSpinner();

        });
        return;
      } else {
        this.closeDialog();
      }

      if (canceled) {
        if (this.onCancel) {
          this.onCancel(this.data);
        }
      } else {
        if (this.onSubmit) {
          this.onSubmit(this.data);
        }
      }
    }
  }
}
</script>
