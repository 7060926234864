import Vue from 'vue'
import VueRouter from 'vue-router'
import Recommendation from '../views/networkers/Recommendation.vue'
import Chat from '../views/Chat.vue'
import Contacts from '../views/networkers/Contacts.vue'
import OwnProfile from '../views/Profile-Own.vue'
import EntryPoint from "../views/EntryPoint";
import localStorageHelper from "../localstorage/local-storage";
import themes from '../themes/themes-helper';
import i18n from '../i18n';
import ProfileOthers from "@/views/Profile-Others";
import Appointments from "@/views/appointments/Appointments";
import Groups from '../views/Groups.vue';
import VideoGroup from '../views/VideoGroup.vue';
import VideoCollection from '@/views/videoCollection/VideoCollection';
import {isDefined} from "@/utils";
import Companies from '@/views/companies/Companies';
import Corporates from '@/views/companies/Corporates';
import langHelper from "@/locales/lang-helper";

const {isNavigationFailure, NavigationFailureType} = VueRouter
Vue.use(VueRouter)

export let componentHistory = [];

function addComponentToHistory(component) {
    componentHistory.push(component);
    if (componentHistory.length > 3) {
        componentHistory.shift();
    }
}

const routes = [
    {
        path: '/',
        name: 'EntryPoint',
        component: EntryPoint
    },
    {
        path: '/home',
        name: 'Home',
        component: Recommendation
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/chat/:userId?',
        name: 'Chat',
        component: Chat
    },
    {
        path: '/profile',
        name: 'Profile',
        component: OwnProfile
    },
    {
        path: '/profile/:userId',
        name: 'ProfileOthers',
        component: ProfileOthers
    },
    {
        path: '/contact-requests',
        name: 'ContactRequests',
        component: Chat
    }
]

const enableGroups = process.env.VUE_APP_ENABLE_GROUPS === 'true';
const enableCompanies = process.env.VUE_APP_ENABLE_COMPANIES === 'true';
const enableCorporates = process.env.VUE_APP_ENABLE_CORPORATES === 'true';
const enableVideoCollection = process.env.VUE_APP_ENABLE_VIDEO_COLLECTION === 'true';
const enableAppointments = process.env.VUE_APP_ENABLE_APPOINTMENTS === 'true';

if (enableAppointments) {
    routes.push({
        path: '/appointments',
        name: 'Appointments',
        component: Appointments
    })
}

if (enableGroups) {
    routes.push({
            path: '/events',
            name: 'Groups',
            component: Groups
        },
        {
            path: '/events/:eventId',
            name: 'Group',
            component: VideoGroup
        });
}

if (enableCompanies) {
    routes.push({
        path: '/companies',
        name: 'Companies',
        component: Companies
    });
}

if (enableCorporates) {
    routes.push({
        path: '/corporates',
        name: 'Corporates',
        component: Corporates
    });
}

if (enableVideoCollection) {
    routes.push({
        path: '/content',
        name: 'VideoCollection',
        component: VideoCollection
    });
}

const router = new VueRouter({
    routes/*,
    scrollBehavior() {
        return {x: 0, y: 0}
    }*/
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
            throw err;
        } else {
            if (err.to.name === 'Profile') {
                const fromThemeSelect = err.to.matched[0].instances.default.selectThemes;
                if (fromThemeSelect) {
                    err.to.matched[0].instances.default.onSelectThemesClosedByRouter();
                }
            }
        }
    });
}


router.beforeEach((to, from, next) => {


       // Internet Explorer 6-11
        let notSupported = /*@cc_on!@*/false || !!document.documentMode;

        if (!isDefined(localStorage)) {
            notSupported = true;
        }

        if (!notSupported) {

            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isAndroid = /android/i.test(userAgent);
            const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
            Vue.prototype.$isMobile = isAndroid || isIOS;

            // device detection and redirect when ios or android mobile device
            if (process.env.VUE_APP_MOBILE_REDIRECT_URL_EN && process.env.VUE_APP_MOBILE_REDIRECT_URL_DE) {


                if (isAndroid || isIOS) {
                    window.location = langHelper.getCurrentLang() === "de" ? process.env.VUE_APP_MOBILE_REDIRECT_URL_DE : process.env.VUE_APP_MOBILE_REDIRECT_URL_EN;
                    return;
                }
            }

            if ((!localStorageHelper.getDeviceId() || !localStorageHelper.getUserId() || !localStorageHelper.getUserConfirmed()) && to.name !== 'EntryPoint') {
                addComponentToHistory('EntryPoint');
                if (from.name !== 'EntryPoint') {
                    next({name: 'EntryPoint'});
                }
            } else {
                if (Vue.prototype.$isMyBookFair) {
                    if (!localStorageHelper.getUserCreatedManually() && localStorageHelper.getWentToMyBookFair() && localStorageHelper.getDeviceId()) {
                        themes.checkForThemeChanges();
                        localStorageHelper.deleteWentToMyBookFair();
                    }
                    const dayInMs = 86400000;
                    const currentTimeInMs = new Date().getTime();
                    if (localStorageHelper.getUserCreatedManually() === 'false' && localStorageHelper.getDeviceId() && (!localStorageHelper.getLastMyBookFairCheck() || localStorageHelper.getLastMyBookFairCheck().getTime() - currentTimeInMs > dayInMs)) {
                        themes.checkForThemeChanges();
                    }
                }

                if (isDefined(localStorageHelper.getRegistrationCompleted())) {
                    addComponentToHistory(to.name);
                    next();
                } else if (to.name === 'EntryPoint') {
                    next();
                }

            }
        } else {
            Vue.toasted.show(i18n.t('browser-not-supported'), {
                position: 'top-center',
                duration: null,
                action: {
                    text: i18n.t('dialog-ok'),
                    onClick: () => {
                        history.back();
                    }
                }
            });
        }
    }
);


export default router
