<template>
  <div class="main">
    <div class="side-header" style="margin-bottom: 24px">{{ eventData && eventData.title }}</div>
    <span v-if="hasEventEnded()" class="event-ended-text">{{$t('video-call-ended')}}</span>
    <span class="moderator-hint" v-if="isModerator()">{{$t('you-are-moderator')}}</span>
    <p class="event-info" v-if="eventData && eventData.info" v-html="eventData.info"></p>


    <div class="countdown" v-if="!isEventRunning() && !hasEventEnded() && !isLoading">
      <span>{{ $t('start-of-video-call') }}</span>
      <flip-countdown :deadline="getEventStartTimeAsLocalTime()" :labels="getCountdownLabels()"
                      @timeElapsed="eventTimerElapsed"></flip-countdown>
    </div>


    <div id="chat" ref="chat" class="chats groupchat"
         v-on:mouseover="mouseover"
         v-on:mousemove="mousemove"
         v-on:mouseleave="mouseleave"
    >
      <!-- p id="chat-title" v-if="!chatActive">Group video chat</p -->
      <div ref="chatContainer" class="messaging vld-parent" id="megs_cont">

        <loading :active.sync="isLoading"
                 :is-full-page="false"
                 color="var(--primary-color)"></loading>

        <div style="position:relative;overflow:hidden;height:100%;">
          <div class="chatlistwrapper" style="overflow:hidden;">
            <perfect-scrollbar ref="scroll_user" :options="scrollOptions" style="height:100%">
            <ul id="chatlist" class="chatlist">
              <li class="msgbox" v-for="msg in messages">
                <div v-if="getTimeline(msg)" class="notification">
                  <div class="pending">
                    <span class="p_spam">{{ getNotification(msg) }}</span>
                  </div>
                </div>
                <div v-if="msg.sender_id != userData.id" :id="getMessageId(msg)">
                  <!-- div v-if="needMessageName(msg)" class="incoming_msg_name">
                    <p>{{ getMessageName(msg) }}</p>
                  </div -->
                  <div class="incoming_msg_img" v-on:click="loadUserAndOpenProfile(msg)">
                    <img v-if="getMessageThumbnail(msg)" :src="getMessageThumbnail(msg)" :alt="getMessageInitials(msg)"
                         class="img" title="Open profile">
                    <div v-else>{{ getMessageInitials(msg) }}</div>
                  </div>
                  <div class="received_msg">
                    <div class="received_withd_msg"><span class="msg_arrow_l"></span>
                      <div><strong>{{ getMessageName(msg) }}</strong><span class="time_date"> {{
                          getTimestamp(msg)
                        }}</span></div>
                      <div :class="{contact_request:!msg.message}" v-html="escapeHtml(getMessage(msg))"></div>
                    </div>
                  </div>
                </div>
                <div v-else :id="getMessageId(msg)" class="outgoing_msg">
                  <div v-if="needMessageName(msg, true)" class="sent_msg">
                    <span class="msg_arrow"></span>
                    <div class="time_date">{{ getTimestamp(msg) }}<span class="status"
                                                                      :id="getStatusId(msg)">{{ getStatus(msg) }}</span>
                    </div>
                    <div :class="{contact_request:!msg.message}" v-html="escapeHtml(getMessage(msg))"></div>
                  </div>
                </div>
              </li>
            </ul>
            </perfect-scrollbar>
          </div>
          <VEmojiPicker ref="emoji_picker" @select="selectEmoji" :i18n="emojiI18n" :localStoragePrefix="localStorageKeyPrefix()" :writeInLocalStorage="true" />
        </div>
        <div class="type_msg" id="footer"
             v-on:mouseover="inputmouseover"
             v-on:mouseleave="inputmouseleave">
          <div class="input_msg_write">
            <div class="input_msg_text_area">
              <div style="min-height:42px;">
                <textarea @input="countCharactersLeft($event.target);" @keydown="handleTextInput($event);"
                          ref="message_input" id="message_input" rows="1" data-min-rows='1' data-max-rows='4'
                          type="text" class="write_msg" :placeholder="$t('chat-input-placeholder')" maxlength="1000"
                          style="overflow:auto"></textarea>
              </div>
              <div ref="text_word_count_container" id="text_word_count_container">{{ $t('chat-chars-left') }} <span
                  ref="word_count" id="word_count">1000</span>
              </div>
            </div>
            <div class="emoji-toggle">
              <a @click="toggle_emojis()">
                <i class="fal fa-smile-plus fa-2x"></i>
              </a>
            </div>
            <div ref="message_send_button" id="message_send_button" class="input_msg_button">
              <button class="msg_send_btn" type="button" @click="sendMessage()">
                <img src="../assets/icons/chat_send_message_icon.png"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="call-control" ref="call_control">
        <!-- div id="mousetrap"></div -->
        <div id="call-setup" class="call-setup-action">
          <a @click="hangup($event);"><img src="@/assets/img/phone-hangup.svg"></a>
        </div>
        <div id="call-audio" class="call-control">
          <a @click="toggle_audio($event)">
            <img id="call-audio-img-on" ref="call_audio_img_on" src="@/assets/img/microphone-on.svg"
                 title="Mute">
            <img id="call-audio-img-off" ref="call_audio_img_off" class="on" src="@/assets/img/microphone-off.svg"
                 title="Unmute"></a>
          <a @click="toggle_video($event)">
            <img id="call-video-img-on" ref="call_video_img_on" src="@/assets/img/video-on.svg"
                 title="Camera off">
            <img id="call-video-img-off" ref="call_video_img_off" src="@/assets/img/video-off.svg"
                 title="Camera on"></a>
          <a @click="share_screen($event)">
            <img id="call-share-screen-on" ref="call_share_screen_on" src="@/assets/img/share-on.svg"
                 title="Share Screen">
            <img id="call-share-screen-off" ref="call_share_screen_off" src="@/assets/img/share-off.svg"
                 title="Stop Sharing Screen">
            <img id="call-share-screen-off2" src="@/assets/img/share-off2.svg" title="Stop sharing screen"></a>
          <a @click="toggle_raise_hand($event)">
            <img id="call-raise-hand-on" ref="call_raise_hand_on" src="@/assets/img/raise-hand-on.svg"
                 title="Raise Hand">
            <img id="call-raise-hand-off" ref="call_raise_hand_off" src="@/assets/img/raise-hand-off.svg"
                 title="Lower Hand"></a>
          <a @click="toggle_fullscreen($event)" ref="fullscreen_button">
            <img id="call-full-screen-on" ref="call_fullscreen_on" src="@/assets/img/fullscreen-on.svg"
                 title="Fullscreen">
            <img id="call-full-screen-off" ref="call_fullscreen_off" src="@/assets/img/fullscreen-off.svg"
                 title="Fullscreen off"></a>
        </div>
      </div>
      <div v-if="chatActive" ref="toggle_control" class="chat_side_menu">
        <div class="chat_side_menu_button">
          <a v-if="chatVisible" @click="toggleChatVisibility()">
            <i class="fas fa-comment-dots"></i>
            <div> {{ $t('toggle-chat-video') }} </div>
            <span class="badge" v-if="msgsReceivedInChat > 0"> {{ msgsReceivedInChat }} </span>
          </a>
          <a v-else @click="toggleChatVisibility()">
            <i class="fas fa-video"></i>
            <div> {{ $t('toggle-chat-video') }} </div>
          </a>
        </div>
      </div>
    </div>


    <div class="join-chat-buttons" v-if="!hasEventEnded()">
      <custom-button v-if="!chatTestActive && (isEventRunning() || isModerator()) && isJitsiApiLoaded()"
                     :onClicked="toggleChat"
                     :icon="'fal fa-chevron-right fa-2x'">
        {{ chatActive ? $t('leave-video-call') : $t('join-video-call') }}
      </custom-button>
      <custom-button v-if="!chatActive || chatTestActive && isJitsiApiLoaded()" :onClicked="toggleTestChat"
                     :icon="'fal fa-chevron-right fa-2x'">
        {{ chatActive ? $t('leave-test-video-call') : 'Test system setup' }}
      </custom-button>
    </div>
    <!-- custom-button v-if="chatActive" :onClicked="toggleChatVisibility" :icon="'fal fa-chevron-right fa-2x'" >
        {{ 'Toggle chat' }}
    </custom-button -->
    <custom-dialog ref="leaveCallWarningDialog" id="leaveCallWarningDialog" :submit-button-text="$t('proceed')"
                   :second-button-text="$t('stay')" :on-second-button-submit="()=>{}"
                   :on-submit="onLeaveCallWarningDialogProceed">
      <span class="header">{{ $t('leave-video-call-warning-dialog-text') }} </span>
    </custom-dialog>

    <custom-dialog ref="countdownElapsedDialog" id="countdownElapsedDialog" :submit-button-text="$t('join-video-call')"
                   :second-button-text="$t('dialog-no')" :on-submit="toggleChat" :on-second-button-submit="() => {}">
      <span class="header">{{$t('video-group-countdown-elapsed-dialog-text')}} </span>
    </custom-dialog>

    <custom-dialog ref="apiLoadingFailedDialog" id="apiLoadingFailedDialog" :submit-button-text="$t('retry')"
                   :second-button-text="$t('cancel')" :on-submit="loadJitsiApi" :on-second-button-submit="() => {}">
      <span class="header">{{$t('video-group-api-failed-dialog-text')}} </span>
    </custom-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import backend from '../mm-backend'
import localStorageHelper from "../localstorage/local-storage";
import {uuid} from 'vue-uuid';
import {chatMixIn} from "@/mixins/chat-mixin";
import screenfull from 'screenfull';
import FlipCountdown from "vue2-flip-countdown";
import moment from "moment";
import {isDefined} from "@/utils";
import {eventBus, logger} from "@/main";

export default {
  mixins: [chatMixIn],
  variable: {
    api: undefined,
    previousUser: undefined,
  },
  components: {FlipCountdown},
  data: function () {
    return {
      isLoading: true,
      userData: localStorageHelper.getUser(),
      moderators: undefined,
      groupData: '',
      eventData: undefined,
      messages: [],
      chatActive: false,
      chatTestActive: false,
      chatVisible: false,
      chatRaisedHand: false,
      chatInputActive: false,
      filmstripDisplayVisible: true,
      msgsReceivedInChat: 0,
      timer: undefined,
      eventStarted: false,
      eventDateFormat: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.chatActive) {
      this.$refs.leaveCallWarningDialog.openDialog(next);
    } else {
      next();
    }
  },
  destroyed() {
    window.onbeforeunload = null;
  },
  mounted: function () {
    const self = this;
    window.onbeforeunload = function (event) {
      if (self.chatActive) {
        // Cancel the event
        event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        event.returnValue = '';
      }
    };
    this.get();
    if (screenfull.isEnabled) {
      screenfull.on('change', this.fullscreenchanged);
    }
  },
  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off('change', this.fullscreenchanged);
    }
    this.$emit('subscribeGroup', '');
    this.$root.$off('group_msg');
    this.stopCallControlTimer();
  },
  methods: {
    getCountdownLabels() {
      if (this.$getCurrentLanguage() === 'de') {
        return {
          days: 'Tage',
          hours: 'Stunden',
          minutes: 'Minuten',
          seconds: 'Sekunden',
        };
      }
      return undefined; // countdown uses en as language when labels are undefined
    },
    onLeaveCallWarningDialogProceed(next) {
      next();
    },
    isJitsiApiLoaded() {
      return window.JitsiMeetExternalAPI;
    },
    loadJitsiApi() {
      const apiPath = this.eventData.video_server ? this.eventData.video_server : 'https://' + process.env.VUE_APP_GROUP_CHAT_SERVER + '/external_api.js';
      logger.log("Loading Jitsi Api: " + apiPath );
      this.loadScript(apiPath, () => {
        if (!window.JitsiMeetExternalAPI) {
           this.$refs.apiLoadingFailedDialog.openDialog();
        }
      });
    },
    get() {
      const self = this;
      backend.getEventById(this.$route.params.eventId).then(resp => {
        const event = resp.data.event;
        self.moderators = resp.data.moderators;
        self.eventData = event;
        self.groupData = event.group;
        this.$emit('subscribeGroup', self.groupData.id);
        self.loadMessages();
        if (self.eventData.video_server) {
          logger.log("custom video server: " + self.eventData.video_server);
        }
        self.loadJitsiApi();
        if (self.isEventRunning()) {
          self.$refs.countdownElapsedDialog.openDialog();
        }
      });

      this.$root.$on('group_msg', (msg) => {
        if (msg.sender_id != this.userData.id) {
          this.loadMessages();
        }
        if (this.chatActive && this.chatVisible) {
          this.msgsReceivedInChat++;
          this.$refs.toggle_control.style.opacity = 1;
        }
      });
    },
    loadMessages() {
      const self = this;
      this.isLoading = true;
      backend.getGroupMessages(this.groupData.id).then(function (resp) {
        self.messages = resp.data.messages;
        self.isLoading = false; //loading finished
        if (self.initialLoading) {
          self.initialLoading = false;
          self.$nextTick(function () {
            self.countCharactersLeft(self.$refs.message_input);
          });
        }
        self.$nextTick(function () {
          self.scrollToBottom();
        });
      }, error => {
        if (self.initialLoading) {
          self.initialLoading = false;
        }
        self.isLoading = false;
      });
    },
    sendMessageText(message) {
      const timestamp = new Date();

      if (!message || message.length == 0 || message.trim().length == 0) return;

      this.$refs.message_input.value = '';
      this.countCharactersLeft(this.$refs.message_input);

      const self = this;
      let writtenAt = timestamp.toISOString();
      writtenAt = writtenAt.replace('T', ' ');
      writtenAt = writtenAt.substring(0, writtenAt.length - 5);
      const tempId = uuid.v1();
      this.messages.push({
        id: tempId,
        message: message,
        created_at: writtenAt,
        sender_id: this.userData.id,
      });
      this.scrollToBottom();
      self.$refs.message_input.disabled = true;
      backend.sendGroupMessage(message, tempId, this.groupData.id, writtenAt).then(function (resp) {
        self.$refs.message_input.disabled = false;
        self.$refs.message_input.focus({preventScroll: true});
        const index = self.messages.findIndex(msg => msg.id === tempId);
        if (index >= 0) {
          Vue.set(self.messages, index, resp.data);
        }
      }).catch(function (error) {
        self.$toasted.global.error_notification({message: 'Could not send message'});
        self.$refs.message_input.disabled = false;
      });
    },
    getMessage(message) {
      return message.message;
    },
    getStatus (message) {
      let status;
      if (message.read == "1") {
        status = this.$t('chat-status-read');
      } else if (message.sent == "1") {
        status = this.$t('chat-status-delivered');
      } else {
        status = this.$t('chat-status-sent');
      }
      return status;
    },
    needMessageName(msg, force) {
      if (msg.sender_id != this.previousUser) {
        this.previousUser = msg.sender_id;
        return true;
      } else {
        return false || force === true;
      }
    },
    getMessageName(msg) {
      return msg.sender_fullName/* + ':'*/;
    },
    getMessageInitials(msg) {
      return msg.sender_initials;
    },
    loadUserAndOpenProfile(msg) {
      const self = this;
      this.isLoading = true;
      backend.user(msg.sender_id).then(resp => {
        const user = resp.data;
        eventBus.$emit('open-profile-dialog', user, false);
        self.isLoading = false;
      }, error => {
        this.$toasted.show("Could not load user", {
          position: "top-center",
          duration: 2000
        });
        self.isLoading = false;
      })
    },
    getMessageThumbnail: function (msg) {
      return msg.sender_photo ? msg.sender_photo : null;
    },
    loadScript(src, cb) {
        const scriptEl = document.createElement('script');
        scriptEl.addEventListener('load', cb);
        scriptEl.addEventListener('error',cb)
        scriptEl.src = src;
        scriptEl.async = true;
        document.querySelector('head').appendChild(scriptEl);
    },
    toggleTestChat() {
      this.chatTestActive = true;
      this.toggleChatRoom(process.env.VUE_APP_PUSH_NAME + '-test', this.groupData.jwt_test);
    },
    toggleChat() {
      this.chatTestActive = false;
      this.toggleChatRoom(process.env.VUE_APP_PUSH_NAME + '-group-' + this.groupData.id, this.groupData.jwt);
    },
    toggleChatRoom(roomName, jwt) {
      if (this.chatActive) {
        this.stopCallControlTimer();
        this.api.executeCommand('hangup');
        if (document.fullscreenElement) {
          this.toggle_fullscreen();
        }
        this.chatActive = false;
        this.chatTestActive = false;
        this.chatVisible = false;
        this.msgsReceivedInChat = 0;
        this.$refs.call_control.style.visibility = 'hidden';
        this.$refs.chatContainer.style.display = 'flex';
        this.api.dispose();
        this.api = undefined;
      } else {
        const domain = this.eventData.video_server ? new URL(this.eventData.video_server).hostname : process.env.VUE_APP_GROUP_CHAT_SERVER;
        const options = {
          roomName: roomName,
          userInfo: {
            displayName: this.userData.getName()
          },
          configOverwrite: {
            // https://github.com/jitsi/jitsi-meet/blob/master/react/features/base/devices/middleware.js
            // https://github.com/jitsi/jitsi-meet/blob/master/config.js#L1201
            notifications: [
              'connection.CONNFAIL',
              'dialog.cameraNotFoundError',
              'dialog.cameraNotSendingData',
              'dialog.cameraPermissionDeniedError',
              'dialog.cameraUnknownError',
              'dialog.micNotFoundError',
              'dialog.micNotSendingData',
              'dialog.micPermissionDeniedError',
              'dialog.micUnknownError',
              'toolbar.talkWhileMutedPopup'
            ],
            disableRemoteMute: true,
            remoteVideoMenu: {
              disableKick: true
            },
            disableThirdPartyRequests: false,
            startWithVideoMuted: false,
            enableWelcomePage: false,
            disableProfile: true,
            disableDeepLinking: true,
            prejoinPageEnabled: false,
            hideConferenceTimer: true,
            hideConferenceSubject: true,
            hideParticipantsStats: true
          },
          interfaceConfigOverwrite: {
            SET_FILMSTRIP_ENABLED: false,
            DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
            DISABLE_VIDEO_BACKGROUND: true,
            VERTICAL_FILMSTRIP: false,
            CONNECTION_INDICATOR_DISABLED: true,
            VIDEO_QUALITY_LABEL_DISABLED: true,
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            DISABLE_FOCUS_INDICATOR: true,
            DEFAULT_BACKGROUND: 'lightgray',
            JITSI_WATERMARK_LINK: '',
            LANG_DETECTION: false,
            MOBILE_APP_PROMO: true,
            SHOW_CHROME_EXTENSION_BANNER: false,
            SHOW_DEEP_LINKING_IMAGE: false,
            SHOW_POWERED_BY: false,
            TOOLBAR_BUTTONS: [
              /*'microphone', 'camera', 'hangup', 'fullscreen', 'raisehand'*/
            ]
          },
          parentNode: document.querySelector("#chat"),
          jwt: jwt,
        };
        this.api = new window.JitsiMeetExternalAPI(domain, options);
        this.api.addListener('videoConferenceJoined', function (event) {
          this.api.executeCommand('avatarUrl', this.userData.getThumbnail());
        }.bind(this));
        this.api.addListener('readyToClose', this.readyToClose);
        this.api.addListener('cameraError', this.setImageState);
        this.api.addListener('micError', this.setImageState);
        this.api.addListener('audioAvailabilityChanged', this.setImageState);
        this.api.addListener('audioMuteStatusChanged', this.setImageState);
        this.api.addListener('videoAvailabilityChanged', this.setImageState);
        this.api.addListener('videoMuteStatusChanged', this.setImageState);
        this.api.addListener('audioMuteStatusChanged', this.setImageState);
        this.api.addListener('screenSharingStatusChanged', function (event) {
          if (event.on) {
            this.setVideoScreenShareState('none', 'inherit');
          } else {
            this.setVideoScreenShareState('inherit', 'none');
          }
        }.bind(this));
        if (screenfull.isEnabled) {
          this.$refs.fullscreen_button.style.display = 'initial';
        } else {
          this.$refs.fullscreen_button.style.display = 'none';
        }
        this.filmstripDisplayVisible = true;
        this.api.addListener('filmstripDisplayChanged', function (event) {
          this.filmstripDisplayVisible = event.visible;
        }.bind(this));
        this.api.addListener('contentSharingParticipantsChanged', function (event) {
          if (event.data && event.data.length > 0) {
            if (this.filmstripDisplayVisible) {
              this.api.executeCommand('toggleFilmStrip');
            }
          } else {
            if (!this.filmstripDisplayVisible) {
              this.api.executeCommand('toggleFilmStrip');
            }
          }
        }.bind(this));

        this.chatRaisedHand = false;
        this.setVideoRaiseHandState('inherit', 'none');
        this.chatActive = true;
        this.chatVisible = true;
        this.$refs.call_control.style.visibility = 'visible';
        this.$refs.chatContainer.style.display = 'none';
        this.startCallControlTimer();
        this.setImageState();
      }
    },
    toggleChatVisibility: function (event) {
      this.chatVisible = !this.chatVisible;
      if (this.chatActive) {
        this.api.getIFrame().style.display = this.chatVisible ? 'initial' : 'none';
        this.$refs.chatContainer.style.display = !this.chatVisible ? 'flex' : 'none';
        this.msgsReceivedInChat = 0;
      }
    },
    readyToClose() {
      logger.log('readyToClose');
      if (this.chatActive) {
        this.toggleChat();
      }
    },
    hangup(event) {
      this.api.executeCommand('hangup');
    },
    toggle_audio(event) {
      this.api.executeCommand('toggleAudio');
    },
    toggle_video(event) {
      this.api.executeCommand('toggleVideo');
    },
    toggle_raise_hand(event) {
      this.api.executeCommand('toggleRaiseHand');
      this.chatRaisedHand = !this.chatRaisedHand;
      if (this.chatRaisedHand) {
        this.setVideoRaiseHandState('none', 'inherit');
      } else {
        this.setVideoRaiseHandState('inherit', 'none');
      }
    },
    toggle_fullscreen(event) {
      screenfull.toggle(this.$refs.chat);
    },
    fullscreenchanged(event) {
      if (screenfull.isFullscreen) {
        this.setVideoFullScreenState('none', 'inherit');
      } else {
        this.setVideoFullScreenState('inherit', 'none');
      }
    },
    share_screen(event) {
      this.api.executeCommand('toggleShareScreen');
    },
    mouseover() {
      if (this.chatActive) {
        this.showCallControl('0.3s');
        this.startCallControlTimer();
      }
    },
    mousemove() {
      if (this.chatActive) {
        this.showCallControl();
        this.startCallControlTimer();
      }
    },
    mouseleave() {
      if (this.chatActive) {
        this.hideCallControl('0.3s');
        this.startCallControlTimer();
      }
    },
    inputmouseover() {
      if (this.chatActive && !this.chatVisible) {
        this.chatInputActive = true;
        this.mousemove();
      }
    },
    inputmouseleave: function () {
      this.chatInputActive = false;
    },
    startCallControlTimer() {
      this.stopCallControlTimer();
      const self = this;
      this.timer = setTimeout(function () {
        self.hideCallControl('1s');
      }, 3000);
    },
    stopCallControlTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
    showCallControl(duration) {
      this.setCallControl(true, duration);
    },
    hideCallControl(duration) {
      this.setCallControl(false, duration);
    },
    setCallControl(enabled, duration) {
      this.$refs.call_control.style.transition = 'opacity ' + (duration !== undefined ? duration : '0s') + ' linear';
      this.$refs.call_control.style.opacity = enabled ? 1 : 0;
      this.$refs.call_control.style.display = (this.chatInputActive || this.$refs.message_input == document.activeElement) ? 'none' : 'initial';
      this.$refs.toggle_control.style.transition = 'opacity ' + (duration !== undefined ? duration : '0s') + ' linear';
      this.$refs.toggle_control.style.opacity = enabled ? 1 : 0;
    },
    setImageState() {
      const self = this;
      this.api.isAudioAvailable().then(available => {
        if (available) {
          self.api.isAudioMuted().then(muted => {
            if (muted) {
              self.setAudioImageState('none', 'inherit');
            } else {
              self.setAudioImageState('inherit', 'none');
            }
          });
        } else {
          self.setAudioImageState('none', 'none');
        }
      });
      this.api.isVideoAvailable().then(available => {
        if (available) {
          self.api.isVideoMuted().then(muted => {
            if (muted) {
              self.setVideoImageState('none', 'inherit');
            } else {
              self.setVideoImageState('inherit', 'none');
            }
          });
        } else {
          self.setVideoImageState('none', 'none');
        }
      });
    },
    setAudioImageState(on, off) {
      this.$refs.call_audio_img_on.style.display = on;
      this.$refs.call_audio_img_off.style.display = off;
    },
    setVideoImageState(on, off) {
      this.$refs.call_video_img_on.style.display = on;
      this.$refs.call_video_img_off.style.display = off;
    },
    setVideoScreenShareState(on, off) {
      this.$refs.call_share_screen_on.style.display = on;
      this.$refs.call_share_screen_off.style.display = off;
    },
    setVideoRaiseHandState(on, off) {
      this.$refs.call_raise_hand_on.style.display = on;
      this.$refs.call_raise_hand_off.style.display = off;
    },
    setVideoFullScreenState(on, off) {
      this.$refs.call_fullscreen_on.style.display = on;
      this.$refs.call_fullscreen_off.style.display = off;
    },
    isEventRunning() {
      if (isDefined(this.eventData)) {
        if (this.eventStarted) {
          return true;
        } else {
          const eventStartTime = moment.utc(this.eventData.start, this.eventDateFormat);
          const eventEndTime = moment.utc(this.eventData.end, this.eventDateFormat);
          const currentTime = moment().utc();
          return currentTime.isBetween(eventStartTime, eventEndTime);
        }
      }
    },
    hasEventEnded() {
      if (isDefined(this.eventData)) {
        const millisUntilEventEnd = moment.utc(this.eventData.end, this.eventDateFormat).toDate() - Date.now();
        return millisUntilEventEnd < 0;
      }
    },
    getEventStartTimeAsLocalTime() {
      const eventDateFormat = 'YYYY-MM-DD HH:mm:ss';
      return moment.utc(this.eventData.start, eventDateFormat).local().format(eventDateFormat);
    },
    eventTimerElapsed() {
      logger.log("Event Started!");
      this.$refs.countdownElapsedDialog.openDialog();
      this.eventStarted = true;
    },
    isModerator() {
      const userId = localStorageHelper.getUserId()
      if (this.moderators !== undefined && this.moderators.length > 0) {
        const moderator = this.moderators.findIndex(m => m.id === +userId);
        return moderator > -1;
      }
      return false;
    }
  }
}
</script>

<style lang="less" scoped>


.join-chat-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  .custom-button {
    margin-right: 0 !important;
    margin-left: 24px;
  }
}


.moderator-hint {
  font-style: italic;
}

.event-ended-text {
  font-style: italic;
}

.countdown {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  div {
    flex-direction: row;
    display: flex;
    margin: 0;
  }
}

#chat {
  height: 70vh;
  width: 100%;
  border: solid 2px var(--primary-color);
  vertical-align: middle;
  position: relative;

  /*p {
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
  }*/

  /*iframe {
    pointer-events: none
  }*/
}

#chat:fullscreen {
  border: none;
}

#mousetrap {
  width: 100%;
  height: 100%;
  position: absolute;
}

#call-audio, #call-setup {
  pointer-events: none;

  a {
    cursor: pointer;
    pointer-events: initial;
  }
}

.groupchat {
  #footer {
    border-left: 0px;
  }

  .incoming_msg_name p {
    margin-top: 0px;
  }
}

.event-info {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chat_side_menu {
  .badge {
    background-color: var(--warning-color);
    margin-left: 4px;
    top: 2px;
  }
}
</style>
