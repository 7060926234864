<template>
  <div class="main profile vld-parent" v-visibility-change="visibilityChange">

    <loading :active.sync="isLoading"
             :is-full-page="false"
             color="var(--primary-color)"></loading>
    <select-themes ref="selectThemes" v-on:submit="saveThemes"
            v-if="selectThemes"
            v-bind:user-themes="userData.themes"
            v-bind:user-role="userData.role"
            v-bind:start-tab-id="selectThemesStartTabId" :isUpdate="true"></select-themes>

    <!-- Confirm Themes Dialog -->
    <custom-dialog :id="'confirm-themes-dialog'" ref="confirmThemesDialog"
                   :on-submit="saveThemesAfterConfirmWithRedirect"
                   :on-second-button-submit="closeThemeSelectWithRedirect"
                   :submit-button-text="$t('dialog-yes')"
                   :second-button-text="$t('dialog-no')">
      <span class="header">{{ $t('confirm-theme-changes') }}</span>
    </custom-dialog>


    <template v-if="!selectThemes">
      <custom-dialog id="complete-profile-wizard-dialog" ref="completeProfilewizardDialog"
                     :disable-buttons="true" :on-cancel="onProfileWizardCancel">
        <form-wizard
            :subtitle="''"
            :next-button-text="$t('next')"
            :back-button-text="$t('back')"
            :finish-button-text="$t('save')"
            @on-complete="saveUserDataFromwizard"
            class="profile-wizard">

          <template slot="title">
            <h2>{{ $t('complete-profile') }}</h2>
          </template>


          <tab-content :title="$t('position')" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-position') }}</span>
              <CustomInputText v-bind:value.sync="userDataNotProvidedByMbf.position"
                               v-bind:place-holder="$t('position')"></CustomInputText>
            </div>
          </tab-content>
          <tab-content :title="$t('phone')" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-tel') }}</span>
              <CustomInputText v-bind:value.sync="userDataNotProvidedByMbf.tel"
                               v-bind:place-holder="$t('phone')"></CustomInputText>
            </div>

          </tab-content>
          <tab-content title="URL" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-url') }}</span>
              <CustomInputUrl v-bind:value.sync="userDataNotProvidedByMbf.web"
                              v-bind:place-holder="'URL'"></CustomInputUrl>
            </div>

          </tab-content>
          <tab-content title="LinkedIn" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-linkedin') }}</span>
              <CustomInputUrl v-bind:value.sync="userDataNotProvidedByMbf.linkedin"
                              v-bind:place-holder="$t('linkedin-profile-url')"></CustomInputUrl>
            </div>
          </tab-content>

          <tab-content title="Twitter" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-twitter') }}</span>
              <CustomInputUrl v-bind:value.sync="userDataNotProvidedByMbf.twitter"
                              v-bind:place-holder="$t('twitter-profile-url')"></CustomInputUrl>
            </div>
          </tab-content>

          <tab-content title="Instagram" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-instagram') }}</span>
              <CustomInputUrl v-bind:value.sync="userDataNotProvidedByMbf.instagram"
                              v-bind:place-holder="$t('instagram-profile-url')"></CustomInputUrl>
            </div>
          </tab-content>

          <tab-content title="Facebook" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-facebook') }}</span>
              <CustomInputUrl v-bind:value.sync="userDataNotProvidedByMbf.facebook"
                              v-bind:place-holder="$t('facebook-profile-url')"></CustomInputUrl>
            </div>
          </tab-content>

          <tab-content :title=" $t('current-headline')" class="tab-content">
            <div class="tab-content-input-wrapper">
              <span>{{ $t('wizard-headline') }}</span>
              <CustomInputTextArea style="margin-top: 40px" v-bind:rows="1"
                                   v-bind:value.sync="userDataNotProvidedByMbf.headline"
                                   v-bind:place-holder="$t('current-headline')"
                                   v-bind:max-length="255"></CustomInputTextArea>
            </div>


          </tab-content>
        </form-wizard>
      </custom-dialog>

      <!-- Logout User Dialog -->
      <custom-dialog :id="'after-logout-profile-dialog'" :on-submit="redirectAfterProfileDelete"
                     :on-cancel="redirectAfterProfileDelete"
                     :submit-button-text="$t('dialog-ok')" ref="logoutSuccessDialog">
            <span class="header">
                {{ $t('logout-successful') }}
            </span>
      </custom-dialog>

      <!-- Delete Profile Dialogs   -->
      <custom-dialog :id="'delete-profile-dialog'" ref="deleteProfileDialog" :on-submit="deleteProfile"
                     :submit-button-text="$t('profile-delete')">
        <span class="header"> {{ $t('profile-delete') }} </span>
        <div v-html="$t('confirm-profile-delete')"></div>
      </custom-dialog>
      <custom-dialog :id="'confirm-delete-all-dialog'" ref="confirmDeleteAllDialog"
                     :on-submit="deleteUserFromAllDevices"
                     :submit-button-text="$t('profile-delete-all')"
                     :on-second-button-submit="deleteUserOnCurrentDevice"
                     :second-button-text="$t('profile-delete-device')">
        <span class="header"> {{ $t('profile-delete-completely') }} </span>
      </custom-dialog>
      <custom-dialog :id="'after-delete-profile-dialog'" :on-submit="redirectAfterProfileDelete"
                     :on-cancel="redirectAfterProfileDelete"
                     :submit-button-text="$t('dialog-ok')" ref="deleteSuccessDialog">
            <span class="header">
                {{ $tc('still-bound-to-devices', boundDevicesCount) }}
            </span>
      </custom-dialog>

      <!-- Reconfirm E-Mail  Dialog   -->
      <custom-dialog :id="'reconfirm-dialog'" ref="reconfirmDialog" :on-submit="loadUser"
                     :submit-button-text="$t('email-confirmed')"
                     :on-second-button-submit="resendEmail"
                     :can-close-dialog-validation-function="canCloseReConfirmDialog"
                     :second-button-text="$t('resend-email')">
        <span class="header"> {{ $t('please-confirm-your-mail') }} </span>
        <div class="reconfirm-dialog-container">
          <i class="fal fa-envelope fa-2x"></i>
          <div class="reconfirm-dialog-text-container">
            <div class="reconfirm-dialog-text"
                 v-html="$tc('please-confirm-your-mail-text',undefined,{email : userData.email})"></div>
          </div>
        </div>
      </custom-dialog>

      <!-- Change E-Mail Dialog -->
      <custom-dialog v-bind:id="'changeEmailDialog'" ref="changeEmailDialog" v-bind:disable-buttons="true"
                     :can-close-dialog-validation-function="canCloseReConfirmDialog"
                     v-bind:onCancel="onChangeEmailDialogCanceled">
        <span class="header">{{ $t('change-email-dialog-header') }}</span>
        <form class="changeEmailDialogForm" v-on:submit.prevent="saveChangedEmail()">
          <CustomInputText v-bind:value.sync="newEmail" v-bind:place-holder="$t('email')"
                           v-bind:custom-type="'email'"
                           v-bind:required="true"></CustomInputText>
          <custom-button v-bind:width="250" v-bind:onClicked="submitChangeEmailForm"
                         v-bind:icon="'fal fa-chevron-right fa-2x'">
            {{ $t('change-email') }}
          </custom-button>
          <input hidden style="display: none" id="submit-change-mail-input" type="submit">
        </form>
      </custom-dialog>

      <!-- Enable Video Chat Dialog -->
      <custom-dialog :id="'enable-video-chat-dialog'" ref="enableVideoChatDialog"
                     :on-submit="enableOrDisableVideoChat(true)"
                     :submit-button-text="$t('dialog-yes')"
                     :on-second-button-submit="enableOrDisableVideoChat(false)"
                     :second-button-text="$t('dialog-no')"
                     :on-cancel="enableOrDisableVideoChat(false)">

        <span class="header">{{ $t('video-chat-enable') }}</span>
        <span class="enable-video-chat-text">
                {{ $t('video-chat-desc') }}<br>
            </span>
        <div class="enable-video-chat-text question">
          {{ $t('video-chat-question') }}
        </div>

        <!-- p>TODO datenschutztexte ?</p -->
      </custom-dialog>

      <!-- Additional Info Dialog -->
      <custom-dialog id="additionalInfosDialog" ref="additionalInfosDialog"
                     v-bind:onSubmit="goToAppointmentsAndOpenReserveTimeDialog"
                     v-bind:submit-button-text="$t('additional-infos-dialog-submit-button-text')"
                     v-bind:second-button-text="$t('additional-infos-dialog-close-button-text')"
                     v-bind:onSecondButtonSubmit="()=>{}">
        <span class="header">{{ $t('additional-infos-dialog-header') }}</span>
        <div class="reserve-time-container">
          <i class="far fa-clock fa-2x"></i>
          <div class="reserve-time-text" v-html="$t('additional-infos-dialog-text')"></div>
        </div>
      </custom-dialog>

      <!-- Save unsaved Changes Dialog -->
      <custom-dialog :id="'save-changes-dialog'" ref="saveChangesDialog"
                     :on-submit="saveChangesAfterConfirmWithRedirect"
                     :on-second-button-submit="closeSavedChangesWithRedirect"
                     :submit-button-text="$t('dialog-yes')"
                     :second-button-text="$t('dialog-no')">
        <span class="header">{{ $t('confirm-theme-changes') }}</span>
      </custom-dialog>

      <div class="side-header-wrapper">
        <div class="side-header">{{ $t('your-profile') }}</div>
        <div class="roleName" v-if="userData.roleName">
          <span>{{ userData.roleName }}</span>
        </div>
      </div>


      <div class="inputs">
        <div class="profile-privacy-disclaimer">{{ $t('profile-privacy-disclaimer') }}</div>
        <form name="form" id="form" class="form" v-on:submit.prevent="saveUserData()" v-if="!isMobile">
          <div class="left-side">
            <MBFSelect v-bind:selected-value.sync="userData.title" v-bind:options="myBookFairTitles"
                       v-bind:place-holder="$t('title')" v-if="$isMyBookFair"></MBFSelect>
            <CustomInputText v-bind:value.sync="userData.title" v-bind:place-holder="$t('title')"
                             v-if="!$isMyBookFair"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.firstName" v-bind:place-holder="$t('firstName')"
                             v-bind:required="true"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.lastName" v-bind:place-holder="$t('lastName')"
                             v-bind:required="true"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.organization"
                             v-bind:place-holder="$t('organization')"></CustomInputText>
            <MBFSelect v-bind:selected-value.sync="userData.country" v-bind:options="countryList"
                       v-bind:place-holder="$t('country')" v-if="showCountrySelect" v-bind:option-selector="'iso_2'"
                       v-bind:translate-function="getDisplayValueForCountry"></MBFSelect>

            <CustomInputText v-bind:value.sync="userData.position"
                             v-bind:place-holder="$t('position')"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.tel" v-bind:place-holder="$t('phone')"></CustomInputText>
            <div class="email-container">
              <CustomInputText v-bind:value.sync="userData.email" v-bind:place-holder="$t('email')"
                               v-bind:custom-type="'email'"
                               v-bind:required="true"></CustomInputText>
              <i v-if="userData && userData.pending_email" class="fas fa-exclamation-triangle fa-lg"
                 v-bind:title="$t('unconfirmed-email-warning-text',{email:userData.pending_email})"></i>
            </div>
            <CustomInputUrl v-bind:value.sync="userData.web" v-bind:place-holder="'URL'"></CustomInputUrl>
            <CustomInputUrl v-bind:value.sync="userData.linkedin"
                            v-bind:place-holder="$t('linkedin-profile-url')"></CustomInputUrl>
            <CustomInputUrl v-bind:value.sync="userData.twitter"
                            v-bind:place-holder="$t('twitter-profile-url')"></CustomInputUrl>
            <CustomInputUrl v-bind:value.sync="userData.instagram"
                            v-bind:place-holder="$t('instagram-profile-url')"></CustomInputUrl>
            <CustomInputUrl v-bind:value.sync="userData.facebook"
                            v-bind:place-holder="$t('facebook-profile-url')"></CustomInputUrl>


            <span>* {{ $t('required-fields') }} </span>

            <input style="display: none" hidden id="submit-input" type="submit">
          </div>

          <div class="right-side">
            <div class="image">
              <span>{{ $t('profile-image') }}</span>
              <img :src="getImage()" alt="Profilbild" ref="img"
                   v-on:error="$refs.img.src = fallbackProfileImageForTemplate"
                   id="profile-image">

              <div class="delete-icon-container" v-if="userData.photo && canDeleteImage()"
                   @click="deleteImg()"><i class="fal fa-trash-alt fa-2x"></i></div>


            </div>
            <div class="own-profile-links">
              <custom-button :onClicked="openFilePickerDialog" :icon="'fal fa-arrow-to-top fa-2x'"
                             class="secondary-button upload-profile-picture-button"
                             :backgroundColor="'dimgrey'" :iconBackGroundColor="'#383838'">
                <input name="profile-image-input" id="profile-image-input" hidden type="file"
                       accept="image/png, image/jpeg"
                       v-on:change="onProfileImageChosen">
                {{ $t('picture-upload') }}
              </custom-button>
            </div>


            <CustomInputTextArea style="margin-top: 40px" v-bind:rows="1" v-bind:value.sync="userData.headline"
                                 v-bind:place-holder="$t('current-headline')"
                                 v-bind:max-length="255"></CustomInputTextArea>


            <custom-check-box v-bind:value.sync="userData.show_contact_data"
                              v-bind:checkbox-text="$t('show-contact-data')"
                              v-if="!isLoading && showContactDataAllowed">
              <label class="enable-video-chat-label">{{ $t('show-contact-data') }}</label>
            </custom-check-box>
            <div class="video-chat-requirements" v-if="showContactDataAllowed">
              <i class="fas fa-info-circle fa-2x"></i>
              <div>{{ $t('show-contact-data-description') }}</div>
            </div>

            <custom-check-box v-bind:value.sync="userData.video_chat_allowed"
                              v-bind:checkbox-text="$t('enable-video-chat')" v-if="!isLoading && $enableVideoChat">
              <label class="enable-video-chat-label">{{ $t('enable-video-chat') }}</label>
            </custom-check-box>

            <div class="push-warning" v-if="showPushWarning() && $enableVideoChat">
              <i class="fas fa-exclamation-triangle fa-2x"></i>
              <div>{{ $t('video-push-warning') }}</div>
            </div>
            <div v-if="showPushHint() && $enableVideoChat">
              <div class="push-hint">
                <i class="fas fa-exclamation-triangle fa-2x"></i>
                <div>{{ $t('video-push-hint') }}</div>
              </div>
              <custom-button class="push-enable-button" :onClicked="registerForPushes"
                             :icon="'fal fa-comment-check fa-2x'">
                {{ $t('enable-push') }}
              </custom-button>
            </div>
            <div class="video-chat-requirements" v-if="$enableVideoChat">
              <i class="fas fa-info-circle fa-2x"></i>
              <div>{{ $t('video-chat-requirements') }}</div>
            </div>

            <div class="own-profile-links">
              <div class="show-profile-container">
                <div class="show-profile-header">{{ $t('how-others-see-profile') }}</div>
                <custom-button class="secondary-button" :onClicked="openProfileDialog"
                               :icon="'fal fa-user-circle fa-2x'">
                  {{ $t('show-profile') }}
                </custom-button>
              </div>

              <div class="copy-profile-link-container">
                <div class="copy-profile-link-header">{{ $t('profile-link-copy-header') }}</div>
                <custom-button class="secondary-button" :onClicked="copyProfileUrlToClipboard"
                               :icon="'fal fa-copy fa-2x'">
                  {{ $t('profile-link-copy') }}
                </custom-button>
              </div>

              <div class="logout-container">
                <div class="logout-header">{{ $t('logout-header') }}</div>
                <custom-button class="secondary-button" :onClicked="onLogoutUserClicked"
                               :icon="'fal fa-sign-out fa-2x'">
                  {{ $t('logout-user') }}
                </custom-button>
              </div>

              <custom-button class="deleteButton" :onClicked="onDeleteProfileClicked" :icon="'fal fa-trash-alt fa-2x'">
                {{ $t('profile-delete') }}
              </custom-button>
            </div>


          </div>
          <div class="save-button">
            <custom-button class="primary-button" :onClicked="submitForm" :icon="'fal fa-chevron-right fa-2x'">
              {{ $t('saving') }}
            </custom-button>
          </div>
        </form>

        <form name="form" id="formMobile" class="form" v-on:submit.prevent="saveUserData()" v-if="isMobile">

          <div class="right-side">
            <div class="image">
              <span>{{ $t('profile-image') }}</span>
              <img :src="getImage()" alt="Profilbild" ref="img"
                   v-on:error="$refs.img.src = fallbackProfileImageForTemplate"
                   id="profile-image">

              <div class="delete-icon-container" v-if="userData.photo && canDeleteImage()"
                   @click="deleteImg()"><i class="fal fa-trash-alt fa-2x"></i></div>


            </div>
            <custom-button :onClicked="openFilePickerDialog" :icon="'fal fa-arrow-to-top fa-2x'"
                           class="secondary-button upload-profile-picture-button"
                           :backgroundColor="'dimgrey'" :iconBackGroundColor="'#383838'">
              <input name="profile-image-input" id="profile-image-input" hidden type="file"
                     accept="image/png, image/jpeg"
                     v-on:change="onProfileImageChosen">
              {{ $t('picture-upload') }}
            </custom-button>


            <CustomInputTextArea style="margin-top: 40px" v-bind:rows="1" v-bind:value.sync="userData.headline"
                                 v-bind:place-holder="$t('current-headline')"
                                 v-bind:max-length="255"></CustomInputTextArea>


            <custom-check-box v-bind:value.sync="userData.video_chat_allowed"
                              v-bind:checkbox-text="$t('enable-video-chat')" v-if="!isLoading && $enableVideoChat">
              <label class="enable-video-chat-label">{{ $t('enable-video-chat') }}</label>
            </custom-check-box>

            <div class="push-warning" v-if="showPushWarning()">
              <i class="fas fa-exclamation-triangle fa-2x"></i>
              <div>{{ $t('video-push-warning') }}</div>
            </div>
            <div v-if="showPushHint()">
              <div class="push-hint">
                <i class="fas fa-exclamation-triangle fa-2x"></i>
                <div>{{ $t('video-push-hint') }}</div>
              </div>
              <custom-button class="push-enable-button" :onClicked="registerForPushes"
                             :icon="'fal fa-comment-check fa-2x'">
                {{ $t('enable-push') }}
              </custom-button>
            </div>
            <div class="video-chat-requirements">
              <i class="fas fa-info-circle fa-2x"></i>
              <div>{{ $t('video-chat-requirements') }}</div>
            </div>
          </div>

          <div class="left-side">

            <MBFSelect v-bind:selected-value.sync="userData.title" v-bind:options="myBookFairTitles"
                       v-bind:place-holder="$t('title')" v-if="$isMyBookFair"></MBFSelect>
            <CustomInputText v-bind:value.sync="userData.firstName" v-bind:place-holder="$t('firstName')"
                             v-bind:required="true"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.lastName" v-bind:place-holder="$t('lastName')"
                             v-bind:required="true"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.organization"
                             v-bind:place-holder="$t('organization')"></CustomInputText>
            <MBFSelect v-bind:selected-value.sync="userData.country" v-bind:options="countryList"
                       v-bind:place-holder="$t('country')" v-if="showCountrySelect" v-bind:option-selector="'iso_2'"
                       v-bind:translate-function="getDisplayValueForCountry"></MBFSelect>
            <CustomInputText v-bind:value.sync="userData.position"
                             v-bind:place-holder="$t('position')"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.tel" v-bind:place-holder="$t('phone')"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.email" v-bind:place-holder="$t('email')"
                             v-bind:custom-type="'email'"
                             v-bind:required="true"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.web" v-bind:place-holder="'URL'"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.linkedin"
                             v-bind:place-holder="$t('linkedin-profile-url')"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.twitter"
                             v-bind:place-holder="$t('twitter-profile-url')"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.instagram"
                             v-bind:place-holder="$t('instagram-profile-url')"></CustomInputText>
            <CustomInputText v-bind:value.sync="userData.facebook"
                             v-bind:place-holder="$t('facebook-profile-url')"></CustomInputText>


            <span>* {{ $t('required-fields') }} </span>


            <div class="own-profile-links">
              <div class="show-profile-container">
                <div class="show-profile-header">{{ $t('how-others-see-profile') }}</div>
                <custom-button class="secondary-button" :onClicked="openProfileDialog"
                               :icon="'fal fa-user-circle fa-2x'">
                  {{ $t('show-profile') }}
                </custom-button>
              </div>

              <div class="copy-profile-link-container">
                <div class="copy-profile-link-header">{{ $t('profile-link-copy-header') }}</div>
                <custom-button class="secondary-button" :onClicked="copyProfileUrlToClipboard"
                               :icon="'fal fa-copy fa-2x'">
                  {{ $t('profile-link-copy') }}
                </custom-button>
              </div>

              <custom-button class="deleteButton" :onClicked="onDeleteProfileClicked" :icon="'fal fa-trash-alt fa-2x'">
                {{ $t('profile-delete') }}
              </custom-button>
            </div>


            <custom-button class="primary-button" :onClicked="submitForm" :icon="'fal fa-chevron-right fa-2x'">
              {{ $t('saving') }}
            </custom-button>

            <input style="display: none" hidden id="submit-input" type="submit">
          </div>
        </form>
      </div>


      <themes-own-profile :user-themes="userData.themes" v-if="!isLoading"
                          v-on:titleClicked="openChangeThemes"></themes-own-profile>
    </template>
  </div>
</template>
<script>
import backend from '../mm-backend'
import themes from '../themes/themes-helper';
import localStorageHelper from "../localstorage/local-storage";
import {countriesStorage, eventBus, logger} from '@/main';
import fallbackProfileImage from '@/assets/img/person.svg';
import {deleteDB} from 'idb';
import {isNotificationDefault, isNotificationDenied, isNotificationSupported, register} from '@/push-backend';
import ThemesRoot from "@/themes/profile-own/themes-own-profile";
import ThemesOwnProfile from "@/themes/profile-own/themes-own-profile";
import CustomCheckBox from "@/components/inputs/CustomCheckBox";
import CustomInputText from "@/components/inputs/CustomInputText";
import CustomInputTextArea from "@/components/inputs/CustomInputTextArea";
import SelectThemes from "@/themes/select/select-themes";
import CustomInputUrl from "@/components/inputs/CustomInputUrl";
import MBFSelect from "@/components/inputs/MBFSelect";
import {deepCopy, isDefined} from "@/utils";

export default {
  components: {
    SelectThemes,
    CustomInputTextArea,
    CustomInputText,
    CustomCheckBox,
    ThemesOwnProfile,
    ThemesRoot,
    CustomInputUrl,
    MBFSelect
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectThemes) {
      const selectedThemes = this.$refs.selectThemes.getSelectedThemes();
      const userThemes = this.$refs.selectThemes.userThemes;
      if (JSON.stringify(userThemes) !== JSON.stringify(selectedThemes)) {
        this.$refs.confirmThemesDialog.openDialog(next);
      } else {
        this.closeThemeSelectWithRedirect(next);
      }
    } else {
      if (this.hasUnsavedChanges() && document.getElementById(this.isMobile ? 'formMobile' : 'form').checkValidity()) {
        this.$refs.saveChangesDialog.openDialog(next);
      } else {
        next();
      }
    }
  },
  data() {
    return {
      isLoading: true,
      userData: {},
      initialUserData: {},
      fallbackProfileImageForTemplate: fallbackProfileImage,
      active_days: {},
      myBookFairTitles: this.$getCurrentLanguage() === 'en' ? ['Dr', 'Prof', 'Prof Dr'] : ['Dr.', 'Prof.', 'Prof. Dr.'],
      lang: this.$getCurrentLanguage(),
      countryList: countriesStorage.getCountryList(),
      showCountrySelect: process.env.VUE_APP_SHOW_COUNTRY_SELECT === 'true',
      deviceId: localStorageHelper.getDeviceId(),
      boundDevicesCount: undefined,
      isMobile: this.$isMobile,
      userDataNotProvidedByMbf: {
        position: undefined,
        web: undefined,
        linkedin: undefined,
        tel: undefined,
        headline: undefined,
        twitter: undefined,
        instagram: undefined,
        facebook: undefined,
      },
      selectThemes: false,
      selectThemesStartTabId: themes.getStartTabId(),
      showContactDataAllowed: process.env.VUE_APP_ALLOW_SHOW_CONTACT_DATA === 'true',
      newEmail: undefined
    }
  },
  beforeMount() {
    eventBus.$on('mbf-themes-updated', function (userThemes) {
      this.userData.themes = userThemes;
    }.bind(this));
    eventBus.$on('themes-edited', this.themesEdited)
    this.loadUser();
    document.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener('keyup',this.onKeyUp);
    eventBus.$off('mbf-themes-updated');
    eventBus.$off('themes-edited', this.themesEdited);
  },
  methods: {
    onKeyUp(e){
      if (e.key === 's' && (e.ctrlKey)) {
        e.preventDefault();
        this.submitForm();
      }
    },
    themesEdited(themes) {
      this.userData.themes = themes;
    },
    hasUnsavedChanges() {
      for (const [key, value] of Object.entries(this.userData)) {
        if (JSON.stringify(this.initialUserData[key]) !== JSON.stringify(value)) {
          return true;
        }
      }
      return false;
    },
    setUserData(data) {
      this.userData = data;
      this.initialUserData = deepCopy(data);
    },
    loadUser() {
      const self = this;
      backend.me().then(function (resp) {
        const confirmed = resp.data.confirmed;
        if (!confirmed) {
          if (isDefined(localStorageHelper.getUser())) {
            self.setUserData(localStorageHelper.getUser());
          }
          localStorageHelper.setUserConfirmed(false);
          self.$toasted.show(self.$t('email-not-confirmed'), {
            duration: 2000,
            position: "top-center"
          });
          self.showReconfirmDialog();
        } else {
          self.setUserData(resp.data.user);
          if (!localStorageHelper.getAlreadyVisited()) {
            let showCompleteWizardDialog = true;
            for (const [key, value] of Object.entries(self.userDataNotProvidedByMbf)) {
              if (isDefined(self.userData[key])) {
                showCompleteWizardDialog = false;
              }
            }
            if (showCompleteWizardDialog && process.env.VUE_APP_ENABLE_PROFILE_CREATE !== 'true') {
              self.$refs.completeProfilewizardDialog.openDialog();
            } else {
              if (self.$enableVideoChat && !isDefined(self.userData.video_chat_enabled) || self.userData.video_chat_enabled === "") {
                self.showEnableVideoChatDialog();
              } else if (self.$showAdditionalInfoDialog) {
                self.showAdditionalInfoDialog();
              }
            }
          }

          localStorageHelper.setUserConfirmed(true);

          localStorageHelper.setUser(resp.data.user);
          self.closeReConfirmDialog();
          if (self.$isMyBookFair && (!localStorageHelper.getUserCreatedManually() || localStorageHelper.getUserCreatedManually() === 'false')) {
            themes.checkForThemeChanges();
          }
          self.$set(self.userData, 'video_chat_allowed', self.userData.video_chat_enabled);
          self.setUserData(self.userData);
        }
        self.$forceUpdate();
        self.isLoading = false; //loading finished
        eventBus.$emit('already-visited');
      }, error => {
        logger.error(error)
        self.isLoading = false;
      });
    },
    canCloseReConfirmDialog() {
      return localStorageHelper.getUserConfirmed();
    },
    getImage() {
      return this.userData.photo ? this.userData.photo : fallbackProfileImage;
    },
    deleteImg() {
      const self = this;
      backend.deleteUserProfilePicture().then(resp => {
        self.$toasted.global.success_notification({message: self.$t('profile-picture-delete-success')});
        this.userData.photo = undefined;
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('profile-picture-delete-error')});
      });
    },
    onLogoutUserClicked() {
      const self = this;
      backend.logoutUser().then(resp => {
        self.clearUserDataAfterProfileDelete();
        self.showLogoutSuccessDialog();
      });
    },
    onDeleteProfileClicked() {
      this.$refs.deleteProfileDialog.openDialog();
    },
    deleteProfile() {
      const self = this;
      backend.requestUserDelete().then(resp => {
        const remainingDevices = resp.data;
        self.boundDevicesCount = resp.data;
        if (remainingDevices > 0) {
          self.showDeleteAllProfileDialog();
        } else {
          self.clearUserDataAfterProfileDelete();
          self.showDeleteProfileSuccessDialog();
        }
      });
    },
    deleteUserFromAllDevices() {
      const self = this;
      backend.deleteUserOnAllDevices().then(resp => {
        self.boundDevicesCount = resp.data;
        self.clearUserDataAfterProfileDelete();
        self.showDeleteProfileSuccessDialog();
      });
    },
    deleteUserOnCurrentDevice() {
      const self = this;
      backend.deleteUserFromCurrentDevice().then(resp => {
        self.boundDevicesCount = resp.data;
        self.clearUserDataAfterProfileDelete();
        self.showDeleteProfileSuccessDialog();
      });
    },
    clearUserDataAfterProfileDelete() {
      localStorageHelper.clear();
      if ('serviceWorker' in navigator && navigator.serviceWorker) {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({cmd: 'delete'});
        } else {
          navigator.serviceWorker.getRegistration().then(function (serviceWorkerRegistration) {
            if (serviceWorkerRegistration) {
              serviceWorkerRegistration.unregister().then(function (boolean) {
                this.deleteDatabase();
              });
            }
          });
        }
      } else {
        this.deleteDatabase();
      }
    },
    async deleteDatabase() {
      await deleteDB('egcf', {
        blocked() {
          logger.log('blocked');
        },
      });
    },
    onChangeEmailDialogCanceled() {
      this.closeChangeEmailDialog();
      this.showReconfirmDialog();
    },
    onEmailChangedClicked() {
      this.closeReConfirmDialog();
      this.showChangeEmailDialog();
    },
    showChangeEmailDialog() {
      this.newEmail = localStorageHelper.getUser().email;
      this.$refs.changeEmailDialog.openDialog();
    },
    showDeleteAllProfileDialog() {
      this.$refs.confirmDeleteAllDialog.openDialog();
    },
    showLogoutSuccessDialog() {
      this.$refs.logoutSuccessDialog.openDialog();
    },
    showDeleteProfileSuccessDialog() {
      this.$refs.deleteSuccessDialog.openDialog();
    },
    showEnableVideoChatDialog() {
      this.$refs.enableVideoChatDialog.openDialog();
    },
    showAdditionalInfoDialog() {
      this.$refs.additionalInfosDialog.openDialog();
    },
    closeChangeEmailDialog() {
      if (this.$refs.changeEmailDialog.isOpen()) {
        this.$refs.changeEmailDialog.closeDialogFromOutside();
      }
    },
    closeAdditionalInfoDialog() {
      if (this.$refs.additionalInfosDialog.isOpen()) {
        this.$refs.additionalInfosDialog.closeDialogFromOutside();
      }
    },
    closeEnableVideoChatDialog() {
      if (this.$refs.enableVideoChatDialog.isOpen()) {
        this.$refs.enableVideoChatDialog.closeDialogFromOutside();
      }
    },
    closeProfileWizardDialog() {
      if (this.$refs.completeProfilewizardDialog.isOpen()) {
        this.$refs.completeProfilewizardDialog.closeDialogFromOutside();
      }
    },
    redirectAfterProfileDelete() {
      window.location = process.env.VUE_APP_EXIT_REDIRECT_URL;
    },
    submitChangeEmailForm() {
      document.getElementById('submit-change-mail-input').click();
    },
    submitForm() {
      const submitInput =  document.getElementById('submit-input');
      if(isDefined(submitInput)) {
        submitInput.click();
      }
    },
    saveUserDataFromwizard() {
      for (const [key, value] of Object.entries(this.userDataNotProvidedByMbf)) {
        this.userData[key] = value;
      }
      this.saveUserData();
      this.closeProfileWizardDialog();
      if (this.$enableVideoChat && !isDefined(this.userData.video_chat_enabled) || this.userData.video_chat_enabled === "") {
        this.showEnableVideoChatDialog();
      } else if (this.$showAdditionalInfoDialog) {
        this.showAdditionalInfoDialog();
      }
    },
    saveThemes(themes) {
      this.userData.themes = themes;
      this.saveUserData();
      this.selectThemes = false;
    },
    saveChangedEmail() {
      const self = this;
      self.userData.email = this.newEmail
      backend.saveData(this.deviceId, this.userData, false).then(resp => {
        const response = JSON.parse(resp);
        if (response.error) {
          self.$toasted.global.error_notification({message: response.error.message});
        } else {
          self.userData.video_chat_enabled = self.userData.video_chat_allowed;
          self.setUserData(self.userData);
          localStorageHelper.setUser(self.userData);
          if (!response.reconfirm && response.confirmed) {
            if (isDefined(self.$refs.completeProfilewizardDialog)) {
              self.$refs.completeProfilewizardDialog.closeDialogFromOutside();
            }
            self.$toasted.global.success_notification({message: self.$t('profile-saved-success')});
            localStorageHelper.setUserConfirmed(true);
            self.$forceUpdate();
          } else {
            localStorageHelper.setUserConfirmed(false);
            self.$refs.changeEmailDialog.closeDialogFromOutside();
            self.showReconfirmDialog();
          }
        }
      });
    },
    saveUserData() {
      const self = this;
      self.isLoading = true;
      backend.registrationCompleted(this.deviceId, this.userData, false).then(resp => {
        const response = JSON.parse(resp);
        if (response.error) {
          self.$toasted.global.error_notification({message: response.error.message});
        } else {
          self.userData.video_chat_enabled = self.userData.video_chat_allowed;
          self.setUserData(self.userData);
          localStorageHelper.setUser(self.userData);
          if (!response.reconfirm) {
            if (isDefined(self.$refs.completeProfilewizardDialog)) {
              self.$refs.completeProfilewizardDialog.closeDialogFromOutside();
            }
            self.$toasted.global.success_notification({message: self.$t('profile-saved-success')});
            localStorageHelper.setUserConfirmed(true);
            self.$forceUpdate();
          } else {
            localStorageHelper.setUserConfirmed(false);
            self.showReconfirmDialog();
          }
        }
        self.isLoading = false;
      },error => {
        self.isLoading = false;
      });
    },
    canDeleteImage() {
      if (!document.getElementById('profile-image')) {
        return false;
      }
      return document.getElementById('profile-image').src !== 'img/person.svg'
    },
    showReconfirmDialog() {
      this.$refs.reconfirmDialog.openDialog();
      this.$nextTick(() => {
        const changeEmailButton = document.getElementById('changeEmailButton');
        if (isDefined(changeEmailButton)) {
          changeEmailButton.onclick = this.onEmailChangedClicked;
        }
      });
    },
    closeReConfirmDialog() {
      if (this.$refs.reconfirmDialog) {
        this.$refs.reconfirmDialog.closeDialogFromOutside()
      }
    },
    openFilePickerDialog() {
      document.getElementById('profile-image-input').click();
    },
    onProfileImageChosen(event) {
      const file = event.target.files[0];
      const self = this;
      if (file) {
        if (file.size > process.env.VUE_APP_MAX_FILE_SIZE_FOR_PROFILE_PICTURE) {
          self.$toasted.global.error_notification({message: self.$tc('file-size-to-big', process.env.VUE_APP_MAX_FILE_SIZE_FOR_PROFILE_PICTURE_TEXT)});
        } else {
          backend.uploadProfileImage(file).then(resp => {
            self.$toasted.global.success_notification({message: self.$t('profile-picture-saved-success')})
            self.loadUser();
          }, error => {
            self.$toasted.global.error_notification({message: self.$t('profile-picture-saved-error')});
          });
        }
      }
    },
    resendEmail() {
      const self = this;
      backend.resendConfirmationEmail().then(resp => {
        self.$toasted.show(self.$t('email-resend'), {
          duration: 2000,
          position: "top-center"
        });
      });
    },
    onProfileWizardCancel() {
      this.saveUserData();
      if (this.$enableVideoChat && !isDefined(this.userData.video_chat_enabled) || this.userData.video_chat_enabled === "") {
        this.showEnableVideoChatDialog();
      } else if (this.$showAdditionalInfoDialog) {
        this.showAdditionalInfoDialog();
      }
    },
    enableOrDisableVideoChat(value) {
      return function () {
        this.closeEnableVideoChatDialog();
        this.userData.video_chat_allowed = value;
        this.userData.video_chat_supported = true;
        this.saveUserData();
        if (this.$showAdditionalInfoDialog) {
          this.showAdditionalInfoDialog();
        }
      }.bind(this);
    },
    visibilityChange: function (evt, hidden) {
      if (this.$isMyBookFair && !hidden && localStorageHelper.getWentToMyBookFair()) {
        themes.checkForThemeChanges();
        localStorageHelper.deleteWentToMyBookFair();
      }
    },
    showPushWarning: function () {
      return this.userData.video_chat_allowed && isNotificationSupported() && isNotificationDenied();
    },
    showPushHint: function () {
      return this.userData.video_chat_allowed && isNotificationSupported() && isNotificationDefault();
    },
    registerForPushes() {
      const self = this;
      register(localStorageHelper.getDeviceId()).then((deviceToken) => {
        this.$forceUpdate();
        logger.log("register push")
        backend.registerv2(localStorageHelper.getDeviceId(), localStorageHelper.getUser(), false).then(resp => {
          logger.log("Register with Push token ");
        });
      }).catch(error => {
        this.$forceUpdate();
      });
    },
    copyProfileUrlToClipboard() {
      const profileUrl = window.location.href + "/" + this.userData.id;
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = profileUrl;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.$toasted.show(this.$t("profile-link-copied"), {
        position: "top-center",
        duration: 2000
      });
    },
    openProfileDialog() {
      eventBus.$emit('open-profile-dialog', this.userData, false);
    },
    openChangeThemes(startTabId) {
      this.selectThemesStartTabId = startTabId;
      this.selectThemes = true;
    },
    saveThemesAfterConfirmWithRedirect(next) {
      const areTabsValid = this.$refs.selectThemes.validateCurrentActiveTab();
      if (areTabsValid === true) {
        this.saveThemes(this.$refs.selectThemes.getSelectedThemes());
        if (next) {
          next();
        }
      } else {
        this.$refs.selectThemes.showCategoriesNotValidErrorMessage(areTabsValid);
      }
    },
    saveChangesAfterConfirmWithRedirect(next) {
      this.saveUserData();
      if (next) {
        next();
      }
    },
    closeThemeSelect() {
      this.selectThemes = false;
    },
    closeThemeSelectWithRedirect(next) {
      this.selectThemes = false;
      if (next) {
        next();
      }
    },
    closeSavedChangesWithRedirect(next) {
      if (next) {
        next();
      }
    },
    onSelectThemesClosedByRouter() {
      const selectedThemes = this.$refs.selectThemes.getSelectedThemes();
      const userThemes = this.$refs.selectThemes.userThemes;
      if (JSON.stringify(userThemes) !== JSON.stringify(selectedThemes)) {
        this.$refs.confirmThemesDialog.openDialog();
      } else {
        this.closeThemeSelect();
      }
    },
    getDisplayValueForCountry(country) {
      return country.getDisplayNameByLanguage(this.$getCurrentLanguage());
    },
    goToAppointmentsAndOpenReserveTimeDialog() {
      this.$router.push({name: 'Appointments', params: {openReserveTimeDialog: 'true'}});
    }
  }
}
</script>

<style lang="less">

.vue-form-wizard .wizard-header {
  padding-top: 0 !important;
}

.wizard-icon-circle {
  width: 36px !important;
  height: 36px !important;
  font-size: 20px !important;
}

.vue-form-wizard.md .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 20px;
}

.vue-form-wizard .wizard-btn {
  border-radius: 0px !important;
}

.vue-form-wizard .wizard-nav-pills > li > a {

  padding: 15px 15px 15px 15px !important;

}

.profile-wizard {
  width: 100%;


  .tab-content {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .tab-content-input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /*min-width: 500px;*/
      min-height: 240px;
      width: 100%;

      > span {
        font-size: 20px;
        font-weight: 700;
        min-height: 70px;
        text-align: left;
      }

      .input-wrapper {
        width: 100%;
        margin-top: 20px;
      }
    }

    .checkbox-wrapper {
      height: 54px;
      margin-bottom: 40px;
      //padding-top: 20px;
      //width: 60%;
    }
  }
}


.change-themes-button {
  margin-top: 60px !important;
}

.upload-profile-picture-button {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.24px;
  line-height: 24px;
}

.enable-video-chat-label {
  font-weight: 700;
  font-size: 20px;
  margin-left: 10px;
}

.enable-video-chat-text {
  font-weight: 400;
  text-align: left;
  white-space: pre-line
}

.enable-video-chat-text.question {
  margin: 1em 0;
}

.push-warning, .push-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--primary-color);
  margin-top: 10px;
  padding: 8px;

  > i {
    padding: 8px;
  }

  > div {
    margin: 1em 0;
  }
}

.push-warning {
  background-color: var(--primary-color);
}

.push-hint {
  background-color: var(--icon-color);
}

.push-enable-button {
  width: 100%;
}

.own-profile-links {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .copy-profile-link-header, .show-profile-header {
    font-weight: 700;
    margin-top: 30px;
    color: #333333;
    text-align: left;
  }

}

.reconfirm-dialog-container {
  .reconfirm-dialog-text-container {
    display: flex;
    flex-direction: column;

    .custom-button {
      margin: 20px;
    }
  }
}

.reserve-time-container, .reconfirm-dialog-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  > i {
    font-size: 6em;
    color: var(--secondary-color);
    padding: 0 20px 20px;
    margin: 0 10px 10px;
  }

  div {
    padding: 20px;
    margin: 0;
  }
}

.reserve-time-text, .reconfirm-dialog-text {
  text-align: left;
}

.profile-privacy-disclaimer {
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
}


</style>
