import {BaseVueEntity} from "@/entities/BaseVueEntity";

export class MissedVideoCall extends BaseVueEntity {

    userData;

    constructor(userData) {
        super();
        this.userData = userData;
    }
}