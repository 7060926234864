<template>
  <div class="incoming-call-popup" v-if="opened">
    <div class="header">
      <span>{{ $t('incoming-call') }}</span>
    </div>
    <div class="call-setup-container">
      <div class="call-setup-wrapper center">
        <div class="call-setup-img" v-if="callData.avatar">
          <img id="caller-img" :src="getImage()" alt="" class="call-setup-img"/>
        </div>
        <div class="call-setup-initials">
          <p id="caller-initials" v-if="!callData.avatar">{{ callData.initials }}</p>
        </div>
        <div class="call-setup-name">
          <p id="caller-name">{{ callData.name }}</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="accept-ignore-call-buttons">
        <img id="accept-button" src="@/assets/img/phone-accept.svg" v-on:click="acceptCall">
        <img src="@/assets/img/phone-hangup.svg" v-on:click="ignoreCall">
      </div>
    </div>
  </div>


</template>

<script>
import fallbackProfileImage from "@/assets/img/person.svg";
import {eventBus} from '@/main';
import SocketIO from 'socket.io-client';
import localStorageHelper from "../localstorage/local-storage";
import {MissedVideoCall} from "@/components/Notifications/MissedVideoCall/MissedVideoCall";
import backend from '../mm-backend';
import {User} from "@/entities/User";

export default {
  name: "IncomingCallPopup",
  props: {
    onAccept: {
      type: Function,
      default: undefined,
      required: true
    },
    onReject: {
      type: Function,
      default: undefined,
      required: true
    }
  },
  data() {
    return {
      opened: false,
      socket: undefined,
      callData: Object,
      ringSound: new Audio(process.env.VUE_APP_CHAT_URL + "/snd/ring.mp3"),
      acceptAnimationInterval: undefined,
      timeOut: undefined
    }
  },
  mounted() {
    this.ringSound.loop = true;
    eventBus.$on('call-accepted', this.handleCall);
    eventBus.$on('call-ignored', this.ignoreCall);
  },
  destroyed() {
    eventBus.$off('call-accepted', this.handleCall);
    eventBus.$off('call-ignored', this.ignoreCall);
  },
  methods: {
    openPopup(callData) {
      if (!this.opened) {
        this.ringSound.play().catch(err => {
        });
        this.callData = callData;
        this.opened = true;
        const self = this;
        this.acceptAnimationInterval = setInterval(function () {
          self.acceptAnimation(document.getElementById('accept-button'));
        }, 600);
        this.timeOut = window.setTimeout(this.timedOut, this.$incomingCallTimeBeforeClose);
      }
    },
    getImage() {
      return this.callData.avatar ? this.callData.avatar : fallbackProfileImage;
    },
    acceptCall() {
      if (this.opened && this.callData !== undefined) {
        if (this.onAccept) {
          this.onAccept(this.callData);
        }
        this.closePopup();
      }
    },
    timedOut() {
      backend.user(this.callData.peer).then(resp => {
        eventBus.$emit('add-notification', new MissedVideoCall(resp.data));
      });
      this.closePopup();
    },
    ignoreCall() {
      if (this.opened && this.callData !== undefined) {
        if (this.onReject) {
          this.onReject(this.callData);
        }
        this.closePopup();
      }
    },
    handleCall() {
      if (this.opened && this.callData !== undefined) {
        this.closePopup();
      }
    },
    closePopup() {
      this.opened = false;
      this.callData = undefined;
      clearInterval(this.acceptAnimationInterval);
      clearTimeout(this.timeOut);
      this.ringSound.pause();
    },
    acceptAnimation(acceptButton) {
      acceptButton.style.transition = '0.5s';
      if (acceptButton.style.transform === 'scale(1.2)') {
        acceptButton.style.transform = 'scale(1.0)';
      } else {
        acceptButton.style.transform = 'scale(1.2)';
      }
    }
  }
}
</script>

<style scoped lang="less">

.call-setup-container {
  position: relative !important;
}

.incoming-call-popup {
  z-index: 101;
  width: 360px;
  height: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  border: 1px solid var(--border-color);
  right: 0;
  background: #ffffff;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .header {
    min-height: 75px;
    width: 100%;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > span {
      color: white;
      font-size: 24px;
    }
  }

  .image-container {

    width: 100%;
    position: relative;
    height: 325px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > img {
      object-fit: cover;
      border: 0px solid white;
      border-radius: 50%;
      box-sizing: border-box;
      display: block;
      margin: 5px auto;
      width: 35vw;
      height: 35vw;
      max-width: 120px;
      max-height: 120px;

      box-shadow: 5px 5px 25px -15px rgba(138, 138, 138, 1);
      -webkit-box-shadow: 5px 5px 25px -15px rgba(138, 138, 138, 1);
      -moz-box-shadow: 5px 5px 25px -15px rgba(138, 138, 138, 1);
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 220px;
    background: #E8E8E8;
    justify-content: center;

    .username {

    }

    .accept-ignore-call-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;

      width: 100%;

      > img {
        height: 90px;
        width: 90px;
        margin: 10px 50px;
      }
    }
  }
}


</style>
