import {messaging} from './firebase'
import axios from "axios";
import backend from "./mm-backend";
import localStorageHelper from "./localstorage/local-storage";
import notificationSound from "@/assets/snd/notification.mp3";
import i18n from './i18n';
import langHelper from "./locales/lang-helper";
import {eventBus, logger} from '@/main';

const pushServer = 'https://apps2.eyeled.de/' + process.env.VUE_APP_PUSH_ENDPOINT;

const callbacks = []

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', event => {
        var payload;
        if (event.data.firebaseMessaging) {
            payload = event.data.firebaseMessaging.payload; // from firebase directly
            logger.log('message received from firebase: ' + JSON.stringify(payload));

            if (!handleMessagePayload(payload)) {
                if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({cmd: 'show', data: payload});
                } else {
                    logger.log('no service worker controller available');
                    if ('serviceWorker' in navigator && navigator.serviceWorker) {
                        logger.log('service worker IS available');
                        navigator.serviceWorker.getRegistration().then(function (serviceWorkerRegistration) {
                            if (serviceWorkerRegistration) {
                                logger.log('using registration');
                                showNotification(serviceWorkerRegistration, payload);
                            }
                        });
                    }
                }
            } else {
                new Audio(notificationSound).play().catch(err => {
                });
            }
        } else if (event.data.playsound) {
            logger.log('play sound');
            new Audio(notificationSound).play().catch(err => {
            });
        } else if (event.data.badge) {
            window.egcf_app.$root.$emit('change-unread-messages', event.data.badge);
        } else if (event.data.call) {
            if (event.data.accepted) {
                eventBus.$emit('call-accepted');
                logger.log('call ' + event.data.call + ' accepted');
            } else if (event.data.rejected) {
                logger.log('call ' + event.data.call + ' rejected');
                eventBus.$emit('call-ignored');
            }
        } else {
            payload = event.data; // from serviceworker
            logger.log('message received from service worker: ' + JSON.stringify(payload));
            handleMessagePayload(payload);
        }
    });
}

function showNotification(serviceWorkerRegistration, payload) {
    if (payload.data.message || payload.data.title) {
        const notificationTitle = payload.data.title ? payload.data.title : '';
        const notificationOptions = {
            body: payload.data.message ? payload.data.message : '',
            badge: process.env.BASE_URL + 'img/badge.png',
            icon: payload.data.avatar ? payload.data.avatar : process.env.BASE_URL + 'img/notification.png',
            data: payload.data,
            // vibrate: [300, 100, 400],
        };
        payload.shown = true;
        logger.log("showing " + payload.data.type + " notification from client");
        if (payload.data && payload.data.type === 'video') {
            notificationOptions.actions = [{
                action: 'accept',
                title: i18n.t('call-accept'),
                // icon: '/img/phone-accept.png' // currently not working
            }, {
                action: 'reject',
                title: i18n.t('call-reject'),
                //icon: '/img/phone-hangup.png' // currently not working
            }];
            serviceWorkerRegistration.showNotification(notificationTitle, notificationOptions);
        } else {
            serviceWorkerRegistration.showNotification(notificationTitle, notificationOptions);
        }
    }
}

function handleMessagePayload(payload) {
    var handled = false;
    if (payload) {
        if (payload.badge) updateUnreadMessages(payload.badge);
        for (const func of callbacks) {
            var ret = func(payload);
            handled |= ret;
        }
    }
    return handled;
}

function isNotificationSupported() {
    return process.env.VUE_APP_PUSH_ENDPOINT != null && process.env.VUE_APP_PUSH_ENDPOINT.length > 0 && (('PushManager' in window && messaging) || ('safari' in window && 'pushNotification' in window.safari));
}

function isNotificationDefault() {
    return isNotificationState('default');
}

function isNotificationAllowed() {
    return isNotificationState('granted');
}

function isNotificationDenied() {
    return isNotificationState('denied');
}

function isNotificationState(state) {
    if ('safari' in window && 'pushNotification' in window.safari) {
        var permissionData = window.safari.pushNotification.permission(`${process.env.VUE_APP_SAFARI_PUSH_NAME}`);
        return permissionData.permission === state;
    } else {
        return Notification.permission === state;
    }
}

function register(deviceId) {
    if ('safari' in window && 'pushNotification' in window.safari) {
        return new Promise(function (resolve, reject) {
            registerSafari().then((currentToken) => {
                if (currentToken) {
                    logger.log(currentToken);
                    sendTokenToServer(currentToken, null, deviceId, 'safaripush')
                        .then((resp) => {
                            logger.log('success: ' + resp);
                            localStorageHelper.setPushToken(currentToken);
                            resolve(currentToken);
                        });
                } else {
                    // Show permission request.
                    logger.log('No Instance ID token available. Request permission to generate one.');
                }
            }).catch((err) => {
                logger.log('An error occurred while retrieving token. ', err);
                reject(err);
            });
        });
    } else {
        if (!messaging) {
            return new Promise(function (resolve, reject) {
                reject('not suported')
            });
        }
        messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken) => {
                logger.log('Token refreshed.');
                backend.registerv2(localStorageHelper.getDeviceId(), localStorageHelper.getUser(), false).then(resp => {
                    logger.log("Register with refreshed pushtoken")
                });
                sendTokenToServer(refreshedToken, localStorageHelper.getPushToken(), deviceId, platform);
            }).catch((err) => {
                logger.log('Unable to retrieve refreshed token ', err);
            });
        });
    }

    return new Promise(function (resolve, reject) {
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken().then((currentToken) => {
            if (currentToken) {
                logger.log(currentToken);
                sendTokenToServer(currentToken, null, deviceId, 'webpush')
                    .then((resp) => {
                        logger.log('success: ' + resp);
                        localStorageHelper.setPushToken(currentToken);
                        resolve(currentToken);
                    });
            } else {
                // Show permission request.
                logger.log('No Instance ID token available. Request permission to generate one.');
            }
        }).catch((err) => {
            logger.log('An error occurred while retrieving token. ', err);
            reject(err);
        });
    });
}

let safariResolve;
let safariReject;

let checkRemotePermission = function (permissionData) {

    if (permissionData.permission === 'default') {
        // This is a new web service URL and its validity is unknown.
        window.safari.pushNotification.requestPermission(
            `${process.env.VUE_APP_SAFARI_PUSH_URL}`, // The web service URL.
            `${process.env.VUE_APP_SAFARI_PUSH_NAME}`,     // The Website Push ID.
            {}, // Data that you choose to send to your server to help you identify the user.
            checkRemotePermission         // The callback function.
        );
    } else if (permissionData.permission === 'denied') {
        // The user said no.
        logger.log('permission denied.');
        if (safariReject) {
            safariReject('denied');
        }
    } else if (permissionData.permission === 'granted') {
        // The web service URL is a valid push provider, and the user said yes.
        // permissionData.deviceToken is now available to use.
        logger.log('received token: ' + permissionData.deviceToken);
        if (safariResolve) {
            safariResolve(permissionData.deviceToken);
        }
    }
}

function registerSafari() {
    return new Promise(function (resolve, reject) {
        safariResolve = resolve;
        safariReject = reject;
        var permissionData = window.safari.pushNotification.permission(`${process.env.VUE_APP_SAFARI_PUSH_NAME}`);
        checkRemotePermission(permissionData);
    });
}

function sendTokenToServer(currentToken, oldToken, deviceId, platform) {
    var previousSettings = localStorageHelper.getPushSettings();
    var params = {
        appname: process.env.VUE_APP_PUSH_NAME,
        appversion: process.env.VUE_APP_VERSION,
        platform: platform,
        testdevice: 'false',
        devicetoken: currentToken,
        deviceuid: deviceId,
        devicemodel: navigator.userAgent,
        lang: langHelper.getCurrentLang(),
        development: process.env.VUE_APP_PUSH_DEVELOPMENT
    };
    var settings = pushServer + ":" + JSON.stringify(params);
    if (oldToken) {
        params.previoustoken = oldToken;
    }
    if (previousSettings && previousSettings === settings) {
        return new Promise(function (resolve, reject) {
            resolve('loaded from cache')
        });
    } else {
        return axios.get(`${pushServer}/public/api/installation`,
            {
                params: params
            }
        ).then((resp) => {
            localStorageHelper.setPushSettings(settings);
            return JSON.stringify(resp.data);
        }, error => {
            logger.error(error, true);
            return "FAILED";
        });
    }
}

function deleteMessageCallback(callback) {
    if (callbacks && callbacks.length > 0) {
        const index = callbacks.indexOf(callback);
        if (index > -1) {
            callbacks.splice(index, 1);
        }
    }
}

function addMessageCallback(callback) {
    if (messaging) {
        callbacks.push(callback)
    }
}

function updateUnreadMessages(count) {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({cmd: 'badge', data: {count: count}});
    }
    window.egcf_app.$root.$emit('change-unread-messages', {count: count});
}

export {
    isNotificationSupported,
    isNotificationDefault,
    isNotificationAllowed,
    isNotificationDenied,
    register,
    addMessageCallback,
    deleteMessageCallback,
    updateUnreadMessages
}
