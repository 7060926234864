import {networkersMixIn} from "@/mixins/networkers-mixin";
import Vue from 'vue';
import backend from "@/mm-backend";
import axios from "axios";
import localStorageHelper from "@/localstorage/local-storage";

export let companiesMixIn = Vue.util.mergeOptions(networkersMixIn, {

    data() {
        return {
            companies: []
        }
    },
    methods: {
        setDataFromMatchingResponse(resp) {
            this.recommendations = [];
            this.totalEntries = resp.data.totalEntries;
            if (resp.data.recommendations) {
                this.recommendations = resp.data.recommendations;
                this.recommendations.forEach(r => {
                    r.is_recommendation = true;
                });
                this.totalEntries += resp.data.recommendations.length;
            }
            this.networkersTitle = resp.data.users_title;
            this.recommendationsTitle = resp.data.recommendations_title;
            this.companies = resp.data.companies;

            this.networkersAndRecommendations = [];
            this.networkersAndRecommendations.push(...this.recommendations);
            this.networkersAndRecommendations.push(...this.companies);
        },
        excludeRecommendation(company) {
            const self = this;
            backend.excludeCompanyRecommendation(company.id).then(resp => {
                self.$toasted.global.success_notification({message: self.$t('exclude-recommendation-success')});
                self.reloadFilter();
            }, error => {
                self.$toasted.global.error_notification({message : self.$t('exclude-recommendation-error')});

            });
        },
        loadNextPageFromServer() {
            this.isLoading = true;
            let self = this;
            if (this.searchValue && this.searchValue !== '') {
                this.params['search'] = this.searchValue;
            }
            this.params['page'] = this.currentPageForBackend;
            this.getObservable(this.params).then(function (resp) {
                self.totalEntries = resp.data.totalEntries;
                if (resp.data.recommendations) {
                    self.recommendations = resp.data.recommendations;
                    self.totalEntries += resp.data.recommendations.length;
                }
                self.networkersTitle = resp.data.users_title;
                self.recommendationsTitle = resp.data.recommendations_title;
                self.companies.push(...resp.data.companies);
                self.networkersAndRecommendations = [];
                self.networkersAndRecommendations.push(...self.companies);
                self.currentPage++;
                self.isLoading = false; //loading finished
            }, error => {
                self.isLoading = false;
            });
        },
        showNextPage() {
            if (this.currentPage < this.maxPages) {
                if (this.currentPage % this.maxPagesToDisplay === 0 && this.companies.length === ((this.currentPage) * this.entriesPerPage)) {
                    this.currentPageForBackend++;
                    if (this.filterEnabled) {
                        this.applyFilter(this.$refs.themeFilter.themesToFilter, this.$refs.themeFilter.filterType, true);
                    } else {
                        this.loadNextPageFromServer()
                    }
                } else {
                    this.currentPage++;
                }
            }
        }

    }

})