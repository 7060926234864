<template>
  <div class="contact"
        v-bind:class="{'has-role' : contact.roleName}">
    <div v-if="contact.roleName" class="user-header">
      <div class="roleName">
        <span :title="contact.roleName">{{ contact.roleName }}</span>
      </div>
    </div>
    <div class="contactInfo">
      <div class="contact-image-container">
        <img :src="getImage(contact)" ref="img"
             v-on:error="setFallBackImageForNetworker()"/>
        <div v-if="contact.isBusy" class="busy-indicator" :title="$t('busy')"></div>
        <div v-else-if="contact.isOnline" class="online-indicator" :title="$t('online-now')"></div>
      </div>
      <div class="contactName">
        <span :title="getName(contact)">{{ getName(contact) }}</span>
        <label :title="contact.position">{{ contact.position }}</label>
        <label :title="contact.organization">{{ contact.organization }}</label>
      </div>
    </div>

    <div class="contactDetails">
      <div class="mail-wrapper">
        <i class="fal fa-envelope fa-lg"></i>
        <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
      </div>

      <div class="site-wrapper">
        <i class="fal fa-globe fa-lg"></i>
        <label v-if="!contact.web">-</label>
        <a v-if="contact.web" :href="validUrl(contact.web)" target="_blank">{{ contact.web }}</a>
      </div>

      <div class="phone-wrapper">
        <i class="fal fa-phone fa-lg"></i>
        <label>{{ contact.tel ? contact.tel : '-' }}</label>
      </div>
    </div>

    <div class="contact-buttons">
      <a>
        <div v-on:click="goToProfile(contact,false)">{{ $t('to-profile') }}</div>
      </a>
      <router-link class="chat_btn" :to="`/chat/${contact.id}`">
        <div>Chat</div>
      </router-link>
    </div>
  </div>
</template>
<script>
import {cardMixin} from "@/components/cards/card-mixin";

export default {
  name: "ContactCard",
  mixins: [cardMixin],
  props: {
    contact: {
      type: Object,
      required: true,
    },
    goToProfile: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>


.contact {
  display: flex;
  flex-direction: column;
  float: left;
  text-align: left;
  margin: 2.5px 20px 20px 0;
  padding: 20px;
  box-sizing: border-box;
  height: 266px;
  width: 270px;
  border: 1px solid var(--border-color);

  .user-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    bottom: 10px;
    left: 10px;
    position: relative;
    height: 25px;

  }

  .contact-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    a {
      width: 45%;
    }

    div, button {
      font-weight: bold;
      height: 44px;
      width: 105px;
      background: var(--secondary-color);
      padding: 12px;
      color: white;
      cursor: pointer;
      border: none;
      font-size: 13.3333px;
      appearance: auto;
      -webkit-writing-mode: horizontal-tb !important;
      text-rendering: auto;
      letter-spacing: normal;
      word-spacing: normal;
      text-transform: none;
      text-indent: 0;
      text-shadow: none;
      display: inline-block;
      text-align: center;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .contactDetails {
    width: 100%;
    float: left;
    height: 100px;

    .mail-wrapper,
    .phone-wrapper,
    .site-wrapper {
      margin: 0 0px 14px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      a, label {
        height: 14px;
        color: #58585A;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;

        overflow: hidden;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 12px;
      }

      label {
        margin-left: 12px;
      }

      > i {
        color: var(--primary-color);
      }
    }
  }

  .contactInfo {
    height: 90px;
    display: flex;
    margin-bottom: 15px;

    .contactName {
      margin-left: 10px;
      min-height: 76px;
      display: flex;
      flex-direction: column;
      width: calc(100% - 70px);

      span {
        color: #58585A;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      label {
        color: #58585A;
        margin-top: 4px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: block-axis;
      }
    }

    .contact-image-container {
      position: relative;
      width: 60px;
      height: 60px;

      .online-indicator, .busy-indicator {
        position: absolute;
        right: -8px;
        bottom: -8px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 3px solid #FFFFFF;
      }

      .busy-indicator {
        background-color: var(--busy-indicator-color);
      }

      .online-indicator {
        background-color: var(--online-indicator-color);
      }

      img {
        display: block;
        object-fit: cover;
        width: 60px;
        height: 60px;
        flex: 0 0 auto; /* dont shrink dont grow */
      }
    }
  }
}
.contact.has-role {
  height: 290px;
}
</style>
