import {zip} from "rxjs";
import {eventBus, logger} from '@/main'
import mybookfair from '../mbf-backend';
import backend from '../mm-backend';
import localStorageHelper from "../localstorage/local-storage";
import {deepCopy, isDefined} from "@/utils";
import {themesStorage} from "@/main";


const startTabId = 'days-tab';
const searchTabId = 'searching-tab';
const offerTabId = 'offering-tab';

const selectThemesTabs = [
    // {
    //     id: 'general-tab',
    //     categoriesInTab: ['MM.CAREER_LEVEL', 'MM.EMPLOYEES_NO', 'MM.COMPANY_ASSIGNMENT'],
    //     selectEntriesAs: 'normal'
    // },
    {id: startTabId, categoriesInTab: ['MM.DAYS'], selectEntriesAs: 'normal'},

    {
        id: searchTabId,
        categoriesInTab: ['MM.MM_TOPICS_OF_INTEREST', 'MM.COUNTRIES'],
        selectEntriesAs: 'search',
        subTabs:[
            {
                selectEntriesAs: 'search',
                id: 'topics-tab-search',
                categoriesInTab:['MM.MM_TOPICS_OF_INTEREST']
            },
            {
                selectEntriesAs: 'search',
                id: 'countries-tab-search',
                categoriesInTab:['MM.COUNTRIES']
            },
        ]
    },
    {
        id: offerTabId,
        categoriesInTab: ['MM.MM_TOPICS_OF_INTEREST', 'MM.COUNTRIES'],
        selectEntriesAs: 'offer',
        subTabs:[
            {
                selectEntriesAs: 'offer',
                id: 'topics-tab-offer',
                categoriesInTab:['MM.MM_TOPICS_OF_INTEREST']
            },
            {
                selectEntriesAs: 'offer',
                id: 'countries-tab-offer',
                categoriesInTab:['MM.COUNTRIES'],
                isLastTab: true
            },
        ]
    }
];

const themesForThemeSelect = [
    {
        id: 'MM.MM_TOPICS_OF_INTEREST',
        showChildrenSeparately: true,
        hasSubCategories: true,
        hasSearchAndOffer: true,
        disableSelectAllCheckbox: true,
        maxSelectCount: 10,
        required: true
    },
    {
        id: 'MM.DAYS',
        showChildrenSeparately: false,
        hasSearchAndOffer: false,
        hasSubCategories: false,
        required: false
    },
    {
        id: 'MM.COUNTRIES',
        showChildrenSeparately: false,
        hasSearchAndOffer: true,
        hasSubCategories: true,
        required: true,
        collapsable: true
    },
    {
        id: 'MM.COMPANY_ASSIGNMENT',
        showChildrenSeparately: false,
        hasSearchAndOffer: false,
        required: false,
        hasSubCategories: false,
        singleton: true
    },
    {
        id: 'MM.CAREER_LEVEL',
        showChildrenSeparately: false,
        hasSearchAndOffer: false,
        required: false,
        hasSubCategories: false,
        singleton: true
    },
    {
        id: 'MM.EMPLOYEES_NO',
        showChildrenSeparately: false,
        hasSearchAndOffer: false,
        required: false,
        hasSubCategories: false,
        singleton: true
    },
    {
        id: 'MM.PROFESSION_DESCRIPTION',
        showChildrenSeparately: false,
        hasSearchAndOffer: false,
        required: false,
        hasSubCategories: false,
        singleton: true
    },
];

const categoriesForFilter = [
    {id: 'MM.MM_TOPICS_OF_INTEREST', showChildrenSeparately: true, hasSearchAndOffer: true},
    {id: 'MM.COUNTRIES', showChildrenSeparately: false, hasSearchAndOffer: true},
    {id: 'MM.COMPANY_ASSIGNMENT', showChildrenSeparately: false},
    {id: 'MM.CAREER_LEVEL', showChildrenSeparately: false},
    {id: 'MM.EMPLOYEES_NO', showChildrenSeparately: false},
    {id: 'MM.DAYS', showChildrenSeparately: false, hasSearchAndOffer: false},
];

const categoriesForFilterForCompanies = [
    {id: 'MM.MM_TOPICS_OF_INTEREST', showChildrenSeparately: false, hasSearchAndOffer: true},
    {id: 'MM.COUNTRIES', showChildrenSeparately: false, hasSearchAndOffer: true},
];

const themeConfigForOwnProfile = [
    {
        id: 'MM.MM_TOPICS_OF_INTEREST',
        hasSearchAndOffer: true,
    },
    {id: 'MM.COUNTRIES', hasSearchAndOffer: true},
    {id: 'MM.CAREER_LEVEL', hasSearchAndOffer: false},
    {id: 'MM.COMPANY_ASSIGNMENT', hasSearchAndOffer: false},
    {
        id: 'MM.DAYS',
        hasSearchAndOffer: false,
        calculateMatch: false,
        separateRow: true,
        additionalCSSClass: 'special-days-style'
    },
    {id: 'MM.EMPLOYEES_NO', hasSearchAndOffer: false},
];


const themeConfigForProfileOthers = [
    {id: 'MM.COMPANY_ASSIGNMENT', hasSearchAndOffer: false, calculateMatch: false},
    {id: 'MM.CAREER_LEVEL', hasSearchAndOffer: false, calculateMatch: false},
    {id: 'MM.EMPLOYEES_NO', hasSearchAndOffer: false, calculateMatch: false},
    {
        id: 'MM.MM_TOPICS_OF_INTEREST',
        hasSearchAndOffer: true,
        calculateMatch: true
    },
    {id: 'MM.DAYS', hasSearchAndOffer: false, calculateMatch: true, separateRow: true},
    {id: 'MM.COUNTRIES', hasSearchAndOffer: true, calculateMatch: true},
];

export default {
    sortCategory(category, lang) {
        if (category.children) {
            category.children.sort(function (a, b) {
                return a.sortOrder.localeCompare(b.sortOrder, lang);
            });
            for (const subCategory of category.children) {
                this.sortCategory(subCategory, lang);
            }
        }
    },
    getEmptyThemesObject() {
        return {
            "MM.MM_TOPICS_OF_INTEREST": {
                "offer": [],
                "search": []
            },
            "MM.COUNTRIES": {
                "search": [],
                "offer": []
            },
            "MM.DAYS": [],
            "MM.COMPANY_ASSIGNMENT": [],
            "MM.CAREER_LEVEL": [],
            "MM.EMPLOYEES_NO": [],
            "MM.PROFESSION_DESCRIPTION": []
        }
    },
    getAllStringForCategory(category) {
        if (category.id.startsWith("MM.COUNTRIES")) {
            return 'all-countries';
        }
        if (category.id.startsWith("MM.MM_TOPICS_OF_INTEREST")) {
            return 'all-themes';
        }
        return 'all-others';
    },
    getThemeSelectTabs() {
        return selectThemesTabs;
    },
    getStartTabId(userRole) {
        return startTabId;
    },
    getOfferTabId() {
        return offerTabId;
    },
    getSearchTabId() {
        return searchTabId;
    },
    getCategoriesToFilterByLanguage(lang) {
        let categories = [];
        for (const value of categoriesForFilter) {
            let category;
            const themes = themesStorage.themes;
            category = themes.find(category => category.id === value.id);

            if (category) {
                const copy = deepCopy(category);
                this.sortCategory(copy, lang);
                copy.hasSearchAndOffer = value.hasSearchAndOffer;
                copy.showChildrenSeparately = value.showChildrenSeparately;
                if (value.filterHeader) {
                    copy.filterHeader = value.filterHeader;
                }
                categories.push(copy);
            }
        }

        return categories;
    },
    getCategoriesToFilterForCompaniesByLanguage(lang) {
        let categories = [];
        for (const value of categoriesForFilterForCompanies) {
            let category;
            const themes = themesStorage.themes;
            category = themes.find(category => category.id === value.id);

            if (category) {
                const copy = deepCopy(category);
                this.sortCategory(copy, lang);
                copy.hasSearchAndOffer = value.hasSearchAndOffer;
                copy.showChildrenSeparately = value.showChildrenSeparately;
                categories.push(copy);
            }
        }
        return categories;
    },
    getThemesForThemeSelectByLanguage(lang) {
        let categories = [];
        for (const value of themesForThemeSelect) {
            let category;
            const themes = themesStorage.themes;
            category = themes.find(category => category.id === value.id);

            if (category) {
                const copy = deepCopy(category);
                this.sortCategory(copy, lang);
                copy.hasSearchAndOffer = value.hasSearchAndOffer;
                copy.showChildrenSeparately = value.showChildrenSeparately;
                if (copy.showChildrenSeparately) {
                    for (const child of copy.children) {
                        child.showChildrenSeparately = value.showChildrenSeparately;
                    }
                }
                copy.hasSubCategories = value.hasSubCategories;
                copy.singleton = value.singleton;
                copy.required = value.required;
                copy.disableSelectAllCheckbox = value.disableSelectAllCheckbox;
                for (const child of copy.children) {
                    child.disableSelectAllCheckbox = value.disableSelectAllCheckbox;
                }
                copy.maxSelectCount = value.maxSelectCount;
                copy.collapsable = value.collapsable;
                categories.push(copy);
            }
        }
        return categories;
    },
    getCategoriesToDisplayForProfileOthersByLanguage(lang) {
        let categories = [];
        for (const value of themeConfigForProfileOthers) {
            let category;
            const themes = themesStorage.themes;
            category = themes.find(category => category.id === value.id);

            if (category) {
                const copy = deepCopy(category);
                this.sortCategory(copy, lang);
                copy.hasSearchAndOffer = value.hasSearchAndOffer;
                copy.calculateMatch = value.calculateMatch;
                copy.separateRow = value.separateRow;
                categories.push(copy);
            }
        }
        return categories;
    },
    getCategoriesToDisplayForOwnProfileByLanguage(lang) {
        let categories = [];
        for (const value of themeConfigForOwnProfile) {
            let category;
            const themes = themesStorage.themes;
            category = themes.find(category => category.id === value.id);

            if (category) {
                const copy = deepCopy(category);
                this.sortCategory(copy, lang);
                copy.hasSearchAndOffer = value.hasSearchAndOffer;
                copy.isEditable = value.isEditable;
                copy.separateRow = value.separateRow;
                copy.additionalCSSClass = value.additionalCSSClass;
                categories.push(copy);
            }
        }
        return categories;
    },
    getTopics() {
        const themes = themesStorage.themes;
        return themes.find(category => category.id === 'MM.MM_TOPICS_OF_INTEREST');
    },
    getCountries() {
        const themes = themesStorage.themes;
        return themes.find(category => category.id === 'MM.COUNTRIES');
    },
    checkForThemeChanges() {
        if (!(process.env.VUE_APP_MYBOOKFAIR_USER_HOST != null && process.env.VUE_APP_MYBOOKFAIR_USER_HOST.length > 0)) {
            return;
        }
        logger.log("Check vor potential theme changes");
        localStorageHelper.setLastMyBookFairCheck(new Date());
        const savedUser = localStorageHelper.getUser();
        const self = this;
        const mbfOffersObservable = mybookfair.offerAnswers();
        const mbfSearchObservable = mybookfair.searchAnswers();
        const activeDaysObservable = mybookfair.activeDays();
        const mbfExtendedUserObservable = mybookfair.extendedUser();

        let observables = savedUser ? zip(mbfOffersObservable, mbfSearchObservable, activeDaysObservable, mbfExtendedUserObservable) : zip(backend.me(), mbfOffersObservable, mbfSearchObservable, activeDaysObservable, mbfExtendedUserObservable);

        observables.subscribe(resp => {
            // TODO rework this when we still need it in the future.

            let user;
            let mbf_offer_countries;
            let mbf_offer_topics;
            let mbf_search_countries;
            let mbf_search_topics;
            let active_days;
            let career_level;
            let company_assignment;
            let employees_no;
            let profession_description;

            if (!savedUser) {
                user = resp[0].data.user;
                mbf_offer_countries = resp[1].data.data.COUNTRIES.options.selected;
                mbf_offer_topics = resp[1].data.data.MM_TOPICS_OF_INTEREST.options.selected;
                mbf_search_countries = resp[2].data.data.COUNTRIES.options.selected;
                mbf_search_topics = resp[2].data.data.MM_TOPICS_OF_INTEREST.options.selected;
                active_days = resp[3].data.data.DAYS.options.selected;
                career_level = resp[4].data.data.CAREER_LEVEL.options.selected;
                company_assignment = resp[4].data.data.COMPANY_ASSIGNMENT.options.selected;
                employees_no = resp[4].data.data.EMPLOYEES_NO.options.selected;
                profession_description = resp[4].data.data.PROFESSION_DESCRIPTION.options.selected;

            } else {
                mbf_offer_countries = resp[0].data.data.COUNTRIES.options.selected;
                mbf_offer_topics = resp[0].data.data.MM_TOPICS_OF_INTEREST.options.selected;
                mbf_search_countries = resp[1].data.data.COUNTRIES.options.selected;
                mbf_search_topics = resp[1].data.data.MM_TOPICS_OF_INTEREST.options.selected;
                active_days = resp[2].data.data.DAYS.options.selected;
                career_level = resp[3].data.data.CAREER_LEVEL.options.selected;
                company_assignment = resp[3].data.data.COMPANY_ASSIGNMENT.options.selected;
                employees_no = resp[3].data.data.EMPLOYEES_NO.options.selected;
                profession_description = resp[3].data.data.PROFESSION_DESCRIPTION.options.selected;
            }

            const mbf_themes = self.getEmptyThemesObject();
            const userThemes = savedUser ? savedUser.themes : user.themes;


            for (const [key, value] of Object.entries(career_level)) {
                if (value) {
                    mbf_themes["MM.CAREER_LEVEL"].push(`MM.CAREER_LEVEL.${key}`);
                }
            }
            for (const [key, value] of Object.entries(company_assignment)) {
                if (value) {
                    mbf_themes["MM.COMPANY_ASSIGNMENT"].push(`MM.COMPANY_ASSIGNMENT.${key}`);
                }
            }
            for (const [key, value] of Object.entries(employees_no)) {
                if (value) {
                    mbf_themes["MM.EMPLOYEES_NO"].push(`MM.EMPLOYEES_NO.${key}`);
                }
            }
            for (const [key, value] of Object.entries(profession_description)) {
                if (value) {
                    mbf_themes["MM.PROFESSION_DESCRIPTION"].push(`MM.PROFESSION_DESCRIPTION.${key}`);
                }
            }

            for (const [key, value] of Object.entries(mbf_offer_countries)) {
                if (value) {
                    mbf_themes["MM.COUNTRIES"].offer.push(`MM.COUNTRIES.${key}`);
                }
            }
            self.combineThemes(mbf_themes["MM.COUNTRIES"].offer, this.getCountries());

            for (const [key, value] of Object.entries(mbf_search_countries)) {
                if (value) {
                    mbf_themes["MM.COUNTRIES"].search.push(`MM.COUNTRIES.${key}`);
                }
            }
            self.combineThemes(mbf_themes["MM.COUNTRIES"].search, this.getCountries());

            for (const [key, value] of Object.entries(mbf_offer_topics)) {
                if (value) {
                    mbf_themes["MM.MM_TOPICS_OF_INTEREST"].offer.push(`MM.MM_TOPICS_OF_INTEREST.${key}`);
                }
            }
            self.combineThemes(mbf_themes["MM.MM_TOPICS_OF_INTEREST"].offer, this.getTopics());

            for (const [key, value] of Object.entries(mbf_search_topics)) {
                if (value) {
                    mbf_themes["MM.MM_TOPICS_OF_INTEREST"].search.push(`MM.MM_TOPICS_OF_INTEREST.${key}`);
                }
            }
            self.combineThemes(mbf_themes["MM.MM_TOPICS_OF_INTEREST"].search, this.getTopics());

            for (const [key, value] of Object.entries(active_days)) {
                if (value) {
                    mbf_themes["MM.DAYS"].push(`MM.DAYS.${key}`);
                }
            }


            if (userThemes && !(Object.keys(userThemes).length === 0 && userThemes.constructor === Object)) {

                const user_offer_countries = userThemes["MM.COUNTRIES"] ? userThemes["MM.COUNTRIES"].offer : [];
                const user_search_countries = userThemes["MM.COUNTRIES"] ? userThemes["MM.COUNTRIES"].search : [];
                const user_offer_topics = userThemes["MM.MM_TOPICS_OF_INTEREST"] ? userThemes["MM.MM_TOPICS_OF_INTEREST"].offer : [];
                const user_search_topics = userThemes["MM.MM_TOPICS_OF_INTEREST"] ? userThemes["MM.MM_TOPICS_OF_INTEREST"].search : [];
                const user_days = userThemes["MM.DAYS"];
                const career_level = userThemes["MM.CAREER_LEVEL"];
                const company_assignment = userThemes["MM.COMPANY_ASSIGNMENT"]
                const employees_no = userThemes["MM.EMPLOYEES_NO"];
                const profession_description = userThemes["MM.PROFESSION_DESCRIPTION"];

                if (!self.arraysEqualOrSameContent(user_offer_countries, mbf_themes["MM.COUNTRIES"].offer) ||
                    !self.arraysEqualOrSameContent(user_search_countries, mbf_themes["MM.COUNTRIES"].search) ||
                    !self.arraysEqualOrSameContent(user_offer_topics, mbf_themes["MM.MM_TOPICS_OF_INTEREST"].offer) ||
                    !self.arraysEqualOrSameContent(user_search_topics, mbf_themes["MM.MM_TOPICS_OF_INTEREST"].search) ||
                    !self.arraysEqualOrSameContent(user_days, mbf_themes["MM.DAYS"]) ||
                    !self.arraysEqualOrSameContent(career_level, mbf_themes["MM.CAREER_LEVEL"]) ||
                    !self.arraysEqualOrSameContent(company_assignment, mbf_themes["MM.COMPANY_ASSIGNMENT"]) ||
                    !self.arraysEqualOrSameContent(employees_no, mbf_themes["MM.EMPLOYEES_NO"]) ||
                    !self.arraysEqualOrSameContent(profession_description, mbf_themes["MM.PROFESSION_DESCRIPTION"])) {


                    logger.log("Änderungen vorhanden, starte theme update");
                    self.updateMbfThemes(mbf_themes);
                }


            } else if (mbf_themes["MM.COUNTRIES"].offer.length !== 0 ||
                mbf_themes["MM.COUNTRIES"].search.length !== 0 ||
                mbf_themes["MM.MM_TOPICS_OF_INTEREST"].offer.length !== 0 ||
                mbf_themes["MM.MM_TOPICS_OF_INTEREST"].search.length !== 0 ||
                mbf_themes["MM.DAYS"].length !== 0 ||
                mbf_themes["MM.CAREER_LEVEL"].length !== 0 ||
                mbf_themes["MM.COMPANY_ASSIGNMENT"].length !== 0 ||
                mbf_themes["MM.EMPLOYEES_NO"].length !== 0 ||
                mbf_themes["MM.PROFESSION_DESCRIPTION"].length !== 0) {

                logger.log("Änderungen vorhanden, starte theme update");
                self.updateMbfThemes(mbf_themes);
            }
        }, error => {
            if(error.response && error.response.status === 500) {
                logger.error(error, true);
            }
        });
    },
    arraysEqualOrSameContent(a1, a2) {
        if ((!a1 && a2) || (a1 && !a2)) {
            return false;
        }
        if (a1.length !== a2.length) {
            return false;
        }

        const containsAll = (arr1, arr2) =>
            arr2.every(arr2Item => arr1.includes(arr2Item))

        const sameMembers = (arr1, arr2) =>
            containsAll(arr1, arr2) && containsAll(arr2, arr1);

        return sameMembers(a1, a2);

    },
    updateMbfThemes(themes) {

        const deviceId = localStorageHelper.getDeviceId();
        const savedUser = localStorageHelper.getUser();
        savedUser.themes = themes;


        backend.registerv2(deviceId, savedUser, false).then(resp => {
            localStorageHelper.setUser(savedUser);
            eventBus.$emit('mbf-themes-updated', themes)
            logger.log("Themes from Mbf updated");
        });

    },
    combineThemes(themeArray, themeTree) {
        for (const child of themeTree.children) {
            if (child.children) {
                let allIncluded = true;
                for (const subchild of child.children) {
                    if (!themeArray.includes(subchild.id)) {
                        allIncluded = false;
                        break;
                    }
                }
                if (allIncluded && !themeArray.includes(child.id)) {
                    themeArray.push(child.id);
                }
            }
        }
    },
    canEditCategory(category) {
        for (const tab of this.getThemeSelectTabs()) {
            if (tab.categoriesInTab.find(c => c === category.id) !== undefined) {
                return true;
            }
        }
        return false;
    },
    getTabIdForCategory(category, categoryType = undefined) {
        for (const tab of this.getThemeSelectTabs()) {

            if (isDefined(categoryType)) {
                if (tab.selectEntriesAs === categoryType && isDefined(tab.categoriesInTab.find(c => c === category.id))) {
                    if (isDefined(tab.subTabs)) {
                        for (const sub of tab.subTabs) {
                            if (isDefined(sub.categoriesInTab.find(c => c === category.id))) {
                                return sub.id;
                            }
                        }
                    }
                    return tab.id;
                }
            } else {


                if (isDefined(tab.categoriesInTab.find(c => c === category.id))) {
                    if (isDefined(tab.subTabs)) {
                        for (const sub of tab.subTabs) {
                            if (isDefined(sub.categoriesInTab.find(c => c === category.id))) {
                                return sub.id;
                            }
                        }
                    }
                    return tab.id;
                }
            }
        }
        return undefined;
    },
}
