import moment from "moment";
import {isDefined} from "@/utils";

export class Room {

    has_tables;
    id;
    open_hours;
    room;
    table_no;
    is_online;


    constructor(roomAsObject) {
        for (const [key, value] of Object.entries(roomAsObject)) {
            this[key] = value;
        }
    }

    getDisplayNameForRoom() {
        return this.room;
    }

    hasValidOpenHours() {
        if (isDefined(this.open_hours)) {
            const now = moment.utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
            for (const open_hour of this.open_hours) {
               const to = moment.utc(open_hour.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
               if(to.isAfter(now)){
                   return true;
               }
            }
        }
        return false;
    }
}
