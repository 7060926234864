import themes from "@/themes/themes-helper";
import {isDefined} from "@/utils";

export let themesProfileOthersCategoriesMixin = {
    data() {
        return {
            allSelected: undefined,
            allJoint: undefined
        }
    },
    props: {
        category: Object,
        parent: Object,
        step: Number,
        jointOnly: Boolean,
        selectOnly: Boolean
    },
    methods: {
        getAllStringForCategory(category) {
            return themes.getAllStringForCategory(category);
        },
        getJointAs(){
            // Needs to be overwritten by parent
            // return 'joint_offer' | 'joint_search' | 'joint'
        },
        allEntriesJoint() {
            if (this.category.children && this.category.children.length > 0) {
                return this.allEntriesJointTraverse(this.category.children);
            } else {
                return false;
            }
        },
        allEntriesJointTraverse(children){
            let allJoint = true;
            for(const child of children){
                if(isDefined(child.children)){
                    allJoint = this.allEntriesJointTraverse(child.children);
                }else if(!child[this.getJointAs()]){
                    return false;
                }
            }
            return allJoint;
        }
    }

}