import {isDefined} from "@/utils";
import themes from "@/themes/themes-helper";

export let profileThemeCategoriesMixin = {
    methods:{
        getSelectedAs(){
          // Needs to be overwritten by parent
          // return 'selected_as_search' | 'selected_as_offer' | 'selected'
        },
        allEntriesSelected() {
            if (this.category.children && this.category.children.length > 0) {
                return this.allEntriesSelectedTraverse(this.category.children);
            } else {
                return false;
            }
        },
        allEntriesSelectedTraverse(children){
            let allSelected = true;
            for(const child of children){
                if(isDefined(child.children)){
                    allSelected = this.allEntriesSelectedTraverse(child.children);
                }else if(!child[this.getSelectedAs()]){
                    return false;
                }
            }
            return allSelected;
        },
        canEditCategory(category) {
            return themes.canEditCategory(category);
        },
        getTabIdForCategory(category,categoryType) {
            return themes.getTabIdForCategory(category,categoryType);
        },
        onTitleClicked(value) {
            this.$emit('titleClicked', value);
        },
    }
}
