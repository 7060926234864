<template>
  <div >
    <label v-on:click="expand()" class="content-box has-children" v-if="category.children">
      <span v-if="category.children">{{ category.title }} ({{ getContentCountForCategory(category) }})  </span>
      <i v-if="category.children" :class="category.expanded ? 'fal fa-chevron-up fa-lg' : 'fal fa-chevron-down fa-lg'"
         :style="category.expanded ? 'color:black;' :''"></i>
    </label>

    <div class="content-box" v-if="!category.children">
      <custom-check-box v-bind:value.sync="category.selected" v-on:update:value="onCheckboxClicked">
        {{ '' }}
      </custom-check-box>
      <span> {{ category.title }}</span>
    </div>

    <ul class="has-children" v-if="category.children && category.children.length > 0" :class="nextCategory != null && !nextCategory.children ?  'extraborder' :''">
      <div class="content" :class="category.expanded ? 'expanded':''">
        <themes-filter-category v-for="(child,index) in category.children" v-bind:category="child" v-bind:nextCategory="index+1 < category.children.length ? category.children[index+1]:null"
                                :key="child.id" v-bind:apply-filter="applyFilter"></themes-filter-category>
      </div>
    </ul>
  </div>
</template>

<script>
import CustomCheckBox from "@/components/inputs/CustomCheckBox";

export default {
  name: "themes-filter-category",
  components: {CustomCheckBox},
  props: {
    category: Object,
    nextCategory: Object,
    applyFilter: Function
  },
  beforeMount() {
    this.$set(this.category, 'selected', false);
  },
  methods: {
    expand() {
      if (!this.category.children) {
        return;
      }
      this.category.expanded = !this.category.expanded;
      this.$forceUpdate();
    },
    onCheckboxClicked() {
      this.applyFilter();
    },
    getContentCountForCategory() {
      let count = 0;
      return this.getContentCountTraverse(this.category.children, count)

    },
    getContentCountTraverse(children, count) {
      for (const entry of children) {
        if (entry.children) {
          count = this.getContentCountTraverse(entry.children, count);
        } else {
          if (entry.selected) {
            count++;
          }
        }
      }
      return count;
    }

  }
}
</script>

<style scoped lang="less">

.content {
  overflow: hidden;
  max-height: 0;
}

.content.expanded {
  height: auto;
  max-height: 13000px;
  transition: max-height 1s ease-in-out;
}

</style>
