import Velocity from "velocity-animate";

export let animationMixIn = {

    methods: {
        animationBeforeEnter(el) {
            el.style.opacity = 0
        },
        animationEnter(el, done) {
            let delay = el.dataset.index * 50;
            if (delay > 1000) delay = 1000;
            setTimeout(function () {
                Velocity(
                    el,
                    {opacity: 1},
                    {complete: done}
                )
            }, delay)
        },
        animationLeave(el, done) {
            done();
        },
    }

}