import {isDefined} from "@/utils";
import moment from "moment";
import {BaseVueEntity} from "@/entities/BaseVueEntity";
import {appointmentsStorage} from "@/main";
import {Room} from "@/entities/Room";

export class Appointment extends BaseVueEntity {

    static block_states = {
        STATE_BLOCKED_SELECTION: 1,
        STATE_BLOCKED_WAITING_FOR_RESPONSE: 2,
        STATE_BOOKED: 3,
        STATE_CANCELED: 4,
        STATE_REJECTED: 5
    }

    id;
    description;
    from;
    to;
    name;
    participants;
    requesting_user;
    custom_room;
    room_id;
    block_state;
    table_name;

    constructor(appointmentAsObject) {
        super();
        for (const [key, value] of Object.entries(appointmentAsObject)) {
            this[key] = value;
        }
        this.generateVueIdBasedOnProperty('id');
    }

    getRoomName() {
        if (isDefined(this.room_id)) {
            const room = appointmentsStorage.getRoomById(this.room_id);
            if (isDefined(room)) {
                return room.room;
            }
        }
        return this.custom_room;
    }

    getRoom() {
        if (isDefined(this.room_id)) {
            const room = appointmentsStorage.getRoomById(this.room_id);
            if (isDefined(room)) {
                return room;
            }
        }
    }

    getTableName() {
        return this.table_name
    }

    isOnline() {
        const room = this.getRoom();
        if (isDefined(room) && room instanceof Room) {
            return room.is_online;
        }
        return false;
    }

    isConfirmed() {
        return this.block_state === Appointment.block_states.STATE_BOOKED;
    }

    isCanceled() {
        return this.block_state === Appointment.block_states.STATE_CANCELED || this.block_state === Appointment.block_states.STATE_REJECTED;
    }

    isRequest() {
        return this.block_state === Appointment.block_states.STATE_BLOCKED_WAITING_FOR_RESPONSE;
    }

    isBlocked() {
        return this.block_state === Appointment.block_states.STATE_BLOCKED_SELECTION;
    }

    isOutGoingRequest(userId) {
        return +this.requesting_user === +userId;
    }

    alreadyPast() {
        const from = moment.utc(this.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        const now = moment.utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        return !from.isAfter(now);
    }

    isDue() {
        return this.isConfirmed() && this.getMillisUntilDue() <= 0 && !this.alreadyPast();
    }

    getMillisUntilDue() {
        const millisBeforeWarning = process.env.VUE_APP_DUE_APPOINTMENTS_TIME_BEFORE_WARNING;
        const from = moment.utc(this.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE).subtract(millisBeforeWarning, 'milliseconds');
        const now = moment.utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);

        return from.valueOf() - now.valueOf();
    }


    static isParticipant(appointment, userId) {
        for (const participant of appointment.participants) {
            if (+participant.user_id === +userId) {
                return true;
            }
        }
        return false;
    }

    static getCanceledAppointments(appointments) {
        return appointments.filter(a => a.block_state === Appointment.block_states.STATE_CANCELED || a.block_state === Appointment.block_states.STATE_REJECTED);
    }

    static getAppointmentRequests(appointments) {
        return appointments.filter(a => a.block_state === Appointment.block_states.STATE_BLOCKED_WAITING_FOR_RESPONSE);
    }

    static getOutgoingRequests(appointments, userId) {
        return appointments.filter(a => a.block_state === Appointment.block_states.STATE_BLOCKED_WAITING_FOR_RESPONSE && +a.requesting_user === +userId);
    }

    static getReceivedRequests(appointments, userId) {
        return appointments.filter(a => a.block_state === Appointment.block_states.STATE_BLOCKED_WAITING_FOR_RESPONSE && this.isParticipant(a, userId));
    }

    static getConfirmedRequests(appointments) {
        return appointments.filter(a => a.block_state === Appointment.block_states.STATE_BOOKED);
    }

    static getBlockedAppointments(appointments) {
        return appointments.filter(a => a.block_state === Appointment.block_states.STATE_BLOCKED_SELECTION);
    }

}
