import i18n from "@/i18n";
import {eventBus} from "@/main";
import moment from "moment";
export let notificationComponentMixin = {
    data(){
        return{
          notificationCreatedTime:undefined
        }
    },
    beforeMount() {
        this.notificationCreatedTime = moment().format('HH:mm');
    },
    methods: {
        translate(key) {
            return i18n.t(key);
        },
        translateWithValue(key, value) {
            return i18n.t(key, value)
        },
        openProfileDialog(userData) {
            eventBus.$emit('open-profile-dialog', userData);
        },
    }
}
