<template>
  <div id="app" class="vld-parent" :class="$mq" v-visibility-change="visibilityChange">

    <div class="app-header">
      <div class="header-left">
        <span class="title">{{ $t('matchmaking-header-title') }}</span>
        <span>{{ $t('matchmaking-header-date') }}</span>
      </div>
      <div class="header-right">
        <span v-on:click="setLang('de')" :class="getSelectedLang() === 'de' ? 'selected': ''">DE</span>
        <span v-on:click="setLang('en')" :class="getSelectedLang() === 'en' ? 'selected': ''">EN</span>
      </div>
    </div>

    <div v-if="registered()" id="nav" :class="$mq">
      <router-link to="/home"><span>{{ $t('discover') }}</span></router-link>

      <router-link to="/contacts"><span>{{ $t('contacts') }}</span></router-link>

      <router-link to="/profile">
        <span> {{ $t('profile-text') }}</span>
        <div class="tooltip" v-if="showTooltip">
          <span class="badge">!</span>
          <span class="tooltiptext arrow-top">{{ $t('complete-profile') }}</span>
        </div>
      </router-link>

      <router-link v-if="$enableAppointments" to="/appointments">
        <span> {{ $t('appointments') }}</span>
        <span class="badge" v-if="appointment_requests_count > 0"> {{ appointment_requests_count }} </span>
      </router-link>


      <router-link to="/chat">
        <span> {{ $t('chats') }}</span>
        <span class="badge" v-if="unread_messages > 0"> {{ unread_messages }} </span>
      </router-link>

      <router-link v-if="splitChatAndContactRequests && contact_requests > 0" :to="{name:'ContactRequests'}">
        <span> {{ $t("contact-requests") }}</span>
        <span class="badge"> {{ contact_requests }} </span>
      </router-link>

      <router-link to="/companies" v-if="$enableCompanies">{{ $t('discover-organisations') }}</router-link>

      <router-link v-if="$enableCorporates && isCorporatesEnabled()" to="/corporates">{{
          $t('discover-corporates')
        }}
      </router-link>

      <router-link to="/events" v-if="$enableGroups"> {{ $t('interest-group-discussion-nav-link') }}</router-link>

      <router-link to="/content" v-if="$enableVideoCollection">{{
          $t('video-collection-nav-link')
        }}
      </router-link>


    </div>

    <div v-if="showReloadPageWarningBanner" class="reloadPageWarningBanner">
      <i class="fa fa-exclamation-triangle fa-3x"></i>
      <span>{{ $t('reload-page-banner-text') }}</span>
      <div class="reloadPageButton" v-on:click="reloadPage">{{ $t('reload-page-banner-button-text') }}</div>
    </div>

    <div v-if="showDisconnectedSocketWarning" class="reloadPageWarningBanner">
      <i class="fa fa-wifi-slash fa-2x"></i>
      <span>{{ $t('disconnected-socket-banner-text') }}</span>
      <div class="reloadPageButton" v-on:click="reloadPage">{{ $t('reload-page-banner-button-text') }}</div>
    </div>

    <router-view @registrationComplete="registrationComplete" @subscribeGroup="subscribeGroup" :key="$route.path"/>

    <video-chat ref="videoChat"></video-chat>
    <incoming-call-popup :on-accept="acceptVideoCall" :on-reject="rejectVideoCall"
                         ref="incomingCallPopup"></incoming-call-popup>
    <notification-component ref="notifications"></notification-component>
    <profile-others ref="profileOthers"></profile-others>
  </div>
</template>
<script>
import backend from "./mm-backend";
import {addMessageCallback} from "./push-backend";
import Router from "./router/index";
import localStorageHelper from "./localstorage/local-storage";
import SocketIO from 'socket.io-client';
import {eventBus, onlineContactsStorage} from './main';
import {Appointment} from "@/entities/Appointment";
import {isDefined} from "@/utils";
import NotificationComponent from "@/components/Notifications/NotificationComponent";
import moment from "moment";
import {appointmentsStorage, logger} from "@/main";
import {MissedVideoCall} from "@/components/Notifications/MissedVideoCall/MissedVideoCall";
import {User} from "@/entities/User";
import langHelper from '@/locales/lang-helper';
import i18n from '@/i18n';

export default {
  components: {NotificationComponent},
  data() {
    return {
      unread_messages: 0,
      contact_requests: 0,
      appointment_requests: [],
      socket: undefined,
      busy: false,
      videoSocket: undefined,
      currentGroup: undefined,
      showTooltip: false,
      showReloadPageWarningBanner: false,
      splitChatAndContactRequests: this.$splitChatAndContactRequests,
      dueAppointmentTimers: {},
      lastVersionCheck: undefined,
      showDisconnectedSocketWarning: false
    }
  },
  computed: {
    appointment_requests_count() {
      return this.appointment_requests.length;
    }
  },
  created() {
    if (!localStorageHelper.getUserId() || !localStorageHelper.getDeviceId()) {
      this.showTooltip = false;
    } else {
      const alreadyVisited = localStorageHelper.getAlreadyVisited();
      if (!alreadyVisited) {
        this.showTooltip = true;
      }
    }
  },
  mounted() {
    this.checkVersion();
    eventBus.$on('already-visited', this.deactivateTooltip);
    eventBus.$on('ignore-contact', this.onIgnoreContact);
    eventBus.$on('update-chat-users-count', this.onUpdateChatUsersCounts);
    eventBus.$on('update-appointment-requests', this.onUpdateAppointmentRequests);
    eventBus.$on('on-appointment-request-handled', this.onAppointmentRequestHandled);
    eventBus.$on('on-appointment-canceled', this.onAppointmentCanceled);
    eventBus.$on('open-video-chat', this.openVideoChat);
    eventBus.$on('open-profile-dialog', this.openProfileDialog);
    eventBus.$on('add-notification', this.addNotification);
    eventBus.$on('reload-notifications', this.reloadDueAppointments);
    eventBus.$on('remove-notification', this.removeNotification)
    eventBus.$on('update-busy-state', this.updateBusyState);
    this.$root.$on('change-unread-messages', (arg) => {
      if (arg.count !== undefined) {
        this.unread_messages = arg.count;
      }
    });
    if (this.registered()) {
      this.registrationComplete();
    }
    addMessageCallback(function (payload) {
      logger.log("received message in App.vue");
      if (Router.currentRoute.name !== 'Chat' && payload.data.badge) {
        this.unread_messages = payload.data.badge;
      }
      if (payload.data.type === "request") {
        //  this.onContactRequestReceived();
      }
      if (payload.data.type === "appointment") {
        if (isDefined(payload.data.appointment_id)) {
          if (+payload.data.state === Appointment.block_states.STATE_BLOCKED_WAITING_FOR_RESPONSE) {
            this.onAppointmentRequestReceived(payload.data.appointment_id);
          } else {
            this.onAppointmentRequestHandled({id: payload.data.appointment_id});
          }
        }
      }
      return false;
    }.bind(this));
  },
  methods: {
    getSelectedLang() {
      return langHelper.getCurrentLang()
    },
    setLang(lang) {
      langHelper.setCurrentLang(lang);
    },
    updateBusyState(busy) {
      if (this.busy != busy) {
        if (this.socket) {
          this.socket.emit("status_update", {busy: busy});
        }
        this.busy = busy;
      }
    },
    visibilityChange(event, hidden) {
      if (!hidden) {
        this.checkVersion();
      }
    },
    checkVersion() {
      const now = moment();
      now.subtract('30', 'minutes');
      if (!isDefined(this.lastVersionCheck) || now.isAfter(this.lastVersionCheck)) {
        backend.getVersion().then(res => {
          this.lastVersionCheck = moment();
          const frontendVersion = this.$appVersion;
          const versionFromServer = +res.data.frontend;
          if (frontendVersion < versionFromServer) {
            logger.log("Frontend Version: " + frontendVersion);
            logger.log("Version from Server: " + versionFromServer);
            this.showReloadPageWarningBanner = true;
            this.$nextTick(() => {
              const reloadPageButton = document.getElementById('reloadPageButton');
              if (isDefined(reloadPageButton)) {
                reloadPageButton.onclick = this.reloadPage;
              }
            });
          }
        });
      }
    },
    isCorporatesEnabled() {
      return User.isCorporatesEnabled();
    },
    registered() {
      const user = localStorageHelper.getUser();
      return isDefined(user) && isDefined(user.id) && localStorageHelper.getUserConfirmed() && isDefined(localStorageHelper.getRegistrationCompleted());
    },
    reloadPage() {
      window.location.reload(true);
    },
    onUpdateChatUsersCounts(counts) {
      this.unread_messages = counts.unread_messages;
      this.contact_requests = counts.contact_requests;
    },
    deactivateTooltip() {
      this.setAlreadyVisited();
      this.showTooltip = false;
    },
    setAlreadyVisited() {
      localStorageHelper.setAlreadyVisited();
    },
    onContactRequestReceived() {
      this.contact_requests++;
    },
    onAppointmentRequestReceived(appointmentId) {
      if (this.appointment_requests.findIndex(id => +id === +appointmentId) === -1) {
        // this.appointment_requests.push(+appointmentId);
        eventBus.$emit('reload-appointment-list');
      }
    },
    onAppointmentCanceled(appointment) {
      if (isDefined(this.dueAppointmentTimers[appointment.id])) {
        window.clearTimeout(this.dueAppointmentTimers[appointment.id]);
      }
      this.removeNotification(appointment);
    },
    onUpdateAppointmentRequests(requests) {
      this.appointment_requests = [];
      if (isDefined(requests) && requests.length > 0) {
        requests.forEach(r => {
          if (this.appointment_requests.findIndex(id => +id === +r.id) === -1) {
            this.appointment_requests.push(+r.id);
          }
        });
      }
    },
    onIgnoreContact() {
      this.contact_requests--;
    },
    onAppointmentRequestHandled(appointment) {
      const index = this.appointment_requests.findIndex(id => +id === +appointment.id);
      if (index > -1) {
        this.appointment_requests.splice(index, 1);
      }
      if (appointment instanceof Appointment && appointment.isDue()) {
        this.addNotification(appointment);
      }
    },
    getUnreadMessages() {
      if (localStorageHelper.getUserId()) {
        const self = this;
        backend.getChatUsers().then(resp => {
          self.unread_messages = resp.data.unread_messages;
          self.contact_requests = resp.data.contact_requests;
        });
      }
    },
    reloadDueAppointments(appointments) {
      const confirmedAppointments = Appointment.getConfirmedRequests(appointments);
      for (const appointment of confirmedAppointments) {
        if (appointment.isDue()) {
          this.removeNotification(appointment);
          this.addNotification(appointment);
        } else if (!appointment.alreadyPast() && appointment.getMillisUntilDue() < 2147483647) { // windows setTimout only works with max 31bit int
          this.dueAppointmentTimers[appointment.id] = window.setTimeout(self.addNotification, appointment.getMillisUntilDue(), appointment);
        }
      }
    },
    checkForDueAppointments(appointments) {
      const confirmedAppointments = Appointment.getConfirmedRequests(appointments);
      for (const appointment of confirmedAppointments) {
        if (appointment.isDue()) {
          this.addNotification(appointment);
        } else if (!appointment.alreadyPast() && appointment.getMillisUntilDue() < 2147483647) { // windows setTimout only works with max 31bit int
          this.dueAppointmentTimers[appointment.id] = window.setTimeout(self.addNotification, appointment.getMillisUntilDue(), appointment);
        }
      }
    },
    getAppointmentRequests() {
      if (localStorageHelper.getUserId()) {
        const self = this;
        backend.getAllAppointments().then(resp => {
          self.dueAppointmentTimers = {};
          const appointments = resp.data.appointments;
          const events = resp.data.events;
          const received_requests = Appointment.getReceivedRequests(appointments, localStorageHelper.getUserId());
          received_requests.forEach(r => {
            if (self.appointment_requests.findIndex(id => +id === +r.id) === -1) {
              self.appointment_requests.push(+r.id);
            }
          });

          appointmentsStorage.setEvents(events);
          appointmentsStorage.setAppointments(appointments)
          appointmentsStorage.setUsers(resp.data.users);
          if (isDefined(resp.data.rooms)) {
            appointmentsStorage.setRooms(resp.data.rooms);
          }


          self.checkForDueAppointments(appointments);

          for (const event of events) {
            if (event.isDue()) {
              self.addNotification(event);
            } else if (!event.alreadyPast() && event.getMillisUntilDue() < 2147483647) {
              self.dueAppointmentTimers[event.event_id] =
                  window.setTimeout(self.addNotification, event.getMillisUntilDue(), event);
            }
          }
        });
      }
    },
    subscribeGroup(event) {
      if (process.env.VUE_APP_CHAT_URL && this.socket) {
        this.socket.emit('group', {
          group_id: event
        });
      } else {
        this.currentGroup = event;
      }
    },
    registrationComplete(event) {
      if (isDefined(process.env.VUE_APP_CHAT_URL) && !isDefined(this.socket)
          && isDefined(localStorageHelper.getUserId()) && isDefined(localStorageHelper.getDeviceId())) {
        logger.log('connecting ' + process.env.VUE_APP_CHAT_URL);
        this.socket = SocketIO(process.env.VUE_APP_CHAT_URL + "/chat", {
          'reconnection': true,
          'reconnectionDelay': 5000
        });
        this.socket.on('connect_error', (err) => {
          logger.error(`connect_error ${err.message}`);
          this.showSocketDisconnectWarning();
        });
        this.socket.on('disconnect', (reason) => {
          logger.log(`socket disconnect ${reason}`);
          this.showSocketDisconnectWarning();
        });
        this.socket.on('chat_msg', data => {
          this.$root.$emit('chat_msg', data);
          if (Router.currentRoute.name !== 'Chat') {
            this.getUnreadMessages();
          }
        });
        this.socket.on('group_msg', data => {
          this.$root.$emit('group_msg', data);
        });
        this.socket.on('chat_status', data => {
          this.$root.$emit('chat_status', data);
        });
        this.socket.on('call-user', data => {
          logger.log('incoming video chat ' + JSON.stringify(data));
          if (this.busy) {
            backend.user(data.peer).then(resp => {
              eventBus.$emit('add-notification', new MissedVideoCall(new User(resp.data)));
            });
            return;
          }
          this.$refs.incomingCallPopup.openPopup(data);
        });
        this.socket.on('call-stop', data => {
          logger.log('stop incoming video chat ' + JSON.stringify(data));
          if (this.$refs.incomingCallPopup && this.$refs.incomingCallPopup.opened && this.$refs.incomingCallPopup.callData.peer == data.peer) {
            this.$refs.incomingCallPopup.timedOut();
          }
        });
        this.socket.on('outgoing-call-accepted', data => {
          if (this.$refs.videoChat && this.$refs.videoChat.opened) {
            this.$refs.videoChat.outgoingCallAccepted(data);
          }
        });
        this.socket.on('outgoing-call-rejected', data => {
          if (this.$refs.videoChat && this.$refs.videoChat.opened) {
            this.$refs.videoChat.outgoingCallRejected(data);
          }
        })
        this.socket.on('incoming-call-accepted', data => {
          //console.log('incoming-call-accepted received for ' + JSON.stringify(data));
          if (this.$refs.incomingCallPopup && this.$refs.incomingCallPopup.opened && this.$refs.incomingCallPopup.callData.peer == data.peer) {
            this.$refs.incomingCallPopup.closePopup();
            logger.log('popup closed');
          }
        });
        this.socket.on('incoming-call-rejected', data => {
          //console.log('incoming-call-rejected received for ' + JSON.stringify(data));
          if (this.$refs.incomingCallPopup && this.$refs.incomingCallPopup.opened && this.$refs.incomingCallPopup.callData.peer == data.peer) {
            this.$refs.incomingCallPopup.closePopup();
            logger.log('popup closed');
          }
        });
        this.socket.on('hangup', data => {
          if (this.$refs.incomingCallPopup) {
            this.$refs.incomingCallPopup.closePopup();
          }
          logger.log('hangup received for ' + data.call);
        });

        this.socket.on('status_list2', data => {
          onlineContactsStorage.setOnlineContacts(data.online);
          onlineContactsStorage.setBusyContacts(data.busy);
          eventBus.$emit('setOnlineContacts', data);
        });

        this.socket.on('status_online', data => {
          onlineContactsStorage.addOnlineContact(data.id);
          eventBus.$emit('setContactOnline', data.id);
        });
        this.socket.on('status_offline', data => {
          onlineContactsStorage.removeOnlineContact(data.id);
          eventBus.$emit('setContactOffline', data.id);
        });
        this.socket.on('status_busy', data => {
          onlineContactsStorage.addBusyContact(data.id);
          eventBus.$emit('setContactBusy', data.id);
        });
        this.socket.on('status_free', data => {
          onlineContactsStorage.removeBusyContact(data.id);
          eventBus.$emit('setContactFree', data.id);
        });

        this.socket.on('appointment_msg', data => {
          if (isDefined(data.block_state) && isDefined(data.requester_id) && +data.requester_id !== +localStorageHelper.getUserId()) {
            if (data.block_state === Appointment.block_states.STATE_BLOCKED_WAITING_FOR_RESPONSE) {
              this.onAppointmentRequestReceived(data.id);
            } else {
              this.onAppointmentRequestHandled(data);
            }
          }
        });

        this.socket.on('contact_msg', data => {
          this.getUnreadMessages();
        });

        this.socket.on('connect', socket => {
          if (isDefined(localStorageHelper.getUserId()) && isDefined(localStorageHelper.getDeviceId())) {
            this.socket.emit('auth', {
              me: localStorageHelper.getUserId(),
              device: localStorageHelper.getDeviceId(),
              prj: process.env.VUE_APP_PUSH_NAME
            });
          } else {
            logger.sendErrorDump("Tried Socket Auth without deviceId or userId", {
              deviceId: localStorageHelper.getDeviceId(),
              userId: localStorageHelper.getUserId()
            });
          }
          if (this.currentGroup) {
            this.subscribeGroup(this.currentGroup);
          }
          this.showDisconnectedSocketWarning = false;
        });
        this.getAppointmentRequests();
        this.getUnreadMessages();
      }
    },
    showSocketDisconnectWarning() {
      this.showDisconnectedSocketWarning = true;
      this.$nextTick(() => {
        const retrySocketButton = document.getElementById('retrySocketButton');
        if (isDefined(retrySocketButton)) {
          retrySocketButton.onclick = this.reloadPage;
        }
      });
    },
    acceptVideoCall(callData) {
      const useVideoServer = !!(process.env.VUE_APP_VIDEO_CHAT_SERVER);
      if (useVideoServer) {
        if (this.socket) {
          this.socket.emit('accept-call', callData);
        }
      }
      this.$refs.videoChat.acceptIncomingVideoChat({
        id: callData.peer,
        fullName: callData.name,
        photo: callData.avatar,
        initials: callData.initials
      }, this.socket, callData.call);
    },
    rejectVideoCall(callData) {
      const useVideoServer = !!(process.env.VUE_APP_VIDEO_CHAT_SERVER);
      if (useVideoServer) {
        if (this.socket) {
          this.socket.emit('reject-call', callData);
        }
      } else {
        this.videoSocket = SocketIO(process.env.VUE_APP_CHAT_URL + "/video", {
          'reconnection': false,
          'reconnectionDelay': 5000
        });
        this.videoSocket.on('connect', socket => {
          this.videoSocket.emit('auth', {
            me: localStorageHelper.getUserId(),
            device: localStorageHelper.getDeviceId(),
            prj: process.env.VUE_APP_PUSH_NAME
          });
          this.videoSocket.emit('reject-call', {
            call: callData.call,
            prj: process.env.VUE_APP_PUSH_NAME
          });
          this.videoSocket = undefined;
        });
      }


    },
    openVideoChat(userData, withoutToken) {
      this.$refs.videoChat.openVideoChat(userData, this.socket, withoutToken);
    },
    addNotification(entity) {
      if (isDefined(this.$refs.notifications)) {
        this.$refs.notifications.addNotification(entity);
      }
    },
    removeNotification(entity) {
      if (isDefined(this.$refs.notifications)) {
        this.$refs.notifications.closeNotificationByVueId(entity.vueId);
      }
    },
    openProfileDialog(userData, isRecommendation) {
      if (isDefined(this.$refs.profileOthers)) {
        this.$refs.profileOthers.openDialog(userData, isRecommendation);
      }
    }
  }
}

</script>


<style lang="scss">
@import "assets/styles/scss/profile-own-themes.scss";
</style>

<style lang="less">
@import "assets/styles/combined.less";

#app, .toasted {
  font-family: Gilroy, Roboto, Arial, sans-serif;

  button, input, input::placeholder, textarea, .videochat, .select {
    font-family: Gilroy, Roboto, Arial, sans-serif;
  }
}


.app-header {
  max-width: calc(1170px + 9%);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-left, .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .header-left {
    .title {
      font-weight: 600;
      margin-right: 8px;
    }
  }


  .header-right {
    .selected {
      font-weight: 800;
    }

    > span {
      cursor: pointer;
      margin-right: 6px;
    }
  }

}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  letter-spacing: .3px;
  line-height: 1.5;
  color: var(--font-color);
  font-size: 16px;
  min-height: 80vh;
  width: 100%;

  .left-aligned {
    text-align: left;
  }

  *, *::after, *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  *, *:after, *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .emoji {
    font-family: Twemoji, NotomojiColor, Color, Symbola, Noto, Segoe UI Emoji, OpenSansEmoji, monospace;
    box-sizing: content-box;
  }

  .main {

    display: flex;
    flex-direction: column;
    max-width: calc(1170px + 9%);
    margin: 0 auto;
    padding: 49px 0;
    align-items: flex-start;

  }

}

#app.mobile {
  padding: 10px;

  .main {
    padding: 9px 0;

    .side-header {
      margin: 0 0 15px 0;
    }
  }
}

#nav.mobile {
  align-items: flex-start;
  flex-direction: column;

  > a {
    margin: 0 10px 0 0;
    letter-spacing: 0;
    font-size: 14px;
  }
}

#nav {

  h1 {
    margin: 0 0 40px 0;
    font-weight: 300;
    font-size: 40px;
    color: var(--primary-color);
  }

  max-width: calc(1170px + 9%);
  margin: 30px auto 0;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;

  .badge {
    -moz-border-radius: 1.8em;
    border-radius: 1.8em;
    background-color: var(--primary-color);
    border: 0 solid white;
    margin-top: calc(10px - 1.0em);
    position: relative;
    float: right;
    color: #fff;
    font-weight: 800;
    font-size: 0.6em;
    text-align: center;
    min-width: 1.8em;
    min-height: 1.8em;
    margin-left: 4px;
  }


  a {
    margin: 0 40px 0 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    letter-spacing: .9px;
    outline: 0;
    border-bottom: 1px solid transparent;
    color: var(--primary-color);
    transition: color .4s, border-bottom-color .4s;
    -webkit-transition: color .4s, border-bottom-color .4s;

    &.router-link-active {
      font-weight: 700;
      color: var(--primary-color);
      border-bottom-color: var(--primary-color);
    }

    &.router-link-exact-active {
      font-weight: 700;
      color: var(--primary-color);
      border-bottom-color: var(--primary-color);
    }
  }

  a:hover, a:focus {
    color: var(--primary-color);
  }

  /* Tooltip */

  .tooltip {
    position: relative;
    display: inline-block;

  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: var(--primary-color);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;

    position: absolute;
    z-index: 1;
    top: 215%;
    left: 50%;
    margin-left: -120px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  /* arrow-top */

  .tooltiptext.arrow-top::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--primary-color);
    border-width: 10px;
    margin-left: -15px;
  }
}


.reloadPageWarningBanner {
  > i {
    color: var(--reload-page-warning-banner-icon-text-color);
    margin-left: 10px;
    margin-right: 10px;
  }

  > span {
    color: var(--reload-page-warning-banner-icon-text-color);
    font-weight: 400;
    margin-right: 20px;
  }


  .reloadPageButton {
    border: none;
    background-color: var(--reload-page-warning-banner-icon-text-color);
    color: #FFFFFF;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    margin-left: auto;
  }

  background-color: var(--reload-page-warning-banner-color);
  display: flex;
  flex-direction: row;
  border: 1px solid var(--reload-page-warning-banner-border-color);
  align-items: center;
  padding: 8px;
  text-align: left;
  width: 100%;
  margin: 30px auto 0;
  max-width: calc(1170px + 9%);
  font-size: 0.8em;
}


</style>
