<template>
  <div class="entry-point" v-visibility-change="visibilityChange">


    <loading :active.sync="showLoadingSpinner"
             :is-full-page="false"
             color="var(--primary-color)">
    </loading>

    <login-view v-if="showLoginPage" v-on:submit="loginPageSubmit"></login-view>
    <select-themes v-if="showThemeSelect" v-on:submit="registerAfterThemeSelect" v-bind:user-themes="userThemes"
                   v-bind:user-role="userRole"
                   v-bind:show-start-tab="true"></select-themes>


    <custom-dialog :id="'emailNotConfirmedDialog'" ref="emailNotConfirmedDialog"
                   :on-submit="getUser"
                   :submit-button-text="$t('email-confirmed')"
                   :can-close-dialog-validation-function="isUserConfirmed"
                   :on-second-button-submit="resendEmail"
                   :second-button-text="$t('resend-email')">
      <template>
        <span class="header"> {{ $t('please-confirm-your-mail') }} </span>
        <div class="email-not-confirmed-dialog-container">
          <i class="fal fa-envelope fa-2x"></i>
          <div class="email-not-confirmed-dialog-text-container">
            <span class="email-confirmation"
                  v-html="$t('please-confirm-your-mail-text',{email:email()})"></span>
          </div>
        </div>
      </template>
    </custom-dialog>

    <!-- Change E-Mail Dialog -->
    <custom-dialog v-bind:id="'changeEmailDialog'" ref="changeEmailDialog" v-bind:disable-buttons="true"
                   :can-close-dialog-validation-function="isUserConfirmed"
                   v-bind:onCancel="onChangeEmailDialogCanceled">
      <span class="header">{{ $t('change-email-dialog-header') }}</span>
      <form class="changeEmailDialogForm" v-on:submit.prevent="saveChangedEmail()">
        <CustomInputText v-bind:value.sync="newEmail" v-bind:place-holder="$t('email')"
                         v-bind:custom-type="'email'"
                         v-bind:required="true"></CustomInputText>
        <custom-button v-bind:width="250" v-bind:onClicked="submitChangeEmailForm"
                       v-bind:icon="'fal fa-chevron-right fa-2x'">
          {{ $t('change-email') }}
        </custom-button>
        <input style="display:none;" hidden id="submit-change-mail-input" type="submit">
      </form>
    </custom-dialog>

    <custom-dialog :id="'login-failed-dialog'" ref="loginFailedDialog"
                   :on-submit="onLoginFailedClosed"
                   :submit-button-text="$t('dialog-ok')"
                   :on-cancel="onLoginFailedClosed">
      <span class="header"> {{ $t('matchmaking-title') }} </span>
      <div id="login-failed-text" class="login-failed" v-html="$t('login-failed-text')"></div>
    </custom-dialog>

    <custom-dialog :id="'push-notifications-disabled-dialog'" ref="notificationsDisabledDialog"
                   :on-submit="registerForPushes"
                   :submit-button-text="$t('dialog-ok')"
                   :on-cancel="cancelRegisterForPushes">
      <span class="header"> {{ $t('notifications-header') }} </span>
      <div class="notifications-confirmation" v-html="$t('notifications-text')"></div>
    </custom-dialog>

    <custom-dialog :id="'firefox-settings-dialog'" ref="firefoxSettingsDialog"
                   :on-submit="firefoxSettingsOk"
                   :submit-button-text="$t('dialog-ok')"
                   :on-cancel="firefoxSettingsOk" :can-close-dialog-validation-function="validateForm"
                   :on-second-button-submit="firefoxSettingsContinue"
                   :second-button-text="$t('continue-anyway')">
      <span class="header"> {{ $t('firefox-header') }} </span>
      <div class="firefox-settings" v-html="$t('firefox-text')"></div>
    </custom-dialog>


  </div>
</template>

<script>
import router from "../router";
import {isNotificationAllowed, isNotificationDefault, isNotificationSupported, register} from '@/push-backend';
import {zip} from "rxjs";
import mybookfair from "../mbf-backend";
import backend from "../mm-backend";
import themes from "../themes/themes-helper";
import {setUserData} from "@/firebase";
import localStorageHelper from "../localstorage/local-storage";
import matomo from "../matomo/tracker";
import CustomCheckBox from "@/components/inputs/CustomCheckBox";
import LoginView from "@/components/LoginView";
import SelectThemes from "@/themes/select/select-themes";
import {isDefined, isEmptyObject, openVideoChat} from "@/utils";
import CustomInputText from "@/components/inputs/CustomInputText";
import {logger} from "@/main";
import errorHandler from "@/error-handler";

export default {
  name: "EntryPoint.vue",
  components: {CustomCheckBox, LoginView, SelectThemes, CustomInputText},
  data() {
    return {
      lang: this.$getCurrentLanguage(),
      acceptTermsOfUse: false,
      registerRequestRunning: false,
      registerRequestSend: false,
      themes: themes,
      showLoginPage: false,
      showThemeSelect: false,
      userThemes: undefined,
      userRole: undefined,
      newEmail: undefined,
      hasMBFThemes: false,
      newMBFUser: false,
      showLoadingSpinner: false
    }
  },
  beforeMount() {
    this.userThemes = themes.getEmptyThemesObject();
  },
  mounted() {
    this.$nextTick(() => {
      if (!localStorageHelper.getDeviceId() || !localStorageHelper.getUserId()) {
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox && !('serviceWorker' in navigator)) {
          this.$refs.firefoxSettingsDialog.openDialog();
        } else {
          this.firstOpen();
        }
      } else {
        //get user to check if he is confirmed or not and save user data to localstorage
        this.getUser(true);
      }
    });
  },
  methods: {
    firstOpen() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('code')) {
        this.register();
      } else {
        this.openEntryDialog();
      }
    },
    firefoxSettingsOk() {
      window.location = process.env.VUE_APP_EXIT_REDIRECT_URL;
    },
    firefoxSettingsContinue() {
      this.$refs.firefoxSettingsDialog.closeDialogFromOutside();
      this.firstOpen();
    },
    validateForm() {
      return this.acceptTermsOfUse && this.isUserConfirmed();
    },
    showChangeEmailDialog() {
      this.newEmail = localStorageHelper.getUser().email;
      this.$refs.changeEmailDialog.openDialog();
    },
    onEmailChangedClicked() {
      this.closeEmailNotConfirmedDialog();
      this.showChangeEmailDialog();
    },
    onChangeEmailDialogCanceled() {
      this.closeChangeEmailDialog();
      this.openEmailNotConfirmedDialog();
    },
    closeChangeEmailDialog() {
      if (this.$refs.changeEmailDialog.isOpen()) {
        this.$refs.changeEmailDialog.closeDialogFromOutside();
      }
    },
    submitChangeEmailForm() {
      document.getElementById('submit-change-mail-input').click();
    },
    resendEmail() {
      const self = this;
      backend.resendConfirmationEmail().then(resp => {
        if (resp && resp.data && resp.data.error) {
          self.$toasted.global.error_notification({message: resp.data.error.message});
        } else {
          self.$toasted.show(self.$t('email-resend'), {
            duration: 2000,
            position: "top-center"
          });
        }
      })
    },
    openEntryDialog() {
      this.showLoginPage = true;
    },
    closeEntryDialog() {
      this.showLoginPage = false;
    },
    openLoginFailedDialog() {
      this.$refs.loginFailedDialog.openDialog();
    },
    closeLoginFailedDialog() {
      this.$refs.loginFailedDialog.closeDialogFromOutside();
    },
    onLoginFailedClosed() {
      this.closeLoginFailedDialog();
      this.openEntryDialog();
    },
    openNotificationsDisabledDialog() {
      this.$refs.notificationsDisabledDialog.openDialog();
    },
    closeNotificationsDisabledDialog() {
      this.$refs.notificationsDisabledDialog.closeDialogFromOutside();
    },
    openEmailNotConfirmedDialog() {
      this.$refs.emailNotConfirmedDialog.openDialog();
      this.$nextTick(() => {
        const changeEmailButton = document.getElementById('changeEmailButton');
        if (isDefined(changeEmailButton)) {
          changeEmailButton.onclick = this.onEmailChangedClicked;
        }
      });
    },
    closeEmailNotConfirmedDialog() {
      this.$refs.emailNotConfirmedDialog.closeDialogFromOutside();
    },
    loginPageSubmit(userData) {
      if (isDefined(userData)) {
        this.registerWithoutMbf(userData);
      } else {
        this.register();
      }
    },
    callbackUrl() {
      let path = window.location.pathname;
      if (path === '/') path = '';
      return window.location.protocol + "//" + window.location.hostname + (window.location.port ? (':' + window.location.port) : '') + path;
    },
    register() {
      if (!process.env.VUE_APP_MYBOOKFAIR_USER_HOST) {
        let urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('code')) {
          let oAuthUrl = process.env.VUE_APP_OAUTH_URL;
          if (process.env.VUE_APP_OAUTH_TYPE == 'mbf') {
            oAuthUrl = new URL("https://" + process.env.VUE_APP_MYBOOKFAIR_STAGING + "services.buchmesse.de/lang/" + this.$getCurrentLanguage() + "/oauth2/authorize");
          }
          oAuthUrl.searchParams.append("client_id", process.env.VUE_APP_OAUTH_CLIENT_ID);
          oAuthUrl.searchParams.append("nonce", "1");
          oAuthUrl.searchParams.append("response_type", "code");
          oAuthUrl.searchParams.append("scope", process.env.VUE_APP_OAUTH_SCOPE);
          oAuthUrl.searchParams.append("redirect_uri", this.callbackUrl());
          if (process.env.VUE_APP_OAUTH_TYPE == 'mbf') {
            oAuthUrl.searchParams.append("state", "egcf");
            oAuthUrl.searchParams.append("popup", "false");
            oAuthUrl.searchParams.append("hide_login_link", "true");
          } else {
            oAuthUrl.searchParams.append("ui_locales", this.$getCurrentLanguage());
          }

          window.location = oAuthUrl.toString();
          return;
        } else {
          logger.log('code: ' + urlParams.get('code'));
          this.code = urlParams.get('code');
        }
      }
      // prevent double-click
      if (this.registerRequestRunning === true) {
        return;
      }

      try {
        this.registerRequestRunning = true;
        this.showLoadingSpinner = true;
        this.registerCall();
      } catch (error) {
        logger.log(error);
        this.registerCallDone();
      }
    },
    registerCallDone() {
      this.showLoadingSpinner = false;
      this.registerRequestRunning = false;
    },
    saveChangedEmail() {
      const self = this;
      let userData = localStorageHelper.getUserParams();
      userData.email = this.newEmail;
      userData.id = localStorageHelper.getUserId();
      const deviceId = localStorageHelper.getDeviceId();
      backend.saveData(deviceId, userData, false).then(resp => {
        localStorageHelper.setUser(userData);
        localStorageHelper.setUserParams(userData)
        self.closeChangeEmailDialog();
        self.getUser();
        self.$forceUpdate();
      });
    },
    registerAfterThemeSelect(themes) {
      this.showLoadingSpinner = true;
      logger.log('registerAfterThemeSelect called');
      const self = this;

      let deviceId = localStorageHelper.getDeviceId();
      const userId = localStorageHelper.getUserId();

      if (!deviceId && !userId) {
        deviceId = this.$uuid.v4();
      }

      let userData = localStorageHelper.getUser();
      userData.id = localStorageHelper.getUserId();
      if (themes) {
        userData.themes = themes;
      }

      backend.registrationCompleted(deviceId, userData, false).then(resp => {
        localStorageHelper.setRegistrationCompleted();
        self.handleRegisterResponse(deviceId, resp);
        self.showLoadingSpinner = false;
        self.getUser();
        this.registerRequestSend = true;
      }).catch(error => {
        this.registerRequestSend = true;
        if (error === 403) {
          self.showThemeSelect = false;
          self.openEntryDialog();
        } else {
          self.openLoginFailedDialog();
        }
      });
    },
    handleRegisterResponse(deviceId, resp) {
      logger.log("register success")
      const response = JSON.parse(resp);
      this.storeUserData(deviceId, response);
    },
    storeUserData(deviceId, userData) {
      localStorageHelper.setUserId(userData.id);
      localStorageHelper.setUser(userData);
      localStorageHelper.setDeviceId(deviceId);
      setUserData({
        path: window.location.pathname + window.location.search,
        me: userData.id,
        device: deviceId,
        appname: process.env.VUE_APP_PUSH_NAME,
        accept_string: this.$t('call-accept'),
        reject_string: this.$t('call-reject'),
        chatUrl: process.env.VUE_APP_CHAT_URL + process.env.VUE_APP_CHAT_URL_PAGE
      });
    },
    isUserConfirmed() {
      return localStorageHelper.getUserConfirmed();
    },
    registerCall() {
      logger.log("register called");
      if (!process.env.VUE_APP_MYBOOKFAIR_USER_HOST) {
        this.registerCallOAuth();
      } else {
        this.registerMbfCall();
      }
    },
    registerCallOAuth() {
      const self = this;
      logger.log("registerOAuth called");

      let deviceId = localStorageHelper.getDeviceId();
      const userId = localStorageHelper.getUserId();

      if (!deviceId && !userId) {
        deviceId = this.$uuid.v4();
      }

      backend.registerOAuth(deviceId, process.env.VUE_APP_OAUTH_TYPE, this.code, this.callbackUrl()).then(resp => {
        if (!isDefined(resp.data.error)) {
          logger.log("registerOAuth ok: " + JSON.stringify(resp));
          self.registerCallDone();
          const response = resp.data.user;
          localStorageHelper.setUserId(response.id);
          localStorageHelper.setUser(response);
          localStorageHelper.setDeviceId(deviceId);
          localStorageHelper.setUserParams(response);
          localStorageHelper.setUserCreatedManually(false);
          const themes = resp.data.user.themes;
          self.userThemes = themes;
          matomo.trackMatchmakingLogin("oauth");
          this.$emit('registrationComplete');
          self.closeEntryDialog();
          if (isNotificationSupported()) {
            if (isNotificationDefault()) {
              logger.log("test 1")
              self.openNotificationsDisabledDialog();
            } else if (isNotificationAllowed()) {
              logger.log("test 2")
              if (this.themesAlreadySelected(themes)) {
                logger.log("test2 user")
                self.registerAfterThemeSelect(undefined);
              } else {
                logger.log("test2 theme")
                self.showThemeSelect = true;
              }
            } else {
              logger.log("test 3")
              if (this.themesAlreadySelected(themes)) {
                self.registerAfterThemeSelect(undefined);
              } else {
                self.showThemeSelect = true;
              }
            }
          } else {
            if (this.themesAlreadySelected(themes)) {
              self.registerAfterThemeSelect(undefined);
            } else {
              self.showThemeSelect = true;
            }
            self.showThemeSelect = true;
          }
          self.userRole = response.role;
          setUserData({
            path: window.location.pathname,
            me: response.id,
            device: deviceId,
            appname: process.env.VUE_APP_PUSH_NAME,
            accept_string: self.$t('call-accept'),
            reject_string: self.$t('call-reject'),
            chatUrl: process.env.VUE_APP_CHAT_URL
          });
        } else {
          self.registerCallDone();
          self.closeEntryDialog();
          self.openLoginFailedDialog();
          self.$nextTick(() => {
            const loginFailedText = document.getElementById('login-failed-text');
            if (isDefined(loginFailedText)) {
              loginFailedText.innerHTML = resp.data.error.message;
            }
          });

          self.deleteCodeParam();
        }
      }).catch(error => {
        const dumpMsg = {
          msg: 'register failed',
          userAgent: window.navigator.userAgent,
          url: location.href,
          error: error
        }
        errorHandler.sendErrorMessage(JSON.stringify(dumpMsg, null, 2)).then();
        self.registerCallDone();
        self.closeEntryDialog();
        self.openLoginFailedDialog();
        self.deleteCodeParam();
      })
    },
    themesAlreadySelected(themes) {
      if (JSON.stringify(themes) !== '{}') {
        if (process.env.VUE_APP_OAUTH_TYPE == 'mbf') {
            for (const key in themes) {
                switch (key) {
                    case 'MM.CAREER_LEVEL':
                    case 'MM.PROFESSION_DESCRIPTION':
                    case 'MM.COMPANY_ASSIGNMENT':
                    case 'MM.EMPLOYEES_NO':
                        break;
                    default:
                        return true;
                }
            }
            return false;
        }
        return true;
      }
      return false;
    },
    registerMbfCall() {
      logger.log("registerMbf called");
      const self = this;
      const mbfUserObservable = mybookfair.profile();
      const mbfOffersObservable = mybookfair.offerAnswers();
      const mbfSearchObservable = mybookfair.searchAnswers();
      const mbfActiveDaysObservable = mybookfair.activeDays();
      const mbfExtendedUserObservable = mybookfair.extendedUser();


      zip(mbfUserObservable, mbfOffersObservable, mbfSearchObservable, mbfActiveDaysObservable, mbfExtendedUserObservable).subscribe(resp => {
        let index = 0;
        const user = resp[index].data;
        index++;

        const offer_countries = resp[index].data.data.COUNTRIES.options.selected;
        const offer_topics = resp[index].data.data.MM_TOPICS_OF_INTEREST.options.selected;
        index++;

        const search_countries = resp[index].data.data.COUNTRIES.options.selected;
        const search_topics = resp[index].data.data.MM_TOPICS_OF_INTEREST.options.selected;
        index++;

        const active_days = resp[index].data.data.DAYS.options.selected;
        index++;


        let career_level = {}
        let company_assignment = {}
        let employees_no = {}
        let profession_description = {}

        try {
          career_level = resp[index].data.data.CAREER_LEVEL.options.selected;
          company_assignment = resp[index].data.data.COMPANY_ASSIGNMENT.options.selected;
          employees_no = resp[index].data.data.EMPLOYEES_NO.options.selected;
          profession_description = resp[index].data.data.PROFESSION_DESCRIPTION.options.selected;
        } catch (e) {
          logger.error(e, true)
        }

        let themes = this.themes.getEmptyThemesObject();


        for (const [key, value] of Object.entries(career_level)) {
          if (value) {
            themes["MM.CAREER_LEVEL"].push(`MM.CAREER_LEVEL.${key}`);
          }
        }
        for (const [key, value] of Object.entries(company_assignment)) {
          if (value) {
            themes["MM.COMPANY_ASSIGNMENT"].push(`MM.COMPANY_ASSIGNMENT.${key}`);
          }
        }
        for (const [key, value] of Object.entries(employees_no)) {
          if (value) {
            themes["MM.EMPLOYEES_NO"].push(`MM.EMPLOYEES_NO.${key}`);
          }
        }
        for (const [key, value] of Object.entries(profession_description)) {
          if (value) {
            themes["MM.PROFESSION_DESCRIPTION"].push(`MM.PROFESSION_DESCRIPTION.${key}`);
          }
        }

        for (const [key, value] of Object.entries(offer_countries)) {
          if (value) {
            themes["MM.COUNTRIES"].offer.push(`MM.COUNTRIES.${key}`);
          }
        }
        self.themes.combineThemes(themes["MM.COUNTRIES"].offer, self.themes.getCountries());

        for (const [key, value] of Object.entries(search_countries)) {
          if (value) {
            themes["MM.COUNTRIES"].search.push(`MM.COUNTRIES.${key}`);
          }
        }
        self.themes.combineThemes(themes["MM.COUNTRIES"].search, self.themes.getCountries());

        for (const [key, value] of Object.entries(offer_topics)) {
          if (value) {
            this.hasMBFThemes = true;
            themes["MM.MM_TOPICS_OF_INTEREST"].offer.push(`MM.MM_TOPICS_OF_INTEREST.${key}`);
          }
        }
        self.themes.combineThemes(themes["MM.MM_TOPICS_OF_INTEREST"].offer, self.themes.getTopics());

        for (const [key, value] of Object.entries(search_topics)) {
          if (value) {
            this.hasMBFThemes = true;
            themes["MM.MM_TOPICS_OF_INTEREST"].search.push(`MM.MM_TOPICS_OF_INTEREST.${key}`);
          }
        }
        self.themes.combineThemes(themes["MM.MM_TOPICS_OF_INTEREST"].search, self.themes.getTopics());

        for (const [key, value] of Object.entries(active_days)) {
          if (value) {
            themes["MM.DAYS"].push(`MM.DAYS.${key}`);
          }
        }

        const userId = localStorageHelper.getUserId();

        let mappedTitle = user.academic_title;
        if ("de" == this.lang) {
          switch (mappedTitle) {
            case "Dr":
              mappedTitle = "Dr.";
              break;
            case "Prof":
              mappedTitle = "Prof.";
              break;
            case "Prof Dr":
              mappedTitle = "Prof. Dr.";
              break;
          }
        }

        const userConvertedFromMbf = {
          id: userId,
          firstName: user.prename,
          lastName: user.surname,
          email: user.email,
          title: mappedTitle,
          organization: user.company,
          themes: themes,
        }

        localStorageHelper.setLastMyBookFairCheck(new Date());
        localStorageHelper.setUser(userConvertedFromMbf)
        localStorageHelper.setUserParams(userConvertedFromMbf);
        localStorageHelper.setUserCreatedManually(false);

        self.userThemes = themes;
        self.newMBFUser = true;

        const deviceId = this.$uuid.v4();
        backend.registerv2(deviceId, userConvertedFromMbf, true).then(resp => {
          self.handleRegisterResponse(deviceId, resp);
          matomo.trackMatchmakingLogin("mybookfair");
          self.registerRequestSend = true;
          self.checkPushRegistration();
        }).catch(error => {
          logger.error(error, true);
          this.registerRequestSend = true;
          self.registerCallDone();
          if (error === 403) {
            self.openEntryDialog();
          }
        });
      }, error => {
        this.registerRequestSend = true;
        self.registerCallDone();
        logger.error(error, true);
      });
    },
    deleteCodeParam() {
      let urlParams = new URLSearchParams(window.location.search);
      const codeParam = urlParams.get('code');

      if (codeParam) {
        urlParams.delete('code');
        if (process.env.VUE_APP_OAUTH_TYPE == 'mbf') {
            urlParams.delete('state');
            urlParams.delete('lang');
        }
        let search = urlParams.toString();
        if (search.length > 0) {
          search = '?' + search;
        }
        window.history.replaceState(null, null, window.location.pathname + search);
      }
    },
    checkPushRegistration() {
      if (isNotificationSupported()) {
        if (isNotificationDefault()) {
          this.closeEntryDialog();
          this.openNotificationsDisabledDialog();
        } else if (isNotificationAllowed()) {
          this.registerForPushes();
        } else {
          this.continueWithPushRegistration(false);
        }
      } else {
        this.continueWithPushRegistration(false);
      }
    },
    registerForPushes() {
      const self = this;
      this.showLoadingSpinner = true;
      register(localStorageHelper.getDeviceId()).then((deviceToken) => {
        logger.log("register push")
        self.continueWithPushRegistration(true);
      }).catch(err => {
        self.continueWithPushRegistration(false);
      });
    },
    cancelRegisterForPushes() {
      this.continueWithPushRegistration(false);
    },
    updatePushRegistration() {
      if (isNotificationSupported() && isNotificationAllowed()) {
        register(localStorageHelper.getDeviceId()).then((deviceToken) => {
        }).catch(err => {
          // ignore
        });
      }
    },
    continueWithPushRegistration(success) {
      this.closeNotificationsDisabledDialog();
      this.closeEntryDialog();

      if (success) {
        const self = this;
        backend.registerv2(localStorageHelper.getDeviceId(), localStorageHelper.getUserParams(), false).then(resp => {
          this.showLoadingSpinner = false;
          logger.log("Register with Push token ");
          self.getUser();
        });
      } else {
        this.showLoadingSpinner = false;
        this.getUser();
      }
    },
    getUser(redirectToHome) {
      const self = this;
      self.showLoadingSpinner = true;
      backend.me().then(function (response) {
        self.showLoadingSpinner = false;
        const confirmed = response.data.confirmed;
        self.registerRequestSend = true;
        if (!confirmed) {
          self.$toasted.show(self.$t('email-not-confirmed'), {
            duration: 2000,
            position: "top-center"
          });
          localStorageHelper.setUserConfirmed(false);
          self.closeChangeEmailDialog();
          self.openEmailNotConfirmedDialog();
        } else {
          localStorageHelper.setUser(response.data.user);
          localStorageHelper.setUserConfirmed(true);
          self.$emit('registrationComplete');
          self.closeEntryDialog();
          self.closeEmailNotConfirmedDialog();

          if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);
            const pushParam = urlParams.get('push');

            if (pushParam) {
              const decodedData = window.atob(pushParam);
              const push = JSON.parse(decodedData);
              window.history.replaceState(null, null, window.location.pathname);
              if (push.type && push.type === 'chat' && push.sender_id) {
                router.push({name: 'Chat', params: {userId: push.sender_id}});
                return;
              } else if (push.type && push.type === 'video' && push.url) {
                const videoUrl = new URL(push.url);
                urlParams = videoUrl.searchParams;
                const peerId = urlParams.get('peer');
                const callId = urlParams.get('call');
                const name = urlParams.get('name');
                const initials = urlParams.get('initials');
                const avatar = urlParams.get('avatar');

                openVideoChat(false, peerId, name, initials, avatar, callId, true);
                return;
              } else if (push.url) {
                window.location = push.url;
                return;
              }
            } else {
              self.deleteCodeParam();
            }
          }
          setUserData({
            path: window.location.pathname + window.location.search,
            me: response.data.user.id,
            device: localStorageHelper.getDeviceId(),
            appname: process.env.VUE_APP_PUSH_NAME,
            accept_string: self.$t('call-accept'),
            reject_string: self.$t('call-reject'),
            chatUrl: process.env.VUE_APP_CHAT_URL + process.env.VUE_APP_CHAT_URL_PAGE
          });
          self.updatePushRegistration();

          if (!isDefined(localStorageHelper.getRegistrationCompleted())) {
            const isMbf = process.env.VUE_APP_MYBOOKFAIR_USER_HOST != null && process.env.VUE_APP_MYBOOKFAIR_USER_HOST.length > 0;
            if ((self.newMBFUser && self.hasMBFThemes) ||
                (!self.newMBFUser && isDefined(response.data.user.themes) && !isEmptyObject(response.data.user.themes))) {
              logger.log("Themes found. Skip Theme Select.")
              localStorageHelper.setRegistrationCompleted();
              if (response.data.user.registration_completed) {
                localStorageHelper.setAlreadyVisited();
                router.push('home');
              } else {
                router.push('profile');
              }
              return;
            } else {
              logger.log("no themes selected yet. show theme select")
              self.userRole = response.data.user.role;
              self.showThemeSelect = true;
              if (isMbf) self.hasMBFThemes = true; // prevent loops
              return;
            }
          }

          if (redirectToHome) {
            router.push('home');
          } else {
            router.push('profile');
          }
        }
      }).catch(error => {
        self.showLoadingSpinner = false;
        self.registerRequestSend = true;
      });
    },
    registerWithoutMbf(userData) {
      logger.log("register without mbf called");

      const self = this;
      // prevent double-click
      if (this.registerRequestRunning === true) {
        return;
      }

      try {
        this.registerRequestRunning = true;
        const deviceId = this.$uuid.v4();
        backend.registerv2(deviceId, userData, false).then(resp => {
          self.handleRegisterResponse(deviceId, resp);
          localStorageHelper.setLastMyBookFairCheck(new Date());
          localStorageHelper.setUserParams(userData);
          localStorageHelper.setUserCreatedManually(true);
          matomo.trackMatchmakingLogin("manual");
          self.registerRequestSend = true;
          self.checkPushRegistration();
          self.registerCallDone();
        }).catch(error => {
          this.registerRequestSend = true;
          self.registerCallDone();
          if (error === 403) {
            self.openEntryDialog();
          }
        });
      } catch (error) {
        this.registerCallDone();
      }
    },
    visibilityChange(evt, hidden) {
      if (!hidden && this.registerRequestSend && !this.isUserConfirmed() && !this.$refs.notificationsDisabledDialog.isOpen()) {
        this.getUser();
      }
    },
    email() {
      const userFromStorage = localStorageHelper.getUser();
      if (isDefined(userFromStorage) && isDefined(userFromStorage.email)) {
        return userFromStorage.email;
      } else if (isDefined(localStorageHelper.getUserParams())) {
        return localStorageHelper.getUserParams().email;
      }
      return undefined;
    }
  }
}
</script>

<style scoped lang="less">

.email-not-confirmed-dialog-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  > i {
    font-size: 6em;
    color: var(--secondary-color);
    padding: 0 20px 20px;
    margin: 0 10px 10px;
  }

  div {
    padding: 20px;
    margin: 0;
  }

  .email-confirmation {
    text-align: left;
  }

  .email-not-confirmed-dialog-text-container {
    display: flex;
    flex-direction: column;

    i {
      cursor: pointer;
    }
  }
}

.privacy, .email-confirmation, .notifications-confirmation, .privacy-confirm, .firefox-settings {
  text-align: left;
  margin: 0 0 30px 0;
  padding: 0;
}


.entry-point {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .select-themes-view {
    margin: 30px 0;
    width: 75%;
  }
}

</style>
<style lang="less">
.firefox-settings {
  ul {
    padding-left: 40px;
  }

  li {
    list-style: disc;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
