import {isDefined} from "@/utils";

export class AppointmentsStorage {

    constructor() {
        this._appointments = [];
        this._rooms = [];
        this._users = [];
        this._events = [];
    }


    getAppointments() {
        return this._appointments;
    }

    setAppointments(appointments) {
        if (isDefined(appointments) && Array.isArray(appointments)) {
            this._appointments = appointments;
        }
    }

    getUsers() {
        return this._users;
    }

    getUserById(userId) {
        for (const u of this._users) {
            if (+u.id === +userId) {
                return u;
            }
        }
        return undefined;
    }

    setUsers(users) {
        if (isDefined(users) && Array.isArray(users)) {
            this._users = users;
        }
    }

    getRooms() {
        return this._rooms;
    }

    setRooms(rooms) {
        if (isDefined(rooms) && Array.isArray(rooms)) {
            this._rooms = rooms;
        }
    }

    getRoomById(roomId) {
        for (const r of this._rooms) {
            if (r.id === roomId) {
                return r;
            }
        }
        return undefined;
    }

    setEvents(events) {
        if (isDefined(events) && Array.isArray(events)) {
            this._events = events;
        }
    }

    getEvents() {
        return this._events;
    }

}

