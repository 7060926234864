<template>
  <div class="input-wrapper">
    <label for="mbfSelect" class="label select-label"
           v-bind:class="{'filled-input' :selectedValue !== '' && selectedValue !== null && selectedValue !== undefined}">{{placeHolder}}</label>
    <i class="far fa-chevron-down select-icon" v-bind:class="{'filled-input-icon' :selectedValue !== '' && selectedValue !== null && selectedValue !== undefined}" v-if="options && options.length > 1"></i>
    <select id="mbfSelect" ref="mbfSelect" class="select"
            v-on:mousedown="onMouseDown"
            v-model="internValue" v-on:change="$emit('update:selectedValue',internValue);$emit('change')">
      <option v-if="noSelectionAllowed" value=""></option>
      <option v-for="option of options" v-bind:value="optionSelector ? option[optionSelector] : option">
        {{getDisplayValue(option)}}
      </option>
    </select>
  </div>
</template>
<script>
import {isDefined} from "@/utils";

export default {
  name: "MBFSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    optionSelector:{
      type: String,
      required: false
    },
    default: {
      required: false,
      default: null,
    },
    translateValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
      default: undefined
    },
    suffix: {
      type: String,
      required: false,
      default: undefined
    },
    selectedValue: {
      required: false,
      default: undefined
    },
    translateFunction: {
      required: false,
      default: undefined
    },
    placeHolder:{
      required: false,
      type : String
    },
    noSelectionAllowed:{
      required:false,
      type:Boolean,
      default : true
    }
  },
  data() {
    return {
      open: false,
      internValue : undefined
    };
  },
  computed: {
    selected() {
      return this.selectedValue ? this.selectedValue : this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0]
              : null
    }
  },
  watch: {
    selectedValue: {
      immediate: true,
      handler(newVal, oldVal) {
        this.internValue = newVal;
      }
    }
  },
  methods: {
    onMouseDown(event){
      if(isDefined(event) && isDefined(this.options) && this.options.length === 1){
        event.preventDefault();
      }
    },
    getDisplayValue(value) {
      if (this.translateFunction) {
        return this.translateFunction(value);
      }
      if (this.translateValues) {
        return (this.prefix ? this.prefix : '') + ' ' + this.$t(value) + ' ' + (this.suffix ? this.suffix : '');
      } else {
        return (this.prefix ? this.prefix : '') + ' ' + value + ' ' + (this.suffix ? this.suffix : '');
      }
    }
  }
}
</script>
<style lang="less">


.mbf-select-wrapper{
  .mbf-select {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: #333333;
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    min-height: 30px;
    font-weight: 700;
    outline: 0;
  }

}


</style>
