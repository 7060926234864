<template>
  <div class="missed-video-call-notification">
    <span class="missed-video-call-notification-header">
      {{ notificationCreatedTime }}: {{ translate('missed-call-notification-header') }}
    </span>
    <div class="missed-video-call-notification-participant"
         v-on:click="openProfileDialog(userData)">
      <span> {{ userData.fullName }}</span>
    </div>

  </div>
</template>

<script>
import {notificationComponentMixin} from "@/mixins/notification-component-mixin";

export default {
  name: "MissedVideoCallNotification",
  mixins: [notificationComponentMixin],
  props: {
    userData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="less">

.missed-video-call-notification {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  &-header {
    max-width: 90%;
    text-align: left;
  }

  &-participant {
    background-color: var(--secondary-color);
    padding: 6px;
    cursor: pointer;
    margin: 5px 0;
    display: flex;
    justify-content: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #FFFFFF;
      font-size: 16px;
      letter-spacing: 0.24px;
      line-height: 24px;
    }

  }
}

</style>