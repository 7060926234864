import backend from "@/mm-backend";
import {logger} from "@/main";

export class ThemesStorage {

    _themes;

    constructor() {
    }

    loadThemes() {
        return new Promise((resolve, reject) => {
            backend.getThemes().then(resp => {
                logger.log("Themes loaded")
                this._themes = resp.data
                resolve(this._themes);
            }, error => {
                reject(error);
            });
        });
    }


    get themes() {
        return this._themes;
    }
}
