<template>
  <div class="custom-select" ref="customSelect" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open;setOpeningDirection()">
      <span class="custom-select-text">{{getDisplayValue(selected) }}</span>
      <i :class=" !open ? 'fal fa-chevron-down fa-lg' : 'fal fa-chevron-up fa-lg'"></i>
    </div>
    <div class="items" ref="items" :class="{ selectHide: !open , upwards : upwards }">
      <div v-for="(option, i) of options"
          :key="i"
          @click="open = false; $emit('input', optionSelector ?  option[optionSelector] : option) ; $emit('update:selectedValue',optionSelector ?  option[optionSelector] : option)">
       <span class="custom-select-text"> {{ getDisplayValue(option) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    optionSelector:{
      type: String,
      required: false
    },
    default: {
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
     translateValues:{
       type : Boolean,
       required : false,
       default : false,
     },
    prefix:{
      type: String,
      required : false,
      default : undefined
    },
    suffix:{
      type: String,
      required : false,
      default : undefined
    },
    selectedValue:{
      required : false,
      default : undefined
    },
    translateFunction:{
      required: false,
      default : undefined
    }
  },
  data() {
    return {
      open: false,
      upwards : false
    };
  },
  computed:{
    selected(){
      return this.selectedValue ? this.selectedValue : this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0]
              : null
    }
  },
  methods:{
    setOpeningDirection(){
      const select = this.$refs.customSelect;
      const rect = select.getBoundingClientRect();
      // const top = rect.top + window.scrollX;
      const bottom =  rect.bottom + window.scrollY;

      this.upwards = window.outerHeight < bottom;
    },
    getDisplayValue(value){
      if(this.translateFunction){
        return this.translateFunction(value);
      }
      if(this.translateValues){
        return (this.prefix ? this.prefix : '') + ' ' + this.$t(value) + ' '+ (this.suffix ? this.suffix : '');
      }else{
        return(this.prefix ? this.prefix : '') + ' ' + value + ' '+ (this.suffix ? this.suffix : '');
      }
    }
  }
};
</script>
