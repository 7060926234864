<template>
  <div class="checkbox-wrapper" v-on:click="onClick">
    <input type="checkbox" :required="required" v-model="value">
    <div class="checkbox">
      <i id="checkbox-icon" class="fas fa-check" v-bind:class="value ? 'enabled' :'disabled'"></i>
    </div>

    <slot>
      <div class="checkbox-label" v-html="checkboxText"/>
    </slot>

  </div>
</template>

<script>
import {isDefined} from "@/utils";

export default {
  name: "CustomCheckBox",
  props: {
    value: {
      required: true
    },
    checkboxText: {
      type: String,
      required: false
    },
    required :{
      required : false,
      type : Boolean
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
       if(!isDefined(newVal)){
         this.$emit('update:value', false);
       }
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('change',!this.value);
      this.$emit('update:value', !this.value);
    }
  }
}
</script>
