import {User} from "@/entities/User";
import {isDefined} from "@/utils";
import {logger} from "@/main";

const key_prefix = process.env.VUE_APP_LOCAL_STORAGE_PREFIX

const device_id_key = `${key_prefix}.device_id`;
const user_id_key = `${key_prefix}.user_id`;
const push_settings_key = `${key_prefix}.pushsettings`;
const push_token_key = `${key_prefix}.pushtoken`;
const user_params_key = `${key_prefix}.user_params`;
const went_to_bookfair = `${key_prefix}.went_to_bookfair`;
const last_mbf_check = `${key_prefix}.last_mbf_check`;
const user_data = `${key_prefix}.user`;
const user_created_manually = `${key_prefix}.user_created_manually`;
const already_visited = `${key_prefix}.already_visited_profile`;
const match_sort_key = `${key_prefix}.match_sort_key`;
const hide_filter_info_notification = `${key_prefix}.hide_filter_info_notification`;
const selected_filter_type = `${key_prefix}.selected_filter_type`;
const selected_filter_themes_contacts = `${key_prefix}.selected_filter_themes_contacts`;
const selected_filter_themes_recommendations = `${key_prefix}.selected_filter_themes_recommendations`;
const selected_filter_themes_corporates = `${key_prefix}.selected_filter_themes_corporates`;
const active_day_for_confirmed_appointments = `${key_prefix}.active_day_for_confirmed_appointments`;
const active_day_for_received_appointments = `${key_prefix}.active_day_for_received_appointments`;
const active_day_for_outgoing_appointments = `${key_prefix}.active_day_for_outgoing_appointments`;
const active_appointment_view = `${key_prefix}.active_appointment_view`;
const active_appointment_state = `${key_prefix}.active_appointment_state`;
const show_outgoing_appointment_requests_in_calendar = `${key_prefix}.show_outgoing_appointment_requests_in_calendar`;
const show_incoming_appointment_requests_in_calendar = `${key_prefix}.show_incoming_appointment_requests_in_calendar`;
const show_confirmed_appointments_in_calendar = `${key_prefix}.show_confirmed_appointments_in_calendar`;
const show_canceled_appointments_in_calendar = `${key_prefix}.show_canceled_appointments_in_calendar`;
const registration_completed = `${key_prefix}.registration_completed`;
const user_confirmed = `${key_prefix}.user_confirmed`;
const current_hide_user_setting_recommendations = `${key_prefix}.current_hide_user_setting_recommendations`;
const current_lang = `${key_prefix}.lang`;

export default {
    setUserConfirmed(value){
        localStorage.setItem(user_confirmed,value);
    },
    setRegistrationCompleted() {
        localStorage.setItem(registration_completed, true);
    },
    setShowCanceledAppointmentsInCalendar(value) {
        localStorage.setItem(show_canceled_appointments_in_calendar, value);
    },
    setShowOutgoingAppointmentRequestsInCalender(value) {
        localStorage.setItem(show_outgoing_appointment_requests_in_calendar, value);
    },
    setShowIncomingAppointmentRequestsInCalender(value) {
        localStorage.setItem(show_incoming_appointment_requests_in_calendar, value);
    },
    setShowConfirmedAppointmentsInCalender(value) {
        localStorage.setItem(show_confirmed_appointments_in_calendar, value);
    },
    setSelectedFilterThemesForContacts(themes) {
        localStorage.setItem(selected_filter_themes_contacts, themes);
    },
    setSelectedFilterThemesForRecommendations(themes) {
        localStorage.setItem(selected_filter_themes_recommendations, themes);
    },
    setCurrentHideUserSettingForRecommendations(hideUser) {
        localStorage.setItem(current_hide_user_setting_recommendations, hideUser);
    },
    setSelectedFilterThemesForCorporates(themes){
        localStorage.setItem(selected_filter_themes_corporates,themes);
    },
    setSelectedFilterType(filterType) {
        localStorage.setItem(selected_filter_type, filterType);
    },
    setHideFilterInfoNotification() {
        localStorage.setItem(hide_filter_info_notification, true);
    },
    setMatchSort(match_sort_value) {
        localStorage.setItem(match_sort_key, match_sort_value);
    },
    setAlreadyVisited() {
        localStorage.setItem(already_visited, true);
    },
    setDeviceId(device_id) {
        localStorage.setItem(device_id_key, device_id);
    },
    setUserId(user_id) {
        localStorage.setItem(user_id_key, user_id);
    },
    setUser(user) {
        if(!isDefined(user)){
            logger.error("Stored undefined user in LocalStorage!",true);
        }
        localStorage.setItem(user_data, JSON.stringify(user));
    },
    setPushSettings(push_settings) {
        localStorage.setItem(push_settings_key, push_settings);
    },
    setPushToken(push_token) {
        localStorage.setItem(push_token_key, push_token);
    },
    setUserParams(params) {
        localStorage.setItem(user_params_key, JSON.stringify(params));
    },
    setWentToMyBookFair() {
        localStorage.setItem(went_to_bookfair, "true");
    },
    setLastMyBookFairCheck(date) {
        localStorage.setItem(last_mbf_check, date.getTime());
    },
    setActiveDayForConfirmedAppointments(day) {
        localStorage.setItem(active_day_for_confirmed_appointments, day);
    },
    setActiveDayForReceivedAppointments(day) {
        localStorage.setItem(active_day_for_received_appointments, day);
    },
    setActiveDayForOutgoingAppointments(day) {
        localStorage.setItem(active_day_for_outgoing_appointments, day);
    },
    setActiveAppointmentView(view) {
        localStorage.setItem(active_appointment_view, view);
    },
    setActiveAppointmentState(state) {
        localStorage.setItem(active_appointment_state, state);
    },

    setLang(lang){
        localStorage.setItem(current_lang, lang);
    },
    getLang(){
        return localStorage.getItem(current_lang);
    },

    // Getter
    getKeyPrefix(){
        return key_prefix + '.';
    },
    getUserConfirmed(){
      return localStorage.getItem(user_confirmed) === 'true';
    },
    getRegistrationCompleted() {
        return localStorage.getItem(registration_completed);
    },
    getSelectedFilterThemesForRecommendations() {
        return localStorage.getItem(selected_filter_themes_recommendations) ? JSON.parse(localStorage.getItem(selected_filter_themes_recommendations)) : undefined;
    },
    getSelectedFilterThemesForContacts() {
        return localStorage.getItem(selected_filter_themes_contacts) ? JSON.parse(localStorage.getItem(selected_filter_themes_contacts)) : undefined;
    },
    getSelectedFilterThemesForCorporates(){
        return localStorage.getItem(selected_filter_themes_corporates) ? JSON.parse(localStorage.getItem(selected_filter_themes_corporates)) : undefined;
    },
    getSelectedFilterType() {
        return localStorage.getItem(selected_filter_type);
    },
    getHideFilterInfoNotification() {
        return localStorage.getItem(hide_filter_info_notification);
    },
    getMatchSort() {
        return localStorage.getItem(match_sort_key);
    },
    getAlreadyVisited() {
        return localStorage.getItem(already_visited);
    },
    setUserCreatedManually(created_manually) {
        localStorage.setItem(user_created_manually, created_manually);
    },
    getDeviceId() {
        return localStorage.getItem(device_id_key);
    },
    getUserId() {
        return localStorage.getItem(user_id_key);
    },
    getUser() {
        return localStorage.getItem(user_data) ? new User(JSON.parse(localStorage.getItem(user_data))) : undefined;
    },
    getPushSettings() {
        return localStorage.getItem(push_settings_key);
    },
    getPushToken() {
        return localStorage.getItem(push_token_key);
    },
    getUserParams() {
        return localStorage.getItem(user_params_key) ? JSON.parse(localStorage.getItem(user_params_key)) : undefined;
    },
    getWentToMyBookFair() {
        return localStorage.getItem(went_to_bookfair);
    },
    getLastMyBookFairCheck() {
        return localStorage.getItem(last_mbf_check) ? new Date(localStorage.getItem(last_mbf_check)) : undefined;
    },
    getUserCreatedManually() {
        return localStorage.getItem(user_created_manually);
    },
    getActiveDayForConfirmedAppointments() {
        return localStorage.getItem(active_day_for_confirmed_appointments);
    },
    getActiveDayForReceivedAppointments() {
        return localStorage.getItem(active_day_for_received_appointments);
    },
    getActiveDayForOutgoingAppointments() {
        return localStorage.getItem(active_day_for_outgoing_appointments);
    },
    getActiveAppointmentView() {
        return localStorage.getItem(active_appointment_view);
    },
    getActiveAppointmentState() {
        return localStorage.getItem(active_appointment_state);
    },
    getShowOutgoingAppointmentRequestsInCalendar() {
        return localStorage.getItem(show_outgoing_appointment_requests_in_calendar);
    },
    getShowIncomingAppointmentRequestsInCalendar() {
        return localStorage.getItem(show_incoming_appointment_requests_in_calendar);
    },
    getShowConfirmedAppointmentsInCalender() {
        return localStorage.getItem(show_confirmed_appointments_in_calendar);
    },
    getShowCanceledAppointmentsInCalendar() {
        return localStorage.getItem(show_canceled_appointments_in_calendar);
    },
    getCurrentHideUserSettingForRecommendations() {
        return localStorage.getItem(current_hide_user_setting_recommendations) === 'true';
    },
    //Delete
    deleteUserParams() {
        localStorage.removeItem(user_params_key);
    },
    deleteWentToMyBookFair() {
        localStorage.removeItem(went_to_bookfair);
    },
    deleteLastMyBookFairCheck() {
        localStorage.removeItem(last_mbf_check);
    },

    deleteSelectedFilterThemesForContacts() {
        localStorage.removeItem(selected_filter_themes_contacts);
    },
    deleteSelectedFilterThemesForRecommendations() {
        localStorage.removeItem(selected_filter_themes_recommendations);
    },
    deleteCurrentHideUserSettingForRecommendations() {
        localStorage.removeItem(current_hide_user_setting_recommendations);
    },

    //only clears our keys.
    clear() {
        localStorage.removeItem(device_id_key);
        localStorage.removeItem(user_id_key);
        localStorage.removeItem(push_settings_key);
        localStorage.removeItem(push_token_key);
        localStorage.removeItem(user_params_key);
        localStorage.removeItem(went_to_bookfair);
        localStorage.removeItem(last_mbf_check);
        localStorage.removeItem(user_data);
        localStorage.removeItem(user_created_manually);
        localStorage.removeItem(already_visited);
        localStorage.removeItem(match_sort_key);
        localStorage.removeItem(hide_filter_info_notification);
        localStorage.removeItem(selected_filter_type);
        localStorage.removeItem(selected_filter_themes_contacts);
        localStorage.removeItem(selected_filter_themes_recommendations);
        localStorage.removeItem(selected_filter_themes_corporates);
        localStorage.removeItem(active_day_for_outgoing_appointments);
        localStorage.removeItem(active_day_for_received_appointments);
        localStorage.removeItem(active_day_for_confirmed_appointments);
        localStorage.removeItem(active_appointment_view);
        localStorage.removeItem(active_appointment_state);
        localStorage.removeItem(show_outgoing_appointment_requests_in_calendar);
        localStorage.removeItem(show_incoming_appointment_requests_in_calendar);
        localStorage.removeItem(show_confirmed_appointments_in_calendar);
        localStorage.removeItem(show_canceled_appointments_in_calendar);
        localStorage.removeItem(registration_completed);
        localStorage.removeItem(user_confirmed);
        localStorage.removeItem(current_hide_user_setting_recommendations)
    }

}
