<template>

  <div :class="step === 0 ? 'category-container-content' :''"
       :style="category.children ? 'flex-basis: 100%;padding-bottom:0px;width:100%' :''">

    <!-- Title of Root Category  -->
    <div class="category-header" v-if="step === 0">
      <div class="category-title">{{ category.title }}{{ category.required ? '*' : '' }}</div>
      <custom-check-box
          v-if="!disableSelectAllCheckbox && parent.maxSelectCount === undefined &&  (parent.showChildrenSeparately || (!parent.singleton && !parent.hasSubCategories))"
          v-bind:checkbox-text="$t('select-all')"
          v-bind:value.sync="allSelected"
          v-on:update:value="toggleAllInCategory"></custom-check-box>
    </div>

    <!-- Title of Child Category example  Continents of Countries   -->
    <hr class="line-break" v-if="category.children && step !== 0">
    <div class="category-header"
         v-if="category.children && step !== 0">
      <div v-on:click="toggleCollapsable" :class="category.collapsable && step > 0 ? 'category-header-collapsed' : ''">
        <i class="far fa-lg" :class="category[`collapsableOpened-${selectAs}`] ? 'fa-minus-square ' : 'fa-plus-square '"
           v-if="category.collapsable"></i>
        <div class="category-title">{{ category.title }} ({{ getContentCountForCategory(category) }})</div>
      </div>
      <custom-check-box v-bind:checkbox-text="$t('select-all')" v-if="!category.disableSelectAllCheckbox && !category.singleton && category.maxSelectCount === undefined"
                        v-bind:value.sync="allSelected" v-on:update:value="toggleAllInCategory"></custom-check-box>
    </div>

    <div class="theme-box" v-if="step > 0 && !category.children" v-on:click="toggleEntry()"
         :class="selectAs === 'offer' ? category.selected_offer   ? 'is-selected':'' :selectAs === 'search' ? category.selected_search  ? 'is-selected':'' : category.selected ? 'is-selected':''">
      <div :title="category.title"> {{ category.title }}</div>
    </div>


    <div class="theme-category" ref="themeCategory"
         :class="getClassForCategory()">
      <select-themes-categories v-if="category.children && category.children.length > 0" v-bind:category="child"
                                :key="child.id" v-bind:singleton="singleton" v-bind:parent="category"
                                v-on:update="onUpdate" v-bind:disable-select-all-checkbox="disableSelectAllCheckbox"
                                v-for="child of category.children" v-bind:step="step +1"
                                v-bind:collapsable-deactivated-from-parent="collapsableDeactivated"
                                v-bind:select-as="selectAs"></select-themes-categories>
    </div>
  </div>

</template>

<script>
import CustomCheckBox from "@/components/inputs/CustomCheckBox";

export default {
  name: "select-themes-categories",
  components: {CustomCheckBox},
  props: {
    category: {
      required: true,
      type: Object
    },
    step: {
      required: true,
      type: Number
    },
    selectAs: {
      required: true
    },
    singleton: {
      required: true
    },
    parent: {
      required: true
    },
    disableSelectAllCheckbox: {
      required: false
    },
    collapsableDeactivatedFromParent: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      allSelected: false,
      collapsableDeactivated: false,
    }
  },
  watch: {
    category: {
      immediate: true,
      handler(newVal, oldVal) {
        this.category.collapsable = this.parent.collapsable;
        this.category[`collapsableOpened-${this.selectAs}`] = newVal[`collapsableOpened-${this.selectAs}`] === true;
        switch (this.selectAs) {
          case 'offer':
            this.allSelected = newVal.allSelectedOffer === true;
            break;
          case 'search' :
            this.allSelected = newVal.allSelectedSearch === true;
            break;
          case 'normal' :
            this.allSelected = newVal.allSelected === true;
            break;
        }
      }
    },
    collapsableDeactivatedFromParent: {
      immediate: true,
      handler(newVal, oldVal) {
        this.collapsableDeactivated = newVal;
      }
    }
  },
  methods: {
    getClassForCategory() {
      if (this.collapsableDeactivated) {
        return 'theme-category-collapsed-deactivated';
      }
      if (this.category.collapsable && this.step > 0 && !this.category[`collapsableOpened-${this.selectAs}`]) {
        return 'theme-category-collapsed-deactivated';
      }
      if (this.category.collapsable && !this.category[`collapsableOpened-${this.selectAs}`]) {
        return 'theme-category-collapsed';
      }
      if (this.category.collapsable) {
        return 'theme-category-collapsed-active';
      }
    },
    toggleCollapsable() {
      this.$set(this.category, `collapsableOpened-${this.selectAs}`, !this.category[`collapsableOpened-${this.selectAs}`]);
      this.collapsableDeactivated = !this.category[[`collapsableOpened-${this.selectAs}`]];
      this.$forceUpdate();
    },
    onUpdate() {
      this.$emit('update', this.parent.id);
    },
    toggleAllInCategory() {
      this.$set(this.category, `collapsableOpened-${this.selectAs}`, true);
      if (!this.allSelected) {
        this.disableAllInCategory(this.category);
      } else {
        this.enableAllInCategory();
      }
      this.onUpdate();
    },
    deSelectEntry(entry) {
      switch (this.selectAs) {
        case 'offer':
          this.$set(entry, 'selected_offer', false)
          break;
        case 'search':
          this.$set(entry, 'selected_search', false)
          break;
        case 'normal' :
          this.$set(entry, 'selected', false)
          break;
      }
    },
    toggleEntry() {
      if (this.singleton) {
        this.disableAllInCategory(this.parent, this.category.id);
      }
      switch (this.selectAs) {
        case 'offer':
          this.$set(this.category, 'selected_offer', !this.category.selected_offer);
          break;
        case 'search':
          this.$set(this.category, 'selected_search', !this.category.selected_search);
          break;
        case 'normal' :
          this.$set(this.category, 'selected', !this.category.selected);
          break;
      }
      this.onUpdate();
    },
    enableAllInCategory() {
      this.enableAllSelected(this.category);
      this.enableAllInCategoryTraverse(this.category.children);
    },
    enableAllInCategoryTraverse(children) {
      for (const child of children) {
        if (child.children) {
          this.enableAllSelected(child);
          this.enableAllInCategoryTraverse(child.children);
        } else {
          switch (this.selectAs) {
            case 'offer':
              this.$set(child, 'selected_offer', true);
              break;
            case 'search':
              this.$set(child, 'selected_search', true);
              break;
            case 'normal' :
              this.$set(child, 'selected', true);
              break;
          }
        }
      }
    },
    disableAllInCategory(category, ignoredEntryId) {
      this.disableAllSelected(category);
      this.disableAllInCategoryTraverse(category.children, ignoredEntryId)
    },
    disableAllInCategoryTraverse(children, ignoredEntryId) {
      for (const child of children) {
        if (child.children) {
          this.disableAllSelected(child);
          this.disableAllInCategoryTraverse(child.children, ignoredEntryId);
        } else {
          if (child.id !== ignoredEntryId) {
            this.deSelectEntry(child);
          }
        }
      }
    },
    disableAllSelected(entity) {
      switch (this.selectAs) {
        case 'offer':
          this.$set(entity, 'allSelectedOffer', false);
          break;
        case 'search':
          this.$set(entity, 'allSelectedSearch', false);
          break;
        case 'normal' :
          this.$set(entity, 'allSelected', false);
          break;
      }
    },
    enableAllSelected(entity) {
      switch (this.selectAs) {
        case 'offer':
          this.$set(entity, 'allSelectedOffer', true);
          break;
        case 'search':
          this.$set(entity, 'allSelectedSearch', true);
          break;
        case 'normal' :
          this.$set(entity, 'allSelected', true);
          break;
      }
    },
    getContentCountForCategory() {
      let count = 0;
      return this.getContentCountTraverse(this.category.children, count)
    },
    getContentCountTraverse(children, count) {
      for (const entry of children) {
        if (entry.children) {
          count = this.getContentCountTraverse(entry.children, count);
        } else {
          switch (this.selectAs) {
            case 'offer':
              if (entry.selected_offer) {
                count++;
              }
              break;
            case 'search':
              if (entry.selected_search) {
                count++;
              }
              break;
            case 'normal' :
              if (entry.selected) {
                count++;
              }
              break;
          }
        }
      }
      return count;
    }
  }
}
</script>
