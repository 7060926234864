import backend from "@/mm-backend";
import moment from "moment";
import {appointmentsStorage, eventBus} from "@/main";
import localStorageHelper from "@/localstorage/local-storage";
import {isDefined} from "@/utils";

export let appointmentsMixin = {
    data() {
        return {
            appointments: [],
            enableRoomAppointments: process.env.VUE_APP_ENABLE_ROOM_APPOINTMENTS === 'true',
            appointmentToEdit: undefined,
            newAppointment: undefined,
            appointmentDurationsInMillis: [], //computed
            appointmentDurations: [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240],
            defaultAppointmentDuration: 30,
            userData: undefined,
            myUserId: localStorageHelper.getUserId(),
            calendarProperties: {
                days: [],
                calendarTimeFrom: undefined,
                calendarTimeTo: undefined,
                minDate: undefined,
                maxDate: undefined,
                selectedDay: undefined,
                newAppointment: undefined,
                appointments: [],
                minEventDuration: 15,
                snapToTime: 15,
                openHours: {
                    start: undefined,
                    end: undefined
                }
            }
        }
    },
    computed: {
        relevantAppointment() {
            if (isDefined(this.appointmentToEdit)) {
                return this.appointmentToEdit;
            } else {
                return this.newAppointment;
            }
        },
        displayWithDay() {
            const rooms = appointmentsStorage.getRooms();
            if (rooms.length === 1) {
                const room = rooms[0];
                if (room.open_hours.length > 1) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        computeCalendarProperties(appointmentForEdit) {
            const rooms = appointmentsStorage.getRooms();
            if (!this.enableRoomAppointments && isDefined(rooms) && rooms.length > 0) {
                const openHours = rooms[0].open_hours;
                const today = moment().utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                let startDateForEvent = moment.utc(openHours[0].from).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                let endDateForEvent = moment.utc(openHours[0].to).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                let minTime = (startDateForEvent.hours() * 60) + startDateForEvent.minutes();
                let maxTime = (endDateForEvent.hours() * 60) + endDateForEvent.minutes();
                let selectedDay = startDateForEvent;
                for (let openHour of openHours) {
                    const from = moment.utc(openHour.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE);

                    const to = moment.utc(openHour.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                    let minT = (from.hours() * 60) + from.minutes();
                    const maxT = (to.hours() * 60) + to.minutes();
                    if (today.isSameOrAfter(from, 'day')) {
                        startDateForEvent = from;
                    }
                    if (to.isAfter(endDateForEvent)) {
                        endDateForEvent = to;
                    }
                    if (minT < minTime) {
                        minTime = minT;
                    }
                    if (today.isSameOrAfter(from, 'day')) {
                        const now = moment().utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                        const minutes = (now.hours() * 60) + now.minutes();
                        if (minT < minutes) {
                            minT = Math.ceil(minutes / 15) * 15;
                        }
                    }
                    if (maxT > maxTime) {
                        maxTime = maxT;
                    }
                    this.calendarProperties.days.push({
                        date: from.format('YYYY-MM-DD'),
                        minTime: minT,
                        maxTime: maxT
                    });
                    if (today.isSame(from, 'day')) {
                        selectedDay = today
                    }
                }
                this.calendarProperties.appointments = this.getCalendarEventsFromAppointments(appointmentForEdit);

                this.calendarProperties.calendarTimeFrom = minTime;
                this.calendarProperties.calendarTimeTo = maxTime;
                this.calendarProperties.minDate = startDateForEvent.format('YYYY-MM-DD');
                this.calendarProperties.maxDate = endDateForEvent.format('YYYY-MM-DD');
                this.calendarProperties.selectedDay = selectedDay.format('YYYY-MM-DD');
            }
        },
        getCalendarEventsFromAppointments(appointmentForEdit) {
            const events = [];
            if (isDefined(appointmentsStorage.getAppointments()) && appointmentsStorage.getAppointments().length > 0) {
                for (const appointment of appointmentsStorage.getAppointments()) {
                    if (appointment.isCanceled()) {
                        continue;
                    }
                    if (isDefined(appointmentForEdit) && +appointment.id === +appointmentForEdit.id) {
                        continue
                    }
                    let cssClassForEvent;
                    if (appointment.isCanceled()) {
                        cssClassForEvent = 'canceled-appointment'
                    } else if (appointment.isConfirmed()) {
                        cssClassForEvent = 'appointment-event'
                    } else if (appointment.isRequest()) {
                        cssClassForEvent = 'appointment-request';
                    } else if (appointment.isBlocked()) {
                        cssClassForEvent = 'blocked-appointment'
                    }

                    const event = {
                        class: cssClassForEvent,
                        title: appointment.name,
                        is_confirmed: appointment.isConfirmed(),
                        is_request: appointment.isRequest(),
                        is_canceled: appointment.isCanceled(),
                        is_outgoing: appointment.isOutGoingRequest(this.myUserId),
                        is_blocked: appointment.isBlocked(),
                        content: appointment.description,
                        start: this.formatTime(appointment.from, 'YYYY-MM-DD HH:mm'),
                        end: this.formatTime(appointment.to, 'YYYY-MM-DD HH:mm'),
                        appointment: appointment,
                        resizable: false,
                        draggable: false
                    }
                    events.push(event);
                }
            }
            return events;
        },
        onAppointmentSuggestionDialogSubmit(appointment, forEdit) {
            if (isDefined(appointment.timeSlot)) {
                //Set new Appointment Dates based on selected Timeslot. Duration doesn't change.
                const newAppointmentStart = moment.utc(appointment.timeSlot.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                appointment.date = newAppointmentStart.format('YYYY-MM-DD')
                appointment.dateString = newAppointmentStart.format('YYYY-MM-DD');
                appointment.time = (newAppointmentStart.hours() * 60 * 60 * 1000) + (newAppointmentStart.minutes() * 60 * 1000);
                appointment.timeString = newAppointmentStart.format('HH:mm');
                this.$refs.appointmentSuggestionDialog.closeDialog();
                if (!forEdit) {
                    this.requestAppointment(true);
                } else {
                    this.sendChangeAppointmentRequest(true);
                }
                this.$forceUpdate();
            }
        },
        closeAppointmentSuggestionDialog() {
            if (isDefined(this.$refs.appointmentSuggestionDialog)) {
                this.$refs.appointmentSuggestionDialog.closeDialog();
            }
        },
        forceAppointmentRequest(forEdit) {
            if (isDefined(this.$refs.appointmentSuggestionDialog)) {
                this.$refs.appointmentSuggestionDialog.closeDialog();
                if (!forEdit) {
                    this.requestAppointment(true);
                } else {
                    this.sendChangeAppointmentRequest(true);
                }
            }
        },
        getDisplayNameForAppointment(appointment) {
            if (isDefined(appointment)) {
                const appointmentStart = moment.tz(appointment.date, 'YYYY-MM-DD', process.env.VUE_APP_GROUPS_TIMEZONE).add(appointment.time, 'milliseconds');
                const appointmentEnd = moment(appointmentStart).add(appointment.duration, 'milliseconds');
                return `${appointment.dateString} ${appointment.timeString} - ${appointmentEnd.format('HH:mm')} `;
            }
        },
        downloadCalendarForAppointment(appointment) {
            const self = this;
            backend.getCalendarForAppointment(appointment.id).then(resp => {
                const blob = new Blob([resp.data], {type: 'text/calendar'});
                const icsFile = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = icsFile;
                a.target = '_blank';
                a.download = self.$t('appointment');
                a.type = 'text/calendar';
                a.click();
            })
        },
        timezone(date) {
            return moment(date).tz(process.env.VUE_APP_GROUPS_TIMEZONE).zoneAbbr();
        },
        getLocalTimeZone() {
            return moment().tz(process.env.VUE_APP_GROUPS_TIMEZONE).zoneAbbr();
        },
        confirmAppointment(appointment) {
            const self = this;
            self.$refs.confirmAppointmentDialog.showSpinner = true;
            backend.confirmAppointment(appointment.requesting_user, appointment.id).then(resp => {
                self.$refs.confirmAppointmentDialog.showSpinner = false;
                if (resp.data.error) {
                    self.$toasted.global.error_notification({message: resp.data.error.message});
                } else {
                    self.$toasted.global.success_notification({message: self.$t('appointment-confirmed')});
                    self.getAllAppointments();
                    eventBus.$emit('on-appointment-request-handled', appointment);
                }
            }, error => {
                self.$toasted.global.error_notification({message: self.$t('confirm-appointment-error')});
                self.$refs.confirmAppointmentDialog.showSpinner = false;
            });
        },
        rejectAppointment(appointment) {
            const self = this;
            self.$refs.rejectAppointmentDialog.showSpinner = true;
            backend.rejectAppointment(appointment.id).then(resp => {
                self.$refs.rejectAppointmentDialog.showSpinner = false;
                if (resp.data.error) {
                    self.$toasted.global.error_notification({message: resp.data.error.message});
                } else {
                    self.$toasted.global.success_notification({message: self.$t('appointment-rejected')});
                    eventBus.$emit('on-appointment-request-handled', appointment);
                    self.getAllAppointments();
                }
            }, error => {
                self.$toasted.global.error_notification({message: self.$t('reject-appointment-error')});
                self.$refs.rejectAppointmentDialog.showSpinner = false;
            });
        },
        cancelAppointment(appointment) {
            const self = this;
            self.$refs.cancelAppointmentDialog.showSpinner = true;
            backend.cancelAppointment(appointment.id).then(resp => {
                self.$refs.cancelAppointmentDialog.showSpinner = false;
                if (resp.data.error) {
                    self.$toasted.global.error_notification({message: resp.data.error.message});
                } else {
                    self.$toasted.global.success_notification({message: self.$t('appointment-canceled')});
                    eventBus.$emit('on-appointment-canceled', appointment);
                    self.getAllAppointments();
                }
            }, error => {
                self.$toasted.global.error_notification({message: self.$t('cancel-appointment-error')});
                self.$refs.cancelAppointmentDialog.showSpinner = false;
            });
        },
        unblockAppointment(appointment) {
            const self = this;
            self.$refs.unblockAppointmentDialog.showSpinner = true;
            backend.cancelAppointment(appointment.id).then(resp => {
                self.$refs.unblockAppointmentDialog.showSpinner = false;
                if (resp.data.error) {
                    self.$toasted.global.error_notification({message: resp.data.error.message});
                } else {
                    self.$toasted.global.success_notification({message: self.$t('appointment-unblocked')});
                    self.getAllAppointments();
                }
            }, error => {
                self.$toasted.global.error_notification({message: self.$t('cancel-appointment-error')});
                self.$refs.cancelAppointmentDialog.showSpinner = false;
            });
        },
        formatTime(dateAsString, format) {
            return moment.utc(dateAsString).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format(format);
        },
        openUnblockAppointmentDialog(appointment) {
            this.$refs.unblockAppointmentDialog.openDialog(appointment);
        },
        openCancelAppointmentDialog(appointment) {
            this.$refs.cancelAppointmentDialog.openDialog(appointment);
        },
        openRejectAppointmentDialog(appointment) {
            this.$refs.rejectAppointmentDialog.openDialog(appointment);
        },
        openConfirmAppointmentDialog(appointment) {
            this.$refs.confirmAppointmentDialog.openDialog(appointment);
        },
        computeAppointmentDurations() {
            const durationsInMillis = []
            for (let duration of this.appointmentDurations) {
                durationsInMillis.push(duration * 60 * 1000)
                this.appointmentDurationsInMillis = durationsInMillis;
            }
        },
        openEditAppointmentDialog(appointment) {
            if (isDefined(this.$refs.detailedCalendarEventDialog)) {
                this.$refs.detailedCalendarEventDialog.closeDialog();
            }
            this.computeAppointmentDurations();
            const start = moment.utc(appointment.from, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
            const end = moment.utc(appointment.to, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
            appointment.duration = moment.duration(end.diff(start)).asMilliseconds();
            appointment.date = start.format('YYYY-MM-DD');
            appointment.time = (start.hours() * 60 * 60 * 1000) + (start.minutes() * 60 * 1000);
            appointment.dateString = start.format('YYYY-MM-DD');
            appointment.timeString = start.format('HH:mm')
            appointment.room = appointment.custom_room;
            this.appointmentToEdit = appointment;

            if (isDefined(this.$refs.editAppointmentWithoutTablesDialog)) {
                this.$refs.editAppointmentWithoutTablesDialog.openDialog(appointment);
            }
        },
        sendChangeAppointmentRequest(force) {
            const self = this;
            const copy = {
                id: this.appointmentToEdit.id,
                name: this.appointmentToEdit.name,
                description: this.appointmentToEdit.description,
                room: this.appointmentToEdit.room,
                date: this.appointmentToEdit.date,
                duration: this.appointmentToEdit.duration,
                time: this.appointmentToEdit.time
            };


            if (!isDefined(this.appointmentToEdit.name) || this.appointmentToEdit.name.trim().length === 0) {
                this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('title')})});
                return;
            }

            if (!isDefined(this.appointmentToEdit.description) || this.appointmentToEdit.description.trim().length === 0) {
                this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('description')})});
                return;
            }

            if (isDefined(copy.room_id)) {
                const timeSlot = copy.timeSlot;
                if (!isDefined(timeSlot)) {
                    return;
                }
                copy.date = timeSlot.from;
                if (!isDefined(copy.duration) || copy.duration === 0) {
                    const from = moment(timeSlot.from, 'YYYY-MM-DD HH:mm:ss');
                    const to = moment(timeSlot.to, 'YYYY-MM-DD HH:mm:ss');
                    copy.duration = to.diff(from);
                } else {
                    copy.duration = copy.duration * 60 * 1000;
                }
            } else {
                delete copy.room_id;
                if (!isDefined(this.appointmentToEdit.room) || this.appointmentToEdit.room.trim().length === 0) {
                    this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('place')})});
                    return;
                }
                if (!this.validateAppointmentTimes(copy)) {
                    return;
                }
                copy.date = moment.tz(this.appointmentToEdit.date, 'YYYY-MM-DD', process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(this.appointmentToEdit.time, 'milliseconds').utc().format('YYYY-MM-DD HH:mm');
            }

            delete copy.time;
            delete copy.open_hour;
            delete copy.timeSlots;
            delete copy.timeSlot;
            delete copy.timeString;
            delete copy.dateString;

            if (isDefined(force)) {
                copy.force = true
            }

            this.$refs.editAppointmentWithoutTablesDialog.showSpinner = true;
            backend.changeAppointment(copy).then(resp => {
                self.$refs.editAppointmentWithoutTablesDialog.showSpinner = false;
                if (resp.data.error) {
                    self.$toasted.global.error_notification({message: resp.data.error.message});
                } else {
                    if (isDefined(resp.data.suggestions)) {
                        self.appointmentToEdit.timeSlot = resp.data.suggestions[0];
                        self.appointmentToEdit.timeSlots = resp.data.suggestions;
                        if (isDefined(this.$refs.appointmentSuggestionDialog)) {
                            self.$refs.appointmentSuggestionDialog.openDialog();
                            self.$forceUpdate();
                        }
                    } else {
                        self.$refs.editAppointmentWithoutTablesDialog.closeDialog();
                        self.postLoadAppointments(resp.data);
                        self.$toasted.global.success_notification({message: self.$t('appointment-request-send')});
                        self.$forceUpdate();
                        eventBus.$emit('reload-notifications',resp.data.appointments)
                    }
                }
            }, error => {
                self.$refs.editAppointmentWithoutTablesDialog.showSpinner = false;
                self.$toasted.global.error_notification({message: self.$t('appointment-request-send-error')});
            });
        },
        postLoadAppointments(data) {

        },
        getAppointmentsForUser() {

        },
        validateAppointmentTimes(appointment) {
            const rooms = appointmentsStorage.getRooms();
            if (isDefined(rooms) && rooms.length > 0) {

                const appointmentStart = moment.tz(appointment.date, 'YYYY-MM-DD', process.env.VUE_APP_GROUPS_TIMEZONE).add(appointment.time, 'milliseconds');
                const appointmentEnd = moment(appointmentStart).add(appointment.duration, 'milliseconds');
                const openHours = rooms[0].open_hours;
                const openHourForDay = openHours.find(openHour =>
                    moment.utc(openHour.from, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('YYYY-MM-DD') === appointmentStart.format('YYYY-MM-DD'));

                if (!isDefined(openHourForDay)) {
                    this.$toasted.global.error_notification({message: this.$t('selection-error-appointment-no-open-hour-available')});
                    return false;
                }
                const startOfDay = moment.utc(openHourForDay.from, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
                const endOfDay = moment.utc(openHourForDay.to, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE);

                if (appointmentStart.isBefore(startOfDay) || appointmentEnd.isAfter(endOfDay)) {
                    this.$toasted.global.error_notification({
                        message: this.$t('selection-error-appointment-outside-open-hours',
                            {openHours: `${startOfDay.format('HH:mm')} - ${endOfDay.format('HH:mm')}`})
                    });
                    return false;
                }
            }
            return true;
        },
        submitSendChangeAppointmentForm() {
            document.getElementById('submitEditAppointment').click();
        },
        closeEditAppointmentDialog() {
            this.appointmentToEdit = undefined;
            this.$refs.editAppointmentWithoutTablesDialog.closeDialog();
        },
        showCalendarDialog(appointment, forEdit) {
            if (isDefined(this.$refs.appointmentCalendarDialog)) {
                this.createNewAppointmentCalendarEvent(appointment);
                if (forEdit) {
                    this.computeCalendarProperties(appointment);
                }
                this.disableCloseButton = true;
                this.$refs.appointmentCalendarDialog.openDialog();
            }
        },
        onCalendarDialogSubmit() {
            const newAppointment = this.calendarProperties.newAppointment;
            const start = moment(newAppointment.start, 'YYYY-MM-DD HH:mm');
            const end = moment(newAppointment.end, 'YYYY-MM-DD HH:mm');
            const appointment = this.relevantAppointment;
            appointment.duration = moment.duration(end.diff(start)).asMilliseconds();
            appointment.date = start.format('YYYY-MM-DD');
            appointment.time = (start.hours() * 60 * 60 * 1000) + (start.minutes() * 60 * 1000);
            appointment.dateString = start.format('YYYY-MM-DD');
            appointment.timeString = start.format('HH:mm');
            this.$forceUpdate();
            this.closeAppointmentCalendarDialog();
        },
        getDisplayNameForTimeSlot(timeSlot, withDate, withDay) {
            if (isDefined(timeSlot)) {

                const from = moment.utc(timeSlot.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE)
                const formattedFrom = from.format((withDay ? 'ddd ' : '') + 'HH:mm');
                const formattedTo = moment.utc(timeSlot.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('HH:mm');
                if (withDate === true) {
                    return ` ${from.format('YYYY-MM-DD')} ${formattedFrom}  - ${formattedTo}  `;
                } else {
                    return `${formattedFrom}  - ${formattedTo}  `;
                }
            }
            return '';
        },
        getDisplayNameForOpenHour(openHour) {
            if (isDefined(openHour)) {
                const formattedFrom = moment.utc(openHour.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("YYYY-MM-DD HH:mm");
                const formattedTo = moment.utc(openHour.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("YYYY-MM-DD HH:mm");
                const fromWithoutTime = moment.utc(openHour.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("YYYY-MM-DD");
                const toWithoutTime = moment.utc(openHour.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("YYYY-MM-DD");
                const localTimeZone = moment().tz(process.env.VUE_APP_GROUPS_TIMEZONE).zoneAbbr();

                if (moment(fromWithoutTime).isSame(toWithoutTime)) {
                    const toOnlyTime = moment.utc(openHour.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("HH:mm");
                    return `${formattedFrom} - ${toOnlyTime} ${localTimeZone} `;
                }

                return `${formattedFrom} ${localTimeZone}  - ${formattedTo} ${localTimeZone} `;
            }
            return '';
        },
        getDisplayNameForDuration(duration) {
            if (isDefined(duration)) {
                return `${duration} min`;
            }
            return '';
        },
        getDisplayNameForRoom(room) {
            if (isDefined(room)) {
                return room.getDisplayNameForRoom();
            }
            return '';
        },
        openHoursForRoom(roomId) {
            const room = appointmentsStorage.getRooms().find(r => r.id === roomId);
            if (isDefined(room)) {
                return room.open_hours;
            }
            return [];
        },
        createNewAppointmentCalendarEvent(appointment) {
            const startDate = moment(appointment.date, 'YYYY-MM-DD').startOf('day').add(appointment.time, 'milliseconds');
            const endDate = moment(startDate).add(appointment.duration, 'milliseconds')

            this.calendarProperties.newAppointment = {
                class: 'new-appointment-event',
                start: startDate.format('YYYY-MM-DD HH:mm'),
                end: endDate.format('YYYY-MM-DD HH:mm')
            }
        },
        closeAppointmentCalendarDialog() {
            if (isDefined(this.$refs.appointmentCalendarDialog)) {
                this.$refs.appointmentCalendarDialog.closeDialog();
            }
        },
        showCellHeader(cell) {
            //Calendar Cell Header value "Keine Termine" is only shown for dates inside open hours and no past dates.
            const propForDay = this.calendarProperties.days.find(d => d.date === cell.startDate.format('YYYY-MM-DD'));
            if (!isDefined(propForDay)) {
                return false;
            }
            const today = moment().utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
            const day = moment(propForDay.date, 'YYYY-MM-DD').utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
            return !day.isBefore(today, 'day');
        },
        onEventDelete(event) {
            //Workaround for Calendar bug that deletes events sometimes on drag & drop
            this.calendarProperties.newAppointment = event
        },
        onEventDrop(vueCalEvent) {
            const event = vueCalEvent.event;
            this.validateAppointmentEvent(event);
        },
        onEventDurationChange(vueCalEvent) {
            const event = vueCalEvent.event;
            this.validateAppointmentEvent(event);
        },
        onCalendarCellClicked(jsDate) {
            if (!(jsDate instanceof Date)) {
                //Cell header values like Day Names are also clickable.
                //In this case null is returned instead of a date.
                return;
            }
            const momentDate = moment(jsDate);
            const event = this.calendarProperties.newAppointment;
            const start = moment(event.start, 'YYYY-MM-DD HH:mm');
            const end = moment(event.end, 'YYYY-MM-DD HH:mm');
            const duration = moment.duration(end.diff(start)).asMilliseconds();
            const roundedUp = Math.floor(momentDate.minute() / 15) * 15;
            event.start = momentDate.minute(roundedUp).toDate();
            event.end = momentDate.add(duration, 'milliseconds').toDate();
            event.startTimeMinutes = (event.start.getHours() * 60) + event.start.getMinutes();
            event.endTimeMinutes = (event.end.getHours() * 60) + event.end.getMinutes();
            this.validateAppointmentEvent(event);
        },
        validateAppointmentEvent(event) {
            const end = moment(event.end);
            const propsForDay = this.calendarProperties.days.find(d => d.date === end.format('YYYY-MM-DD'));
            if (event.startTimeMinutes < propsForDay.minTime) {
                //When Event Start Time is before open hours for that day.
                const duration = event.endTimeMinutes - event.startTimeMinutes;
                event.start = moment(event.start).startOf('day').add(propsForDay.minTime, 'minutes').toDate();
                event.end = event.start.addMinutes(duration);
            }
            if (event.endTimeMinutes === event.startTimeMinutes) {
                //When Event Start Time is equal to end time add min duration.
                event.end = event.end.addMinutes(this.calendarProperties.minEventDuration);
            }
            if (event.endTimeMinutes > propsForDay.maxTime) {
                //When Event End Time is after open hours for that day.
                //Set Event End to max Time for that day.
                event.end = moment(event.end).startOf('day').add(propsForDay.maxTime, 'minutes').toDate();
            }
            if (event.startTimeMinutes >= propsForDay.maxTime) {
                //When Event Start Time is after open hours for that day.
                //Set Event Start to max Time for that day minus the current appointment duration.
                const duration = event.endTimeMinutes - event.startTimeMinutes;
                event.start = moment(event.start).startOf('day').add(propsForDay.maxTime - duration, 'minutes').toDate();
            }
            if (moment(event.end).diff(moment(event.start), 'hours') >= 4) {
                //When Event Duration exceeds 4 hours set it to 4 hours.
                event.end = moment(event.start).add(4, 'hours').toDate();
            }
            this.calendarProperties.newAppointment = event;
        },
        translateAppointmentDuration(duration) {
            if (isDefined(duration)) {
                //milliseconds to minutes
                duration = duration / 60000;
                if (duration >= 60) {
                    const hours = Math.floor(duration / 60);
                    const minutes = duration % 60;
                    const hourText = hours > 1 ? this.$t('hours') : this.$t('hour')
                    if (minutes === 0) {
                        return `${hours} ${hourText}`;
                    }
                    return `${hours} ${hourText} ${minutes} ${this.$t('minutes')}`;
                }
                return `${duration} ${this.$t('minutes')}`;
            }
            return '';
        },
        getRoomStringForAppointment(appointment) {
            return `${appointment.getRoomName()}${appointment.getTableName() ? ', ' + this.$t('table').trim() + ' ' + appointment.getTableName() : ''}`
        },
        joinOnlineAppointment(appointment) {
            const me = localStorageHelper.getUser();
            let userData;
            if (+me.id === +appointment.requesting_user) {
                userData = appointmentsStorage.getUserById(appointment.participants[0].user_id);
            } else {
                userData = appointmentsStorage.getUserById(appointment.requesting_user);
            }
            eventBus.$emit('remove-notification', appointment);
            eventBus.$emit('open-video-chat', userData, true);
        },
        timeSlotClicked(timeSlot, forEdit) {
            if (forEdit) {
                this.appointmentToEdit.timeSlot = timeSlot;
            } else {
                this.newAppointment.timeSlot = timeSlot;
            }
            this.$forceUpdate()
        },
    }
}
