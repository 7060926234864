<template>
  <div class="main companies">
    <h3 class="side-header">{{ $t('recommendations-header') }}</h3>

    <div class="search-and-sort-input-wrapper">
      <div class="search-input">
        <input v-model="searchValue" v-on:input="search" type="text" :placeholder="$t('search')">
        <i class="fal fa-search fa-lg"></i>
        <div v-if="searchValue && searchValue.length > 0 && filterEnabled" class="search-warning"><i
            class="fal fa-exclamation-triangle"></i> {{ $t('filter-active') }}
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="filter-container">
        <themes-filter :apply-filter="applyFilter" ref="themeFilter" v-bind:disable-switch-type="true"
                                 :reset-filter="resetFilter" :is-loading="isLoading" :type="'corporates'"/>
      </div>
      <div class="companieslist vld-parent">

        <loading :active.sync="isLoading"
                 :is-full-page="false"
                 color="var(--primary-color)">
        </loading>


        <div class="empty-recommendations-header"
             v-if="limitedRecommendations.length === 0 && limitedCompanies.length === 0 && !isLoading">
          <h3> {{
              filterEnabled ? $t('empty-filter-results') : searchValue ? $t("empty-search-results") :
                  $t("no-recommendations-available")
            }}</h3>
        </div>


        <div v-if="limitedRecommendations.length > 0" class="recommendations-header">
          <h3>{{ recommendationsTitle }} </h3>
        </div>
        <transition-group name="list" tag="div"
                          v-bind:css="false"
                          v-on:before-enter="animationBeforeEnter"
                          v-on:enter="animationEnter"
                          v-on:leave="animationLeave">
          <companies-card v-for="(company,index) in limitedRecommendations" :company="company" :key="company.id" v-bind:data-index="index"
                          :hide-recommendation="hideRecommendation" :is-recommendation="true"></companies-card>
        </transition-group>

        <div v-if="limitedCompanies.length > 0"
             class="networker-header">
          <h3>
            <div v-if="limitedRecommendations.length > 0"> {{ networkersTitle }}</div>
          </h3>
        </div>

        <transition-group name="list" tag="div"
                          v-bind:css="false"
                          v-on:before-enter="animationBeforeEnter"
                          v-on:enter="animationEnter"
                          v-on:leave="animationLeave">
          <companies-card v-for="(company,index) in limitedCompanies" :company="company" :key="company.id" v-bind:data-index="index"
                          :is-recommendation="false"></companies-card>
        </transition-group>

        <div class="paginator-wrapper">
          <div class="paginator"
               v-if="!isLoading && (this.limitedCompanies.length !== 0 || this.limitedRecommendations.length !== 0) ">

            <custom-select
                :suffix="$t('entries-per-page')"
                :options="entriesPerPageOptions"
                :default="entriesPerPage"
                v-on:input="entriesPerPage=$event; get()">
            </custom-select>
            <div class="page-count">
              <span>{{ $t('page') }} {{ currentPage }} {{ $t('of') }}  {{ maxPages }}</span>
              <div class="arrows">
                <i v-on:click="showPreviousPage" class="fal fa-chevron-left fa-2x left-arrow "></i>
                <i v-on:click="showNextPage" class="fal fa-chevron-right fa-2x right-arrow"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <custom-dialog :id="'confirm-exclude-dialog'" ref="confirmExcludeDialog" :on-submit="excludeRecommendation"
                   :submit-button-text="$t('dialog-ok')">
      <span class="header">  {{ $t('hide-recommendation') }} </span>
      <div> {{ $t('hide-recommendation-text-corporates') }} </div>
    </custom-dialog>
  </div>
</template>

<script>
import backend from "@/mm-backend";
import MatchScale from "@/components/MatchScale";
import {companiesMixIn} from "@/mixins/companies-mixin";
import ThemesFilter from "@/themes/filter/themes-filter";

export default {
  mixins: [companiesMixIn],
  name: "Corporates",
  components: {
    ThemesFilter,
    MatchScale
  },
  computed: {
    limitedRecommendations() {
      return this.recommendations.slice((this.currentPage - 1) * this.entriesPerPage, this.currentPage * this.entriesPerPage);
    },
    limitedCompanies() {
      let startIndex = (this.currentPage - 1) * this.entriesPerPage;
      // We only show recommendations on the first page
      if (this.currentPage > 1) {
        startIndex = startIndex - this.recommendations.length;
      }
      let maxIndex = (this.currentPage * this.entriesPerPage) - this.recommendations.length;

      return this.companies.slice(startIndex, maxIndex);
    }
  },
  mounted() {
   this.get();
  },
  methods: {
    beforeGet() {
      this.getObservable = backend.getCompanies.bind(backend);
      this.params = {
        type: 'corporates',
        pageSize: this.maxPagesToDisplay * this.entriesPerPage,
        page: this.currentPageForBackend,
      }
    },
    afterGet() {
      // TODO
      //  matomo.trackContactList();
    }
  }
}
</script>

<style lang="less" scoped>
.companieslist{
  margin-left: 29px;
}
</style>
