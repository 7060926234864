export class OnlineContactsStorage {

    constructor() {
        this._idsOnline = [];
        this._idsBusy = [];
    }

    setBusyContacts(ids) {
        this._idsBusy = ids;
        if (Array.isArray(ids)) {
            ids.forEach(i => this.addBusyContact(i));
        }
    }

    getBusyContacts() {
        return this._idsBusy;
    }

    addBusyContact(id) {
        const isOnline = this._idsOnline.indexOf(id) > -1;
        const isAlreadyBusy = this._idsBusy.indexOf(id) > -1;
        if (isOnline && !isAlreadyBusy) {
            this._idsBusy.push(id)
        }
    }

    removeBusyContact(id) {
        const index = this._idsBusy.indexOf(id);
        if (index > -1) {
            this._idsBusy.splice(index, 1);
        }
    }

    setOnlineContacts(ids) {
        this._idsOnline = ids;
    }

    getOnlineContacts() {
        return this._idsOnline;
    }

    addOnlineContact(id) {
        const index = this._idsOnline.indexOf(id);
        if (index === -1) {
            this._idsOnline.push(id);
        }
    }

    removeOnlineContact(id) {
        const index = this._idsOnline.indexOf(id);
        if (index > -1) {
            this._idsOnline.splice(index, 1);
        }
        this.removeBusyContact(id);
    }


}