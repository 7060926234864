<template>
  <svg height="18">
    <path :style="`fill:${color}`" d="M 1,0 L 9,0 L 5,6 " :transform="`translate(${calcScore()},2)`"/>
    <rect style="fill: #aaa" x="5" y="11" width="230" height="8" />
    <rect :style="`fill: ${color}`" x="5" y="11" :width="calcScore()" height="8" />
  </svg>
</template>

<script>
export default {
  name: 'MatchScale',
  props: {
    score: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#aa0000'
    }
  },
  methods: {
    calcScore() {
      return this.score * 230 / 100;
    }
  }
}
</script>
