<template>
  <div class="user"
       v-bind:class="{ 'is-contact' : networker.match_confirmed,
                       'is-ignored' : networker.match_ignored,
                       'is-requesting' : !networker.match_ignored && networker.contactUrl,
                       'is-requested' : !networker.match_confirmed && !networker.contactUrl && networker.match_requested,
                       'recommendation-border' : isRecommendation,
                       'is-hidden': networker.hidden,
                       'has-role' : networker.roleName}">

    <div v-if="networker.roleName" class="user-header">
      <div class="roleName">
        <span :title="networker.roleName">{{ networker.roleName }}</span>
      </div>
      <i v-if="networker.match_confirmed" class="fas fa-user-friends contact-icon" :title="$t('hint-match-confirmed')"></i>
      <i v-else-if="networker.match_ignored" class="fas fa-times-circle ignored-icon" :title="$t('hint-match-ignored')"></i>
      <i v-else-if="networker.contactUrl" class="fas fa-envelope request-icon" :title="$t('hint-match-incoming-request')"></i>
      <i v-else-if="networker.match_requested" class="fas fa-envelope request-icon" :title="$t('hint-match-outgoing-request')"></i>
    </div>
    <div v-else class="user-header-floating">
      <i v-if="networker.match_confirmed" class="fas fa-user-friends contact-icon" :title="$t('hint-match-confirmed')"></i>
      <i v-else-if="networker.match_ignored" class="fas fa-times-circle ignored-icon" :title="$t('hint-match-ignored')"></i>
      <i v-else-if="networker.contactUrl" class="fas fa-envelope request-icon" :title="$t('hint-match-incoming-request')"></i>
      <i v-else-if="networker.match_requested" class="fas fa-envelope request-icon" :title="$t('hint-match-outgoing-request')"></i>
    </div>
    <div class="contactInfo">
      <img :src="getImage(networker)" :ref="'img'"
           v-on:error="setFallBackImageForNetworker()"/>

      <div class="recommendation-header">
        <span :title="networker.fullName">{{ networker.fullName }}</span>
        <label :title="networker.position">{{ networker.position }}</label>
        <label :title="networker.organization">{{ networker.organization }}</label>
      </div>
    </div>
    <!-- .contactInfo -->

    <label class="scale-title">{{ $t('match-scale') }}</label>
    <MatchScale class="scale-meter" color="var(--scale-color)"
                :score="getMatchingScore(networker)"/>

    <!-- .scale-wrapper -->


    <div class="recommendation-button">
      <div v-on:click="goToProfile(networker,isRecommendation)">
        {{ $t('to-profile') }}
      </div>
    </div>

    <div class="hide-recommendation-button" v-if="isRecommendation">
      <div v-on:click="hideRecommendation(networker)">
        {{ $t('hide-recommendation') }}
      </div>
    </div>

    <!-- .recommendation-button -->
  </div>
</template>

<script>
import MatchScale from "@/components/MatchScale";
import {cardMixin} from "@/components/cards/card-mixin";

export default {
  name: "NetworkersCard",
  mixins: [cardMixin],
  components: {MatchScale},
  props: {
    networker: {
      type: Object,
      required: true
    },
    goToProfile: {
      type: Function,
      required: true
    },
    hideRecommendation: {
      type: Function,
      required: false
    },
    isRecommendation: {
      required: false,
      default: false,
      type: Boolean
    }

  }
}
</script>

<style scoped lang="less">


.recommendation-border {
  border: 2px solid var(--primary-color) !important;
  height: 260px !important;
}

.recommendation-border.has-role {
  height: 290px !important;
}

.is-hidden {
  opacity: 0.6 !important;
}


.is-contact, .is-requesting, .is-requested, .is-ignored {
  position: relative;
}

.is-contact, .is-requesting, .is-requested {
  background-color: #F5F0E7;
}

.user {
  transition: opacitiy 0.1s;
  width: 270px;
  height: 226px;
  float: left;
  text-align: left;
  border: 1px solid var(--border-color);
  margin: 0 20px 20px 0;
  padding: 20px;

  .recommendation-header {

    margin-left: 10px;

    display: flex;
    flex-direction: column;
    width: calc(100% - 70px);

    span {
      color: #58585A;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    label {
      color: #58585A;
      font-size: 12px;
      margin-top: 4px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: block-axis;
    }
  }

  .scale-meter {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 10px;
  }

  .scale-title {
    display: block;
    height: 14px;
    width: 86px;
    color: #58585A;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }


  .recommendation-button, .hide-recommendation-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    a {
      text-decoration: none;
    }

    div {

      -webkit-writing-mode: horizontal-tb !important;
      text-rendering: auto;
      letter-spacing: normal;
      word-spacing: normal;
      line-height: normal;
      text-transform: none;
      text-indent: 0;
      text-shadow: none;
      display: inline-block;
      text-align: center;
      align-items: flex-start;
      font-size: 13.333333px;
      font-weight: bold;
      height: 44px;
      width: 100%;
      background: var(--secondary-color);
      padding: 12px;
      color: white;
      cursor: pointer;
      border: none;
    }
  }

  .hide-recommendation-button {
    div {
      color: var(--secondary-color);
      background: white;
      height: 42px;
    }
  }

  .user-header, .user-header-floating {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
    position: relative;

    .contact-icon, .request-icon, .ignored-icon {
      margin-left: 10px;
      font-size: 1.5em;
      font-weight: 900;
      color: var(--primary-color);
    }
  }

  .user-header {
    height: 25px;
    bottom: 10px;
    left: 10px;
  }

  .user-header-floating {
    position: absolute;
    right: 10px;
    top: 8px;
  }

  .contactInfo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 70px;

    img {
      object-fit: cover;
      width: 60px;
      height: 60px;
      flex: 0 0 auto;
    }
  }
}

.user.has-role {
  height: 250px;
}

</style>
