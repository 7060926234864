import {logger} from "@/main";

const category_person = "person";
const category_matchmaking = "matchmaking";
const category_matchmaking_person = "networker";
const category_recommendation = "networker_contact";
const category_chat = "networker_chat";
// actions
const action_view = "view";
const action_list = "list";
const action_login = "login";

export default {

    trackEvent(category, action, name, value) {
        if(process.env.VUE_APP_MATOMO_ENABLED === 'true' && document.cookie && document.cookie !== '') {
            const optanonConsentCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('OptanonConsent'));
            if (optanonConsentCookie) {
                let groupsPos = optanonConsentCookie.search('groups=');
                if(groupsPos > -1) {
                    const decodedUri = decodeURIComponent(optanonConsentCookie);
                    if(decodedUri) {
                        let groups = decodedUri.split('&').find(entry => entry.startsWith('groups='));
                        if (groups) {
                            groups = groups.substr(7);

                            const findEnablingMatomoValues = groups.split(',').filter(part => part === '2:1' || part === '0_24175:1');
                            //both values are present and matomo is enabled
                            if (findEnablingMatomoValues.length === 2) {
                                logger.log("Matomo category :" + category + "   Matomo Action : " + action)
                                if (window.Piwik && window.Piwik.getTracker && window.Piwik.getTracker().trackEvent) {
                                    logger.log(`track event for category :${category}  with action : ${action}`)
                                    window.Piwik.getTracker().trackEvent(category, action ? action : '-', name ? name : undefined, value ? value : undefined);
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    trackDetailView(id) {
        this.trackEvent(`${category_person}_${action_view}`, id, "Person")
    },
    trackMatchmakingLogin(type) {
        this.trackEvent(`${category_matchmaking}_${action_login}`, type)
    },
    trackRecommendationList() {
        this.trackEvent(`${category_matchmaking_person}_${action_list}`)
    },
    trackContactList() {
        this.trackEvent(`${category_recommendation}_${action_list}`)
    },
    trackChatList() {
        this.trackEvent(`${category_chat}_${action_list}`)
    }

}
