export class Country {

    iso_2;
    name_en;
    name_de;
    continent_code;
    continent_name_en;
    continent_name_de;

    constructor(jsonObject) {
        this.iso_2 = jsonObject.iso_2;
        this.name_en = jsonObject.name_en;
        this.name_de = jsonObject.name_de;
        this.continent_code = jsonObject.continent_code;
        this.continent_name_en = jsonObject.continent_name_en;
        this.continent_name_de = jsonObject.continent_name_de;
    }

    getDisplayNameByLanguage(lang) {
        switch (lang) {
            case 'de':
                return this.name_de;
            case 'en' :
                return this.name_en;
            default :
                return this.name_en;
        }
    }

}