import {BaseVueEntity} from "@/entities/BaseVueEntity";
import moment from "moment";
import {isDefined} from "@/utils";

export class Event extends BaseVueEntity {

    end;
    event_id;
    group_id;
    has_waitinglist;
    id;
    info;
    location;
    max_participants;
    moderator_ids;
    start;
    title;
    video_server;

    constructor(eventAsObject) {
        super();
        for (const [key, value] of Object.entries(eventAsObject)) {
            this[key] = value;
        }
        if(isDefined(this.event_id)) {
            this.generateVueIdBasedOnProperty('event_id');
        }
    }

    isDue() {
        this.getMillisUntilDue() <= 0 && !this.alreadyPast();
    }

    getMillisUntilDue() {
        const millisBeforeWarning = process.env.VUE_APP_DUE_EVENT_TIME_BEFORE_WARNING;
        const start = moment.utc(this.start).tz(process.env.VUE_APP_GROUPS_TIMEZONE).subtract(millisBeforeWarning, 'milliseconds');
        const now = moment.utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);

        return start.valueOf() - now.valueOf();
    }

    alreadyPast() {
        const end = moment.utc(this.end).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        const now = moment.utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        return now.isAfter(end);
    }

    isRunning() {
        const start = moment.utc(this.start).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        const end = moment.utc(this.end).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        const now = moment.utc().tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        return now.isBetween(start, end);
    }
}
