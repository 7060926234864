<template>
  <div class="input-wrapper">
    <label class="label filled-input">
      {{ placeHolder }}</label>
    <input type="date" class="input" v-model="internDate" :required="required" v-on:change="valueChanged" v-bind:min="min" v-bind:max="max">
  </div>

</template>


<script>


export default {
  name: "CustomDatePicker",
  data(){
    return {
      internDate: undefined,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
       this.internDate = newVal;
      }
    }
  },
  props: {
    value: {
      required: true
    },
    placeHolder: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    min:{
      type: String,
      required: false,
    },
    max:{
      type: String,
      required: false,
    }

  },
  methods:{
    valueChanged(){
      this.$emit('update:value',this.internDate);
      this.$emit('change');
    }
  }
}
</script>