
import axios from 'axios'

const host = process.env.VUE_APP_MYBOOKFAIR_USER_HOST;
const surveyHost = process.env.VUE_APP_MYBOOKFAIR_SURVEY_HOST
const backendHost = process.env.VUE_APP_URL;

const searchGroup = 'MATCHMAKING_LOOKING_FOR'
const offerGroup = 'MATCHMAKING_OFFER'
const days = 'DAYS'
const extendedUser = 'EXTENDED_USER'

const accessToken = process.env.VUE_APP_MYBOOKFAIR_ACCESS_TOKEN;

export default {
  saveData(formData) {
      return new Promise(function (resolve, reject) {
          let request = new XMLHttpRequest();
          request.onreadystatechange = function () {
              if (request.readyState === XMLHttpRequest.DONE) {
                  if (request.status === 200) {
                      resolve(request.response);
                  } else {
                      reject(request.status);
                  }
              }
          }
          if (accessToken) {
              request.open("POST", `${backendHost}/api/mbf/saveData`);
              request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
          } else {
              request.open("POST", `https://${host}/oauth/matchmaking/save-data`);
          }
          request.setRequestHeader('Accept', 'application/json');
          request.send(formData);
      });
  },

  profile() {
    if (accessToken) {
      return axios.get(`https://${host}/api/v1/me`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }).catch(this.handleError);
    } else {
      return axios.get(`https://${host}/oauth/account/get-data`, {
        headers: {
          Accept: 'application/json'
        }
      }).catch(this.handleError);
    }
  },

  searchAnswers() {
    return this.answers(searchGroup);
  },

  offerAnswers() {
    return this.answers(offerGroup);
  },
  
  answers(group) {
    if (accessToken) {
      return axios.get(`https://${surveyHost}/api/v1/me/groups/${group}/answers`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }).catch(this.handleError);
    } else {
      return axios.get(`https://${surveyHost}/oauth/matchmaking/get-groups/${group}`, {
        headers: {
          Accept: 'application/json'
        }
      }).catch(this.handleError);
    }
  },
  activeDays(){
    return this.answers(days);
  },
  extendedUser(){
    return this.answers(extendedUser);
  },
  handleError(error) {
    if (error.message && (error.message.includes('code 500') || error.message.includes('code 403'))) {
      window.location='/oauth/call/self';
    } else {
      throw error;
    }
  }
}
