export function isDefined(value) {
    return value !== null && value !== undefined;
}

export function deepCopy(value) {
    return JSON.parse(JSON.stringify(value));
}

export function scrollToTop() {
    const root = document.getElementById('app');
    if (isDefined(root)) {
        window.scrollTo({top: getOffsetTop(root) - 200, behavior: "smooth"});
    }
}

export function scrollToElement(element) {
    if (isDefined(element)) {
        window.scrollTo({top: getOffsetTop(element), behavior: "smooth"});
    }
}

export function isEmptyObject(object) {
    return JSON.stringify(object) === JSON.stringify({});
}

export function getOffsetTop(elem) {
    const clientRect = elem.getBoundingClientRect();
    return clientRect.top + document.documentElement.scrollTop;
}


export function hashCode(value) {
    let hash = 0, i, chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function durationString(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    let minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
}

export function openVideoChat(openInWindow, userId, userName, initials, avatar, call, accept) {
    const targetUrl = new URL(process.env.VUE_APP_CHAT_URL + process.env.VUE_APP_CHAT_URL_PAGE);
    let search = '?me=' + encodeURIComponent(this.me());
    search += '&device=' + encodeURIComponent(this.device());
    search += '&peer=' + encodeURIComponent(userId);
    search += '&name=' + encodeURIComponent(userName);
    search += '&initials=' + encodeURIComponent(initials);
    search += '&appname=' + encodeURIComponent(process.env.VUE_APP_PUSH_NAME);
    if (avatar) {
        search += '&avatar=' + encodeURIComponent(avatar);
    }
    if (call) {
        search += '&call=' + encodeURIComponent(call);
    }
    if (accept) {
        search += '&accept=true';
    }
    targetUrl.search = search;

    if (openInWindow) {
        window.open(targetUrl, '_blank');
    } else {
        window.location = targetUrl;
    }
}

export function deleteUrlSearchParam(param) {
    let urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get(param);

    if (codeParam) {
        urlParams.delete(param);
        let search = urlParams.toString();
        if (search.length > 0) {
            search = '?' + search;
        }
        window.history.replaceState(null, null, window.location.pathname + search + window.location.hash);
    }
}
