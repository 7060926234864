<template>

  <div class="customToggle">
    <div class="left-toggle"
         v-bind:class="{'is-active' : value === values[0]}"
         v-on:click="toggleValue()">
      <span>{{ $t(values[0]) }} </span>
    </div>
    <div class="right-toggle"
         v-bind:class="{'is-active' : value === values[1]}"
         v-on:click="toggleValue()">
      <span>{{ $t(values[1]) }} </span>
    </div>
  </div>

</template>

<script>
export default {
  name: "CustomToggle",
  props: {
    value: {
      required: true
    },
    values: {
      required: true,
      type: Array
    }
  },
  methods: {
    toggleValue() {
      if (this.value === this.values[0]) {
        this.$emit('update:value', this.values[1]);
      } else {
        this.$emit('update:value', this.values[0]);
      }
    },
  }
}
</script>
