<template>
  <div class="input-wrapper">
    <input type="time" class="input" v-model="internValue" :required="required" v-bind:min="min" v-bind:max="max"
           v-on:change="valueChanged"/>
    <label class="label filled-input">
      {{ placeHolder }}</label>
    <div class="hint" v-if="hint">{{ hint }}</div>
  </div>
</template>

<script>
// Time format is milliseconds.
import moment from "moment";

export default {
  name: "CustomTimePicker",
  data() {
    return {
      internValue: undefined,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          this.internValue = '00:00'
          this.valueChanged();
        } else {
          this.internValue = moment.utc(moment.duration(newVal).asMilliseconds()).format('HH:mm');
        }
      }
    }
  },
  props: {
    value: {
      required: true
    },
    placeHolder: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    min: {
      type: String,
      required: false
    },
    max: {
      type: String,
      required: false
    },
    hint: {
      required: false,
      type: String
    }
  },
  methods: {
    valueChanged() {
      this.$emit('update:value', moment.duration(this.internValue).asMilliseconds())
    }
  }
}
</script>
