import { VEmojiPicker, emojisDefault, categoriesDefault } from "v-emoji-picker";
import fallbackProfileImage from '@/assets/img/person.svg';
import localStorageHelper from "../localstorage/local-storage";
import {logger} from "@/main";

export let chatMixIn = {
    components: {
        VEmojiPicker
    },
    variable: {
        previousDate: undefined,
    },
    data() {
        return {
            lang: this.$getCurrentLanguage(),
            emojiI18n: undefined,
            initialLoading: true,
            messages: [],
            scrollOptions: {suppressScrollX: true},
        }
    },
    // Component hooks are called in sequence
    // mixins hook -> component hook
    beforeMount() {
        this.emojiI18n = {
            search: this.$t('emoji-search'),
            categories: {
                Activity: this.$t('emoji-activity'),
                Flags: this.$t('emoji-flags'),
                Foods: this.$t('emoji-foods'),
                Frequently: this.$t('emoji-frequently'),
                Objects: this.$t('emoji-objects'),
                Nature: this.$t('emoji-nature'),
                Peoples: this.$t('emoji-peoples'),
                Symbols: this.$t('emoji-symbols'),
                Places: this.$t('emoji-places')
            }
        };
    },
    methods: {
        localStorageKeyPrefix() {
            return localStorageHelper.getKeyPrefix();
        },

        handleTextInput: function (e) {

            if (e.keyCode == 13 && !e.shiftKey && !e.altKey && !e.ctrlKey) {
                let message = this.$refs.message_input.value;
                if (message.length > 0 && message.charAt(message.length - 1) == '\n') {
                    message = message.substring(0, message.length - 1);
                }
                this.sendMessageText(message);
                e.preventDefault();
            } else {
                this.countCharactersLeft(e.target);
            }
        },

        countCharactersLeft(elem, e) {
            if (elem) {
                let left = (1000 - elem.value.length);
                if (left < 0) left = 0;
                if (elem.value.trim().length == 0) {
                    this.$refs.message_send_button.style.display = 'none';
                    this.$refs.text_word_count_container.style.visibility = 'hidden';
                } else {
                    this.$refs.message_send_button.style.display = 'table-cell';
                    this.$refs.text_word_count_container.style.visibility = 'visible';
                }

                this.$refs.word_count.innerHTML = "" + left;
            }
        },

        getThumbnail(user) {
            return user.getThumbnail();
        },

        getName(user) {
            return user.getName();
        },

        getImage(chat) {
            return chat.thumbnail ? chat.thumbnail : (chat.photo ? chat.photo : fallbackProfileImage);
        },

        getInitials(user) {
            return user.getInitials();
        },

        getTimestamp(message) {
            const date = this.getDate(message);
            return date.toLocaleTimeString(navigator.language);
        },

        getMessageId(msg) {
            return 'message' + msg.id;
        },

        getStatusId(msg) {
            return 'status' + msg.id;
        },

        escapeHtml(text) {
            text = text.replace(/&/g, "&amp;");
            text = text.replace(/</g, "&lt;");
            text = text.replace(/>/g, "&gt;");
            text = text.replace(/\n/g, "<br />");
            return text;
        },

        sendMessage() {
            const message = this.$refs.message_input.value;
            this.sendMessageText(message);
        },

        scrollToBottom() {
            const container = this.$el.querySelector("#chatlist");
            if (container) {
                this.$nextTick(function () {
                    container.parentNode.scrollTop = container.scrollHeight;
                });
            } else {
                logger.log("#chatlist not found");
            }
        },

        toggle_emojis() {
            if (this.$refs.emoji_picker.$el.style.visibility !== 'visible') {
                this.$refs.emoji_picker.$el.style.visibility = 'visible';
            } else {
                this.$refs.emoji_picker.$el.style.visibility = 'hidden';
            }
        },

        selectEmoji(emoji) {
            this.$refs.message_input.value += emoji.data;
            this.countCharactersLeft(this.$refs.message_input);
            this.toggle_emojis();
        },

        getNotification(message) {
            const date = this.getDate(message);
            const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
            return date.toLocaleDateString(this.lang, options);
        },

        getDate(message) {
            return new Date(message.created_at.replace(' ', 'T') + '.000Z');
        },

        getTimeline(message) {
            const date = this.getDate(message);
            if (this.messages.indexOf(message) != 0) {
                if (this.sameDay(this.previousDate, date)) {
                    return false;
                }
            }
            this.previousDate = date;
            return true;
        },

        sameDay(d1, d2) {
            return d1.getFullYear() == d2.getFullYear() && d1.getMonth() == d2.getMonth() && d1.getDate() == d2.getDate();
        },
    }
}
