<template>
  <div class="input-wrapper">

    <input type="text" class="input" v-model="internValue" :required="required" ref="urlInput"
           v-on:keyup="onKeyUp"
           spellcheck="false"/>
    <label class="label"
           v-bind:class="value !== '' && value !== null && value !== undefined ? 'filled-input' : '' ">
      {{ placeHolder }}{{ required ? '*' : '' }}</label>
  </div>
</template>

<script>
import {isDefined} from "@/utils";

export default {
  name: "CustomInputUrl",
  props: {
    value: {
      required: true
    },
    placeHolder: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.internValue = newVal;
      }
    }
  },
  data() {
    return {
      internValue: undefined
    }
  },
  methods: {
    onKeyUp() {
      const input = this.$refs.urlInput;
      if (isDefined(this.internValue) && this.internValue !== '') {
        const isValid = this.validateInternUrl();
        if (!isValid) {
          input.setCustomValidity(this.$t('invalid-url').toString());
        } else {
          input.setCustomValidity('');
          input.reportValidity();
        }

      } else {
        input.setCustomValidity('');
        input.reportValidity();
      }

      this.$emit('update:value', this.internValue)
    },
    validateInternUrl() {
      try {
        const url = new URL(this.internValue);
      } catch (_) {
        if (this.internValue.startsWith('@') && this.internValue.length === 1) {
          return false;
        }
        if (this.internValue.startsWith('http://') || this.internValue.startsWith('https://')) {
          return false;
        }
        if (!this.internValue.includes('/')) {
          return true;
        }
        return false;
      }
      return true;
    }

  }
}
</script>
