<template>
  <div class="notification-list" ref="notificationList"></div>
</template>

<script>
import {Appointment} from "@/entities/Appointment";
import {Event} from "@/entities/Event";
import DueAppointmentNotification from "./Appointments/DueAppointmentNotification"
import MissedVideoCallNotification from "./MissedVideoCall/MissedVideoCallNotification";
import DueEventNotification from "./Events/DueEventNotification";
import Vue from 'vue';
import {isDefined} from "@/utils";
import {MissedVideoCall} from "@/components/Notifications/MissedVideoCall/MissedVideoCall";

export default {
  name: "NotificationComponent",
  components: {DueAppointmentNotification,MissedVideoCallNotification,DueEventNotification},
  methods: {
    //Entity need to extend BaseVueEntity or have vueId Property
    addNotification(entity) {
      if (entity instanceof Appointment) {
        const constructor = Vue.extend(DueAppointmentNotification);
        const component = new constructor({props: {appointment: entity}});
        component.$props.appointment = entity;
        this.addNotificationToDom(component, entity);
      }else if(entity instanceof MissedVideoCall){
        const constructor = Vue.extend(MissedVideoCallNotification);
        const component = new constructor({props: {userData: entity.userData}});
        component.$props.userData = entity.userData;
        this.addNotificationToDom(component, entity);
      }else if(entity instanceof Event){
        const constructor = Vue.extend(DueEventNotification);
        const component = new constructor({props: {event: entity}});
        component.$props.event = entity;
        this.addNotificationToDom(component, entity);
      }
    },
    addNotificationToDom(component, entity) {
      const div = document.createElement('div');
      this.$refs.notificationList.appendChild(div);
      const e = component.$mount(div);
      e.$el.setAttribute('id', entity.vueId);
      const closeButton = document.createElement('div');
      closeButton.onclick = this.closeNotification;
      closeButton.classList.add('notification-close-button');
      e.$el.insertBefore(closeButton, e.$el.firstChild);
    },
    closeNotification(event) {
      this.$refs.notificationList.removeChild(event.target.parentNode);
    },
    //VueId of Entity
    closeNotificationByVueId(vueId) {
      const e = document.getElementById(vueId);
      if (isDefined(e)) {
        this.$refs.notificationList.removeChild(e);
      }
    }
  }
}
</script>
