<template>

  <div class="themes-container">

    <h1>{{ $t('your-interests') }}</h1>
    <div class="themes-container-normal" v-if="selected_themes_without_search_and_offer.length > 0">
      <div class="themes-normal" :class="category.additionalCSSClass"
           v-for="category of selected_themes_without_search_and_offer">
        <div v-if="category.children && !category.has_selected" class="themes-category-header">
          <h5 class="themes-category-header-text">{{ category.title }}
            <div v-if="canEditCategory(category)" v-on:click="onTitleClicked(getTabIdForCategory(category))"
                 class="edit-themes-button">
              {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
            </div>
          </h5>
          <h3 class="none-selected-text">{{ $t("none-selected") }}</h3>
        </div>
        <themes-category v-if="category.has_selected" v-bind:category="category" v-bind:parent="category"
                         v-bind:step="0" :key="category.id+'_normal'"
                         v-on:titleClicked="onTitleClicked"></themes-category>
      </div>
    </div>
    <div class="themes-container-normal" v-if="selected_themes_without_search_and_offer_separated.length > 0">
      <div class="themes-normal flex-break" :class="category.additionalCSSClass"
           v-for="category of selected_themes_without_search_and_offer_separated">
        <div v-if="category.children && !category.has_selected" class="themes-category-header">
          <h5 class="themes-category-header-text">{{ category.title }}
            <div v-if="canEditCategory(category)" v-on:click="onTitleClicked(getTabIdForCategory(category))"
                 class="edit-themes-button">
              {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
            </div>
          </h5>
          <h3 class="none-selected-text">{{ $t("none-selected") }}</h3>
        </div>
        <themes-category v-if="category.has_selected" v-bind:category="category" v-bind:parent="category"
                         v-bind:step="0" :key="category.id+'_normal'"
                         v-on:titleClicked="onTitleClicked"></themes-category>
      </div>
    </div>
    <h3> {{ $t('me-searching') }}
    </h3>
    <template v-for="category of selected_themes_with_search_and_offer">
      <!-- No selected entries header-->

      <div v-if="category.children && !category.has_selected_search" class="themes-category-header"
           :class="category.additionalCSSClass">
        <h5 class="themes-category-header-text">
          {{ category.title }}
          <div v-if="canEditCategory(category)" v-on:click="onTitleClicked(getTabIdForCategory(category,'search'))"
               class="edit-themes-button">
            {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
          </div>
        </h5>
        <h3 class="none-selected-text">{{ $t("none-selected") }}</h3>
      </div>
      <themes-category-search  v-on:titleClicked="onTitleClicked" v-if="category.has_selected_search" v-bind:category="category" v-bind:parent="category"
                              v-bind:step="0" :key="category.id+'_search'"></themes-category-search>
    </template>
    <h3>
      {{ $t('me-offering') }}
    </h3>
    <template v-for="category of selected_themes_with_search_and_offer">
      <!-- No selected entries header-->
      <div v-if="category.children && !category.has_selected_offer" class="themes-category-header"
           :class="category.additionalCSSClass">
        <h5 class="themes-category-header-text">
          {{ category.title }}
          <div v-if="canEditCategory(category)" v-on:click="onTitleClicked(getTabIdForCategory(category,'offer'))"
               class="edit-themes-button">
            {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
          </div>
        </h5>
        <h3 class="none-selected-text">{{ $t("none-selected") }}</h3>
      </div>
      <themes-category-offer v-if="category.has_selected_offer" v-bind:category="category" v-bind:parent="category"
                             v-bind:step="0"  v-on:titleClicked="onTitleClicked"
                             :key="category.id+'_offer'"></themes-category-offer>

    </template>

  </div>
</template>

<script>
import ThemesCategoryOffer from './themes-categories-offer';
import themes from "@/themes/themes-helper";
import ThemesCategorySearch from "@/themes/profile-own/themes-categories-search";
import ThemesCategory from "@/themes/profile-own/themes-categories";

export default {
  name: "ThemesOwnProfile",
  data() {
    return {
      selected_themes: [],
      searchTabId: themes.getSearchTabId(),
      offerTabId: themes.getOfferTabId(),
      selectThemesTabs: themes.getThemeSelectTabs()
    }
  },
  computed: {
    selected_themes_without_search_and_offer_separated() {
      return this.selected_themes.filter(category => !category.hasSearchAndOffer && category.separateRow);
    },
    selected_themes_without_search_and_offer() {
      return this.selected_themes.filter(category => !category.hasSearchAndOffer && !category.separateRow);
    },
    selected_themes_with_search_and_offer() {
      return this.selected_themes.filter(category => category.hasSearchAndOffer && !category.separateRow);
    }
  },
  props: {
    userThemes: {
      required: true
    }
  },
  watch: {
    userThemes: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.init();
        }
      }
    }
  },
  components: {
    ThemesCategory,
    ThemesCategorySearch,
    ThemesCategoryOffer
  },
  methods: {
    init() {
      this.buildThemeViews(this.userThemes);
    },
    sortCategories(themesToDisplay) {
      themesToDisplay.sort(function (a, b) {
        return a.sortOrder.localeCompare(b.sortOrder);
      });
      for (const category of themesToDisplay) {
        if (category.children) {
          this.sortCategory(category.children);
        }
      }
    },
    sortCategory(children) {
      for (const entry of children) {
        if (entry.children) {
          this.sortCategory(entry.children);
        } else {
          children.sort(function (a, b) {
            if (a.sortOrder && b.sortOrder) {
              return a.sortOrder.localeCompare(b.sortOrder);
            } else {
              return 0;
            }
          });

        }
      }
    },
    buildThemeViews(userThemes) {
      const self = this;
      let themesToDisplay = themes.getCategoriesToDisplayForOwnProfileByLanguage(this.$getCurrentLanguage());
      this.applyUserThemes(userThemes, themesToDisplay);
      this.sortCategories(themesToDisplay);
      self.selected_themes = themesToDisplay;
    },
    applyUserThemes(userThemes, themesToDisplay) {
      for (const category of themesToDisplay)
        if (category.children) {
          this.traverseCategory(category.children, category, userThemes, [category]);
        }
    },
    traverseCategory(children, category, userThemes, parents) {
      for (const entry of children) {
        if (entry.children) {
          this.traverseCategory(entry.children, category, userThemes, [...parents, entry]);
        } else {
          if (userThemes[category.id]) {
            if (category.hasSearchAndOffer) {
              if (userThemes[category.id].search && userThemes[category.id].search.length > 0 && userThemes[category.id].search.includes(entry.id)) {
                entry.selected_as_search = true;
                category.has_selected_search = true;
                parents.forEach(parent => {
                  parent.has_selected_search = true;
                });
              }
              if (userThemes[category.id].offer && userThemes[category.id].offer.length > 0 && userThemes[category.id].offer.includes(entry.id)) {
                entry.selected_as_offer = true;
                category.has_selected_offer = true;
                parents.forEach(parent => {
                  parent.has_selected_offer = true;
                });
              }
            } else {
              if (userThemes[category.id] && userThemes[category.id].length > 0 && userThemes[category.id].includes(entry.id)) {
                entry.selected = true;
                category.has_selected = true;
                parents.forEach(parent => {
                  parent.has_selected = true;
                });
              }
            }
          }
        }
      }
    },
    canEditCategory(category) {
      return themes.canEditCategory(category);
    },
    getTabIdForCategory(category,categoryType) {
      return themes.getTabIdForCategory(category,categoryType);
    },
    onTitleClicked(value) {
      this.$emit('titleClicked', value);
    }
  }
}
</script>
