<template>
  <div>
    <dialog :id="'profileOthers'" role="dialog" class="profileOthersDialog"
            :class="opened ? 'dialog--open' : ''" v-on:animationend="animationEnd()">
      <div class="close-button" v-on:click="closeProfile(true)" v-if="opened && !disableCloseButton">
        <div class="close-button-icon" id="profileCloseButtonIcon"></div>
      </div>
      <perfect-scrollbar ref="scroll" :options="scrollOptions">
        <div class="main profile profileOthersContent" v-if="opened">
          <div v-visibility-change="visibilityChange"></div>
          <div class="userDataContainer">
            <loading :active.sync="isLoading"
                     :is-full-page="false"
                     color="var(--primary-color)">
            </loading>
            <img class="profile-image" ref="img" :src="getImage()"
                 v-on:error="onProfileImageError" alt="Profilbild"
                 id="profile-image"/>
            <div class="userData">
              <div class="userNameContainer">
                <h2 class="fullName">{{ userData.fullName }}</h2>
                <div class="roleName" v-if="userData.roleName">
                  <span>{{ userData.roleName }}</span>
                </div>
              </div>
              <div class="position-orga-wrapper">
                <label class="position"
                       v-if="userData.position">{{ userData.position }}{{ userData.organization ? ',' : '' }} </label>
                <label class="orga">{{ userData.organization }}</label>
              </div>

              <div class="status-wrapper" v-if="userData.headline">
                <label class="status-title">{{ $t('current-headline') }}</label>
                <label class="status">{{ userData.headline ? `„${userData.headline}“` : '' }}</label>
              </div>


              <div class="country-wrapper" v-if="userData.country">
                <i class="fal fa-flag fa-lg"></i>
                <label class="country">{{ getDisplayNameForCountryByISO(userData.country) }}</label>
              </div>

              <div class="email-wrapper" v-if="userData.email">
                <i class="fal fa-envelope fa-lg"></i>
                <a class="email" :href="`mailto:${userData.email}`">{{ userData.email }}</a>
              </div>

              <div class="url-wrapper" v-if="userData.web">
                <i class="fal fa-globe fa-lg"></i>
                <a class="url" :href="validUrl(userData.web,undefined)" target="_blank">{{ userData.web }}</a>
              </div>

              <div class="linkedin-wrapper" v-if="userData.linkedin">
                <i class="fab fa-linkedin linkedin"></i>
                <a class="url" :href="validUrl(userData.linkedin, webServices.linkedin)"
                   target="_blank">{{ validUrl(userData.linkedin, webServices.linkedin) }}</a>
              </div>

              <div class="twitter-wrapper" v-if="userData.twitter">
                <i class="fab fa-twitter fa-lg "></i>
                <a class="url" :href="validUrl(userData.twitter,webServices.twitter)"
                   target="_blank">{{ validUrl(userData.twitter, webServices.twitter) }}</a>
              </div>

              <div class="instagram-wrapper" v-if="userData.instagram">
                <i class="fab fa-instagram fa-lg "></i>
                <a class="url" :href="validUrl(userData.instagram,webServices.instagram)"
                   target="_blank">{{ validUrl(userData.instagram, webServices.instagram) }}</a>
              </div>

              <div class="facebook-wrapper" v-if="userData.facebook">
                <i class="fab fa-facebook fa-lg "></i>
                <a class="url" :href="validUrl(userData.facebook,webServices.facebook)"
                   target="_blank">{{ validUrl(userData.facebook, webServices.facebook) }}</a>
              </div>

              <div class="phone-wrapper" v-if="userData.tel">
                <i class="fal fa-phone-alt fa-lg"></i>
                <label class="phone">{{ userData.tel }}</label>
              </div>
              <custom-button
                  class="secondary-button"
                  v-if="unconfirmedContact()"
                  :onClicked="confirmContact"
                  :icon="'fal fa-envelope fa-2x'">
                {{ $t('profile-accept-contact') }}
              </custom-button>
              <custom-button
                  class="secondary-button"
                  v-if="unconfirmedContact()  && !userData.match_ignored"
                  :onClicked="ignoreContact"
                  :icon="'fal fa-times-circle fa-2x'">
                {{ $t('profile-ignore-contact') }}
              </custom-button>
              <custom-button
                  v-if="!unconfirmedContact()"
                  class="secondary-button"
                  :onClicked=" (userData.match_requested) && !userData.match_confirmed ? () =>{} : userData.match_confirmed ? goToChat : openAddContactDialog"
                  :icon=" userData.match_confirmed ? 'fal fa-comment-check fa-2x' : 'fal fa-plus fa-2x'"
                  :icon-disabled="(userData.match_requested) && !userData.match_confirmed">
                {{ getButtonText() }}
              </custom-button>
              <custom-button
                  class="secondary-button"
                  v-if="!userData.match_requested && !userData.match_confirmed && is_recommendation"
                  :onClicked="hideRecommendation"
                  :icon="'fal fa-chevron-right fa-2x'">
                {{ $t('hide-recommendation') }}
              </custom-button>
              <custom-button
                  class="secondary-button"
                  v-if="$enableHideUnhideUserHandling && !isOwnUser() && !userData.hidden"
                  :onClicked="openHideUserDialog"
                  :icon="'fal fa-chevron-right fa-2x'">
                {{ $t('hide-user') }}
              </custom-button>
              <custom-button
                  class="secondary-button"
                  v-if="$enableHideUnhideUserHandling && !isOwnUser() && userData.hidden"
                  :onClicked="unHideUser"
                  :icon="'fal fa-chevron-right fa-2x'">
                {{ $t('unhide-user') }}
              </custom-button>
              <custom-button
                  class="secondary-button"
                  v-if=" videoChatEnabled() "
                  :onClicked="goToVideoChat"
                  :icon="'fal fa-video fa-2x'">
                {{ $t('video-chat') }}
              </custom-button>
              <custom-button
                  class="secondary-button"
                  v-if="$enableAppointments && userData.match_confirmed && hasValidRooms()"
                  :on-clicked="openCreateAppointmentDialog"
                  :icon="'fal fa-calendar fa-2x'">
                {{ $t('request-appointment') }}
              </custom-button>

            </div>
          </div>


          <h2 class="appointment-title" v-if="appointments.length > 0 && !ownProfile && userData.match_confirmed">
            {{ $t('appointments') }}</h2>
          <div class="appointment-list" v-if="appointments.length > 0 && !ownProfile">
            <div class="appointment" v-for="appointment of appointments" :key="appointment.id">
              <div class="appointment-content">
                <div class="appointment-content-left">
                  <div class="name">{{
                      appointment.isCanceled() ? $t('canceled') + ':' : appointment.isRequest() ? $t('requested') + ':' : appointment.isConfirmed() ? $t('confirmed') + ':' : ''
                    }} {{ appointment.name }}
                  </div>
                  <i class="fas fa-calendar-plus fa-2x download-calendar-icon" v-if="appointment.isConfirmed()"
                     v-on:click="downloadCalendarForAppointment(appointment)"> <span>iCal</span> </i>
                </div>

                <div class="appointment-content-center">
                  <div class="status">
                    <div class="appointment-times">
                      <span><i class="far fa-clock"/>

                  {{ formatTime(appointment.from, "L") }}      {{
                          formatTime(appointment.from, "HH:mm")
                        }} - {{ formatTime(appointment.to, "HH:mm") }} {{ timezone(appointment.from) }}</span>
                    </div>
                    <span>
                      <i class="far fa-map-marker-alt"/>
                      {{ $t('place') }}: {{ getRoomStringForAppointment(appointment) }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="description">
                <div><i class="far fa-info-circle"/>{{ appointment.description }}</div>
              </div>
              <div class="action-container"
                   :class="appointment.isConfirmed() && appointment.isOnline() ? 'action-container-online': ''">

                <div class="actions">
                  <div class="open-video-call" v-if="appointment.isConfirmed() && appointment.isOnline()"
                       v-on:click="joinOnlineAppointment(appointment)">
                    <span>{{ $t('appointments-join-online-appointment') }}</span><i class="fal fa-video fa-2x"></i>
                  </div>
                  <div class="cancel-appointment" v-on:click="openCancelAppointmentDialog(appointment)"
                       v-if="appointment.isConfirmed()">
                    <span>{{ $t('cancel-appointment') }}</span>
                  </div>
                  <div class="decline-appointment"
                       v-on:click="openCancelAppointmentDialog(appointment)"
                       v-if="appointment.isRequest() && appointment.isOutGoingRequest(myUserId)">
                    <span>{{ $t('cancel-appointment') }}</span>
                  </div>
                  <div class="decline-appointment"
                       v-on:click="openRejectAppointmentDialog(appointment)"
                       v-if="appointment.isRequest() && !appointment.isOutGoingRequest(myUserId)">
                    <span>{{ $t('reject-appointment') }}</span>
                  </div>
                  <div class="confirm-appointment"
                       v-on:click="openConfirmAppointmentDialog(appointment)"
                       v-if="appointment.isRequest() && !appointment.isOutGoingRequest(myUserId)">
                    <span>{{ $t('confirm-appointment') }}</span>
                  </div>
                  <div class="edit-appointment"
                       v-on:click="openEditAppointmentDialog(appointment)"
                       v-if="!appointment.isCanceled() ">
                    <span>{{ appointment.isOutGoingRequest(myUserId) ? $t('edit-appointment') :  $t('edit-requested-appointment') }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <themes-profile-others v-bind:for-own-profile="ownProfile"
                                 v-bind:user-themes="userData.themes"></themes-profile-others>


          <div class="same-contacts" v-if="userData.match_confirmed && shared_contacts.length > 0">
            <h1>{{ $t('same-contacts') }}</h1>
            <div class="same-contacts-wrapper">
              <ContactCard v-for="(contact,i) in shared_contacts" :key="i.id" :contact="contact"
                           :go-to-profile="switchUser"/>
            </div>
          </div>

          <div class="close-button-container">
            <custom-button :on-clicked=" () => {closeProfile(true); }" :icon="'fal fa-times fa-2x'" :width="303"
                           class="primary-button">
              {{ $t('close') }}
            </custom-button>
          </div>

          <custom-dialog ref="addContactDialog" :id="'contactDialog'" :onSubmit="addToContacts"
                         class="addContactDialog"
                         :submit-button-text="$t('contact-request-send')">
            <span class="header">{{ $t('contact-request-to', {to: userData.fullName}) }}</span>
            <div class="add-contact-dialog-hint left-aligned">{{ $t('contact-request-limited') }}</div>
            <custom-input-text-area v-bind:value.sync="contactMessage" v-bind:max-length="1024"
                                    v-bind:place-holder=" $t('your-message')"></custom-input-text-area>
          </custom-dialog>

          <custom-dialog :id="'confirm-exclude-dialog-profile-others'" ref="confirmExcludeDialogProfileOthers"
                         :on-submit="excludeRecommendation" class="excludeRecommendationDialog"
                         :submit-button-text="$t('hide-recommendation')">
            <span class="header">{{ $t('hide-recommendation') }} </span>
            <div> {{ $t('hide-recommendation-text') }}</div>
          </custom-dialog>

          <custom-dialog :id="'hide-user-dialog-profile-others'" ref="confirmHideUserDialogProfileOthers"
                         :on-submit="hideUser" class="confirmHideUserDialogProfileOthers"
                         :submit-button-text="$t('hide-user')">
            <span class="header">{{ $t('hide-user') }} </span>
            <div> {{ $t('hide-user-text') }}</div>
          </custom-dialog>


          <!-- Edit Appointment Dialog -->
          <custom-dialog :id="'editAppointmentWithoutTablesDialog'" ref="editAppointmentWithoutTablesDialog"
                         class="createAppointmentWithoutTablesDialog" :disable-buttons="true"
                         v-bind:disable-close-button="true">
            <span class="header"> {{ $t('edit-appointment') }}</span>


            <form id="editAppointmentForm" class="editAppointmentForm"
                  v-on:submit.prevent="sendChangeAppointmentRequest()"
                  v-if="appointmentToEdit">


              <custom-input-text v-bind:value.sync="appointmentToEdit.name" :required="true" v-bind:max-length="50"
                                 v-bind:place-holder="$t('title')"></custom-input-text>


              <custom-input-text :required="true" v-bind:value.sync="appointmentToEdit.room"
                                 v-if="!enableRoomAppointments"
                                 v-bind:place-holder="$t('place')"></custom-input-text>
              <div class="appointment-time-duration-container" v-if="!enableRoomAppointments">

                <custom-input-text class="appointment-date-input" v-bind:required="true"
                                   v-bind:value="appointmentToEdit.dateString" v-on:click="showCalendarDialog(appointmentToEdit,true)"
                                   v-bind:place-holder="$t('date')" v-bind:read-only="true"></custom-input-text>


                <custom-input-text class="appointment-time-input" v-bind:required="true"
                                   v-bind:value="appointmentToEdit.timeString" v-on:click="showCalendarDialog(appointmentToEdit,true)"
                                   v-bind:hint="$t('time-zone-hint',{timeZone: timezone(appointmentToEdit.date)})"
                                   v-bind:place-holder="$t('time')" v-bind:read-only="true"></custom-input-text>


                <MBFSelect v-bind:selected-value.sync="appointmentToEdit.duration"
                           v-bind:options="appointmentDurationsInMillis"
                           v-bind:no-selection-allowed="false"
                           v-bind:place-holder="$t('duration')"
                           v-bind:translate-function="translateAppointmentDuration"></MBFSelect>

              </div>


              <div class="appointment-room-time-duration-container" v-if="enableRoomAppointments">

                <MBFSelect :class="{'room-select' : appointmentToEdit.duration > 0}" v-if="enableRoomAppointments" v-bind:options="appointmentRooms"
                           v-bind:selected-value.sync="appointmentToEdit.room_id" v-bind:no-selection-allowed="false"
                           v-bind:option-selector="'id'" v-bind:place-holder="$t('room')"
                           v-bind:translate-function="getDisplayNameForRoom"></MBFSelect>

                <MBFSelect class="timeslot-select" v-if="appointmentToEdit.room_id && appointmentToEdit.duration > 0" v-bind:place-holder="$t('open-hours')"
                           v-bind:options="openHoursForRoom(appointmentToEdit.room_id)"
                           v-bind:selected-value.sync="appointmentToEdit.open_hour" v-bind:no-selection-allowed="false"
                           v-bind:translate-function="getDisplayNameForOpenHour"></MBFSelect>

                <MBFSelect v-if="appointmentToEdit.timeSlots && appointmentToEdit.duration > 0" v-bind:place-holder="$t('duration')"
                           v-bind:options="appointmentDurations" v-on:change="reloadFreeSlotsForRoom"
                           v-bind:selected-value.sync="appointmentToEdit.duration" v-bind:no-selection-allowed="false"
                           v-bind:translate-function="getDisplayNameForDuration"></MBFSelect>

              </div>


              <custom-input-text-area :required="true" v-bind:value.sync="appointmentToEdit.description"
                                      v-bind:place-holder="$t('description')"
                                      v-bind:max-length="512"></custom-input-text-area>

              <div class="createAppointmentWithoutTablesDialogButtons">
                <custom-button :disabled="enableRoomAppointments && !appointmentToEdit.timeSlot"
                               :onClicked="submitSendChangeAppointmentForm" :icon="'fal fa-chevron-right fa-2x'">
                  {{ $t('edit-appointment-send')  }}
                </custom-button>
                <custom-button
                    :onClicked="closeEditAppointmentDialog" :icon="'fal fa-chevron-right fa-2x'">
                  {{ $t('cancel') }}
                </custom-button>
              </div>
              <input style="display: none" id="submitEditAppointment" hidden type="submit">
            </form>
          </custom-dialog>



          <custom-dialog :id="'createAppointmentWithoutTablesDialog'" ref="createAppointmentWithoutTablesDialog"
                         class="createAppointmentWithoutTablesDialog" :disable-buttons="true"
                         v-bind:disable-close-button="true">
            <span class="header"> {{ $t('create-appointment') }}</span>


            <form id="requestAppointmentForm" class="requestAppointmentForm"
                  v-on:submit.prevent="requestAppointment()"
                  v-if="newAppointment">


              <custom-input-text v-bind:value.sync="newAppointment.name" :required="true" v-bind:max-length="50"
                                 v-bind:place-holder="$t('title')"></custom-input-text>


              <custom-input-text :required="true" v-bind:value.sync="newAppointment.room"
                                 v-if="!enableRoomAppointments"
                                 v-bind:place-holder="$t('place')"></custom-input-text>
              <div class="appointment-time-duration-container" v-if="!enableRoomAppointments">

                <custom-input-text class="appointment-date-input" v-bind:required="true"
                                   v-bind:value="newAppointment.dateString" v-on:click="showCalendarDialog(newAppointment)"
                                   v-bind:place-holder="$t('date')" v-bind:read-only="true"></custom-input-text>


                <custom-input-text class="appointment-time-input" v-bind:required="true"
                                   v-bind:value="newAppointment.timeString" v-on:click="showCalendarDialog(newAppointment)"
                                   v-bind:hint="$t('time-zone-hint',{timeZone: timezone(newAppointment.date)})"
                                   v-bind:place-holder="$t('time')" v-bind:read-only="true"></custom-input-text>


                <MBFSelect v-bind:selected-value.sync="newAppointment.duration"
                           v-bind:options="appointmentDurationsInMillis"
                           v-bind:no-selection-allowed="false"
                           v-bind:place-holder="$t('duration')"
                           v-bind:translate-function="translateAppointmentDuration"></MBFSelect>

              </div>


              <div class="appointment-room-time-duration-container" v-if="enableRoomAppointments">

                <MBFSelect :class="{'room-select' : newAppointment.duration > 0}" v-if="enableRoomAppointments" v-bind:options="appointmentRooms"
                           v-bind:selected-value.sync="newAppointment.room_id" v-bind:no-selection-allowed="false"
                           v-bind:option-selector="'id'" v-bind:place-holder="$t('room')"
                           v-bind:translate-function="getDisplayNameForRoom"></MBFSelect>

                <MBFSelect class="timeslot-select" v-if="newAppointment.room_id && newAppointment.duration > 0" v-bind:place-holder="$t('open-hours')"
                           v-bind:options="openHoursForRoom(newAppointment.room_id)"
                           v-bind:selected-value.sync="newAppointment.open_hour" v-bind:no-selection-allowed="false"
                           v-bind:translate-function="getDisplayNameForOpenHour"></MBFSelect>

                <MBFSelect v-if="newAppointment.timeSlots && newAppointment.duration > 0" v-bind:place-holder="$t('duration')"
                           v-bind:options="appointmentDurations" v-on:change="reloadFreeSlotsForRoom"
                           v-bind:selected-value.sync="newAppointment.duration" v-bind:no-selection-allowed="false"
                           v-bind:translate-function="getDisplayNameForDuration"></MBFSelect>

              </div>

              <div class="timeslot-container" v-if="enableRoomAppointments">
                <div class="timeslot" :class="newAppointment.timeSlot === t ? 'isSelected' :''"
                     v-for="t of newAppointment.timeSlots" v-on:click="timeSlotClicked(t)">
                  <span>{{ getDisplayNameForTimeSlot(t, false, displayWithDay) }}</span>
                </div>
              </div>

              <custom-input-text-area :required="true" v-bind:value.sync="newAppointment.description"
                                      v-bind:place-holder="$t('description')"
                                      v-bind:max-length="512"></custom-input-text-area>

              <div class="createAppointmentWithoutTablesDialogButtons">
                <custom-button :disabled="enableRoomAppointments && !newAppointment.timeSlot"
                               :onClicked="submitRequestAppointmentForm" :icon="'fal fa-chevron-right fa-2x'">
                  {{ $t('request-appointment') }}
                </custom-button>
                <custom-button
                    :onClicked="closeCreateAppointmentWithoutTableDialog" :icon="'fal fa-chevron-right fa-2x'">
                  {{ $t('cancel') }}
                </custom-button>
              </div>
              <input style="display: none" id="submitRequestAppointment" hidden type="submit">
            </form>
          </custom-dialog>

          <custom-dialog :id="'addSelfToContactWarningDialog'" ref="addSelfToContactWarningDialog"
                         class="addSelfToContactWarningDialog"
                         :submit-button-text="'OK'">
            <span class="header">  {{ $t('add-to-contacts') }} </span>
            <div> {{ $t('add-own-profile-as-contact-warning') }}</div>
          </custom-dialog>

          <custom-dialog :id="'sendAppointToSelfWarningDialog'" ref="sendAppointToSelfWarningDialog"
                         class="sendAppointToSelfWarningDialog"
                         :submit-button-text="'OK'">
            <span class="header">  {{ $t('request-appointment') }} </span>
            <div> {{ $t('send-appointment-request-to-self-warning') }}</div>
          </custom-dialog>


          <custom-dialog ref="confirmAppointmentDialog" :id="'confirmAppointmentDialog'" :on-submit="confirmAppointment"
                         class="confirmAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                         :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
            <span class="header"> {{ $t('confirm-appointment') }} </span>
            <span> {{ $t('confirm-appointment-warning') }}</span>
          </custom-dialog>


          <custom-dialog ref="rejectAppointmentDialog" :id="'rejectAppointmentDialog'" :on-submit="rejectAppointment"
                         class="rejectAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                         :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
            <span class="header"> {{ $t('reject-appointment') }} </span>
            <span> {{ $t('reject-appointment-warning') }}</span>
          </custom-dialog>

          <custom-dialog ref="cancelAppointmentDialog" :id="'cancelAppointmentDialog'" :on-submit="cancelAppointment"
                         class="cancelAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                         :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
            <span class="header"> {{ $t('cancel-appointment') }} </span>
            <span> {{ $t('cancel-appointment-warning') }}</span>
          </custom-dialog>

        </div>
      </perfect-scrollbar>
    </dialog>

    <!-- Calendar Dialog -->
    <custom-dialog v-bind:disable-close-button="true" v-bind:id="'appointmentCalendarDialog'"
                   v-bind:disable-buttons="true" ref="appointmentCalendarDialog"
                   class="appointmentCalendarDialog">
      <div class="calender-wrapper">
        <span class="calendar-hint" v-html="$t('create-appointment-calendar-hint')"></span>
        <vue-cal active-view="week"
                 v-bind:editable-events="{
                         drag:true,
                         resize:true,
                         delete:false
                       }"
                 v-bind:small="true"
                 v-bind:hideViewSelector="true"
                 v-bind:hideTitleBar="true"
                 v-bind:maxDate="calendarProperties.maxDate"
                 v-bind:minDate="calendarProperties.minDate"
                 :disable-views="['years', 'year','month','day']"
                 :minCellWidth="50" :timeCellHeight="60"
                 :locale="$getCurrentLanguage()"
                 :time-from="calendarProperties.calendarTimeFrom"
                 :time-to="calendarProperties.calendarTimeTo"
                 :selected-date="calendarProperties.selectedDay"
                 :snap-to-time="calendarProperties.snapToTime"
                 :events=" [this.calendarProperties.newAppointment,...this.calendarProperties.appointments]"
                 @cell-click="onCalendarCellClicked"
                 @event-drop="onEventDrop"
                 @event-delete="onEventDelete"
                 @event-duration-change="onEventDurationChange">

          <template #cell-content="{ cell, view, events, goNarrower }">
              <span class="vuecal__cell-date" :class="view.id" v-if="view.id === 'day'" @click="goNarrower">
                {{ cell.date.getDate() }}
              </span>
            <span class="vuecal__no-event"
                  v-if="['week', 'day'].includes(view.id) && !events.length && showCellHeader(cell)"> {{
                $t('no-appointments-calendar')
              }}</span>
          </template>


          <template v-slot:event="{ event, view }">
            <div class="vuecal__event-title">
                    <span>{{ event.start.formatTime("HH:mm") }} - {{
                        event.end.formatTime("HH:mm")
                      }} {{ timezone(event.start) }}</span>
            </div>

          </template>

        </vue-cal>
        <div class="color-legend">
          <div class="color-legend-request">
            <div class="color-legend-request-colour"/>
            <span>{{ $t('appointments-requests') }}</span>
          </div>
          <div class="color-legend-new">
            <div class="color-legend-new-colour"/>
            <span>{{ $t('new-appointment') }}</span>
          </div>
          <div class="color-legend-confirmed">
            <div class="color-legend-confirmed-colour"/>
            <span> {{ $t('confirmed-appointments') }}</span>
          </div>
          <div class="color-legend-blocked">
            <div class="color-legend-blocked-colour"/>
            <span>{{ $t('blocked-appointments') }}</span>
          </div>
        </div>
        <div class="calendar-action-buttons">
          <custom-button :onClicked="onCalendarDialogSubmit" :icon="'fal fa-chevron-right fa-2x'">
            {{ $t('save-time') }}
          </custom-button>
          <custom-button
              :onClicked="closeAppointmentCalendarDialog" :icon="'fal fa-chevron-right fa-2x'">
            {{ $t('cancel') }}
          </custom-button>
        </div>
      </div>
    </custom-dialog>


    <custom-dialog ref="deletedProfileWarningDialog" :id="'deletedProfileWarningDialog'" :on-submit="redirectToHome"
                   class="deletedProfileWarningDialog" v-bind:submit-button-text="$t('dialog-ok')">
      <span class="header"> {{ $t('dialog-error-header') }} </span>
      <span> {{ $t('delete-profile-dialog-text') }}</span>
    </custom-dialog>


    <custom-dialog ref="appointmentSuggestionDialog" :id="'appointmentSuggestionDialog'"
                   :disable-buttons="true" v-bind:disable-close-button="true"
                   class="appointmentSuggestionDialog" v-if="relevantAppointment">
      <span class="header"> {{ $t('appointment-suggestion-dialog-header') }} </span>
      <span v-if="relevantAppointment.timeSlots"> {{
          relevantAppointment.timeSlots.length > 1 ? $t('appointment-suggestion-dialog-text') : $t('appointment-suggestion-dialog-text-single')
        }}</span>
      <div class="timeslot-container" v-if="relevantAppointment.timeSlots" >
        <div class="timeslot" :class="relevantAppointment.timeSlot === t ? 'isSelected' :''"
             v-for="t of relevantAppointment.timeSlots" v-on:click="timeSlotClicked(t,appointmentToEdit !== undefined)">
          <span>{{ getDisplayNameForTimeSlot(t, true) }}</span>
        </div>
      </div>


      <custom-button :onClicked="() => { onAppointmentSuggestionDialogSubmit(relevantAppointment,appointmentToEdit !== undefined) }" :icon="'fal fa-chevron-right fa-2x'">
        {{  $t('request-this-suggestion') }}
      </custom-button>

      <div class="old-appointment-container">
        <span>{{ $t('old-appointment') }}</span>
        <span>{{ getDisplayNameForAppointment(relevantAppointment) }} </span>
      </div>
      <div class="action-buttons">
        <custom-button  :onClicked="()=>{forceAppointmentRequest(appointmentToEdit !== undefined) }" :icon="'fal fa-chevron-right fa-2x'">
          {{ $t('force-appointment-submit') }}
        </custom-button>
        <custom-button :onClicked="closeAppointmentSuggestionDialog" :icon="'fal fa-chevron-right fa-2x'">
          {{ $t('cancel') }}
        </custom-button>
      </div>

    </custom-dialog>

  </div>
</template>

<script>

import Vue from 'vue'
import backend from "../mm-backend";
import localStorageHelper from "../localstorage/local-storage";
import matomo from "../matomo/tracker";
import visibility from 'vue-visibility-change';
import fallbackProfileImage from '@/assets/img/person.svg';
import {addMessageCallback, deleteMessageCallback} from '@/push-backend';
import router from "@/router";
import {appointmentsStorage, countriesStorage, eventBus, logger} from "@/main";
import CustomInputTextArea from "@/components/inputs/CustomInputTextArea";
import ThemesProfileOthers from "@/themes/profile-others/themes-profile-others";
import ThemesOwnProfile from "@/themes/profile-own/themes-own-profile";
import CustomDateTimePicker from "@/components/inputs/CustomDateTimePicker";
import CustomToggle from "@/components/inputs/CustomToggle";
import CustomCheckBox from "@/components/inputs/CustomCheckBox";
import CustomInputText from "@/components/inputs/CustomInputText";
import CustomTimePicker from "@/components/inputs/CustomTimePicker";
import moment from 'moment';
import CustomDatePicker from "@/components/inputs/CustomDatePicker";
import {Appointment} from "@/entities/Appointment";
import {appointmentsMixin} from "@/mixins/appointments-mixin";
import {webServices} from "@/entities/WebServices";
import MBFSelect from "@/components/inputs/MBFSelect";
import {isDefined} from "@/utils";
import {User} from "@/entities/User";
import VueCal from "eyeled-vue-cal";
import 'eyeled-vue-cal/dist/drag-and-drop.es.js'
import 'eyeled-vue-cal/dist/i18n/de.es.js'
import 'eyeled-vue-cal/dist/style.css'

Vue.use(visibility);

export default {
  components: {
    VueCal,
    MBFSelect,
    CustomDatePicker,
    CustomTimePicker,
    CustomInputText,
    CustomCheckBox,
    CustomToggle, CustomDateTimePicker, ThemesOwnProfile, ThemesProfileOthers, CustomInputTextArea
  },
  mixins: [appointmentsMixin],
  data() {
    return {
      isLoading: true,
      opened: false,
      fallbackProfileImageForTemplate: fallbackProfileImage,
      userData: {},
      contactMessage: undefined,
      active_days: {},
      lang: this.$getCurrentLanguage(),
      deviceId: localStorageHelper.getDeviceId(),
      loaded: undefined,
      contact_confirmed: false,
      is_recommendation: undefined,
      shared_contacts: [],
      ownProfile: false,
      scrollOptions: {suppressScrollX: true},
      disableCloseButton: false,
      webServices: webServices,
      countryList: countriesStorage.getCountryList(),
      appointmentSuggestions: []
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.userData.id && vm.$route.params.userId && !vm.$route.path.startsWith("/chat/")) {
        vm.loadUser();
      }
      addMessageCallback(vm.handlePush);
    });
  },
  beforeMount() {
    eventBus.$on('reload-appointment-list', this.getAllAppointments);
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
    eventBus.$off('reload-appointment-list', this.getAllAppointments);
    deleteMessageCallback(this.handlePush);
    let div = document.querySelector(`#backdrop-profileOthers`);
    if (div) {
      div.parentNode.removeChild(div);
    }
  },
  computed: {
    appointmentRooms() {
      return appointmentsStorage.getRooms();
    },
  },
  methods: {

    closeAppointmentCalendarDialog() {
      if (isDefined(this.$refs.appointmentCalendarDialog)) {
        this.disableCloseButton = false;
        this.$refs.appointmentCalendarDialog.closeDialog();
      }
    },
    computeNewAppointmentProperties() {
      this.computeCalendarProperties()
      const selectedDay = this.calendarProperties.selectedDay;

      const propsForDay = this.calendarProperties.days.find(d => d.date === selectedDay);
      const defaultAppointmentDurationAsMillis = this.defaultAppointmentDuration * 60 * 1000;
      let startDate = moment.utc(selectedDay, 'YYYY-MM-DD').tz(process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(propsForDay.minTime, 'minutes');
      let endDate = moment.utc(startDate).add(defaultAppointmentDurationAsMillis, 'milliseconds');
      let endForDay = moment.utc(selectedDay, 'YYYY-MM-DD').tz(process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(propsForDay.maxTime, 'minutes');



      for (const event of this.calendarProperties.appointments) {
        const appointment = event.appointment;
        const appointmentStart = moment.utc(appointment.from, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        const appointmentEnd = moment.utc(appointment.to, 'YYYY-MM-DD HH:mm').tz(process.env.VUE_APP_GROUPS_TIMEZONE);

        while ((startDate.isSameOrAfter(appointmentStart) && startDate.isBefore(appointmentEnd)) || (endDate.isAfter(appointmentStart) && startDate.isBefore(appointmentStart))) {
          startDate = moment(appointmentEnd).tz(process.env.VUE_APP_GROUPS_TIMEZONE);
          while (moment(startDate).add(defaultAppointmentDurationAsMillis, 'milliseconds').isAfter(endForDay)) {
            //Start is after open_hour for that day. go to next open_hour
            const findNextDay = this.calendarProperties.days.findIndex(d => d.date === moment(startDate).add(1, 'day').format('YYYY-MM-DD'));
            if (findNextDay > -1) {
              const day = this.calendarProperties.days[findNextDay];
              startDate = moment.utc(day.date).tz(process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(day.minTime, 'minutes');
              endForDay = moment.utc(day.date).tz(process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(day.maxTime, 'minutes');
            } else {
              //No free hours available. go back to Default
              startDate = moment.utc(selectedDay, 'YYYY-MM-DD').tz(process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(propsForDay.minTime, 'minutes');
              endForDay = moment.utc(selectedDay, 'YYYY-MM-DD').tz(process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(propsForDay.maxTime, 'minutes');
              break;
            }
          }
          endDate = moment.utc(startDate).add(defaultAppointmentDurationAsMillis, 'milliseconds').tz(process.env.VUE_APP_GROUPS_TIMEZONE)
        }
      }


      this.newAppointment = {
        name: undefined,
        room: undefined,
        description: undefined,
        date: startDate.format('YYYY-MM-DD'),
        dateString: startDate.format('YYYY-MM-DD'),
        duration: defaultAppointmentDurationAsMillis,
        time: (startDate.hours() * 60 * 60 * 1000) + (startDate.minutes() * 60 * 1000),
        timeString: startDate.format('HH:mm'),
        timeSlots: [],
        timeSlot: undefined
      };
    },
    openDialog(user, isRecommendation) {
      if (isDefined(user)) {
        this.isLoading = true;
        this.$refs.scroll.$el.scrollTop = 0;
        const localUser = localStorageHelper.getUser();
        this.ownProfile = isDefined(localUser) && localUser.id === user.id;

        let dialog = document.getElementById('profileOthers');
        if (!dialog.hasAttribute('open')) {
          dialog.setAttribute('open', 'open');
          let div = document.createElement('div');
          div.id = `backdrop-profileOthers`;
          div.className = "backdrop-profileOthersDialog";
          document.body.appendChild(div);
        }
        this.opened = true;
        this.userData = user;
        if (this.userData.match_confirmed) {
          this.loadSharedContacts();
        }
        this.is_recommendation = isRecommendation;
        matomo.trackDetailView(this.userData.id);
        document.body.style.overflow = 'hidden';
        this.getAppointmentsForUser();
        this.isLoading = false;
      }
    },
    getAllAppointments() {
      const self = this;
      this.isLoading = true;
      backend.getAllAppointments().then(resp => {
        this.postLoadAppointments(resp.data);
        self.isLoading = false;
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('get-appointments-error')});
        self.isLoading = false;
      });
    },
    postLoadAppointments(data) {
      const appointments = data.appointments;
      const users = data.users;
      const events = data.events;
      appointmentsStorage.setAppointments(appointments);
      appointmentsStorage.setUsers(users);
      appointmentsStorage.setEvents(events);

      if (isDefined(data.rooms)) {
        appointmentsStorage.setRooms(data.rooms);
      }
      eventBus.$emit('update-appointment-requests', Appointment.getReceivedRequests(appointments, this.myUserId));
      this.getAppointmentsForUser();
    },
    getAppointmentsForUser() {
      let relevantAppointments = [];
      for (const appointment of appointmentsStorage.getAppointments()) {
        if (Appointment.isParticipant(appointment, this.userData.id) || +appointment.requesting_user === +this.userData.id) {
          relevantAppointments.push(appointment);
        }
      }
      relevantAppointments = relevantAppointments.sort((a, b) => moment(new Date(a.from)).format('YYYYMMDD') - moment(new Date(b.from)).format('YYYYMMDD'));
      this.appointments = relevantAppointments;
    },
    animationEnd() {
      this.$nextTick(() => {
        this.$refs.scroll.ps.scrollbarY.parentNode.style.opacity = 0.6;
        this.$refs.scroll.update();
        let self = this;
        setTimeout(function () {
          if (self.$refs.scroll) {
            self.$refs.scroll.ps.scrollbarY.parentNode.style.removeProperty('opacity');
          }
        }, 1000);
      });
    },
    redirectToHome() {
      router.push({name: 'Home'});
    },
    closeProfile(withRedirect) {
      this.$refs.scroll.$el.scrollTop = 0;
      this.$refs.confirmExcludeDialogProfileOthers.closeDialogFromOutside();
      this.$refs.confirmHideUserDialogProfileOthers.closeDialogFromOutside();
      this.$refs.addContactDialog.closeDialogFromOutside();
      this.$refs.createAppointmentWithoutTablesDialog.closeDialogFromOutside();
      this.$refs.appointmentCalendarDialog.closeDialogFromOutside();
      if (isDefined(this.$refs.appointmentSuggestionDialog)) {
        this.$refs.appointmentSuggestionDialog.closeDialogFromOutside();
      }
      if (isDefined(this.$refs.editAppointmentWithoutTablesDialog)) {
        this.$refs.editAppointmentWithoutTablesDialog.closeDialogFromOutside();
      }
      let dialog = document.getElementById('profileOthers');
      dialog.removeAttribute('open');
      let div = document.querySelector(`#backdrop-profileOthers`);
      if (div) {
        div.parentNode.removeChild(div);
      }
      this.opened = false;
      this.userData = undefined;
      this.appointmentToEdit = undefined;
      this.newAppointment = undefined;
      document.body.style.overflow = 'auto';

      const regex = /^[\/profile\/]+[0-9]+/;
      if (withRedirect && regex.test(this.$route.path.toLowerCase())) {
        this.redirectToHome();
      }
    },
    loadUser() {
      let self = this;
      let userId = self.$route.params.userId;
      if (!userId && this.userData) {
        userId = this.userData.id;
      }
      backend.user(userId).then(resp => {
        if (resp.data.error) {
          if (resp.data.error.code === 20) {
            this.$refs.deletedProfileWarningDialog.openDialog();
          }
        } else {
          self.openDialog(resp.data, false);
        }
      });
    },
    loadSharedContacts() {
      let self = this;
      backend.getSharedContacts(this.userData.id).then(resp => {
        self.shared_contacts = resp.data.shared_contacts;
        self.shared_contacts.forEach(contact => contact.match_confirmed = true);
      });
    },
    getImage() {
      return this.userData.photo ? this.userData.photo : fallbackProfileImage;
    },
    onProfileImageError(e) {
      if (isDefined(e) && isDefined(e.target)) {
        e.target.src = fallbackProfileImage;
      }
    },
    validUrl(url, service) {
      if (url) {
        if (!(url.startsWith("http://") || url.startsWith("https://"))) {
          if (service !== undefined && (url.startsWith("@") || !url.includes("/"))) {
            if (url.startsWith("@")) {
              return `https://www.${service}.com/${url.substr(1)}`;
            }
            return `https://www.${service}.com/${url}`;
          }
          return `https://${url}`;
        } else {
          return url;
        }
      }
      return url;
    },
    goToChat() {
      this.$router.push({name: "Chat", params: {userId: this.userData.id}});
      this.closeProfile();
    },
    unconfirmedContact() {
      return !this.userData.match_confirmed && this.userData.contactUrl;
    },
    confirmContact() {
      const self = this;
      self.isLoading = true;
      backend.confirmContactRequest(this.userData.id).then(resp => {
        self.isLoading = false;
        if (isDefined(resp.data.error)) {
          self.$toasted.global.error_notification({message: resp.data.error});
        } else {
          self.$toasted.global.success_notification({message: resp.data.message});
          self.contact_confirmed = true;
          self.userData.match_confirmed = true;
        }
      });
    },
    ignoreContact() {
      const self = this;
      self.isLoading = true;
      backend.ignoreContactRequest(this.userData.id).then(resp => {
        self.isLoading = false;
        if (resp.data.error) {
          self.$toasted.global.error_notification({message: resp.data.error});
        } else {
          self.$toasted.global.success_notification({message: resp.data.message});
          self.userData.match_ignored = true;
          eventBus.$emit('ignore-contact', this.userData.id);
        }
      });
    },
    videoChatEnabled() {
      return process.env.VUE_APP_CHAT_URL && this.userData.isVideoChatEnabled() && this.userData.match_confirmed && this.userData.video_chat_enabled;
    },
    goToVideoChat() {
      document.getElementById('profileCloseButtonIcon').style.opacity = '0';
      eventBus.$emit('open-video-chat', this.userData);
    },
    openAddContactDialog() {
      const localUser = localStorageHelper.getUser();

      if (!isDefined(localUser)) {
        const self = this;
        backend.me().then(function (resp) {
          if (resp.data.confirmed) {
            self.openAddContactDialogSub(resp.data.user)
          }
        });
      } else {
        this.openAddContactDialogSub(localUser);
      }
    },
    isOwnUser() {
      const localUser = localStorageHelper.getUser();
      if (!isDefined(localUser)) {
        backend.me().then(function (resp) {
          if (resp.data.confirmed) {
            return resp.data.user.id === this.userData.id;
          }
        });
      } else {
        return localUser.id === this.userData.id;
      }
    },
    openAddContactDialogSub(localUser) {
      if (this.userData.id === +this.$route.params.userId || this.userData.id === localUser.id) {
        this.$refs.addSelfToContactWarningDialog.openDialog();
      } else {
        this.$refs.addContactDialog.openDialog();
      }
    },
    addToContacts() {
      const self = this;
      self.isLoading = true;
      backend.sendContactRequest(this.userData.id, this.contactMessage ? this.contactMessage : '').then(function (response) {
        if (response.data.error) {
          self.isLoading = false;
          self.$toasted.global.error_notification({message: response.data.error.message});
        } else {
          self.isLoading = false;
          self.userData.match_requested = true;
          self.$forceUpdate();
        }
      });
    },
    getButtonText() {
      if ((this.userData.match_requested) && !this.userData.match_confirmed) {
        return this.$t("contact_message_send");
      }
      if (this.userData.match_confirmed) {
        return this.$t("to-chat");
      } else {
        return this.$t("add-to-contacts");
      }
    },
    visibilityChange: function (evt, hidden) {
      if (!hidden && this.contact_confirmed) {
        this.contact_confirmed = false;
        this.loadUser();
      }
    },
    hideRecommendation() {
      this.$refs.confirmExcludeDialogProfileOthers.openDialog();
    },
    openHideUserDialog() {
      this.$refs.confirmHideUserDialogProfileOthers.openDialog();
    },
    hideUser() {
      const self = this;
      backend.hideUser(this.userData.id).then(resp => {
        self.$toasted.global.success_notification({message: self.$t('hide-user-success')});
        this.closeProfile();
        self.$root.$emit('hide_user');
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('hide-user-error')});
      });
    },
    unHideUser() {
      const self = this;
      backend.unHideUser(this.userData.id).then(resp => {
        self.$toasted.global.success_notification({message: self.$t('unhide-user-success')});
        this.closeProfile();
        self.$root.$emit('unhide_user');
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('unhide-user-error')});
      });
    },
    excludeRecommendation() {
      const self = this;
      backend.excludeRecommendation(this.userData.id).then(resp => {
        self.$toasted.global.success_notification({message: self.$t('exclude-recommendation-success')});
        self.is_recommendation = false;
        self.$root.$emit('recommendations_updated');
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('exclude-recommendation-error')});
      });
    },
    handlePush: function (payload) {
      if (payload.data && payload.data.type === 'confirm' && this.userData.id !== undefined && payload.data.peer_id /* String */ === this.userData.id.toString() /* int */) {
        this.loadUser();
      }
      return false;
    },
    switchUser(user) {
      this.contactMessage = undefined
      this.openDialog(user, false);
    },
    hasValidRooms() {
      if (!isDefined(appointmentsStorage.getRooms()) || appointmentsStorage.getRooms().length === 0) {
        return false;
      }
      for (const room of appointmentsStorage.getRooms()) {
        if (room.hasValidOpenHours()) {
          return true;
        }
      }
      return false;
    },
    openCreateAppointmentDialog() {
      this.appointmentToEdit = undefined;
      if (isDefined(this.userData) && +this.userData.id === +User.me()) {
        this.$refs.sendAppointToSelfWarningDialog.openDialog();
        return;
      }

      if (this.enableRoomAppointments) {
        if (appointmentsStorage.getRooms().length === 0) {
          // Show error dialog
          logger.log("TODO show error dialog")
        } else if (appointmentsStorage.getRooms().length === 1) {
          const room = appointmentsStorage.getRooms()[0];
          const open_hours_start = room.open_hours[0];
          const open_hours_end = room.open_hours[room.open_hours.length - 1];
          // if room has an assigned duriation, we must not send a custom duration
          const durationInMillis = isDefined(open_hours_start.duration) && open_hours_start.duration > 0 ? 0 : this.defaultAppointmentDuration * 60 * 1000;
          this.isLoading = true;
          backend.findFreeSlotsForRoom(this.userData.id, room.id, open_hours_start.from, open_hours_end.to, durationInMillis).then(resp => {
            this.isLoading = false;
            if (isDefined(resp.data.error)) {
              this.$toasted.global.error_notification({message: resp.data.error.message});
            } else if (resp.data.length === 0) {
              logger.log("Show Error Dialog");
            } else {
              this.createEmptyAppointmentForRoom(appointmentsStorage.getRooms()[0], resp.data);
              this.$refs.createAppointmentWithoutTablesDialog.openDialog();
            }
          });
        }
      } else {
        const durationsInMillis = []
        for (let duration of this.appointmentDurations) {
          durationsInMillis.push(duration * 60 * 1000)
          this.appointmentDurationsInMillis = durationsInMillis;
        }
        this.computeNewAppointmentProperties();
        this.$refs.createAppointmentWithoutTablesDialog.openDialog();
      }
    },
    reloadFreeSlotsForRoom() {
      this.$refs.createAppointmentWithoutTablesDialog.showLoadingSpinner();
      const room = appointmentsStorage.getRooms()[0];
      const open_hours_start = room.open_hours[0];
      const open_hours_end = room.open_hours[room.open_hours.length - 1];
      const durationInMillis = this.newAppointment.duration * 60 * 1000;

      backend.findFreeSlotsForRoom(this.userData.id, room.id, open_hours_start.from, open_hours_end.to, durationInMillis).then(resp => {
        this.$refs.createAppointmentWithoutTablesDialog.closeLoadingSpinner();
        if (isDefined(resp.data.error)) {
          this.newAppointment.timeSlots = [];
          this.newAppointment.timeSlot = undefined;
          this.$toasted.global.error_notification({message: resp.data.error.message});
        } else if (resp.data.length === 0) {
          logger.log("Show Error Dialog");
        } else {
          this.newAppointment.timeSlots = resp.data;
          this.newAppointment.timeSlot = resp.data[0];
        }
      });
    },
    closeCreateAppointmentWithoutTableDialog() {
      this.$refs.createAppointmentWithoutTablesDialog.closeDialogFromOutside();
    },
    requestAppointment(force) {
      const self = this;
      const copy = JSON.parse(JSON.stringify(this.newAppointment));
      copy.other = this.userData.id;


      if (!isDefined(this.newAppointment.name) || this.newAppointment.name.trim().length === 0) {
        this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('title')})});
        return;
      }

      if (!isDefined(this.newAppointment.description) || this.newAppointment.description.trim().length === 0) {
        this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('description')})});
        return;
      }

      if (isDefined(copy.room_id)) {
        const timeSlot = copy.timeSlot;
        if (!isDefined(timeSlot)) {
          return;
        }
        copy.date = timeSlot.from;
        if (!isDefined(copy.duration) || copy.duration === 0) {
          const from = moment(timeSlot.from, 'YYYY-MM-DD HH:mm:ss');
          const to = moment(timeSlot.to, 'YYYY-MM-DD HH:mm:ss');
          copy.duration = to.diff(from);
        } else {
          copy.duration = copy.duration * 60 * 1000;
        }
      } else {
        delete copy.room_id;
        if (!isDefined(this.newAppointment.room) || this.newAppointment.room.trim().length === 0) {
          this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('place')})});
          return;
        }
        if (!this.validateAppointmentTimes(copy)) {
          return;
        }
        copy.date = moment.tz(this.newAppointment.date, 'YYYY-MM-DD', process.env.VUE_APP_GROUPS_TIMEZONE).startOf('day').add(this.newAppointment.time, 'milliseconds').utc().format('YYYY-MM-DD HH:mm');
      }

      delete copy.time;
      delete copy.open_hour;
      delete copy.timeSlots;
      delete copy.timeSlot;
      delete copy.timeString;
      delete copy.dateString;

      if (isDefined(force)) {
        copy.force = true
      }

      this.$refs.createAppointmentWithoutTablesDialog.showSpinner = true;
      backend.suggestAppointment(copy, this.userData.id).then(resp => {
        self.$refs.createAppointmentWithoutTablesDialog.showSpinner = false;
        if (resp.data.error) {
          self.$toasted.global.error_notification({message: resp.data.error.message});
        } else {
          if (isDefined(resp.data.suggestions)) {
            self.newAppointment.timeSlot = resp.data.suggestions[0];
            self.newAppointment.timeSlots = resp.data.suggestions;
            if (isDefined(this.$refs.appointmentSuggestionDialog)) {
              self.$refs.appointmentSuggestionDialog.openDialog();
            }
          } else {
            self.closeCreateAppointmentWithoutTableDialog();
            self.postLoadAppointments(resp.data);
            self.$toasted.global.success_notification({message: self.$t('appointment-request-send')});
          }
        }
      }, error => {
        self.$refs.createAppointmentWithoutTablesDialog.showSpinner = false;
        self.$toasted.global.error_notification({message: self.$t('appointment-request-send-error')});
      });
    },
    createEmptyAppointmentForRoom(room, timeSlots) {
      this.newAppointment = {
        name: undefined,
        room_id: room.id,
        duration: isDefined(room.open_hours[0].duration) && room.open_hours[0].duration > 0 ? 0 : this.defaultAppointmentDuration,
        open_hour: room.open_hours[0],
        timeSlots: timeSlots,
        timeSlot: timeSlots[0],
        description: undefined
      };
    },
    submitRequestAppointmentForm() {
      document.getElementById('submitRequestAppointment').click();
    },
    getDisplayNameForCountryByISO(iso) {
      const country = this.countryList.find(c => c.iso_2 === iso);
      if (isDefined(country)) {
        return country.getDisplayNameByLanguage(this.$getCurrentLanguage());
      }
      return '';
    }
  }
}

</script>

<style lang="less">


.createAppointmentWithTablesDialog {
  .slot-select {
    width: 80%;
  }
}

.dialog--open {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: anim-open;
  -webkit-animation-name: anim-open;
  display: block !important;
}

@-webkit-keyframes anim-open {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
  }
}


@keyframes anim-open {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.profileOthersDialog {
  z-index: 101;
  width: calc(100% - 124px);
  max-width: 1300px;
  border: none;
  background: #FFFFFF;
  position: fixed;
  align-self: center;
  left: 0;
  right: 0;
  margin: auto;
  padding: 16px;
  display: none;
  top: 10%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  .close-button {
    position: absolute;
    margin-left: calc(100%);
    margin-top: -60px;
    z-index: 90;
    cursor: pointer;
  }

  .profileOthersContent {
    height: 85vh;
  }
}

.add-contact-dialog-hint {
  margin: 1em 0;
}

.confirmHideUserDialogProfileOthers {
  .dialog-content {
    > div {
      text-align: left;
    }
  }
}

.addContactDialog, .excludeRecommendationDialog, .addSelfToContactWarningDialog, .confirmHideUserDialogProfileOthers, .confirmUnhideUserDialogProfileOthers,
.createAppointmentWithoutTablesDialog, .sendAppointToSelfWarningDialog, .cancelAppointmentDialog, .rejectAppointmentDialog, .confirmAppointmentDialog, .videoCallDisabledDialog, .appointmentCalendarDialog, .appointmentSuggestionDialog {
  border: 1px solid var(--border-color) !important;

  .close-button {
    div::before, div::after {
      background-color: var(--border-color) !important;
    }
  }
}

.appointmentSuggestionDialog {
  z-index: 151 !important;

  .dialog-content {

    .old-appointment-container {
      display: flex;
      flex-direction: column;
      margin: 24px 0 12px 0;
      align-items: flex-start;
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

}



.createAppointmentWithoutTablesDialog, .appointmentSuggestionDialog {


  .timeslot-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 12px 0;

    .timeslot {
      border: 1px solid var(--secondary-color);
      height: 40px;
      margin: 0 10px 10px 0;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;

      > span {
        padding: 5px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--font-color);
      }
    }

    .isSelected {
      background-color: var(--secondary-color);

      > span {
        color: var(--topic-text-color);
      }
    }
  }
}

.createAppointmentWithoutTablesDialog {
  width: 1200px !important;


  .requestAppointmentForm,.editAppointmentForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .createAppointmentWithoutTablesDialogButtons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .input-wrapper {
      width: 100% !important;
      margin-bottom: 20px;
    }

    .appointment-time-duration-container {
      display: flex;
      width: 100%;
      flex-direction: row;

      .appointment-date-input, .appointment-time-input {
        margin-right: 12px;
      }

      .appointment-time-input:last-of-type {
        margin-right: 0;
      }
    }

    .appointment-room-time-duration-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .room-select, .timeslot-select {
        margin-right: 12px;
      }
    }
  }
}


.close-button-container {
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: center;

}

.profileOthersDialog::-webkit-backdrop, .backdrop-profileOthersDialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  opacity: 0.9;
  z-index: 100;
}


.same-contacts {
  width: 100%;
  margin: 30px 0 0 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;


  .same-contacts-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .button-wrapper {

    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.userDataContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: left;
  margin-bottom: 40px;

  .profile-image {
    width: 50%;
    max-width: 350px;
    max-height: 350px;
    height: auto;
    object-fit: contain;
  }

  .userData {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 60px;

    .userNameContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .roleName {
        margin-left: 25px;
      }


      .fullName {
        color: var(--fullname-color);
        margin: 0;
        font-size: 50px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 60px;
        word-break: break-all;
      }
    }

    .position-orga-wrapper {
      padding-top: 10px;

      .position {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
      }

      .orga {
        color: #333333;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 30px;
      }
    }

    .status-wrapper {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .status-title {
        color: #58585A;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }

      .status {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        width: 100%;
        text-align: left;
        padding-top: 5px;
        font-style: italic;
      }
    }

    .country-wrapper,
    .email-wrapper,
    .url-wrapper,
    .linkedin-wrapper,
    .twitter-wrapper,
    .facebook-wrapper,
    .instagram-wrapper,
    .phone-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      > a {
        word-break: break-all;
      }

      .linkedin {
        padding-left: 4px;
        padding-right: 3px;
      }

      .country,
      .url,
      .phone,
      .email {
        padding-left: 10px;
        text-decoration: none;
        color: #58585A;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }

      > i {
        color: var(--icon-color);
      }
    }
  }
}

.fa-linkedin {
  font-size: 1.5em;
  margin-left: -4px;
  margin-right: -3px;
}

.appointment-title {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  font-weight: bold;
}

.profileOthersContent .custom-button {
  width: 300px;
}
</style>
