import firebase from 'firebase/app'
import 'firebase/messaging'
import axios from 'axios';
import {logger} from "@/main";

var firebaseConfig = {
    apiKey: "AIzaSyAMqNeRVAEK-PVYEn9dBuax34Y0vmhjdyw",
    authDomain: "frankfurter-buchmesse-215114.firebaseapp.com",
    databaseURL: "https://frankfurter-buchmesse-215114.firebaseio.com",
    projectId: "frankfurter-buchmesse-215114",
    storageBucket: "frankfurter-buchmesse-215114.appspot.com",
    messagingSenderId: "444374624125",
    appId: "1:444374624125:web:17f0a5384bfc16dfb18e14"
};

var messaging = null;
var userData = null;

if (firebase.messaging.isSupported()) {
    firebase.initializeApp(firebaseConfig);

    messaging = firebase.messaging();

    // Add the public key generated from the console here.
    messaging.usePublicVapidKey("BH7pxq1Im5-h1tWI8boJkwkvOsjzKpkH7ns0d1S_e8dhaA-4S9IiwKCrfJyNcLa7Nb8soK2znZVWWDej-N1PqxI");

    if (process.env.VUE_APP_PUSH_ENDPOINT != null && process.env.VUE_APP_PUSH_ENDPOINT.length > 0 && 'serviceWorker' in navigator) {
        navigator.serviceWorker.register(process.env.BASE_URL + 'firebase-messaging-sw.js', { scope: "/" }).then(function(reg) {
            // Registrierung erfolgreich
            logger.log('Messaging service worker registration successful. Scope is ' + reg.scope);

            messaging.useServiceWorker(reg);

        }).catch(function(error) {
            // Registrierung fehlgeschlagen
            logger.log('Registrierung fehlgeschlagen mit ' + error);
        });
        navigator.serviceWorker.ready.then(function(registration) {
            logger.log("service worker ready");
            if (userData) {
                setUserData(userData);
            }
        });
    }
}

function setUserData(data) {
    if ('serviceWorker' in navigator) {
        if (navigator.serviceWorker.controller) {
            logger.log('sending user data: ' + JSON.stringify(data));
            navigator.serviceWorker.controller.postMessage( { cmd: 'user', data: data} );
        } else {
            userData = data;
        }
    } else {
        // console.log('service worker not available');
    }

}

export {
    messaging,
    setUserData
}
