<template>

  <div v-if="category.has_selected_search || category.selected_as_search"
       :class="!allSelected ? 'not-all-selected-box':''">

    <!-- No selected entries header-->
    <div v-if="category.children && !category.has_selected_search && category.id === parent.id"
         class="themes-category-header">
      <h5 class="themes-category-header-text">{{ category.title }}</h5>
      <h3 class="none-selected-text">{{ $t("none-selected") }}</h3>
    </div>

    <div class="themes-category-entry" v-if="allSelected">
      <div :title="category.title">{{ category.title }} - {{ $t('all-others') }} </div>
    </div>

    <!-- Title of Root Category  -->
    <h5 v-if=" category.has_selected_search && step === 0">
      {{ category.title }}
      <div v-if="canEditCategory(category)" v-on:click="onTitleClicked(getTabIdForCategory(category,'search'))"
           class="edit-themes-button">
        {{ $t('themes-edit') }}<i class="fas fa-pen edit-themes-icon" :title="$t('change-themes-button-text')"/>
      </div>
    </h5>


    <!-- Title of Child Category example  Continents of Countries   -->
    <hr class="line-break" v-if="(category.has_selected_search && step !== 0 &&  !allSelected) ">
    <div class="themes-category-entry"
         v-if="!allSelected && category.has_selected_search && step !== 0">
      <div>{{ category.title }}</div>
    </div>


    <!-- Entries -->
    <div class="themes-category-entry"
         v-if="!allSelected && category.selected_as_search && step !== 0">
      <div>{{ category.title }}</div>
    </div>


    <div
        :class="[step === 0 ? 'combined-topic-container' : category.children ? 'active-topic-container' : '', category.children ? `step-${step}` : '']">

      <themes-category-search
          v-if="category.children && category.children.length > 0 && category.has_selected_search && !allSelected"
          v-for="child in category.children"
          v-bind:category="child" v-bind:parent="parent" v-bind:step="step+1" :key="child.id"></themes-category-search>


    </div>
  </div>
</template>

<script>


import {profileThemeCategoriesMixin} from "@/mixins/profile-theme-categories-mixin";
import themes from "@/themes/themes-helper";

export default {
  name: "ThemesCategorySearch",
  mixins: [profileThemeCategoriesMixin],
  props: {
    category: Object,
    parent: Object,
    step: Number
  },
  data() {
    return {
      allSelected: undefined
    }
  },
  watch: {
    category: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          if (newVal.id !== this.parent.id) {
            this.allSelected = this.allEntriesSelected();
          }
        }
      }
    }
  },
  methods: {
    getSelectedAs() {
      return 'selected_as_search';
    }

  }
}
</script>
