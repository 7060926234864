<template>
  <div class="main companies">
    <div class="side-header">{{ $t('recommendations-header') }}</div>

    <div class="search-and-sort-input-wrapper">
      <div class="search-input">
        <input v-model="searchValue" v-on:input="search" type="text" :placeholder="$t('search')">
        <i class="fal fa-search fa-lg"></i>
        <div v-if="searchValue && searchValue.length > 0 && filterEnabled" class="search-warning"><i
            class="fal fa-exclamation-triangle"></i> {{ $t('filter-active') }}
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="companieslist organisations vld-parent">

        <loading :active.sync="isLoading"
                 :is-full-page="false"
                 color="var(--primary-color)">
        </loading>


        <div class="empty-recommendations-header"
             v-if="limitedRecommendations.length === 0 && limitedCompanies.length === 0 && !isLoading">
          <h3> {{
              filterEnabled ? $t('empty-filter-results') : searchValue ? $t("empty-search-results") :
                  $t("no-recommendations-available")
            }}</h3>
        </div>


        <div v-if="limitedRecommendations.length > 0" class="recommendations-header">
          <h3>{{ recommendationsTitle }} </h3>
        </div>
        <transition-group name="list" tag="div"
                          v-bind:css="false"
                          v-on:before-enter="animationBeforeEnter"
                          v-on:enter="animationEnter"
                          v-on:leave="animationLeave">
          <companies-card v-for="(company,index) in limitedRecommendations" :company="company" :key="company.id"
                          v-bind:data-index="index"
                          :hide-recommendation="hideRecommendation" :is-recommendation="true"></companies-card>
        </transition-group>

        <!-- div v-if="limitedCompanies.length > 0"
             class="networker-header">
          <h3>
            <div v-if="limitedRecommendations.length > 0"> {{ networkersTitle }}</div>
          </h3>
        </div -->

        <transition-group name="list" tag="div"
                          v-bind:css="false"
                          v-on:before-enter="animationBeforeEnter"
                          v-on:enter="animationEnter"
                          v-on:leave="animationLeave">
          <companies-card v-for="(company,index) in limitedCompanies" :company="company" :key="company.id"
                          v-bind:data-index="index"
                          :is-recommendation="false" :show-matching-scala="false"></companies-card>
        </transition-group>

        <div class="paginator-wrapper">
          <div class="paginator"
               v-if="!isLoading && (this.limitedCompanies.length !== 0 || this.limitedRecommendations.length !== 0) ">

            <custom-select
                :suffix="$t('entries-per-page')"
                :options="entriesPerPageOptions"
                :default="entriesPerPage"
                v-on:input="entriesPerPage=$event; get()">
            </custom-select>
            <div class="page-count">
              <span>{{ $t('page') }} {{ currentPage }} {{ $t('of') }}  {{ maxPages }}</span>
              <div class="arrows">
                <i v-on:click="showPreviousPage" class="fal fa-chevron-left fa-2x left-arrow "></i>
                <i v-on:click="showNextPage" class="fal fa-chevron-right fa-2x right-arrow"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <custom-dialog :id="'confirm-exclude-dialog'" ref="confirmExcludeDialog" :on-submit="excludeRecommendation"
                   :submit-button-text="$t('dialog-ok')">
      <span class="header">  {{ $t('hide-recommendation') }} </span>
      <div> {{ $t('hide-recommendation-text-companies') }} </div>
    </custom-dialog>
  </div>
</template>

<script>
import backend from "@/mm-backend";
import MatchScale from "@/components/MatchScale";
import {companiesMixIn} from "@/mixins/companies-mixin";
import axios from "axios";
import localStorageHelper from "@/localstorage/local-storage";
import {logger} from "@/main";

export default {
  mixins: [companiesMixIn],
  name: "Companies",
  data() {
    return {
      entriesPerPage: 12,
      entriesPerPageOptions: [12, 36, 72, 96],
    }
  },
  components: {
    MatchScale
  },
  computed: {
    limitedRecommendations() {
      return this.recommendations.slice((this.currentPage - 1) * this.entriesPerPage, this.currentPage * this.entriesPerPage);
    },
    limitedCompanies() {
      let startIndex = (this.currentPage - 1) * this.entriesPerPage;
      // We only show recommendations on the first page
      if (this.currentPage > 1) {
        startIndex = startIndex - this.recommendations.length;
      }
      let maxIndex = (this.currentPage * this.entriesPerPage) - this.recommendations.length;

      return this.companies.slice(startIndex, maxIndex);
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.beforeGet();
      if (this.searchValue && this.searchValue.trim() !== '') {
        this.search();
      } else {
        this.isLoading = true;
        let self = this;
        this.getObservable(this.params).then(function (resp) {
          self.currentPage = 1;
          self.setDataFromMatchingResponse(resp);
          self.isLoading = false; //loading finished
          self.afterGet();
        }, error => {
          self.$toasted.global.error_notification({message : 'Could not load Organisations'});
          self.isLoading = false;
        });
      }
    },
    beforeGet() {
      this.getObservable = backend.getCompanies.bind(backend);
      this.params = {
        pageSize: this.maxPagesToDisplay * this.entriesPerPage,
        page: this.currentPageForBackend,
      };
    },
    afterGet() {
      // TODO
      //  matomo.trackContactList();
    },
    search() {
      // Clears Timeout so that we dont send multiple request if the user writes
      window.clearTimeout(this.searchTimer);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      for (let source of this.requestCancelTokensSearch) {
        source.cancel('Request Canceled');
      }
      this.requestCancelTokensSearch.push(source);
      if (this.searchValue && this.searchValue !== '') {
        this.params['search'] = this.searchValue;
      } else {
        this.params['search'] = undefined;
      }
      this.params['page'] = this.currentPageForBackend;
      let self = this;

      this.searchTimer = window.setTimeout(function () {
        self.isLoading = true;
        self.getObservable(self.params, source.token).then(function (resp) {
          self.currentPage = 1;
          self.setDataFromMatchingResponse(resp);
          self.isLoading = false; //loading finished
        }).catch(function (error) {
          if (axios.isCancel(error)) {
            logger.log("Request canceled")
          } else {
            self.isLoading = false;
          }
        });
      }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));

    },
    sort() {
      // Clears Timeout so that we dont send multiple request if the user writes
      window.clearTimeout(this.sortTimer);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      for (let source of this.requestCancelTokensSort) {
        source.cancel('Request Canceled');
      }
      this.requestCancelTokensSort.push(source);
      this.params['sort'] = this.sortValue;
      this.params['page'] = 0;
      let self = this;
      localStorageHelper.setMatchSort(this.sortValue);

      this.sortTimer = window.setTimeout(function () {
        self.isLoading = true;
        self.getObservable(self.params, source.token).then(function (resp) {
          self.currentPageForBackend = 0;
          self.currentPage = 1;
          self.setDataFromMatchingResponse(resp);
          self.isLoading = false; //loading finished
        }).catch(function (error) {
          if (axios.isCancel(error)) {
            logger.log("Request canceled")
          } else {
            self.isLoading = false;
          }
        });
      }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));
    },
    showNextPage() {
      if (this.currentPage < this.maxPages) {
        if (this.currentPage % this.maxPagesToDisplay === 0 && this.companies.length === ((this.currentPage) * this.entriesPerPage)) {
          this.currentPageForBackend++;
          this.loadNextPageFromServer()
        } else {
          this.currentPage++;
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.companies .wrapper .organisations .company .companyInfo {
  height: 110px !important;
  align-items: flex-start !important;
}
.companies .wrapper .organisations .company .withoutMatchingScale {
  margin-bottom: 20px !important;
}
.companies .wrapper .companieslist .company .company-header label {
  -webkit-line-clamp: 4 !important;
}
</style>
