<template>
  <div class="company" v-bind:class="isRecommendation ? 'recommendation-border' : ''">
    <div class="companyInfo" v-bind:class="showMatchingScala ? 'withMatchingScale' : 'withoutMatchingScale'">
      <img :src="getImage()" ref="img"
           v-on:error="setFallBackImageForCompany()"/>
      <div class="company-header">
        <span :title="company.name">{{ company.name }}</span>
        <label :title="company.description">{{ company.description }}</label>
      </div>
    </div>

    <template v-if="showMatchingScala">
      <label class="scale-title">{{ $t('match-scale') }}</label>
      <MatchScale class="scale-meter" color="var(--scale-color)"
                  :score="getMatchingScore(company)"/>
    </template>
    <div class="recommendation-button">
      <button v-on:click="viewProfile()">
        View Company Profile
      </button>
    </div>

    <div class="hide-recommendation-button" v-if="isRecommendation">
      <button v-on:click="hideRecommendation(company)">
        {{ $t('hide-recommendation') }}
      </button>
    </div>

  </div>
</template>
<script>
import fallbackProfileImage from '@/assets/img/company.svg';
import MatchScale from "@/components/MatchScale";
import {cardMixin} from "@/components/cards/card-mixin";

export default {
  name: "CompaniesCard",
  mixins: [cardMixin],
  components: {
    MatchScale
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    isRecommendation: {
      type: Boolean,
      required: true
    },
    hideRecommendation: {
      type: Function,
      required: false
    },
    showMatchingScala: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    getImage() {
      if (this.$refs.img && this.$refs.img.src === fallbackProfileImage) {
        return fallbackProfileImage;
      }
      return this.company.logo ? this.company.logo : fallbackProfileImage;
    },
    setFallBackImageForCompany() {
      if (this.$refs.img) {
        this.$refs.img.src = fallbackProfileImage;
        this.$forceUpdate();
      }
    },
    viewProfile() {
      let win = window.open(this.company.url, '_blank');
      win.focus();
    }

  }
};
</script>
