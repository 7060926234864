<template>
  <div class="main">
    <div class="side-header">{{ $t('groups-site-header') }}</div>

    <div class="groups-hint left-aligned" v-if="!isLoading">{{ $t('groups-site-hint') }}</div>

    <div class="groups-content vld-parent">

      <loading :active.sync="isLoading"
               :is-full-page="false"
               color="var(--primary-color)">
      </loading>

      <div class="days-container">
        <div v-for="day in days" class="day" :class="activeDay === day ? 'isActive' :'' "
             v-on:click="setActiveDay(day)">
          {{ day }}
        </div>
      </div>

      <div class="events-list" v-if="!isLoading">
        <transition-group name="list" tag="div"
                          v-bind:css="false"
                          v-on:before-enter="animationBeforeEnter"
                          v-on:enter="animationEnter"
                          v-on:leave="animationLeave">
          <div class="event" v-for="event in eventsByActiveDay" :key="event.id">
            <div class="event-content">
              <div class="event-content-left">
                <h3 class="title">{{ event.title }}</h3>
                <span class="live-text" v-if="isEventLifeMap[event.id]">LIVE</span>
              </div>

              <div class="event-content-center">
                <div class="moderator-list">
                  <span class="title" v-if="event.moderator_ids.length > 0">{{
                      event.moderator_ids.length > 1 ? $t('moderators') + ':' : $t('moderator') + ':'
                    }} </span>
                  <div class="moderator" v-for="moderator in getModeratorsByIds(event.moderator_ids)"
                       v-on:click="openProfileDialog(moderator)">
                    <span> {{ moderator.fullName }}</span>
                  </div>
                </div>
                <div class="status">
                <span>   {{ $t('duration-of-video-call') }}
                  {{ convertDateToHoursAndMinutes(event.start) }} -
                  {{ convertDateToHoursAndMinutes(event.end) }} {{ getLocalTimeZone() }}</span>
                  <span
                      v-if="event.registrationState === 1 && !isModerator(event.moderator_ids)">{{ $t('request-is-being-reviewed') }}</span>
                  <span
                      v-if="event.registrationState === 2 && !isModerator(event.moderator_ids)">{{ $t('please-confirm-registration') }}</span>
                  <span
                      v-if="event.registrationState === 3 && !(event.count === event.max_participants) && !isModerator(event.moderator_ids) ">{{ $t('registered-for-event') }}</span>
                  <span
                      v-if="event.registrationState === 5 && !isModerator(event.moderator_ids)">{{ $t('on-waiting-list') }}</span>
                  <span v-if="event.max_participants > 0 && event.count >= event.max_participants">{{ $t('maximum-participants-reached') }}</span>
                  <span v-if="isModerator(event.moderator_ids)">{{ $t('you-are-moderator') }}</span>
                </div>
              </div>
            </div>
            <div class="info-text" v-if="event.info">
              <div v-html="event.info"></div>
            </div>
            <div class="actions">
              <div class="booknow"
                   v-if="!event.registered && (event.max_participants === 0 || event.count < event.max_participants) && !eventFinished(event)"
                   v-on:click="openBookToEventDialog(event.id)">
                <span>{{ $t('book-now') }}</span>
              </div>
              <div class="cancel-booking"
                   v-if="event.registrationState !== 4 && event.registered && !isModerator(event.moderator_ids)"
                   v-on:click="openUnregisterDialog(event.id)">
                <span v-if="event.registrationState === 5 || event.registrationState === 1">{{
                    $t('cancel-request')
                  }}</span>
                <span v-else> {{ $t('unregister') }}</span>
              </div>
              <div class="accept-booking" v-if="event.registrationState === 2"
                   v-on:click="confirmEvent(event.registrationToken)">
                <span>{{ $t('confirm-registration') }} </span>
              </div>


              <div class="toGroupChat" v-if="event.registrationState === 3"
                   v-on:click="goToGroupChat(event.id)">
                <span>{{ $t('join-chat') }}</span>
              </div>
              <div class="rejected" v-if="event.registrationState === 4">
                <span>{{ $t('rejected') }}</span>
              </div>
            </div>
            <div class="participants">
              <span v-if="event.max_participants > 0" class="text">{{ $t('seats-booked', {count: event.count, max: event.max_participants}) }} </span>
              <span v-else class="text">{{ $t('participant-count', {count: event.count}) }} </span>
            </div>

          </div>
        </transition-group>
      </div>
    </div>


    <custom-dialog ref="bookEventDialog" :id="'bookEventDialog'"
                   class="bookEventDialog" :close-when-ready-observable="getClosingObservableForBookDialog"
                   :submit-button-text="$t('book-now')">
      <span class="header">{{ $t('book-seat') }}</span>
      <span style="text-align: left;">{{ $t(bookToEventMessageRequired ? 'book-seat-dialog-hint-with-motivation' : 'book-seat-dialog-hint') }}</span>
      <custom-input-text-area v-if="bookToEventMessageRequired" v-bind:value.sync="bookToEventMessage" v-bind:max-length="1024"
                              v-bind:place-holder=" $t('your-message')"></custom-input-text-area>
      <div v-if="bookToEventMessageRequired && showEmptyEventMessageWarning" class="search-warning"><i
          class="fal fa-exclamation-triangle"></i> {{ $t('please-enter-message') }}
      </div>
    </custom-dialog>

    <custom-dialog ref="unregisterDialog" :id="'unregisterDialog'" :on-submit="unregisterFromEvent"
                   class="unregisterDialog" :submit-button-text="$t('unregister')"
                   :second-button-text="$t('cancel')">
      <span class="header"> {{ $t('unregister') }} </span>
      <span> {{ $t('group-unregister-dialog-text') }}</span>
    </custom-dialog>
  </div>
</template>

<script>
import backend from '@/mm-backend';
import moment from 'moment';
import 'moment-timezone';
import {Observable} from "rxjs";
import Vue from "vue";
import localStorageHelper from "../localstorage/local-storage";
import CustomInputTextArea from "@/components/inputs/CustomInputTextArea";
import {animationMixIn} from "@/mixins/animation-mixin";
import {isDefined} from "@/utils";
import {eventBus, logger} from "@/main";

export default {
  components: {CustomInputTextArea},
  mixins: [animationMixIn],
  data() {
    return {
      events: undefined,
      moderators: undefined,
      isLoading: true,
      days: [],
      activeDay: undefined,
      bookToEventMessage: '',
      bookToEventMessageRequired: process.env.VUE_APP_GROUPS_NEED_MOTIVATION === 'true',
      showEmptyEventMessageWarning: false,
      eventDateFormat: 'YYYY-MM-DD HH:mm:ss',
      dayFormat: 'ddd, LL',
      isEventLifeMap: {}
    }
  },
  mounted() {
    this.loadEvents();
  },
  computed: {
    eventsByActiveDay() {
      return isDefined(this.events) ? this.events.filter(e => moment.utc(e.start, this.eventDateFormat).format(this.dayFormat) === this.activeDay) : [];
    }
  },
  methods: {
    createEventStartTimers() {
      if (this.events && this.events.length > 0) {
        const self = this;
        for (const event of this.events) {
          const millisUntilEventStart = moment.utc(event.start, this.eventDateFormat).toDate() - Date.now();
          if (millisUntilEventStart >= 0) {
            if (millisUntilEventStart <= 2147483648) { // TODO delete when events are close to 24 days away
              setTimeout(function () {
                Vue.set(self.isEventLifeMap, event.id, true);
              }, millisUntilEventStart);
            }
          } else {
            const millisUntilEventEnd = moment.utc(event.end, this.eventDateFormat).toDate() - Date.now();
            if (millisUntilEventEnd >= 0) {
              // event is currently running
              Vue.set(self.isEventLifeMap, event.id, true);
            }
          }
        }
      }
    },
    createEventEndTimers() {
      if (this.events && this.events.length > 0) {
        const self = this;
        for (const event of this.events) {
          const millisUntilEventEnd = moment.utc(event.end, this.eventDateFormat).toDate() - Date.now();
          if (millisUntilEventEnd >= 0) {
            if (millisUntilEventEnd <= 2147483648) { // TODO delete when events are close to 24 days away
              setTimeout(function () {
                Vue.set(self.isEventLifeMap, event.id, false);
              }, millisUntilEventEnd);
            }
          }
        }
      }
    },
    loadEvents(skipPostLoad) {
      this.isLoading = true;
      const self = this;
      backend.getAllEvents().then(resp => {
        self.events = resp.data.events;
        self.moderators = resp.data.moderators;
        if (!skipPostLoad) {
          self.postLoadEvents();
        }
        self.isLoading = false;
      }, error => {
        logger.error(error,true);
        self.$toasted.global.error_notification({message: self.$t('groups-loading-error')});
        self.isLoading = false;
      });
    },
    postLoadEvents() {
      if (this.events.length > 0) {
        this.createDaysFromEvents();
        this.createEventStartTimers();
        this.createEventEndTimers();
      }
    },
    confirmEvent(token) {
      this.isLoading = true;
      const self = this;
      backend.confirmEvent(token).then(resp => {
        self.$toasted.global.success_notification({message: self.$t('groups-confirm-successful')});
        self.loadEvents(true);
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('groups-confirm-failed')});
        self.isLoading = false;
      });
    },
    unregisterFromEvent(eventId) {
      const self = this;
      this.isLoading = true;
      backend.unregisterFromEvent(eventId).then(resp => {
        self.$toasted.global.success_notification({message: self.$t('groups-unregister-successful')});
        self.loadEvents(true);
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('groups-unregister-failed')});
        self.isLoading = false;
      });
    },
    goToGroupChat(eventId) {
      this.$router.push({name: 'Group', params: {eventId: eventId}});
    },
    isBookToEventMessagePresent() {
      return this.bookToEventMessage.trim() !== '';
    },
    openBookToEventDialog(eventId) {
      this.bookToEventMessage = '';
      this.$refs.bookEventDialog.openDialog(eventId);
    },
    openUnregisterDialog(eventId) {
      this.$refs.unregisterDialog.openDialog(eventId);
    },
    openProfileDialog(userData) {
      eventBus.$emit('open-profile-dialog', userData, false);
    },
    setActiveDay(day) {
      this.activeDay = day;
    },
    createDaysFromEvents() {
      let days = [];
      for (const event of this.events) {
        const startDay = moment.utc(event.start, this.eventDateFormat).format(this.dayFormat);
        if (!days.includes(startDay)) {
          days.push(startDay);
        }
      }

      this.days = days.sort((a, b) => moment(new Date(a)).format('YYYYMMDD') - moment(new Date(b)).format('YYYYMMDD'));
      const today = moment().format(this.dayFormat);
      const findDay = this.days.find(d => d === today);
      this.activeDay = isDefined(findDay) ? findDay : this.days[0];
    },
    getModeratorsByIds(idsAsArrays) {
      const moderators = [];
      if (idsAsArrays && this.moderators) {
        for (const id of idsAsArrays) {
          moderators.push(this.moderators.find(m => m.id === +id));
        }
      }
      return moderators;
    },
    getLocalTimeZone() {
      return moment().tz(process.env.VUE_APP_GROUPS_TIMEZONE).zoneAbbr();
    },
    convertDateToHoursAndMinutes(dateAsString) {
      return moment.utc(dateAsString, this.eventDateFormat).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('HH:mm');
    },
    getClosingObservableForBookDialog(eventId) {
      const self = this;
      return new Observable(subscriber => {
        if (!this.bookToEventMessageRequired || this.isBookToEventMessagePresent()) {
          this.showEmptyEventMessageWarning = false;
          backend.registerToEvent(eventId, this.bookToEventMessageRequired ? this.bookToEventMessage.trim() : "").then(resp => {
            if (resp.data.error) {
              self.$toasted.global.error_notification({message: resp.data.error.message});
            } else {
              const event = resp.data.events.find(event => event.id == eventId);
              if (event && event.registrationState == 3) {
                self.$toasted.global.success_notification({message: self.$t('groups-register-successful')});
              } else {
                self.$toasted.global.success_notification({message: self.$t('groups-book-successful')});
              }
            }
            self.loadEvents(true);
            subscriber.next(true);
            subscriber.complete();
          }, error => {
            subscriber.next(false);
            subscriber.complete();
            self.$toasted.global.error_notification({message: self.$t('groups-register-failed')});
          });
        } else {
          subscriber.next(false);
          subscriber.complete();
          self.showEmptyEventMessageWarning = true;
        }
      });
    },
    isEventRunning(event) {
      const eventStartTime = moment.utc(event.start, this.eventDateFormat);
      const eventEndTime = moment.utc(event.end, this.eventDateFormat);
      const currentTime = moment().utc();
      return currentTime.isBetween(eventStartTime, eventEndTime);
    },
    isModerator(moderator_ids) {
      if (moderator_ids) {
        const userId = localStorageHelper.getUserId()
        const isModerator = moderator_ids.findIndex(id => id === userId);
        return isModerator > -1;
      }
      return false;
    },
    eventFinished(event) {
      const eventEndTime = moment.utc(event.end, this.eventDateFormat);
      const currentTime = moment().utc();
      return currentTime.isAfter(eventEndTime);
    }

  }
}
</script>

<style scoped lang="less">


.search-warning {
  margin: 0;
}

.groups-hint {
  margin-top: -10px;
  margin-bottom: 20px;
}

.groups-content {
  width: 100%;
  min-height: 200px;
  height: 100%;

  .days-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .day {
      padding: 6px 16px;
      margin-right: 32px;
      border: 1px solid var(--border-color);
      color: var(--font-color);
      cursor: pointer;
    }

    .isActive {
      background-color: var(--secondary-color);
      color: white;
    }
  }

  .events-list {
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
    width: 100%;
    height: 100%;
    padding: 22px;


    .event:last-child {
      margin: 0;
    }

    .event {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 16px;
      margin-bottom: 22px;
      width: 100%;
      background-color: #FFFFFF;

      &-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-left {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 32px;


          .title {
            text-align: left;
            font-size: 40px;
            font-weight: bold;
            letter-spacing: 0;
            margin: 0;
          }

          .live-text {
            color: red;
            font-size: 24px;
            font-weight: bold;
            margin-top: 12px;
          }


          > span {
            text-align: left;
          }

        }

        &-center {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;

          .status {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .moderator-list {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: auto;

            .title {
              margin-right: 12px;
            }

            .moderator {
              background-color: var(--secondary-color);
              padding: 12px;
              cursor: pointer;
              margin: 0 10px;

              span {
                color: #FFFFFF;
                font-size: 16px;
                letter-spacing: 0.24px;
                line-height: 24px;
              }
            }
          }
        }

        &-right {
          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: auto;
            text-align: right;

            > span {
              margin: 2px 0;
              width: 100%;
            }
          }

          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 40%;
        }
      }

      .info-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0;

        > p {
          text-align: left;
        }
      }

      .actions {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;


        .booked {
          background-color: #63b701;
        }

        .full {
          background-color: var(--warning-color);
        }

        .accept-booking {
          cursor: pointer;
          background-color: #63b701;
        }

        .waiting-list {
          background-color: #63b701;
        }

        .rejected {
          background-color: var(--warning-color);
        }

        .booknow, .cancel-booking, .accept-booking, .full, .booked, .toGroupChat, .rejected, .waiting-list {
          padding: 12px 24px;
          margin-left: 12px;

          span {
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 0.24px;
            line-height: 24px;
          }
        }

        .toGroupChat {
          background-color: var(--secondary-color);
          cursor: pointer;
          margin-left: 12px;
        }

        .booknow {
          background-color: var(--secondary-color);
          cursor: pointer;
        }

        .cancel-booking {
          background-color: var(--warning-color);
          cursor: pointer;
        }

      }

      .participants {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 12px;

        .text {
          color: var(--primary-color);
          font-weight: bold;
          font-size: 20px;
        }
      }

    }
  }
}

</style>
