<template>
  <div class="input-wrapper">
    <textarea :id="id" type="text" class="textarea" v-model="internValue" v-bind:maxlength="maxLength" v-bind:required="required"
              v-on:keyup="$emit('update:value',internValue)" :rows="rows"
              spellcheck="false"/>
    <label class="label" v-bind:class="{'filled-input': value !== '' && value !== undefined && value !== null,'required': required && !value}">
      {{ placeHolder }}{{ required ? '*' : '' }} </label>
  </div>
</template>

<script>
export default {
  name: "CustomInputTextArea",
  props: {
    value: {
      required: true
    },
    placeHolder: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    maxLength: {
      required: false,
      type: Number,
      default: 255
    },
    rows: {
      required: false,
      type: Number,
      default: 1
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.internValue = newVal;
      }
    }
  },
  data() {
    return {
      internValue: undefined,
      id: undefined
    }
  },
  beforeUpdate() {
    this.$nextTick(function () {
      const tx = document.getElementById(this.id);
      if (tx) {
        tx.setAttribute('style', 'height:' + (tx.scrollHeight > 52 ? tx.scrollHeight : 52) + 'px;overflow-y:hidden;');
        const maxHeight = getComputedStyle(tx).maxHeight;

        if (+maxHeight.slice(0, maxHeight.indexOf('p')) < +tx.style.height.slice(0, tx.style.height.indexOf('p'))) {
          tx.style.marginTop = '12px';
        } else {
          tx.style.marginTop = '0';
        }
        tx.addEventListener("input", this.onInput, false);
      }
    });
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    onInput() {
      const tx = document.getElementById(this.id);
      if (tx) {
        tx.style.height = 'auto';
        tx.style.height = (tx.scrollHeight > 52 ? tx.scrollHeight : 52) + 'px';
        const maxHeight = getComputedStyle(tx).maxHeight;

        if (+maxHeight.slice(0, maxHeight.indexOf('p')) < +tx.style.height.slice(0, tx.style.height.indexOf('p'))) {
          tx.style.marginTop = '12px';
        } else {
          tx.style.marginTop = '0';
        }
      }
    },
  }
}
</script>

