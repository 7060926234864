import backend from "@/mm-backend";
import {logger} from "@/main";
import {isDefined} from "@/utils";
import {Country} from "@/entities/Country";
import langHelper from "@/locales/lang-helper";

export class CountriesStorage {

    _countries;

    constructor() {
    }

    loadCountries() {
        return new Promise((resolve, reject) => {
            backend.getCountries().then(resp => {
                logger.log("Countries loaded")
                this._countries = resp.data
                resolve(this._countries);
            }, error => {
                reject(error);
            });
        });
    }

    getCountryList() {
        if (isDefined(this._countries) && this._countries.length > 0) {
            const countries = [];
            this._countries.forEach(c => countries.push(new Country(c)));
            switch (langHelper.getCurrentLang()) {
                case 'de':
                    countries.sort((a, b) => {
                        return a.name_de.localeCompare(b.name_de);
                    });
                    break;
                case 'en':
                    countries.sort((a, b) => {
                        return a.name_en.localeCompare(b.name_en);
                    });
                    break;
                default:
            }
            return countries;
        }
        return [];
    }

    get countries() {
        return this._countries;
    }
}
