<template>

  <div
      v-if="(!jointOnly && category.has_selected_search || category.selected_as_search) || ( !selectOnly && category.has_joint_search || category.joint_search)"
      :class="!allSelected && !allJoint  ? 'not-all-selected-box':''">


    <div class="themes-category-entry" :class="allJoint ? 'joint' :'' " v-if=" (allSelected || allJoint)">
      <div :title="category.title">{{ category.title }} - {{ $t(getAllStringForCategory(category)) }} </div>
      <i v-if="allJoint" class="fal fa-user-friends fa-lg"></i>
    </div>


    <!-- Title of Root Category  -->
    <h5 v-if="step === 0 && ((category.has_selected_search && !allSelected) || (category.has_joint_search && !allJoint))">
      {{ category.title }}</h5>


    <!-- Title of Child Category example  Continents of Countries   -->
    <hr class="line-break"
        v-if="step !== 0 && ((category.has_selected_search  && !allSelected) || (category.has_joint_search && !allJoint))">
    <div class="themes-category-entry"
         v-if="((!allSelected && category.has_selected_search) || (!allJoint && category.has_joint_search)) && step !== 0">
      <div v-bind:title="category.title">{{ category.title }}</div>
    </div>


    <!-- Entries -->
    <template
        v-if="(((jointOnly && category.joint_search) || (selectOnly && !category.joint_search )) || (!selectOnly && !jointOnly))">
      <div class="themes-category-entry" :class="category.joint_search ? 'joint' :''"
           v-if="((!allSelected && category.selected_as_search) || (!allJoint && category.joint_search)) && step !== 0">
        <div v-bind:title="category.title">{{ category.title }}</div>
        <i v-if="category.joint_search" class="fal fa-user-friends fa-lg"></i>
      </div>
    </template>

    <div
        :class="[step === 0 ? 'combined-topic-container' : category.children ? 'active-topic-container' : '', category.children ? `step-${step}` : '']">

      <themes-profile-others-search v-bind:joint-only="jointOnly" v-bind:select-only="selectOnly"
                                    v-if="category.children && category.children.length > 0 && ((category.has_selected_search && !allSelected) || (category.has_joint_search && !allJoint))"
                                    v-for="child in category.children"
                                    v-bind:category="child" v-bind:parent="parent" v-bind:step="step+1"
                                    :key="child.id"></themes-profile-others-search>


    </div>
  </div>
</template>

<script>
import {themesProfileOthersCategoriesMixin} from "@/themes/profile-others/themes-profile-others-categories-mixin";
import {profileThemeCategoriesMixin} from "@/mixins/profile-theme-categories-mixin";

export default {
  mixins: [themesProfileOthersCategoriesMixin, profileThemeCategoriesMixin],
  name: "themes-profile-others-search",
  watch: {
    category: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          if (newVal.id !== this.parent.id) {
            if (!this.selectOnly) {
              this.allJoint = this.allEntriesJoint();
            }
            if (!this.jointOnly) {
              this.allSelected = this.allEntriesSelected();
            }
          }
        }
      }
    }
  },
  methods: {
    getSelectedAs() {
      return 'selected_as_search';
    },
    getJointAs() {
      return 'joint_search';
    }
  }
}
</script>
