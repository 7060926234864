<template>
  <div class="notification">
    <span class="notification-header">{{ translate('due-appointment') }}</span>
    <span class="notification-name">{{ appointment.name }}</span>
    <span class="notification-time"><i class="far fa-clock"/> {{
        formatTime(appointment.from, 'HH:mm')
      }} - {{ formatTime(appointment.to, 'HH:mm') }} {{ timezone(appointment.from) }}</span>


    <div class="notification-participants">
      <span>{{ translate('participant') }}:</span>
      <div class="participant" v-for="participant of appointment.participants"
           v-on:click="getUserAndOpenProfileDialog(participant.user_id === userId ? appointment.requesting_user : participant.user_id)">
        <span> {{
            getUserById(participant.user_id === userId ? appointment.requesting_user : participant.user_id).fullName
          }}</span>
      </div>
    </div>
    <custom-button v-if="appointment.isOnline()" :icon-back-ground-color="'var(--secondary-color)'"
                   :icon="'fal fa-video fa-1x'" :width="200" :icon-width="50"
                   :onClicked="() => {joinOnlineAppointment(appointment)}">
      {{ translate('appointments-join-online-appointment') }}
    </custom-button>
  </div>
</template>

<script>
import {Appointment} from "@/entities/Appointment";
import {appointmentsMixin} from "@/mixins/appointments-mixin";
import localStorageHelper from "@/localstorage/local-storage";
import {notificationComponentMixin} from "@/mixins/notification-component-mixin";
import {appointmentsStorage} from "@/main";

export default {
  name: "DueAppointmentNotification",
  mixins: [appointmentsMixin, notificationComponentMixin],
  data() {
    return {
      userId: localStorageHelper.getUserId()
    }
  },
  props: {
    appointment: {
      required: true,
      type: Appointment
    }
  },
  methods: {
    getUserById(id) {
      const users = appointmentsStorage.getUsers();
      return users.find(u => +u.id === +id);
    },
    getUserAndOpenProfileDialog(userId){
      const userData = this.getUserById(userId);
      this.openProfileDialog(userData);
    }
  }
}
</script>

<style scoped lang="less">

.notification {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-header {

  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &-time {

  }

  &-participants {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: left;


    .participant {
      background-color: var(--secondary-color);
      padding: 6px;
      cursor: pointer;
      margin: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: #FFFFFF;
        font-size: 16px;
        letter-spacing: 0.24px;
        line-height: 24px;
      }
    }
  }
}

</style>
