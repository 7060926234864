import localStorageHelper from "@/localstorage/local-storage";
import {addMessageCallback, deleteMessageCallback} from "@/push-backend";
import backend from "@/mm-backend";
import axios from "axios";
import fallbackProfileImage from "@/assets/img/person.svg";
import Vue from "vue";
import {animationMixIn} from "@/mixins/animation-mixin";
import {uuid} from "vue-uuid";
import {isDefined} from "@/utils";
import {eventBus, logger} from "@/main";
import {User} from "@/entities/User";

export let networkersMixIn = Vue.util.mergeOptions(animationMixIn, {
    data() {
        return {
            isLoading: true,
            searchValue: undefined,
            lang: this.$getCurrentLanguage(),
            entriesPerPage: 9,
            entriesPerPageOptions: [9, 27, 45, 99],
            currentPage: 1,
            sortValue: localStorageHelper.getMatchSort() ? localStorageHelper.getMatchSort() : 'pc',
            sortValues: ['pc', 'firstName', 'lastName', 'updated_at', 'organization'],
            filterEnabled: false,
            searchTimer: undefined,
            sortTimer: undefined,
            totalEntries: undefined,
            maxPagesToDisplay: process.env.VUE_APP_MAX_LOADING_PAGES_FOR_MATCHES,
            currentPageForBackend: 0,
            requestCancelTokensSearch: [],
            requestCancelTokensSort: [],
            recommendations: [],
            recommendationsTitle: undefined,
            networkersTitle: undefined,
            networkers: [],
            params: undefined,
            getObservable: undefined,
            isMobile: this.$isMobile,
            filterVisible: !this.$isMobile
        }
    },
    // Component hooks are called in sequence
    // mixins hook -> component hook
    beforeMount() {
        addMessageCallback(this.handlePush);
    },
    beforeDestroy() {
        deleteMessageCallback(this.handlePush);
    },
    computed: {
        maxPages() {
            return Math.ceil(this.totalEntries / this.entriesPerPage);
        }
    },
    methods: {
        toggleFilter() {
            this.filterVisible = !this.filterVisible;
        },
        handlePush(payload) {
            if (payload.data && payload.data.type === 'confirm') {
                this.reloadFilter();
            }
            return false;
        },
        resetFilter() {
            this.filterEnabled = false;
            this.isLoading = true;
            this.get();
        },
        reloadFilter() {
            this.applyFilter(this.$refs.themeFilter.themesToFilter, this.$refs.themeFilter.filterType, false, this.$refs.themeFilter.showHiddenUsers);
        },
        openProfileDialog(user, isRecommendation) {
            eventBus.$emit('open-profile-dialog', user, isRecommendation);
        },
        beforeGet() {
            // Components need to implement this function
            // Set get Parameters and set getObservable
        },
        get() {
            this.beforeGet();
            if (this.searchValue && this.searchValue.trim() !== '') {
                this.search();
            } else if (this.filterEnabled || this.$refs.themeFilter.hasSavedThemes) {
                this.reloadFilter();
            } else {
                this.isLoading = true;
                let self = this;
                this.getObservable(this.params).then(function (resp) {
                    self.currentPage = 1;
                    self.setDataFromMatchingResponse(resp);
                    self.isLoading = false; //loading finished
                    self.afterGet();
                }, error => {
                    self.isLoading = false;
                });
            }
        },
        afterGet() {
            // Components need to implement this function
            // Mainly used for matomo tracking
        },
        setDataFromMatchingResponse(resp) {
            this.totalEntries = resp.data.totalEntries;
            this.recommendations = [];
            if (resp.data.recommendations) {
                this.recommendations = resp.data.recommendations;
                this.recommendations.forEach(r => {
                    r.is_recommendation = true;
                });
                this.totalEntries += resp.data.recommendations.length;
            }
            this.networkersTitle = resp.data.users_title;
            this.recommendationsTitle = resp.data.recommendations_title;
            this.networkers = resp.data.users;
            if (isDefined(this.networkers) && this.networkers.length > 0) {
                this.networkers.forEach(n => n.internalId = uuid.v4())
            }
        },
        showPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        loadNextPageFromServer() {
            this.isLoading = true;
            let self = this;
            if (this.searchValue && this.searchValue !== '') {
                this.params['search'] = this.searchValue;
            }
            this.params['page'] = this.currentPageForBackend;
            this.getObservable(this.params).then(function (resp) {
                self.totalEntries = resp.data.totalEntries;
                self.recommendations = [];
                if (resp.data.recommendations) {
                    self.recommendations = resp.data.recommendations;
                    self.recommendations.forEach(r => {
                        r.is_recommendation = true;
                    });
                    self.totalEntries += resp.data.recommendations.length;
                }
                self.networkersTitle = resp.data.users_title;
                self.recommendationsTitle = resp.data.recommendations_title;
                self.networkers.push(...resp.data.users);
                self.currentPage++;
                self.isLoading = false; //loading finished
            }, error => {
                self.isLoading = false;
            });
        },
        showNextPage() {
            if (this.currentPage < this.maxPages) {
                if (this.currentPage % this.maxPagesToDisplay === 0 && this.networkers.length === ((this.currentPage) * this.entriesPerPage)) {
                    this.currentPageForBackend++;
                    if (this.filterEnabled) {
                        this.applyFilter(this.$refs.themeFilter.themesToFilter, this.$refs.themeFilter.filterType, true, this.$refs.themeFilter.showHiddenUsers);
                    } else {
                        this.loadNextPageFromServer()
                    }
                } else {
                    this.currentPage++;
                }
            }
        },
        search() {
            // Clears Timeout so that we dont send multiple request if the user writes
            window.clearTimeout(this.searchTimer);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            for (let source of this.requestCancelTokensSearch) {
                source.cancel('Request Canceled');
            }
            this.requestCancelTokensSearch.push(source);
            if (this.searchValue && this.searchValue !== '') {
                this.params['search'] = this.searchValue;
            } else {
                this.params['search'] = undefined;
            }
            this.params['page'] = this.currentPageForBackend;
            let self = this;

            if (this.filterEnabled) {
                this.searchTimer = window.setTimeout(function () {
                    self.reloadFilter();
                }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));
            } else {
                this.searchTimer = window.setTimeout(function () {
                    self.isLoading = true;
                    self.getObservable(self.params, source.token).then(function (resp) {
                        self.currentPage = 1;
                        self.setDataFromMatchingResponse(resp);
                        self.isLoading = false; //loading finished
                    }).catch(function (error) {
                        if (axios.isCancel(error)) {
                            logger.log("Request canceled")
                        } else {
                            self.isLoading = false;
                        }
                    });
                }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));
            }
        },
        sort() {
            // Clears Timeout so that we dont send multiple request if the user writes
            window.clearTimeout(this.sortTimer);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            for (let source of this.requestCancelTokensSort) {
                source.cancel('Request Canceled');
            }
            this.requestCancelTokensSort.push(source);
            this.params['sort'] = this.sortValue;
            this.params['page'] = 0;
            let self = this;
            localStorageHelper.setMatchSort(this.sortValue);
            if (this.filterEnabled) {
                this.sortTimer = window.setTimeout(function () {
                    self.reloadFilter();
                }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));
            } else {
                this.sortTimer = window.setTimeout(function () {
                    self.isLoading = true;
                    self.getObservable(self.params, source.token).then(function (resp) {
                        self.currentPageForBackend = 0;
                        self.currentPage = 1;
                        self.setDataFromMatchingResponse(resp);
                        self.isLoading = false; //loading finished
                    }).catch(function (error) {
                        if (axios.isCancel(error)) {
                            logger.log("Request canceled")
                        } else {
                            self.isLoading = false;
                        }
                    });
                }, parseInt(process.env.VUE_APP_TIMEOUT_FOR_QUICKSEARCH));
            }
        },
        traverseCategory(children, filterType, userThemes, category) {
            for (const entry of children) {
                if (entry.children) {
                    this.traverseCategory(entry.children, filterType, userThemes, category);
                } else {
                    if (entry.selected) {
                        if (!userThemes[category.id]) {
                            if (category.hasSearchAndOffer) {
                                userThemes[category.id] = {};
                                userThemes[category.id][filterType] = [];
                            } else {
                                userThemes[category.id] = [];
                            }
                        }
                        if (category.hasSearchAndOffer) {
                            userThemes[category.id][filterType].push(entry.id);
                        } else {
                            userThemes[category.id].push(entry.id);
                        }
                    }
                }
            }
        },
        applyFilter(themes, filterType, showNextPage, showHiddenUsers) {
            let self = this;
            const userThemes = {};


            for (const category of themes) {
                this.traverseCategory(category.children, filterType, userThemes, category)
            }

            let themesAreEmpty = true;
            for (let i in userThemes) {
                themesAreEmpty = false;
                break;
            }

            // if no topic or country is selected we dont send the themes object
            if (!themesAreEmpty) {
                this.params['themes'] = userThemes;
                this.filterEnabled = true;
            } else {
                this.params['themes'] = undefined;
                this.filterEnabled = false;
            }
            if (process.env.VUE_APP_ENABLE_HIDE_UNHIDE_USER_HANDLING === 'true') {
                if (showHiddenUsers) {
                    this.params['showHidden'] = true;
                } else if (this.params['showHidden'] !== undefined) {
                    delete this.params['showHidden'];
                }
            }
            this.params['page'] = showNextPage ? this.currentPageForBackend : 0

            self.isLoading = true;
            this.getObservable(this.params).then(function (resp) {
                if (!showNextPage) {
                    self.setDataFromMatchingResponse(resp);
                    self.currentPage = 1;
                } else {
                    self.setDataFromMatchingResponse(resp);
                    self.currentPage++;
                }
                self.afterGet();
                self.isLoading = false; //loading finished
            }, error => {
                self.isLoading = false;
            });
        },
        getImage(user) {
            if (this.$refs['img' + user.id] && this.$refs['img' + user.id].src === fallbackProfileImage) {
                return fallbackProfileImage;
            }
            return user.thumbnail ? user.thumbnail : (user.photo ? user.photo : fallbackProfileImage);
        },
        hideRecommendation(user) {
            this.$refs.confirmExcludeDialog.openDialog(user);
        },
        excludeRecommendation(user) {
            const self = this;
            backend.excludeRecommendation(user.id).then(resp => {
                self.$toasted.global.success_notification({message: self.$t('exclude-recommendation-success')});
                self.reloadFilter();
            }, error => {
                self.$toasted.show(self.$t('exclude-recommendation-error'), {
                    duration: 2000,
                    position: "top-center"
                });
            });
        },
        getName(user) {
            return user.getName();
        },
        setFallBackImageForUser(userId) {
            this.$refs['img' + userId].src = fallbackProfileImage;
            this.$forceUpdate();
        }

    }
});

