import Vue from 'vue';
import UUID from "vue-uuid";
import App from './App.vue';
import router, {componentHistory} from './router';
import i18n from './i18n';
import Recommendation from './views/networkers/Recommendation.vue';
import ContactCard from './components/cards/ContactCard';
import CompaniesCard from './components/CompaniesCard';
import CustomButton from './components/inputs/CustomButton';
import Dialog from "./components/Dialog"
import ToastedPlugin from "vue-toasted";
import langHelper, {supportedLanguages} from "./locales/lang-helper";
import Loading from 'vue-loading-overlay';
import VueMatomo from 'vue-matomo';
import CreateProfile from "./components/CreateProfile";
import ProfileOthers from "./views/Profile-Others";
import VideoChat from "./views/VideoChat";
import VueFormWizard from "vue-form-wizard/src";
import IncomingCallPopup from "@/components/IncomingCallPopup";
import CustomSelect from "@/components/inputs/CustomSelect";
import VueMq from 'vue-mq'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import moment from "moment";
import {OnlineContactsStorage} from "@/localstorage/online-contacts-storage";
import NotificationComponent from './components/Notifications/NotificationComponent';
import VueLazyload from 'vue-lazyload';
import {getOffsetTop, isDefined} from "@/utils";
import {AppointmentsStorage} from "@/views/appointments/AppointmentsStorage";
import {ThemesStorage} from "@/themes/storage/themes-storage";

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import {Logger} from "@/logger";
import {CountriesStorage} from "@/themes/storage/countries-storage";

export const logger = new Logger();
export const themesStorage = new ThemesStorage();
export const countriesStorage = new CountriesStorage();
export const eventBus = new Vue();
export const onlineContactsStorage = new OnlineContactsStorage();
export const appointmentsStorage = new AppointmentsStorage();

//Components
Vue.component('Recommendation', Recommendation);
Vue.component('custom-button', CustomButton);
Vue.component('custom-dialog', Dialog);
Vue.component('ContactCard', ContactCard);
Vue.component('CompaniesCard', CompaniesCard);
Vue.component("loading", Loading);
Vue.component("create-profile", CreateProfile);
Vue.component("profile-others", ProfileOthers);
Vue.component('video-chat', VideoChat);
Vue.component('incoming-call-popup', IncomingCallPopup);
Vue.component('custom-select', CustomSelect);
Vue.component('notifications', NotificationComponent)
//Prototypes
Vue.prototype.$getCurrentLanguage = langHelper.getCurrentLang;
Vue.prototype.$getSupportedLanguages = supportedLanguages;
Vue.prototype.$isMyBookFair = process.env.VUE_APP_MYBOOKFAIR_USER_HOST != null && process.env.VUE_APP_MYBOOKFAIR_USER_HOST.length > 0;
Vue.prototype.$createProfileEnabled = process.env.VUE_APP_ENABLE_PROFILE_CREATE === 'true';
Vue.prototype.$showAdditionalInfoDialog = process.env.VUE_APP_ENABLE_ADDITIONAL_INFO_DIALOG === 'true';
Vue.prototype.$appVersion = process.env.VUE_APP_VERSION;
Vue.prototype.$splitChatAndContactRequests = process.env.VUE_APP_SPLIT_CHAT_AND_CONTACT_REQUESTS === 'true'
Vue.prototype.$incomingCallTimeBeforeClose = process.env.VUE_APP_INCOMING_CALL_TIME_BEFORE_CLOSE;
Vue.prototype.$enableGroups = process.env.VUE_APP_ENABLE_GROUPS === 'true';
Vue.prototype.$enableVideoCollection = process.env.VUE_APP_ENABLE_VIDEO_COLLECTION === 'true';
Vue.prototype.$enableCompanies = process.env.VUE_APP_ENABLE_COMPANIES === 'true';
Vue.prototype.$enableCorporates = process.env.VUE_APP_ENABLE_CORPORATES === 'true';
Vue.prototype.$enableAppointments = process.env.VUE_APP_ENABLE_APPOINTMENTS === 'true';
Vue.prototype.$showInviteContactButton = process.env.VUE_APP_SHOW_INVITE_CONTACT_BUTTON === 'true';
Vue.prototype.$showReserveTimeButton = process.env.VUE_APP_SHOW_RESERVE_TIME_BUTTON === 'true';
Vue.prototype.$enableVideoChat = process.env.VUE_APP_ENABLE_VIDEO_CHAT === 'true';
Vue.prototype.$enablePresetFilter = process.env.VUE_APP_FILTER_PRESET_WITH_OWN_THEMES === 'true';
Vue.prototype.$splitFilterThemes = process.env.VUE_APP_SPLIT_FILTER_THEMES === 'true';
Vue.prototype.$enableHideUnhideUserHandling = process.env.VUE_APP_ENABLE_HIDE_UNHIDE_USER_HANDLING === 'true';

moment.locale(langHelper.getCurrentLang());

Vue.use(UUID);
Vue.use(VueFormWizard);
Vue.use(ToastedPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VueLazyload, {lazyComponent: true});
Vue.config.productionTip = false;

Vue.use(VueMq, {
    breakpoints: {
        mobile: 600,
        desktop: Infinity,
    }
});

if (process.env.VUE_APP_MATOMO_ENABLED === 'true') {
    Vue.use(VueMatomo, {
        siteId: process.env.VUE_APP_MATOMO_ID,
        host: process.env.VUE_APP_MATOMO_URL,
        trackInitialView: false,    // Whether to track the initial page view
        enableLinkTracking: false,  //Enables link tracking on regular links. Note that this won't
        debug: true
    });
}


i18n.locale = langHelper.getCurrentLang();
window.onerror = null;

Vue.config.errorHandler = (error, vm, info) => {
    logger.error(error)
    let vueData;
    try {
        const maxDataLength = 100000;
        let data = JSON.stringify(vm._data, null, 2);
        if (data.length > maxDataLength) {
            vueData = data.substring(0, maxDataLength) + '…';
        } else {
            vueData = vm._data;
        }
    } catch (error) {
        vueData = error.message;
    }

    logger.sendErrorDump(error, vueData);

};

document.onkeydown = function (e) {
    if (e.key == process.env.VUE_APP_SEND_ERROR_DUMP_KEY && e.altKey && e.ctrlKey) {
        e.preventDefault();
        logger.sendBasicErrorDump();
    } else if (e.key == 's' && e.ctrlKey) {
        //Prevent Browser Save Dialog to open.
        e.preventDefault();
    }
}

Vue.toasted.register('success_notification', payload => {
    if (payload.message) {
        return payload.message;
    }
}, {type: 'success', position: 'bottom-right', duration: 5000});


Vue.toasted.register('error_notification', payload => {
    if (payload.message) {
        return payload.message;
    }
}, {
    position: "bottom-right",
    duration: 10000,
    type: 'error',
    action: {
        text: '',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
});


window.onscroll = function () {
    const header = document.getElementById('header');
    if (isDefined(header)) {
        const headerHeight = header.offsetHeight
        const sticky = getOffsetTop(document.getElementById('app')) - headerHeight;
        header.style['transition-property'] = 'opacity';
        header.style['transition-delay'] = '1s';
        if (window.scrollY > sticky) {
            header.style.opacity = '0'
        } else {
            header.style.opacity = '1'
        }
    }
}

const startAppPromises = [];
startAppPromises.push(themesStorage.loadThemes());
startAppPromises.push(countriesStorage.loadCountries());

Promise.all(startAppPromises).then(() => {
        window.egcf_app = new Vue({
            router,
            i18n,
            render: h => h(App)
        }).$mount('#app')
    }, error => {
        //TODO error message when themes could not be loaded
        const appContent = document.getElementById("app");
        appContent.innerHTML = "<center><span style='color:white'>We're sorry, your browser is not supported.</span></center>";
    }
);

