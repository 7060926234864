<template>
  <div class="filter">

    <custom-dialog class="filter-type-dialog" :id="'changeFilterTypeInfoDialog'" ref="changeFilterTypeInfoDialog"
                   :submit-button-text="$t('dialog-ok')" :on-submit="onFilterInfoDialogClose">
      <span class="header"> {{ $t('filtersettings') }} </span>
      <span class="filter-type-info-text"> {{ $t('filter_type_explanation_dialog') }} </span>
      <span class="filter-type-info-text"> {{ $t('filter_type_explanation_dialog_offer') }} </span>
      <div class="filter-type-toggle">
        <div class="filter-type-toggle-left"
             v-bind:class="{'is-active' : true,'deactivated' : isLoading}">
          <span>{{ $t('select-' + filterTypes[1]) }}</span>
        </div>
        <div class="filter-type-toggle-right"
             v-bind:class="{'is-active' : false,'deactivated' : isLoading}">
          <span> {{ $t('select-' + filterTypes[0]) }}</span>
        </div>
      </div>
      <span class="filter-type-info-text"> {{ $t('filter_type_explanation_dialog_search') }} </span>
      <div class="filter-type-toggle">
        <div class="filter-type-toggle-left"
             v-bind:class="{'is-active' : false,'deactivated' : isLoading}">
          <span>{{ $t('select-' + filterTypes[1]) }}</span>
        </div>
        <div class="filter-type-toggle-right"
             v-bind:class="{'is-active' : true,'deactivated' : isLoading}">
          <span> {{ $t('select-' + filterTypes[0]) }}</span>
        </div>
      </div>
      <span class="filter-type-info-text" v-if="$enablePresetFilter"> {{
          $t('filter_type_explanation_dialog_change')
        }} </span>

      <!-- div class="checkbox-wrapper" v-on:click="toggleHideFilterInfoCheckbox()">
        <input type="checkbox">
        <div class="checkbox">
          <i id="checkbox-info-icon" class="fas fa-check "></i>
        </div>
        <label class="dont-show-again-label">{{ $t('dont-show-notification-again') }}</label>
      </div -->
    </custom-dialog>

    <div class="egcf-header-container">
      <span class="header">Filter
        <div v-if="!disableSwitchType" class="filter-toggle-explanation-icon"
             v-on:click="showFilterInfoDialog(undefined)"><i
            class="far fa-question-circle"></i></div>
      </span>
    </div>


    <template v-if="$splitFilterThemes">

      <template v-for="category of themesToFilterWithOutSearchOrOffer">
        <div class="header" v-if="category.filterHeader">{{ category.filterHeader }}</div>
        <div class="accordion" v-if="!category.showChildrenSeparately">
          <ul>
            <themes-filter-category v-bind:category="category"
                                    v-bind:apply-filter="callApplyFilter"></themes-filter-category>
          </ul>
        </div>
        <div v-else v-for="child of category.children" class="accordion">
          <ul>
            <themes-filter-category v-bind:category="child"
                                    v-bind:apply-filter="callApplyFilter"></themes-filter-category>
          </ul>
        </div>
      </template>

      <div class="filter-type-toggle" v-if="!disableSwitchType"
           v-bind:class="themesToFilterWithOutSearchOrOffer !== undefined && themesToFilterWithOutSearchOrOffer.length > 0 ? 'filter-type-toggle-with-top-margin' : ''">
        <div class="filter-type-toggle-left"
             v-bind:class="{'is-active' : filterType === 'offer','deactivated' : isLoading}"
             v-on:click="!isLoading && hideFilterInfoNotification ? enableSearchFilterType() : !hideFilterInfoNotification ? showFilterInfoDialog(enableSearchFilterType) : () =>{}">
          <span>{{ $t('select-' + filterTypes[1]) }}</span>
        </div>
        <div class="filter-type-toggle-right"
             v-bind:class="{'is-active' : filterType === 'search','deactivated' : isLoading}"
             v-on:click="!isLoading && hideFilterInfoNotification ? enableOfferFilterType() : !hideFilterInfoNotification ? showFilterInfoDialog(enableOfferFilterType) : () =>{}">
          <span> {{ $t('select-' + filterTypes[0]) }}</span>
        </div>
      </div>


      <template v-for="category of themesToFilterWithSearchOrOffer">
        <div class="header" v-if="category.filterHeader">{{ category.filterHeader }}</div>
        <div class="accordion" v-if="!category.showChildrenSeparately">
          <ul>
            <themes-filter-category v-bind:category="category"
                                    v-bind:apply-filter="callApplyFilter"></themes-filter-category>
          </ul>
        </div>
        <div v-else v-for="child of category.children" class="accordion">
          <ul>
            <themes-filter-category v-bind:category="child"
                                    v-bind:apply-filter="callApplyFilter"></themes-filter-category>
          </ul>
        </div>
      </template>
    </template>
    <template v-else>

      <div class="filter-type-toggle" v-if="!disableSwitchType">
        <div class="filter-type-toggle-left"
             v-bind:class="{'is-active' : filterType === 'offer','deactivated' : isLoading}"
             v-on:click="!isLoading && hideFilterInfoNotification ? enableSearchFilterType() : !hideFilterInfoNotification ? showFilterInfoDialog(enableSearchFilterType) : () =>{}">
          <span>{{ $t('select-' + filterTypes[1]) }}</span>
        </div>
        <div class="filter-type-toggle-right"
             v-bind:class="{'is-active' : filterType === 'search','deactivated' : isLoading}"
             v-on:click="!isLoading && hideFilterInfoNotification ? enableOfferFilterType() : !hideFilterInfoNotification ? showFilterInfoDialog(enableOfferFilterType) : () =>{}">
          <span> {{ $t('select-' + filterTypes[0]) }}</span>
        </div>
      </div>


      <template v-for="category of themesToFilter">
        <div class="header" v-if="category.filterHeader">{{ category.filterHeader }}</div>
        <div class="accordion" v-if="!category.showChildrenSeparately">
          <ul>
            <themes-filter-category v-bind:category="category"
                                    v-bind:apply-filter="callApplyFilter"></themes-filter-category>
          </ul>
        </div>
        <div v-else v-for="child of category.children" class="accordion">
          <ul>
            <themes-filter-category v-bind:category="child"
                                    v-bind:apply-filter="callApplyFilter"></themes-filter-category>
          </ul>
        </div>
      </template>
    </template>

    <custom-check-box v-if="$enableHideUnhideUserHandling && this.type === 'recommendations'"
                      class="show-hidden-users-checkbox" v-bind:value.sync="showHiddenUsers" v-on:update:value="onHiddenUsersClicked">
      <span>{{ $t('show-hidden-users-filter') }}</span>
    </custom-check-box>


    <div class="reset-button" v-on:click="reset">
      <span> {{ $t('reset-filter') }}</span>
    </div>

  </div>

</template>

<script>
import localStorageHelper from "@/localstorage/local-storage";
import themesHelper from "@/themes/themes-helper";
import CustomCheckBox from "@/components/inputs/CustomCheckBox";
import ThemesFilterCategory from "@/themes/filter/themes-filter-category";
import backend from "@/mm-backend";
import {isDefined} from "@/utils";
import {logger} from "@/main";

export default {
  name: "themes-filter",
  components: {ThemesFilterCategory, CustomCheckBox},
  beforeMount() {
    this.filterType = localStorageHelper.getSelectedFilterType() ? localStorageHelper.getSelectedFilterType() : 'offer';
    if (this.type === 'corporates') {
      this.themesToFilter = themesHelper.getCategoriesToFilterForCompaniesByLanguage(this.$getCurrentLanguage());
    } else {
      this.themesToFilter = themesHelper.getCategoriesToFilterByLanguage(this.$getCurrentLanguage());
    }
    this.init();
    this.user = localStorageHelper.getUser();
    if (!isDefined(this.user)) {
      logger.log("Could not get User from Localstorage. Loading from backend instead.")
      const self = this;
      backend.me().then(function (resp) {
        if (resp.data.confirmed) {
          localStorageHelper.setUser(resp.data.user);
          self.user = resp.data.user;
        }
      });
    }
  },
  mounted() {
    let savedThemesFromStorage;
    if (this.type === 'recommendations') {
      this.showHiddenUsers = localStorageHelper.getCurrentHideUserSettingForRecommendations();
      savedThemesFromStorage = localStorageHelper.getSelectedFilterThemesForRecommendations();
    } else if (this.type === 'contacts') {
      savedThemesFromStorage = localStorageHelper.getSelectedFilterThemesForContacts();
    } else if (this.type === 'corporates') {
      this.filterType = 'search';
      savedThemesFromStorage = localStorageHelper.getSelectedFilterThemesForCorporates();
    }
    if ((isDefined(savedThemesFromStorage) && JSON.stringify(savedThemesFromStorage) !== '{}') || this.showHiddenUsers) {
      this.hasSavedThemes = true;
      this.applyFilterWithSavedThemes(savedThemesFromStorage)
    }
  },
  computed: {
    themesToFilterWithSearchOrOffer() {
      if (isDefined(this.themesToFilter)) {
        return this.themesToFilter.filter(category => category.hasSearchAndOffer === true);
      }
      return undefined;
    },
    themesToFilterWithOutSearchOrOffer() {
      if (isDefined(this.themesToFilter)) {
        return this.themesToFilter.filter(category => category.hasSearchAndOffer === false || !isDefined(category.hasSearchAndOffer));
      }
      return undefined;
    }
  },
  data() {
    return {
      hasSavedThemes: false,
      themesToFilter: undefined,
      user: undefined,
      showHiddenUsers: false,
      filterTypes: ['offer', 'search'],
      filterType: undefined,
      filterTypeForDialog: undefined,
      hideFilterInfoNotification: localStorageHelper.getHideFilterInfoNotification(),
      hideFilterInfoNotificationForDialog: undefined,
      onFilterInfoDialogCloseAction: undefined
    }
  },
  props: {
    applyFilter: Function,
    resetFilter: Function,
    isLoading: Boolean,
    type: String,
    disableSwitchType: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    init() {
      for (const category of this.themesToFilter)
        if (category.children) {
          this.traverseCategory(category.children, category, category);
        }
    },
    traverseCategory(children, category, parent) {
      for (const entry of children) {
        if (entry.children) {
          this.traverseCategory(entry.children, category, entry);
        } else {
          parent.hasEntries = true;
        }
      }
    },
    callApplyFilter() {
      this.saveThemes();
      this.applyFilter(this.themesToFilter, this.filterType);
    },
    saveThemes() {
      if (this.type === 'contacts') {
        localStorageHelper.setSelectedFilterThemesForContacts(JSON.stringify(this.getSelectedThemes()));
      } else if (this.type === 'recommendations') {
        localStorageHelper.setSelectedFilterThemesForRecommendations(JSON.stringify(this.getSelectedThemes()));
        localStorageHelper.setCurrentHideUserSettingForRecommendations(this.showHiddenUsers);
      }
    },
    clearThemes() {
      if (this.type === 'contacts') {
        localStorageHelper.deleteSelectedFilterThemesForContacts();
      } else if (this.type === 'recommendations') {
        localStorageHelper.deleteSelectedFilterThemesForRecommendations();
        localStorageHelper.deleteCurrentHideUserSettingForRecommendations();
      }
    },
    showFilterInfoDialog(onClose) {
      this.onFilterInfoDialogCloseAction = onClose;
      this.$refs.changeFilterTypeInfoDialog.openDialog();
    },
    onFilterInfoDialogClose() {
      // show only once
      this.hideFilterInfoNotification = true;
      localStorageHelper.setHideFilterInfoNotification();
      if (this.onFilterInfoDialogCloseAction) {
        this.onFilterInfoDialogCloseAction();
      }
    },
    enableOfferFilterType() {
      this.filterType = 'search';
      localStorageHelper.setSelectedFilterType('search');
      this.applyFilterWithUserSearchThemes();
    },
    enableSearchFilterType() {
      this.filterType = 'offer';
      localStorageHelper.setSelectedFilterType('offer');
      this.applyFilterWithUserOfferThemes();
    },
    applyFilterWithSavedThemes(savedThemes) {
      for (const category of this.themesToFilter) {
        if (savedThemes[category.id] && savedThemes[category.id].length > 0) {
          if (savedThemes[category.id].includes(category.id)) {
            category.selected = true;
          }
          this.applyFilterWithSavedThemesTraverse(category.children, savedThemes[category.id])
        }
      }
      this.$forceUpdate();
    },
    applyFilterWithSavedThemesTraverse(children, entriesToSelect) {
      for (const entry of children) {
        if (entriesToSelect.includes(entry.id)) {
          entry.selected = true;
        }
        if (entry.children) {
          this.applyFilterWithSavedThemesTraverse(entry.children, entriesToSelect);
        }
      }
    },
    applyFilterWithUserSearchThemes() {

      if (process.env.VUE_APP_FILTER_PRESET_WITH_OWN_THEMES === 'true') {
        this.resetFilterAndDisableAllCheckboxes();

        for (const category of this.themesToFilter) {
          if (category.hasSearchAndOffer) {
            if (this.user.themes[category.id] && this.user.themes[category.id].search && this.user.themes[category.id].search.length > 0) {
              if (this.user.themes[category.id].search.includes(category.id)) {
                category.selected = true;
              }
              this.applyFilterWithSavedThemesTraverse(category.children, this.user.themes[category.id].search);
            }
          }
        }
        this.$toasted.show(this.$t('filter-with-own-search-themes-applied-toast-message'), {
          duration: 2000,
          position: "top-center"
        });
      }
      this.$forceUpdate();
      this.callApplyFilter();
    },
    applyFilterWithUserOfferThemes() {
      if (process.env.VUE_APP_FILTER_PRESET_WITH_OWN_THEMES === 'true') {
        this.resetFilterAndDisableAllCheckboxes();

        for (const category of this.themesToFilter) {
          if (category.hasSearchAndOffer) {
            if (this.user.themes[category.id] && this.user.themes[category.id].offer && this.user.themes[category.id].offer.length > 0) {
              if (this.user.themes[category.id].offer.includes(category.id)) {
                category.selected = true;
              }
              this.applyFilterWithSavedThemesTraverse(category.children, this.user.themes[category.id].offer);
            }
          }
        }
        this.$toasted.show(this.$t('filter-with-own-offer-themes-applied-toast-message'), {
          duration: 2000,
          position: "top-center"
        });
      }
      this.$forceUpdate();
      this.callApplyFilter();
    },
    reset() {
      this.resetFilterAndDisableAllCheckboxes();
      this.clearThemes();
      this.resetFilter();
    },
    resetFilterAndDisableAllCheckboxes() {
      this.showHiddenUsers = false;
      for (const category of this.themesToFilter) {
        category.selected = false;
        this.disableCategory(category.children);
      }
    },
    disableCategory(children) {
      for (const entry of children) {
        entry.selected = false;
        if (entry.children) {
          this.disableCategory(entry.children);
        }
      }
    },
    getSelectedThemes() {
      const selectedThemes = {};
      for (const category of this.themesToFilter) {
        this.getSelectedThemesInCategory(category.children, category, selectedThemes, category)
      }
      return selectedThemes;
    },
    getSelectedThemesInCategory(children, category, selectedThemes, parent) {
      for (const entry of children) {
        if (entry.children) {
          this.getSelectedThemesInCategory(entry.children, entry, selectedThemes, parent);
        }
        if (entry.selected) {
          if (!selectedThemes[parent.id]) {
            selectedThemes[parent.id] = [];
          }
          selectedThemes[parent.id].push(entry.id);
        }
      }
    },
    onHiddenUsersClicked() {
      this.saveThemes();
      this.applyFilter(this.themesToFilter, this.filterType, false, this.showHiddenUsers);
    }
  }
}
</script>
