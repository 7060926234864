<template>
  <div class="event">
    <span class="event-header">{{ event.isRunning() ? translate('live-event') : translate('due-event') }}</span>
    <span class="event-name">{{ event.title }}</span>
    <span class="event-time"><i class="far fa-clock"/> {{
        formatTime(event.start, 'HH:mm')
      }} - {{ formatTime(event.end, 'HH:mm') }} {{ timezone(event.start) }}</span>


    <custom-button :icon-back-ground-color="'var(--secondary-color)'"
                   :icon="'fal fa-video fa-1x'" :width="220" :icon-width="50"
                   :onClicked="goToGroupChat">
      {{ translate('join-event') }}
    </custom-button>
  </div>
</template>

<script>
import {Event} from "@/entities/Event";
import {notificationComponentMixin} from "@/mixins/notification-component-mixin";
import {eventBus} from "@/main";
import {appointmentsMixin} from "@/mixins/appointments-mixin";
import router from "@/router";

export default {
  name: "DueEventNotification",
  mixins: [notificationComponentMixin, appointmentsMixin],
  props: {
    event: {
      required: true,
      type: Event
    }
  },
  methods: {
    goToGroupChat() {
      eventBus.$emit('remove-notification', this.event);
      router.push({name: 'Group', params: {eventId: this.event.event_id}});
    }
  }
}
</script>

<style scoped lang="less">

.event {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &-header {

  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

}

</style>
