<template>

  <div class="main appointments">

    <div class="side-header">{{ $t('appointments') }}</div>

    <div class="egcf-header-container">
      <div class="switch-view-toggle" v-if="all_appointments.length > 0">
        <div class="switch-view-toggle-element" v-for="view of appointmentViews"
             :class="activeAppointmentView === view ? 'is-active':''" v-on:click="setActiveAppointmentView(view)">
          <i v-if="view === 'calendar-view'" class="far fa-calendar-alt"/>
          <i v-if="view === 'list-view'" class="fa fa-list"/>
          <span> {{ $t(view) }}</span>
        </div>
      </div>

      <div class="block-times-button" v-if="$showReserveTimeButton && !isLoading" v-on:click="openBlockTimeDialog">
        {{ $t('reserve-time') }}
      </div>
    </div>

    <template>
      <h3 class="empty-appointments-header" v-if="!isLoading && all_appointments.length === 0">
        {{ $t('no-appointments') }}</h3>


      <div class="switch-status-type-toggle"
           v-if="activeAppointmentView !== 'calendar-view' && !isLoading && all_appointments.length > 0">
        <div class="switch-status-type-toggle-element" v-for="view of views"
             :class="activeView === view ? 'is-active':''"
             v-on:click="setActiveView(view)">
          <span>{{ $t(view) }} ({{ getAppointmentsCountForView(view) }})</span>
        </div>
      </div>

      <h3 class="empty-appointments-header"
          v-if="!isLoading && all_appointments.length > 0 && activeAppointmentView === 'list-view' && appointmentsByView.length === 0">
        {{
          activeView === 'confirmed-view' ? $t('no-confirmed-appointments') : activeView === 'received-requests-view' ? $t('no-received-requests') : $t('no-send-requests')
        }}</h3>


      <!--Calendar Start -->
      <template v-if="activeAppointmentView === 'calendar-view' && all_appointments.length > 0">

        <div class="calendar-appointment-types-setting">
          <custom-check-box v-bind:value.sync="showConfirmedAppointmentsInCalender"
                            v-on:change="setShowConfirmedAppointmentsInCalender"
                            v-bind:checkbox-text="$t('confirmed-view') + ` (${getAppointmentsCountForCalendarSetting('confirmed')})`"></custom-check-box>
          <custom-check-box v-bind:value.sync="showReceivedAppointmentsInCalender"
                            v-on:change="setShowIncomingAppointmentRequestsInCalender"
                            v-bind:checkbox-text="$t('received-requests-view') + ` (${getAppointmentsCountForCalendarSetting('received')})`"></custom-check-box>
          <custom-check-box v-bind:value.sync="showOutgoingAppointmentRequestsInCalender"
                            v-on:change="setShowOutgoingAppointmentRequestsInCalender"
                            v-bind:checkbox-text="$t('outgoing-requests-view') + ` (${getAppointmentsCountForCalendarSetting('outgoing')})`"></custom-check-box>
          <custom-check-box v-bind:value.sync="showCanceledAppointmentRequestsInCalendar"
                            v-on:change="setShowCanceledAppointmentsInCalendar"
                            v-bind:checkbox-text="$t('canceled-appointments') + ` (${getAppointmentsCountForCalendarSetting('canceled')})`"></custom-check-box>
        </div>

        <div class="calender-wrapper">

          <loading :active.sync="isLoading"
                   :is-full-page="false"
                   color="var(--primary-color)"></loading>


          <vue-cal active-view="week"
                   v-bind:hideTitleBar="true"
                   v-bind:maxDate="calendarProperties.maxDate"
                   v-bind:minDate="calendarProperties.minDate"
                   :disable-views="['years', 'year']"
                   :minCellWidth="50" :timeCellHeight="60"
                   :locale="$getCurrentLanguage()"
                   :time-from="calendarProperties.calendarTimeFrom"
                   :time-to="calendarProperties.calendarTimeTo"
                   :selected-date="calendarProperties.selectedDay"
                   :on-event-click="onCalendarEventClick"
                   :events="eventsForCalender"
                   :events-on-month-view="true">

            <template v-slot:title="{ title, view }">
              <span v-if="view.id === 'day'">{{ getDayTitle(view.startDate) }}</span>
              <span v-else>{{ title }}</span>
            </template>

            <template v-slot:no-event>{{ $t('no-appointments-calendar') }}</template>

            <template v-slot:event="{ event, view }">

              <div class="vuecal__event-title">
                <span>{{
                    event.is_canceled ? $t('canceled') + ':' : (event.is_request) ? $t('requested') + ':' : ''
                  }}</span>
                {{ event.title }}
              </div>


              <small class="vuecal__event-time">
                <!-- Using Vue Cal injected Date prototypes -->
              </small>
            </template>

          </vue-cal>
        </div>
        <!-- Color legend   -->
        <div class="color-legend">
          <div class="color-legend-request">
            <div class="color-legend-request-colour"/>
            <span>{{ $t('appointments-requests') }}</span>
          </div>
          <div class="color-legend-canceled">
            <div class="color-legend-canceled-colour"/>
            <span> {{ $t('canceled-appointments') }}</span>
          </div>
          <div class="color-legend-confirmed">
            <div class="color-legend-confirmed-colour"/>
            <span> {{ $t('confirmed-appointments') }}</span>
          </div>
          <div v-if="$showReserveTimeButton" class="color-legend-blocked">
            <div class="color-legend-blocked-colour"/>
            <span>{{ $t('blocked-appointments') }}</span>
          </div>
        </div>
      </template>
      <!-- Calendar end -->

      <div v-if="activeAppointmentView === 'list-view'" class="appointments-content vld-parent">

        <loading :active.sync="isLoading"
                 :is-full-page="false"
                 color="var(--primary-color)"></loading>


        <div class="days-container">
          <div v-for="day in days" class="day" :class="activeDay === day ? 'isActive' :'' "
               v-on:click="setActiveDay(day)">
            {{ day }}
          </div>
        </div>

        <div class="appointment-list" v-if="!isLoading && appointmentsByActiveDay.length > 0">
          <transition-group name="list" tag="div"
                            v-bind:css="false"
                            v-on:before-enter="animationBeforeEnter"
                            v-on:enter="animationEnter"
                            v-on:leave="animationLeave">

            <div class="appointment" v-for="appointment in appointmentsByActiveDay" :key="appointment.id">
              <div class="appointment-content">
                <div class="appointment-content-left">
                  <h3 class="name"> {{ appointment.name }}</h3>
                  <i class="fas fa-calendar-plus fa-2x download-calendar-icon" v-if="appointment.isConfirmed()"
                     v-on:click="downloadCalendarForAppointment(appointment)"> <span>iCal</span> </i>
                </div>

                <div class="appointment-content-center">
                  <div class="status">
                    <div class="appointment-times">
                      <span>
                        <i class="far fa-clock"/>
                        {{ formatTime(appointment.from, "HH:mm") }} - {{
                          formatTime(appointment.to, "HH:mm")
                        }} {{ timezone(appointment.from) }}
                      </span>
                    </div>
                    <span>
                      <i class="far fa-map-marker-alt"/>

                      {{ $t('place') }}:  {{ getRoomStringForAppointment(appointment) }}
                    </span>
                  </div>
                  <div class="organizer-participant-container">
                    <div class="participant-container">
                      <span>{{ $t('organizer') }}:</span>
                      <div class="participant" v-on:click="openProfileDialog(appointment.requesting_user)">
                        <span> {{ getUserById(appointment.requesting_user).fullName }}</span>
                      </div>
                    </div>
                    <div class="participant-container">
                      <span>{{ $t('participant') }}:</span>
                      <div class="participant" v-for="participant of appointment.participants"
                           v-on:click="openProfileDialog(participant.user_id)">
                        <span> {{ getUserById(participant.user_id).fullName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="description">
                <div><i class="far fa-info-circle"/>{{ appointment.description }}</div>
              </div>
              <div class="action-container"
                   :class="appointment.isConfirmed() && appointment.isOnline() ? 'action-container-online': ''">

                <div class="actions">
                  <div class="open-video-call" v-if="appointment.isConfirmed() && appointment.isOnline()"
                       v-on:click="joinOnlineAppointment(appointment)">
                    <span>{{ $t('appointments-join-online-appointment') }}</span><i class="fal fa-video fa-2x"></i>
                  </div>
                  <div class="cancel-appointment" v-on:click="openCancelAppointmentDialog(appointment)"
                       v-if="appointment.isConfirmed()">
                    <span>{{ $t('cancel-appointment') }}</span>
                  </div>
                  <div class="decline-appointment"
                       v-on:click="openCancelAppointmentDialog(appointment)"
                       v-if="appointment.isRequest() && appointment.isOutGoingRequest(user.id)">
                    <span>{{ $t('cancel-appointment') }}</span>
                  </div>
                  <div class="decline-appointment"
                       v-on:click="openRejectAppointmentDialog(appointment)"
                       v-if="appointment.isRequest() && !appointment.isOutGoingRequest(user.id)">
                    <span>{{ $t('reject-appointment') }}</span>
                  </div>
                  <div class="confirm-appointment"
                       v-on:click="openConfirmAppointmentDialog(appointment)"
                       v-if="appointment.isRequest() && !appointment.isOutGoingRequest(user.id)">
                    <span>{{ $t('confirm-appointment') }}</span>
                  </div>
                  <div class="edit-appointment"
                       v-on:click="openEditAppointmentDialog(appointment)"
                       v-if="!appointment.isCanceled()">
                    <span>{{ (appointment.isOutGoingRequest(user.id) || appointment.isConfirmed()) ? $t('edit-appointment') :  $t('edit-requested-appointment') }}</span>
                  </div>
                </div>
              </div>

            </div>
          </transition-group>
        </div>
      </div>

    </template>


    <custom-dialog ref="confirmAppointmentDialog" :id="'confirmAppointmentDialog'" :on-submit="confirmAppointment"
                   class="confirmAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                   :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
      <span class="header"> {{ $t('confirm-appointment') }} </span>
      <span> {{ $t('confirm-appointment-warning') }}</span>
    </custom-dialog>


    <custom-dialog ref="rejectAppointmentDialog" :id="'rejectAppointmentDialog'" :on-submit="rejectAppointment"
                   class="rejectAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                   :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
      <span class="header"> {{ $t('reject-appointment') }} </span>
      <span> {{ $t('reject-appointment-warning') }}</span>
    </custom-dialog>

    <custom-dialog ref="cancelAppointmentDialog" :id="'cancelAppointmentDialog'" :on-submit="cancelAppointment"
                   class="cancelAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                   :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
      <span class="header"> {{ $t('cancel-appointment') }} </span>
      <span> {{ $t('cancel-appointment-warning') }}</span>
    </custom-dialog>

    <custom-dialog ref="unblockAppointmentDialog" :id="'unblockAppointmentDialog'" :on-submit="unblockAppointment"
                   class="unblockAppointmentDialog" :submit-button-text="$t('dialog-yes')"
                   :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
      <span class="header"> {{ $t('unblock-appointment') }} </span>
      <span> {{ $t('unblock-appointment-warning') }}</span>
    </custom-dialog>


    <!-- Edit Appointment Dialog  -->
    <custom-dialog :id="'editAppointmentWithoutTablesDialog'" ref="editAppointmentWithoutTablesDialog"
                   class="createAppointmentWithoutTablesDialog" :disable-buttons="true"
                   v-bind:disable-close-button="true">
      <span class="header"> {{ $t('edit-appointment') }}</span>


      <form id="editAppointmentForm" class="editAppointmentForm" v-if="relevantAppointment"
            v-on:submit.prevent="sendChangeAppointmentRequest()">


        <custom-input-text v-bind:value.sync="relevantAppointment.name" :required="true" v-bind:max-length="50"
                           v-bind:place-holder="$t('title')"></custom-input-text>


        <custom-input-text :required="true" v-bind:value.sync="relevantAppointment.room"
                           v-if="!enableRoomAppointments"
                           v-bind:place-holder="$t('place')"></custom-input-text>
        <div class="appointment-time-duration-container" v-if="!enableRoomAppointments">

          <custom-input-text class="appointment-date-input" v-bind:required="true"
                             v-bind:value="relevantAppointment.dateString" v-on:click="showCalendarDialog(relevantAppointment,true)"
                             v-bind:place-holder="$t('date')" v-bind:read-only="true"></custom-input-text>


          <custom-input-text class="appointment-time-input" v-bind:required="true"
                             v-bind:value="relevantAppointment.timeString" v-on:click="showCalendarDialog(relevantAppointment,true)"
                             v-bind:hint="$t('time-zone-hint',{timeZone: timezone(relevantAppointment.date)})"
                             v-bind:place-holder="$t('time')" v-bind:read-only="true"></custom-input-text>


          <MBFSelect v-bind:selected-value.sync="relevantAppointment.duration"
                     v-bind:options="appointmentDurationsInMillis"
                     v-bind:no-selection-allowed="false"
                     v-bind:place-holder="$t('duration')"
                     v-bind:translate-function="translateAppointmentDuration"></MBFSelect>

        </div>





        <custom-input-text-area :required="true" v-bind:value.sync="relevantAppointment.description"
                                v-bind:place-holder="$t('description')"
                                v-bind:max-length="512"></custom-input-text-area>

        <div class="createAppointmentWithoutTablesDialogButtons">
          <custom-button :disabled="enableRoomAppointments && !relevantAppointment.timeSlot"
                         :onClicked="submitSendChangeAppointmentForm" :icon="'fal fa-chevron-right fa-2x'">
            {{ $t('edit-appointment-send')  }}
          </custom-button>
          <custom-button :onClicked="closeEditAppointmentDialog" :icon="'fal fa-chevron-right fa-2x'">
            {{ $t('cancel') }}
          </custom-button>
        </div>
        <input style="display: none" id="submitEditAppointment" hidden type="submit">
      </form>
    </custom-dialog>


    <custom-dialog ref="blockTimeDialog" :id="'blockTimeDialog'" v-bind:disable-buttons="true"
                   class="blockTimeDialog" :submit-button-text="$t('dialog-yes')"
                   :second-button-text="$t('dialog-no')" :on-second-button-submit="()=>{}">
      <span class="header"> {{ $t('reserve-time') }} </span>
      <span class="left-aligned">{{ $t('reserve-time-description', {timezone: timezone(blockedAppointment.maxDate)}) }}</span>

      <form v-on:submit.prevent="blockAppointment()" id="blockAppointmentForm" class="blockAppointmentForm">
        <custom-input-text v-bind:value.sync="blockedAppointment.name" :required="true"
                           v-bind:place-holder="$t('title')"></custom-input-text>
        <div class="appointment-time-duration-container">
          <custom-date-picker class="appointment-date-input" :required="true"
                              v-bind:value.sync="blockedAppointment.date" v-bind:min="blockedAppointment.minDate"
                              v-bind:max="blockedAppointment.maxDate" v-on:change="onBlockAppointmentDateChanged"
                              v-bind:place-holder="$t('date')"></custom-date-picker>


          <custom-time-picker class="appointment-time-input" :required="true"
                              v-bind:value.sync="blockedAppointment.from" v-bind:min="blockedAppointment.minTime"
                              v-bind:max="blockedAppointment.maxTime"
                              v-bind:place-holder="$t('from')"></custom-time-picker>

          <custom-time-picker class="appointment-time-input" :required="true"
                              v-bind:value.sync="blockedAppointment.until" v-bind:min="blockedAppointment.minTime"
                              v-bind:max="blockedAppointment.maxTime"
                              v-bind:place-holder="$t('until')"></custom-time-picker>

        </div>
        <custom-button :onClicked="submitRequestAppointmentForm" :icon="'fal fa-chevron-right fa-2x'">
          {{ $t('reserve-time') }}
        </custom-button>
        <input id="submitBlockAppointment" style="display:none;" hidden type="submit">
      </form>
    </custom-dialog>


    <custom-dialog ref="detailedCalendarEventDialog" id="detailedCalendarEventDialog"
                   class="detailedCalendarEventDialog" v-show="clickedCalendarEvent" :disable-buttons="true">
      <template v-if="clickedCalendarEvent.appointment && !clickedCalendarEvent.is_blocked">

        <span class="header"> {{ $t('appointment-details') }} </span>
        <span><strong>Name:</strong> {{ clickedCalendarEvent.title }}</span>
        <div class="participant-container" v-if="clickedCalendarEvent">
          <span><strong>{{ $t('organizer') }}:</strong></span>
          <div class="participant"
               v-on:click="openProfileDialog(clickedCalendarEvent.appointment.requesting_user)">
            <span> {{ getUserById(clickedCalendarEvent.appointment.requesting_user).fullName }}</span>
          </div>
        </div>
        <div class="participant-container"
             v-if="clickedCalendarEvent.appointment.participants && clickedCalendarEvent.appointment.participants.length > 0">
          <span><strong>{{ $t('participant') }}:</strong></span>
          <div class="participant"
               v-for="participant of clickedCalendarEvent.appointment.participants"
               v-on:click="openProfileDialog(participant.user_id)">
            <span> {{ getUserById(participant.user_id).fullName }}</span>
          </div>
        </div>
        <span
            v-if="clickedCalendarEvent.appointment.custom_room || clickedCalendarEvent.appointment.room_id">
        <strong>{{ $t('place') }}:  </strong>
         {{ getRoomStringForAppointment(clickedCalendarEvent.appointment) }}
      </span>
        <div class="start-time">
          <strong>{{ $t('from') }}: </strong>
          <span class="time">{{ formatTime(clickedCalendarEvent.appointment.from, "YYYY-MM-DD HH:mm") }} {{
              timezone(clickedCalendarEvent.appointment.from)
            }}</span>
        </div>
        <div class="end-time">
          <strong>{{ $t('until') }}: </strong>
          <span class="time">{{ formatTime(clickedCalendarEvent.appointment.to, "YYYY-MM-DD HH:mm") }} {{
              timezone(clickedCalendarEvent.appointment.to)
            }}</span>
        </div>
        <span v-if="clickedCalendarEvent.content"><strong>{{ $t('description') }}:</strong></span>
        <span>{{ clickedCalendarEvent.content }}</span>

        <custom-button v-if="clickedCalendarEvent.appointment.isOnline()"
                       :icon="'fal fa-video fa-1x'" :icon-back-ground-color="'var(--secondary-color)'"
                       :onClicked="() => {joinOnlineAppointment(clickedCalendarEvent.appointment)}">
          {{ $t('appointments-join-online-appointment') }}
        </custom-button>

      </template>
      <template v-else-if="clickedCalendarEvent.appointment">
        <span class="header"> {{ $t('blocked-appointment-details') }} </span>
        <span><strong>Name:</strong> {{ clickedCalendarEvent.title }}</span>
        <div class="start-time">
          <strong>{{ $t('from') }}: </strong>
          <span class="time"> {{ formatTime(clickedCalendarEvent.appointment.from, "YYYY-MM-DD HH:mm") }} {{
              timezone(clickedCalendarEvent.appointment.from)
            }}</span>
        </div>
        <div class="end-time">
          <strong>{{ $t('until') }}: </strong>
          <span class="time">{{ formatTime(clickedCalendarEvent.appointment.to, "YYYY-MM-DD HH:mm") }} {{
              timezone(clickedCalendarEvent.appointment.to)
            }}</span>
        </div>
      </template>
      <div class="detailed-calendar-event-dialog-action-buttons">
        <custom-button
            :onClicked="!clickedCalendarEvent.is_outgoing && clickedCalendarEvent.is_request ? ()=>{openRejectAppointmentDialog(clickedCalendarEvent.appointment)} : closeDetailedCalendarEventDialog"
            :icon="'fal fa-chevron-right fa-2x'">
          {{ !clickedCalendarEvent.is_outgoing && clickedCalendarEvent.is_request ? $t('reject-appointment') : $t('close') }}
        </custom-button>
        <custom-button v-if="!clickedCalendarEvent.is_canceled" :onClicked="onDetailedCalendarEventDialogSecondButtonClicked" :icon="'fal fa-chevron-right fa-2x'">
          {{ getSecondButtonTextForCalendarDialog() }}
        </custom-button>
        <custom-button v-if="!clickedCalendarEvent.is_blocked && !clickedCalendarEvent.is_canceled"
                       :onClicked="() => {openEditAppointmentDialog(clickedCalendarEvent.appointment)}" :icon="'fal fa-chevron-right fa-2x'">
          {{ clickedCalendarEvent.is_outgoing ? $t('edit-appointment') : $t('edit-requested-appointment') }}
        </custom-button>
      </div>
    </custom-dialog>

    <!-- Calendar Dialog -->
    <custom-dialog v-bind:disable-close-button="true" v-bind:id="'appointmentCalendarDialog'"
                   v-bind:disable-buttons="true" ref="appointmentCalendarDialog"
                   class="appointmentCalendarDialog">
      <div class="calender-wrapper">
        <span class="calendar-hint" v-html="$t('create-appointment-calendar-hint')"></span>
        <vue-cal active-view="week"
                 v-bind:editable-events="{
                         drag:true,
                         resize:true,
                         delete:false
                       }"
                 v-bind:small="true"
                 v-bind:hideViewSelector="true"
                 v-bind:hideTitleBar="true"
                 v-bind:maxDate="calendarProperties.maxDate"
                 v-bind:minDate="calendarProperties.minDate"
                 :disable-views="['years', 'year','month','day']"
                 :minCellWidth="50" :timeCellHeight="60"
                 :locale="$getCurrentLanguage()"
                 :time-from="calendarProperties.calendarTimeFrom"
                 :time-to="calendarProperties.calendarTimeTo"
                 :selected-date="calendarProperties.selectedDay"
                 :snap-to-time="calendarProperties.snapToTime"
                 :events=" [this.calendarProperties.newAppointment,...this.calendarProperties.appointments]"
                 @cell-click="onCalendarCellClicked"
                 @event-drop="onEventDrop"
                 @event-delete="onEventDelete"
                 @event-duration-change="onEventDurationChange">

          <template #cell-content="{ cell, view, events, goNarrower }">
              <span class="vuecal__cell-date" :class="view.id" v-if="view.id === 'day'" @click="goNarrower">
                {{ cell.date.getDate() }}
              </span>
            <span class="vuecal__no-event"
                  v-if="['week', 'day'].includes(view.id) && !events.length && showCellHeader(cell)"> {{
                $t('no-appointments-calendar')
              }}</span>
          </template>


          <template v-slot:event="{ event, view }">
            <div class="vuecal__event-title">
                    <span>{{ event.start.formatTime("HH:mm") }} - {{
                        event.end.formatTime("HH:mm")
                      }} {{ timezone(event.start) }}</span>
            </div>

          </template>

        </vue-cal>
        <div class="color-legend">
          <div class="color-legend-request">
            <div class="color-legend-request-colour"/>
            <span>{{ $t('appointments-requests') }}</span>
          </div>
          <div class="color-legend-new">
            <div class="color-legend-new-colour"/>
            <span>{{ $t('new-appointment') }}</span>
          </div>
          <div class="color-legend-confirmed">
            <div class="color-legend-confirmed-colour"/>
            <span> {{ $t('confirmed-appointments') }}</span>
          </div>
          <div class="color-legend-blocked">
            <div class="color-legend-blocked-colour"/>
            <span>{{ $t('blocked-appointments') }}</span>
          </div>
        </div>
        <div class="calendar-action-buttons">
          <custom-button
              :onClicked="onCalendarDialogSubmit" :icon="'fal fa-chevron-right fa-2x'">
            {{ $t('save-time') }}
          </custom-button>
          <custom-button
              :onClicked="closeAppointmentCalendarDialog" :icon="'fal fa-chevron-right fa-2x'">
            {{ $t('cancel') }}
          </custom-button>
        </div>
      </div>
    </custom-dialog>


    <custom-dialog ref="appointmentSuggestionDialog" :id="'appointmentSuggestionDialog'"
                   :disable-buttons="true" v-bind:disable-close-button="true"
                   class="appointmentSuggestionDialog" v-if="relevantAppointment">
      <span class="header"> {{ $t('appointment-suggestion-dialog-header') }} </span>
      <span v-if="relevantAppointment.timeSlots"> {{
          relevantAppointment.timeSlots.length > 1 ? $t('appointment-suggestion-dialog-text') : $t('appointment-suggestion-dialog-text-single')
        }}</span>
      <div class="timeslot-container" v-if="relevantAppointment.timeSlots" >
        <div class="timeslot" :class="relevantAppointment.timeSlot === t ? 'isSelected' :''"
             v-for="t of relevantAppointment.timeSlots" v-on:click="timeSlotClicked(t,appointmentToEdit !== undefined)">
          <span>{{ getDisplayNameForTimeSlot(t, true) }}</span>
        </div>
      </div>


      <custom-button :onClicked="() => { onAppointmentSuggestionDialogSubmit(relevantAppointment,appointmentToEdit !== undefined) }" :icon="'fal fa-chevron-right fa-2x'">
        {{  $t('request-this-suggestion') }}
      </custom-button>

      <div class="old-appointment-container">
        <span>{{ $t('old-appointment') }}</span>
        <span>{{ getDisplayNameForAppointment(relevantAppointment) }} </span>
      </div>
      <div class="action-buttons">
        <custom-button  :onClicked="()=>{forceAppointmentRequest(appointmentToEdit !== undefined) }" :icon="'fal fa-chevron-right fa-2x'">
          {{ $t('force-appointment-submit') }}
        </custom-button>
        <custom-button :onClicked="closeAppointmentSuggestionDialog" :icon="'fal fa-chevron-right fa-2x'">
          {{ $t('cancel') }}
        </custom-button>
      </div>

    </custom-dialog>



  </div>

</template>

<script>
import backend from '../../mm-backend'
import VueCal from 'eyeled-vue-cal'
import 'eyeled-vue-cal/dist/i18n/de.es.js'
import 'eyeled-vue-cal/dist/style.css'
import moment from 'moment';
import 'moment-timezone';
import {animationMixIn} from "@/mixins/animation-mixin";
import localStorageHelper from "@/localstorage/local-storage";
import {Appointment} from "@/entities/Appointment";
import {appointmentsStorage, eventBus} from "@/main";
import {appointmentsMixin} from "@/mixins/appointments-mixin";
import CustomCheckBox from "@/components/inputs/CustomCheckBox";
import {isDefined} from "@/utils";
import CustomTimePicker from "@/components/inputs/CustomTimePicker";
import CustomDatePicker from "@/components/inputs/CustomDatePicker";
import CustomInputText from "@/components/inputs/CustomInputText";
import CustomInputTextArea from '@/components/inputs/CustomInputTextArea.vue';
import MBFSelect from '@/components/inputs/MBFSelect.vue';

export default {

  name: "Appointments",
  components: {MBFSelect, CustomInputTextArea, CustomCheckBox, VueCal, CustomDatePicker, CustomTimePicker, CustomInputText},
  mixins: [animationMixIn, appointmentsMixin],
  data() {
    return {
      isLoading: true,
      confirmedAppointments: [],
      appointment_requests: [],
      all_appointments: [],
      events: [],
      users: [],
      appointmentDateFormat: 'YYYY-MM-DDTHH:mm.SSSSSSZ',
      dayFormat: 'ddd, LL',
      longDayFormat: 'dddd, LL',
      daysForConfirmedAppointments: [],
      daysForOutgoingAppointmentRequests: [],
      daysForReceivedAppointmentRequests: [],
      activeDayForConfirmedAppointments: undefined,
      activeDayForOutgoingAppointmentRequests: undefined,
      activeDayForReceivedAppointmentRequests: undefined,
      views: ['confirmed-view', 'received-requests-view', 'outgoing-requests-view'],
      activeView: 'confirmed-view',
      appointmentViews: ['calendar-view', 'list-view'],
      activeAppointmentView: 'calendar-view',
      showOutgoingAppointmentRequestsInCalender: undefined,
      showReceivedAppointmentsInCalender: undefined,
      showConfirmedAppointmentsInCalender: undefined,
      showCanceledAppointmentRequestsInCalendar: undefined,
      clickedCalendarEvent: {},
      user: undefined,
      calendarStartDate: undefined,
      blockedAppointment: {}
    }
  },
  beforeMount() {
    this.user = localStorageHelper.getUser();
    this.loadSavedViewsFromStorage();
    moment.locale(this.$getCurrentLanguage());
    eventBus.$on('reload-appointment-list', this.getAllAppointments);
    this.getAllAppointments();
  },
  mounted() {
    if (this.$route.params.openReserveTimeDialog === 'true') {
      this.openBlockTimeDialog();
    }
  },
  beforeDestroy() {
    eventBus.$off('reload-appointment-list', this.getAllAppointments);
  },
  computed: {
    activeDay() {
      switch (this.activeView) {
        case 'confirmed-view':
          return this.activeDayForConfirmedAppointments;
        case 'outgoing-requests-view':
          return this.activeDayForOutgoingAppointmentRequests
        case 'received-requests-view':
          return this.activeDayForReceivedAppointmentRequests;
      }
    },
    days() {
      switch (this.activeView) {
        case 'confirmed-view':
          return this.daysForConfirmedAppointments;
        case 'outgoing-requests-view':
          return this.daysForOutgoingAppointmentRequests
        case 'received-requests-view':
          return this.daysForReceivedAppointmentRequests;
      }
    },
    eventsForCalender() {
      let calendarEvents = [];
      calendarEvents.push(...this.createCalendarViewsFromAppointments(this.appointmentsByView));
      calendarEvents.push(...this.createCalendarViewsFromEvents());
      return calendarEvents;
    },
    appointmentsByView() {
      if (this.activeAppointmentView === 'calendar-view') {
        const appointments = [];
        if (this.showConfirmedAppointmentsInCalender) {
          appointments.push(...this.confirmedAppointments);
        }
        if (this.showReceivedAppointmentsInCalender) {
          appointments.push(...Appointment.getReceivedRequests(this.appointment_requests, this.user.id));
        }
        if (this.showOutgoingAppointmentRequestsInCalender) {
          appointments.push(...Appointment.getOutgoingRequests(this.appointment_requests, this.user.id));
        }
        if (this.showCanceledAppointmentRequestsInCalendar) {
          appointments.push(...Appointment.getCanceledAppointments(this.all_appointments));
        }
        appointments.push(...Appointment.getBlockedAppointments(this.all_appointments));
        return appointments;
      } else {
        switch (this.activeView) {
          case 'confirmed-view':
            return this.confirmedAppointments;
          case 'outgoing-requests-view':
            return Appointment.getOutgoingRequests(this.appointment_requests, this.user.id);
          case 'received-requests-view':
            return Appointment.getReceivedRequests(this.appointment_requests, this.user.id);
        }
      }
    },
    appointmentsByActiveDay() {
      return this.appointmentsByView.filter(a => moment(a.from, this.appointmentDateFormat).format(this.dayFormat) === this.activeDay);
    }
  },
  methods: {
    loadSavedViewsFromStorage() {
      const savedAppointmentView = localStorageHelper.getActiveAppointmentView();
      if (isDefined(savedAppointmentView)) {
        this.activeAppointmentView = savedAppointmentView;
      }
      const savedAppointmentState = localStorageHelper.getActiveAppointmentState();
      if (isDefined(savedAppointmentState)) {
        this.activeView = savedAppointmentState;
      }
      const savedShowOutgoingRequestInCalender = localStorageHelper.getShowOutgoingAppointmentRequestsInCalendar();
      if (isDefined(savedShowOutgoingRequestInCalender)) {
        this.showOutgoingAppointmentRequestsInCalender = savedShowOutgoingRequestInCalender === 'true';
      } else {
        this.showOutgoingAppointmentRequestsInCalender = true;
      }
      const savedShowIncomingRequestInCalender = localStorageHelper.getShowIncomingAppointmentRequestsInCalendar();
      if (isDefined(savedShowIncomingRequestInCalender)) {
        this.showReceivedAppointmentsInCalender = savedShowIncomingRequestInCalender === 'true';
      } else {
        this.showReceivedAppointmentsInCalender = true;
      }
      const savedShowConfirmedAppointmentsInCalender = localStorageHelper.getShowConfirmedAppointmentsInCalender();
      if (isDefined(savedShowConfirmedAppointmentsInCalender)) {
        this.showConfirmedAppointmentsInCalender = savedShowConfirmedAppointmentsInCalender === 'true';
      } else {
        this.showConfirmedAppointmentsInCalender = true;
      }
      const savedShowCanceledAppointmentsInCalendar = localStorageHelper.getShowCanceledAppointmentsInCalendar();
      if (isDefined(savedShowCanceledAppointmentsInCalendar)) {
        this.showCanceledAppointmentRequestsInCalendar = savedShowCanceledAppointmentsInCalendar === 'true';
      } else {
        this.showCanceledAppointmentRequestsInCalendar = true;
      }
    },
    getAllAppointments() {
      const self = this;
      this.isLoading = true;
      this.events = [];
      if (isDefined(this.$refs.detailedCalendarEventDialog) && !this.$refs.detailedCalendarEventDialog.isOpen()) {
        this.clickedCalendarEvent = {};
      }
      backend.getAllAppointments().then(resp => {
        self.postLoadAppointment(resp.data);
        self.computeCalendarProperties();
        self.isLoading = false;
      }, error => {
        self.$toasted.global.error_notification({message: self.$t('get-appointments-error')});
        self.isLoading = false;
      });
    },
    postLoadAppointments(data) {
      this.postLoadAppointment(data)
    },
    postLoadAppointment(data) {
      const appointments = data.appointments;
      const users = data.users;
      const events = data.events;
      appointmentsStorage.setAppointments(appointments);
      appointmentsStorage.setUsers(users);
      appointmentsStorage.setEvents(events);
      this.events = events;

      if (isDefined(data.rooms)) {
        appointmentsStorage.setRooms(data.rooms);
      }

      this.users = users;
      this.all_appointments = appointments;
      this.appointment_requests = Appointment.getAppointmentRequests(appointments);
      eventBus.$emit('update-appointment-requests', Appointment.getReceivedRequests(appointments, this.user.id));
      this.confirmedAppointments = Appointment.getConfirmedRequests(appointments);
      this.createDaysFromAppointments();
    },
    createCalendarViewsFromEvents() {
      const events = [];
      const minCalendarTime = moment(new Date().setHours(0, this.calendarTimeFrom, 0)).format("HH:mm");
      const maxCalendarTime = moment(new Date().setHours(0, this.calendarTimeTo, 0)).format("HH:mm");
      if (isDefined(this.events) && this.events.length > 0) {
        for (const event of this.events) {
          const eventStart = moment.utc(event.start).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("HH:mm");
          const eventEnd = moment.utc(event.end).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("HH:mm");
          const startCalendar = moment(minCalendarTime, "HH:mm");
          const startEvent = moment(eventStart, "HH:mm");
          if (moment.duration(startCalendar.diff(startEvent)).asHours() > 0) {
            this.calendarTimeFrom = startEvent.get("hours") * 60;
          }

          const endCalendar = moment(maxCalendarTime, "HH:mm");
          const endEvent = moment(eventEnd, "HH:mm");
          if (moment.duration(endCalendar.diff(endEvent)).asHours() < 0) {
            let newMaxHour = endEvent.get("hours") * 60;
            if (endEvent.get("minute") > 0) {
              newMaxHour += 60;
            }
            this.calendarTimeTo = newMaxHour;
          }

          const eventForCalendar = {
            class: 'event-calendar-view',
            title: event.title,
            content: event.info,
            start: this.formatTime(event.start, 'YYYY-MM-DD HH:mm'),
            end: this.formatTime(event.end, 'YYYY-MM-DD HH:mm'),
            event: event
          }
          events.push(eventForCalendar);
        }
      }
      return events;
    },
    createCalendarViewsFromAppointments(appointments) {
      const events = [];

      const minCalendarTime = moment(new Date().setHours(0, this.calendarTimeFrom, 0)).format("HH:mm");
      const maxCalendarTime = moment(new Date().setHours(0, this.calendarTimeTo, 0)).format("HH:mm");
      if (appointments && appointments.length > 0) {
        for (const appointment of appointments) {
          const appointmentStart = moment.utc(appointment.from).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("HH:mm");
          const appointmentEnd = moment.utc(appointment.to).tz(process.env.VUE_APP_GROUPS_TIMEZONE).format("HH:mm");


          const startCalendar = moment(minCalendarTime, "HH:mm");
          const startAppointment = moment(appointmentStart, "HH:mm");
          if (moment.duration(startCalendar.diff(startAppointment)).asHours() > 0) {
            this.calendarTimeFrom = startAppointment.get("hours") * 60;
          }

          const endCalendar = moment(maxCalendarTime, "HH:mm");
          const endAppointment = moment(appointmentEnd, "HH:mm");
          if (moment.duration(endCalendar.diff(endAppointment)).asHours() < 0) {
            let newMaxHour = endAppointment.get("hours") * 60;
            if (endAppointment.get("minute") > 0) {
              newMaxHour += 60;
            }
            this.calendarTimeTo = newMaxHour;
          }

          let cssClassForEvent;
          if (appointment.isCanceled()) {
            cssClassForEvent = 'canceled-appointment'
          } else if (appointment.isConfirmed()) {
            cssClassForEvent = 'appointment-event'
          } else if (appointment.isRequest()) {
            cssClassForEvent = 'appointment-request';
          } else if (appointment.isBlocked()) {
            cssClassForEvent = 'blocked-appointment'
          }

          const event = {
            class: cssClassForEvent,
            title: appointment.name,
            is_confirmed: appointment.isConfirmed(),
            is_request: appointment.isRequest(),
            is_canceled: appointment.isCanceled(),
            is_outgoing: appointment.isOutGoingRequest(this.user.id),
            is_blocked: appointment.isBlocked(),
            content: appointment.description,
            start: this.formatTime(appointment.from, 'YYYY-MM-DD HH:mm'),
            end: this.formatTime(appointment.to, 'YYYY-MM-DD HH:mm'),
            appointment: appointment
          }
          events.push(event);
        }
      }
      return events;
    },
    getUserById(id) {
      return this.users.find(u => +u.id === +id);
    },
    createDaysFromAppointments() {
      this.createDaysForConfirmedAppointments();
      this.createDaysForOutgoingAppointmentRequests();
      this.createDaysForReceivedAppointmentRequests();
    },
    createDaysForConfirmedAppointments() {
      let days = [];
      for (const appointment of this.confirmedAppointments) {
        const startDay = moment.utc(appointment.from, this.appointmentDateFormat).format(this.dayFormat);
        if (!days.includes(startDay)) {
          days.push(startDay);
        }
      }

      this.daysForConfirmedAppointments = days.sort((a, b) => moment(new Date(a)).format('YYYYMMDD') - moment(new Date(b)).format('YYYYMMDD'));
      const savedDayFromStorage = localStorageHelper.getActiveDayForConfirmedAppointments();
      let findDay;
      if (savedDayFromStorage) {
        findDay = this.daysForConfirmedAppointments.find(d => d === savedDayFromStorage);
      } else {
        const today = moment().format(this.dayFormat);
        findDay = this.daysForConfirmedAppointments.find(d => d === today);
      }
      this.activeDayForConfirmedAppointments = isDefined(findDay) ? findDay : this.daysForConfirmedAppointments[0];
    },
    createDaysForReceivedAppointmentRequests() {
      let days = [];
      for (const appointment of Appointment.getReceivedRequests(this.appointment_requests, this.user.id)) {
        const startDay = moment.utc(appointment.from, this.appointmentDateFormat).format(this.dayFormat);
        if (!days.includes(startDay)) {
          days.push(startDay);
        }
      }

      this.daysForReceivedAppointmentRequests = days.sort((a, b) => moment(new Date(a)).format('YYYYMMDD') - moment(new Date(b)).format('YYYYMMDD'));
      const savedDayFromStorage = localStorageHelper.getActiveDayForReceivedAppointments();
      let findDay;
      if (savedDayFromStorage) {
        findDay = this.daysForReceivedAppointmentRequests.find(d => d === savedDayFromStorage);
      } else {
        const today = moment().format(this.dayFormat);
        findDay = this.daysForReceivedAppointmentRequests.find(d => d === today);
      }
      this.activeDayForReceivedAppointmentRequests = isDefined(findDay) ? findDay : this.daysForReceivedAppointmentRequests[0];
    },
    createDaysForOutgoingAppointmentRequests() {
      let days = [];
      for (const appointment of Appointment.getOutgoingRequests(this.appointment_requests, this.user.id)) {
        const startDay = moment.utc(appointment.from, this.appointmentDateFormat).format(this.dayFormat);
        if (!days.includes(startDay)) {
          days.push(startDay);
        }
      }

      this.daysForOutgoingAppointmentRequests = days.sort((a, b) => moment(new Date(a)).format('YYYYMMDD') - moment(new Date(b)).format('YYYYMMDD'));
      const savedDayFromStorage = localStorageHelper.getActiveDayForOutgoingAppointments();
      let findDay;
      if (savedDayFromStorage) {
        findDay = this.daysForOutgoingAppointmentRequests.find(d => d === savedDayFromStorage);
      } else {
        const today = moment().format(this.dayFormat);
        findDay = this.daysForOutgoingAppointmentRequests.find(d => d === today);
      }
      this.activeDayForOutgoingAppointmentRequests = isDefined(findDay) ? findDay : this.daysForOutgoingAppointmentRequests[0];
    },
    setActiveDay(day) {
      switch (this.activeView) {
        case 'confirmed-view':
          this.activeDayForConfirmedAppointments = day;
          localStorageHelper.setActiveDayForConfirmedAppointments(day);
          break;
        case 'outgoing-requests-view':
          this.activeDayForOutgoingAppointmentRequests = day;
          localStorageHelper.setActiveDayForOutgoingAppointments(day);
          break;
        case 'received-requests-view':
          this.activeDayForReceivedAppointmentRequests = day;
          localStorageHelper.setActiveDayForReceivedAppointments(day);
          break;
      }
    },
    setActiveView(view) {
      localStorageHelper.setActiveAppointmentState(view);
      this.activeView = view;
    },
    setActiveAppointmentView(view) {
      localStorageHelper.setActiveAppointmentView(view);
      this.activeAppointmentView = view;
    },
    setShowCanceledAppointmentsInCalendar(value) {
      localStorageHelper.setShowCanceledAppointmentsInCalendar(value);
    },
    setShowOutgoingAppointmentRequestsInCalender(value) {
      localStorageHelper.setShowOutgoingAppointmentRequestsInCalender(value);
    },
    setShowIncomingAppointmentRequestsInCalender(value) {
      localStorageHelper.setShowIncomingAppointmentRequestsInCalender(value);
    },
    setShowConfirmedAppointmentsInCalender(value) {
      localStorageHelper.setShowConfirmedAppointmentsInCalender(value);
    },
    openProfileDialog(userId) {
      const userData = this.getUserById(userId);
      eventBus.$emit('open-profile-dialog', userData, false);
    },
    closeDetailedCalendarEventDialog() {
      this.$refs.detailedCalendarEventDialog.closeDialog();
    },
    onDetailedCalendarEventDialogSecondButtonClicked() {
      this.closeDetailedCalendarEventDialog();
      if (isDefined(this.clickedCalendarEvent)) {
        if (this.clickedCalendarEvent.is_confirmed || this.clickedCalendarEvent.is_outgoing) {
          if (this.clickedCalendarEvent.is_blocked) {
            this.openUnblockAppointmentDialog(this.clickedCalendarEvent.appointment);
          } else {
            this.openCancelAppointmentDialog(this.clickedCalendarEvent.appointment);
          }
        } else if (this.clickedCalendarEvent) {
          this.openConfirmAppointmentDialog(this.clickedCalendarEvent.appointment);
        }
      }
    },
    onCalendarEventClick(event) {
      if (isDefined(event.event)) {
        this.$router.push({name: 'Group', params: {eventId: event.event.event_id}});
      }
      this.clickedCalendarEvent = event;
      this.$refs.detailedCalendarEventDialog.openDialog(event.appointment);
    },
    getSecondButtonTextForCalendarDialog() {
      if (this.clickedCalendarEvent.is_canceled) {
        return undefined;
      }

      if (this.clickedCalendarEvent.is_blocked) {
        return this.$t('unblock-appointment')
      }

      if (this.clickedCalendarEvent.is_confirmed || this.clickedCalendarEvent.is_outgoing) {
        return this.$t('cancel-appointment');
      }

      return this.$t('confirm-appointment')
    },
    getAppointmentsCountForView(view) {
      switch (view) {
        case 'confirmed-view':
          return this.confirmedAppointments.length;
        case 'outgoing-requests-view':
          return Appointment.getOutgoingRequests(this.appointment_requests, this.user.id).length;
        case 'received-requests-view':
          return Appointment.getReceivedRequests(this.appointment_requests, this.user.id).length;
      }
    },
    getAppointmentsCountForCalendarSetting(setting) {
      switch (setting) {
        case 'received':
          return Appointment.getReceivedRequests(this.appointment_requests, this.user.id).length;
        case 'canceled':
          return Appointment.getCanceledAppointments(this.all_appointments).length;
        case 'outgoing':
          return Appointment.getOutgoingRequests(this.appointment_requests, this.user.id).length;
        case 'confirmed':
          return this.confirmedAppointments.length;
      }
    },
    openBlockTimeDialog() {
      this.resetBlockedAppointment();
      this.$refs.blockTimeDialog.openDialog();
    },
    onBlockAppointmentDateChanged() {
      const rooms = appointmentsStorage.getRooms();
      if (isDefined(rooms) && rooms.length > 0 && isDefined(this.blockedAppointment.date)) {
        const openHours = rooms[0].open_hours;
        const openHoursForDay = openHours.filter(o => o.from.includes(this.blockedAppointment.date)) // date is string value
        if (!isDefined(openHoursForDay) || openHoursForDay.length === 0) {
          return;
        }
        let minTime = moment.utc(openHoursForDay[0].from, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        let maxTime = moment.utc(openHoursForDay[0].to, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
        for (let openHour of openHoursForDay) {
          let min = moment.utc(openHour.from, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
          let max = moment.utc(openHour.to, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE);
          if (min < minTime) {
            minTime = min;
          }
          if (max > maxTime) {
            maxTime = max
          }
        }
        this.blockedAppointment.minTime = minTime.format('HH:mm');
        this.blockedAppointment.maxTime = maxTime.format('HH:mm');
      }
    },
    resetBlockedAppointment() {
      let date = new Date().format("YYYY-MM-DD");
      let startHour = 8;
      let startMinutes = 0;
      let endHour = 9;
      let endMinutes = 0;
      let minDate = date;
      let maxDate = date;
      const rooms = appointmentsStorage.getRooms();
      if (isDefined(rooms) && rooms.length > 0) {
        const openHour = rooms[0].open_hours[0];
        const lastOpenHour = rooms[0].open_hours[rooms[0].open_hours.length - 1];
        date = moment.utc(openHour.from, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('YYYY-MM-DD');
        minDate = moment.utc(openHour.from, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('YYYY-MM-DD');
        maxDate = moment.utc(lastOpenHour.to, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('YYYY-MM-DD');
        startHour = moment.utc(openHour.from, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('H');
        startMinutes = moment.utc(openHour.from, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('m');
        endHour = moment.utc(openHour.to, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('H');
        endMinutes = moment.utc(openHour.to, 'YYYY-MM-DD HH:mm:ss').tz(process.env.VUE_APP_GROUPS_TIMEZONE).format('m');
      }
      this.blockedAppointment = {
        date: date,
        from: startHour * 60 * 60 * 1000 + (startMinutes * 60 * 1000),
        until: endHour * 60 * 60 * 1000 + (endMinutes * 60 * 1000),
        name: undefined,
        minDate: date,
        maxDate: maxDate
      }
      this.onBlockAppointmentDateChanged();
    },
    submitRequestAppointmentForm() {
      document.getElementById('submitBlockAppointment').click();
    },
    blockAppointment() {
      const duration = this.blockedAppointment.until - this.blockedAppointment.from;
      if (duration <= 0) {
        this.$toasted.global.error_notification({message: this.$t('selected-time-error')});
        return;
      }

      if (!isDefined(this.blockedAppointment.name)) {
        this.$toasted.global.error_notification({message: this.$t('selection-error-mandatory', {title: this.$t('title')})});
        return;
      }

      const self = this;
      const date = moment.tz(this.blockedAppointment.date, process.env.VUE_APP_GROUPS_TIMEZONE).add(this.blockedAppointment.from, 'milliseconds').utc().format('YYYY-MM-DD HH:mm');
      backend.blockAppointment(this.blockedAppointment.name, duration, date).then(resp => {
        if (isDefined(resp.data.error)) {
          self.$toasted.global.error_notification({message: resp.data.error.message});
        } else {
          self.postLoadAppointment(resp.data);
          self.$refs.blockTimeDialog.closeDialogFromOutside();
          self.$toasted.global.success_notification({message: self.$t('appointment-blocked')});
        }
      });
    },
    getDayTitle(date) {
      return moment(date).format(this.longDayFormat);
    }
  },
}
</script>

<style lang="less" scoped>

.appointment-list {
  margin-top: 30px;
}

.empty-appointments-header, .empty-appointments-requests-header {
  margin-top: 48px;
  color: var(--primary-color);
  padding-left: 0;
  font-weight: 400;
  font-size: 20px;
}

.calendar-appointment-types-setting {
  display: flex;
  flex-direction: row;

  .checkbox-wrapper {
    margin-right: 16px;
  }
}

.calender-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-height: 700px;
  margin-top: 32px;

}


.switch-view-container {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 16px;

  .button {
    padding: 6px 16px;
    margin-right: 32px;
    border: 1px solid var(--border-color);
    color: var(--font-color);
    cursor: pointer;
  }

  .is-active {
    background-color: var(--secondary-color);
    color: white;
  }

}

@media (max-width: 600px) {

  .actions {
    margin-top: 32px !important;
  }

  .cancel-appointment, .confirm-appointment, .decline-appointment {
    padding: 5px 10px !important;
    height: 35px !important;
  }

  .appointment {
    .status {
      width: 50% !important;
    }

    .participant-container {
      flex: none !important;
      flex-direction: column;

      .participant {
        padding: 6px !important;
        margin: 0 !important;
      }
    }


    span, p {
      font-size: 12px !important;
      word-break: break-word;
      min-width: 130px;
    }
  }

  .calendar-appointment-types-setting {
    .checkbox-wrapper {
      margin-bottom: 6px;
    }

    flex-direction: column;
  }

  .color-legend {
    flex-direction: column;

    .color-legend-blocked-colour, .color-legend-canceled-colour, .color-legend-request-colour, .color-legend-confirmed-colour {
      margin-left: 0;
    }

    .color-legend-confirmed, .color-legend-request, .color-legend-blocked, .color-legend-canceled {
      margin-bottom: 12px;
    }

  }

  .switch-status-type-toggle {
    flex-direction: column !important;
    border: none !important;
    height: 150px !important;

    &-element {
      height: 50px;
      width: 100% !important;
      border: 1px solid var(--border-color);
    }

    &-element:nth-child(2) {
      border-top: none !important;
      border-bottom: none !important;
    }
  }
}

.switch-status-type-toggle {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--border-color);
  margin-bottom: 64px;
  height: 45px;
  width: 66%;
  align-self: flex-start;

  &-element {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-element:nth-child(1), &-element:nth-child(2) {
    border-right: 1px solid var(--border-color);
  }

  .is-active {
    color: #FFFFFF;
    background-color: var(--secondary-color);
  }
}


.egcf-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .block-times-button {
    width: 200px;
    height: 46px;
    background: var(--secondary-color);
    padding: 12px;
    color: white;
    cursor: pointer;
    border: none;
  }

  .switch-view-toggle {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--border-color);
    margin-bottom: 20px;
    height: 45px;
    width: 250px;
    align-self: flex-start;

    &-element {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      > i {
        padding-right: 6px;
        padding-bottom: 2px;
      }
    }

    .is-active {
      color: #FFFFFF;
      background-color: var(--secondary-color);
    }
  }
}

.participant-container:first-of-type {
  margin-bottom: 0px;
}

.participant-container {
  display: flex;
  align-items: baseline;

  > span {
    min-width: 120px;
    text-align: left;
  }

  .participant {
    background-color: var(--secondary-color);
    padding: 6px;
    cursor: pointer;
    margin: 5px 10px;

    span {
      color: #FFFFFF;
      font-size: 16px;
      letter-spacing: 0.24px;
      line-height: 24px;
    }
  }
}

.appointments-content {
  width: 100%;
  min-height: 200px;
  height: 100%;


  .days-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .day {
      padding: 6px 16px;
      margin-right: 32px;
      border: 1px solid var(--border-color);
      color: var(--font-color);
      cursor: pointer;
    }

    .isActive {
      background-color: var(--secondary-color);
      color: white;
    }
  }
}

.blockTimeDialog {

  .blockAppointmentForm {
    width: 100%;
    margin-top: 16px;
  }

  .appointment-time-duration-container {
    display: flex;
    width: 100%;
    flex-direction: row;

    .appointment-date-input, .appointment-time-input {
      margin-right: 12px;
    }

    .appointment-time-input:last-of-type {
      margin-right: 0;
    }
  }
}

.detailedCalendarEventDialog {
  word-break: break-word;
  text-align: left;

  .dialog-content {
    .custom-button {
      margin-bottom: 24px;
    }

    .detailed-calendar-event-dialog-action-buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

</style>
